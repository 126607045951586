import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export function createfournisseur(tab1, total) {
  var tabBody = [];
  tabBody.push([
    { text: "Ref Fournisseur", style: "tableHeader" },
    { text: "Articles", style: "tableHeader" },
    { text: "Prix", style: "tableHeader" },
  ]);
  for (const art of tab1) {
    tabBody.push(art);
  }
  tabBody.push([
    {
      colSpan: 3,
      text: "Total :" + total + " €",
      alignment: "right",
    },
  ]);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  var dd = {
    content: [
      {
        style: "tableExample",
        table: {
          headerrows: 1,
          widths: [150, "*", 100],
          body: tabBody,
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };
  pdfMake.createPdf(dd).open();
}
