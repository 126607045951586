<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/depot')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text-gray-500">
      <caption
        class="text-xl bg-orangevk w-full font-semibold text-white pl-4 py-2"
      >
        Reçu
      </caption>
      <thead class="text-xs bg-orangevk text-white uppercase bg-gray-50">
        <tr>
          <th scope="col" class="px-6 py-3">Fournisseur</th>
          <th scope="col" class="px-6 py-3">Receveur</th>
          <th scope="col" class="px-6 py-3">Référence</th>
          <th scope="col" class="px-6 py-3">Quantité</th>
          <th scope="col" class="px-6 py-3">EAN</th>
          <th scope="col" class="px-6 py-3">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b"
          v-for="(item, index) in archivedTab"
          :key="index"
        >
          <td class="px-6 py-4">{{ item.plateform_provider }}</td>
          <td class="px-6 py-4">{{ item.plateform_receiver }}</td>
          <td class="px-6 py-4">
            {{ item.Prefixe_tarif }} - {{ item.Ref_fournisseur }}
          </td>
          <td class="px-6 py-4">{{ item.quantity }}</td>
          <td class="px-6 py-4">{{ item.Code_EAN }}</td>
          <td class="px-6 py-4">{{ item.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getArchivedTransfert } from "@/hooks/stock/transfertStock";
import { mapGetters } from "vuex";

export default {
  name: "TransfertArchived",
  data() {
    return {
      archivedTab: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  async mounted() {
    this.archivedTab = await getArchivedTransfert(this.user.proprietaire);
  },
};
</script>

<style></style>
