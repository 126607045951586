<template>
  <!-- modal modif mode reglement -->
  <div
    :class="`modal ${
      !showModifDoc && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showModifDoc = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">
            Mode de règlement {{ tabModifDoc.type }} n°{{ tabModifDoc.Numero }}
          </p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showModifDoc = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Modal Detail Articles -->
        <div class="m-auto mt-2 bg-gray-200 p-2 rounded shadow-lg z-20">
          <!--Body-->

          <div class="flex justify-end mb-1">
            <div class="flex">
              <label>Montant total TTC : </label>
              <label class="ml-1"
                >{{ parseFloat(setTotalTTC).toFixed(2) }} €</label
              >
            </div>
          </div>

          <div
            class="flex justify-center mt-6"
            v-if="
              tabModifDoc.status != 'Comptabilisée' &&
              tabModifDoc.status != 'Archivée'
            "
          >
            <div
              v-for="(mode, index) in modePaiement"
              :key="index"
              class="mode-space-x"
            >
              <div class="flex flex-col">
                <button
                  class="flex space-x-4 rounded-md shadow p-2 w-24"
                  @click="setReglement(mode.type)"
                >
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    :src="mode.image"
                  />
                </button>
                <h4 class="text-sm m-auto">{{ mode.type }}</h4>
              </div>
            </div>
          </div>

          <table class="min-w-full">
            <div class="overflow-y-auto h-[20rem] max-h-[20rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Type
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Date
                  </th>
                  <th
                    class="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Montant payé
                  </th>
                </tr>
              </thead>

              <tbody v-if="tabModifDoc" class="bg-white w-fit overflow-y-auto">
                <tr
                  v-for="(reg, index) in tabModifDoc.reglement"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-col">
                      <div>
                        <input
                          type="image"
                          v-model="tabModifDoc.reglement[index].img"
                          class="w-12 border border-black rounded-md focus:outline-2 outline-sky-300"
                          v-bind:src="reg.img"
                        />
                      </div>
                      <div>
                        <span
                          class="focus:outline-2 outline-sky-300"
                          style="font-size: 8px"
                        >
                          {{ reg.placeholder }}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <!-- v-model="tabModifDoc.reglement[index].date" -->
                    <input
                      class="text-sm leading-5 w-4/5 p-2 text-sky-600 border focus:outline-2 outline-sky-300 rounded-md"
                      type="datetime-local"
                      step="1"
                      :disabled="
                        tabModifDoc.status === 'Comptabilisée' ||
                        tabModifDoc.status === 'Archivée'
                      "
                      v-model="tabModifDoc.reglement[index].date"
                      @change="saveModif(tabModifDoc._id, tabModifDoc)"
                    />
                    <!-- @input="updateDate(reg, $event.target.value)" -->
                  </td>

                  <td
                    class="px-3 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex justify-between">
                      <div>
                        <input
                          v-if="reg.placeholder === 'Reste dû'"
                          class="leading-5 p-2 text-sky-600 border focus:outline-2 outline-sky-300 rounded-md"
                          style="width: 60%"
                          type="number"
                          :value="parseFloat(setRestant).toFixed(2)"
                          @input="updateResteDuModel($event, index)"
                          @change="saveReglements(index)"
                          :disabled="
                            tabModifDoc.status === 'Comptabilisée' ||
                            tabModifDoc.status === 'Archivée'
                          "
                        />
                        <input
                          v-else
                          class="leading-5 p-2 text-sky-600 border focus:outline-2 outline-sky-300 rounded-md"
                          style="width: 60%"
                          type="number"
                          v-model="tabModifDoc.reglement[index].model"
                          :disabled="
                            tabModifDoc.status === 'Comptabilisée' ||
                            tabModifDoc.status === 'Archivée'
                          "
                          @change="saveReglements(index)"
                        />
                        <span class="py-3">€</span>
                      </div>
                      <div
                        class="py-2 ml-12 mt-0.5"
                        v-if="
                          tabModifDoc.status != 'Comptabilisée' &&
                          tabModifDoc.status != 'Archivée' &&
                          tabModifDoc.reglement[index].placeholder != 'Reste dû'
                        "
                      >
                        <span
                          class="material-icons-outlined text-xl text-red-600 cursor-pointer rounded-full px-0.5 py-0.25 w-6 h-6 hover:bg-red-300 hover:bg-opacity-25"
                          @click="deleteLine(index)"
                        >
                          clear
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </div>
          </table>
          <!-- <div
            class="flex flex-col border-t border-gray-900"
            v-if="tabModifDoc"
          >
            <div
              class="flex justify-end text-red-700"
              v-if="tabModifDoc.reglement"
            >
              <div class="w-36 px-6 py-4">
                <span class="focus:outline-2 outline-sky-300"> Reste dû </span>
              </div>
              <div class="w-56 px-6 py-4">
                <label>{{ parseFloat(setRestant).toFixed(2) }} €</label>
              </div>
            </div>

            <div
              class="flex justify-end text-gray-900"
              v-if="tabModifDoc.reglement"
            >
              <div class="w-36 px-6 py-4">
                <span
                  class="focus:outline-2 outline-sky-300"
                  style="font-size: 10px"
                >
                  Rendu
                </span>
              </div>
              <div class="w-56 px-6 py-4">
                <label>{{ parseFloat(setRendu).toFixed(2) }} €</label>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div
          class="flex justify-end"
          v-if="
            tabModifDoc.status != 'Comptabilisée' &&
            tabModifDoc.status != 'Archivée'
          "
        >
          <button
            class="mt-4 p-2 bg-blue-500 hover:bg-opacity-25 text-white rounded shadow"
            @click="saveModif(tabModifDoc._id, tabModifDoc)"
          >
            Modifier
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- Fin Modif Mode Reglement -->

  <!-- Back Button -->
  <div class="flex justify-between">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/caisse'" />
    </div>
  </div>
  <!-- Fin Back Button -->

  <div class="mt-6">
    <!-- Rechercher -->
    <div>
      <button
        v-if="!expandFilter"
        class="flex text-bluevk space-x-10 justify-between bg-gray-100 rounded-md shadow border px-2 py-0.5 w-fit"
        @click="expandFilter = true"
      >
        <p>Filtrer par</p>
        <span class="material-icons-round my-auto">expand_more</span>
      </button>
      <button
        v-else
        class="flex text-bluevk space-x-10 justify-between bg-gray-100 rounded-t px-2 py-0.5 w-fit"
        @click="expandFilter = false"
      >
        <p>Filtrer par</p>
        <span class="material-icons-round my-auto">expand_less</span>
      </button>

      <div
        v-if="expandFilter"
        class="grid grid-cols-6 gap-x-12 gap-y-4 bg-gray-100 rounded-tr-md rounded-b-md shadow mb-2 p-3 w-fit"
        @keyup.enter="getDocuments(1, 10)"
      >
        <div class="flex flex-col">
          <label for="searchIn" class="text-sm">Rechercher dans </label>
          <select
            list="searchIn"
            v-model="searchIn"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          >
            <option value="commandes et factures">
              Bon de commande et facture
            </option>
            <option value="facture">Facture</option>
            <option value="commandes">Bon de commande</option>
          </select>
        </div>

        <div class="flex flex-col">
          <label for="searchClient" class="text-sm">Nom du client</label>
          <input
            list="searchClient"
            type="text"
            v-model="searchClient"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchClient = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchNumF" class="text-sm">Numéro de facture</label>
          <input
            list="searchNumF"
            type="text"
            v-model="searchNumF"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchNumF = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchNumBC" class="text-sm"
            >Numéro de bon de commande</label
          >
          <input
            list="searchNumBC"
            type="text"
            v-model="searchNumBC"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchNumBC = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchTotalHT" class="text-sm">Total HT</label>
          <input
            list="searchTotalHT"
            type="text"
            v-model="searchTotalHT"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchTotalHT = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchTotalTTC" class="text-sm">Total TTC</label>
          <input
            list="searchTotalTTC"
            type="text"
            v-model="searchTotalTTC"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchTotalTTC = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchDate" class="text-sm">Date de début</label>
          <input
            list="searchDate"
            type="datetime-local"
            v-model="searchDate"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchDate = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchDateFin" class="text-sm">Date de fin</label>
          <input
            list="searchDateFin"
            type="datetime-local"
            v-model="searchDateFin"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchDateFin = ''"
          />
        </div>

        <div class="flex flex-col">
          <label for="searchMode" class="text-sm">Mode de règlement</label>
          <select
            list="searchMode"
            v-model="searchMode"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          >
            <option value=""></option>
            <option value="LCR">LCR</option>
            <option value="Chèque Comptant">Chèque Comptant</option>
            <option value="Carte Bancaire">Carte Bancaire</option>
            <option value="Espèce">Espèce</option>
            <option value="Virement">Virement</option>
            <option value="Reste dû">Reste dû</option>
            <option value="Rendu">Rendu</option>
          </select>
        </div>

        <div class="flex flex-col">
          <label for="searchDateR" class="text-sm">Date de règlement</label>
          <input
            list="searchDateR"
            type="date"
            v-model="searchDateR"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            @click="searchDateR = ''"
          />
        </div>

        <div class="my-auto">
          <button
            v-if="!loadingSearch"
            class="w-36 bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit hover:border-transparent rounded mr-1 flex justify-center items-center"
            @click="getDocuments(1, 10)"
          >
            <span class="material-icons-outlined text-xl mr-2"> search </span>
            <span>Rechercher</span>
          </button>
          <button
            v-else
            class="w-36 bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit hover:border-transparent rounded mr-1 flex justify-center items-center"
          >
            <PulseLoader color="#ffff" class="mr-2" />
            <span>En cours </span>
          </button>
        </div>
      </div>
    </div>

    <!-- documents -->
    <div class="mt-4">
      <div
        class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <div
          class="inline-block min-w-full overflow-hidden align-middle border border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex space-x-4">
                    <input
                      type="checkbox"
                      class="focus:outline-2 outline-sky-300"
                      v-model="selectAll"
                      :value="true"
                      @click="selectAllDoc"
                    />
                    <span>Règlement</span>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Statut</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>TTC</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro / Date</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in documents"
                :key="index"
                class="cursor-pointer hover:bg-gray-100"
              >
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <div v-for="reglement in u.reglement" :key="reglement">
                      <div
                        v-if="reglement.placeholder == 'Reste dû'"
                        class="flex justify-between"
                      >
                        <h1>
                          {{ reglement.placeholder }} : {{ reglement.model }} €
                        </h1>
                        <span
                          @click="openModalDetail(u)"
                          class="material-icons-outlined text-2xl my-auto text-cyan-600 hover: text-cyan-500 animate-pulse cursor-pointer"
                        >
                          payments
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-col" v-if="u.reglementBanque">
                      <div
                        v-for="reglementB in u.reglementBanque"
                        :key="reglementB"
                      >
                        <div class="flex space-x-2">
                          <input
                            type="checkbox"
                            class="focus:outline-2 outline-sky-300"
                            v-model="reglementB.value"
                            :checked="selectAll"
                            @click="changeReglement(u, reglementB)"
                          />
                          <h1 class="my-auto w-36">{{ reglementB.moyen }}</h1>
                          <div
                            class="flex space-x-2"
                            @change="saveModif(u._id, u)"
                          >
                            <input
                              type="number"
                              step="0.01"
                              class="text-green-500 text-2xl focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-green-500 rounded-md"
                              v-model="reglementB.totalTTC"
                            />
                            <input
                              type="datetime-local"
                              class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
                              :value="displayedDate(reglementB.date)"
                              @input="
                                updateDate(reglementB, $event.target.value)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <select
                    v-model="u.status"
                    :class="getStatusColorClass(u.status)"
                    class="border rounded-md shadow focus:outline-2 outline-sky-300"
                    @change="saveModif(u._id, u)"
                  >
                    <option class="text-blue-400" value="Éditée">Éditée</option>
                    <option class="text-blue-500" value="Émise">Émise</option>
                    <option class="text-red-600" value="Rejetée">
                      Rejetée
                    </option>
                    <option class="text-blue-600" value="Reçue">Reçue</option>
                    <option class="text-red-600" value="Refusée">
                      Refusée
                    </option>
                    <option class="text-orange-600" value="Suspendue">
                      Suspendue
                    </option>
                    <option class="text-green-400" value="Paiement en cours">
                      Paiement en cours
                    </option>
                    <option class="text-green-500" value="Encaissée">
                      Encaissée
                    </option>
                    <option class="text-green-600" value="Comptabilisée">
                      Comptabilisée
                    </option>
                    <option class="text-cyan-600" value="Archivée">
                      Archivée
                    </option>
                  </select>
                </td>

                <td
                  v-if="u.TotalPriceTTC"
                  class="text-2xl text-orangevk px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <h1>{{ formatPrice(u.TotalPriceTTC) }} €</h1>
                </td>
                <td
                  v-else
                  class="text-2xl text-orangevk px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ formatPrice(u.TotalPrice + u.TotalPrice * 0.2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 text-sm border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <h1>{{ u.type }} n° {{ u.Numero }}</h1>
                    <h1>
                      {{ getFRDate(u.Date) }}
                    </h1>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    >{{ u.Name }}</span
                  >
                  <div class="text-sm leading-5 text-gray-900">
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                      >{{ u.Client }}</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="documentsLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(documentsLength / 10)"
            :clickFunction="getDocuments"
            :currentPage="currentPage"
            :typeList="'documents'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pageNumber from "@/components/pageNumber.vue";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import { getFrDate, getDateToISO } from "@/hooks/tools/date.ts";
import backButton from "@/components/Button/back.vue";

export default {
  name: "MyEncaissement",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      expandFilter: true,
      loadingSearch: false,

      searchIn: "facture",
      searchClient: "",
      searchNumF: "",
      searchNumBC: "",
      searchTotalHT: "",
      searchTotalTTC: "",
      searchDate: "",
      searchDateFin: "",
      searchMode: "",
      searchDateR: "",

      modePaiement: [
        {
          type: "LCR",
          image: require("@/assets/icons_payments/LCR.png"),
        },
        {
          type: "Espèce",
          image: require("@/assets/icons_payments/ESP.png"),
        },
        {
          type: "Virement",
          image: require("@/assets/icons_payments/Virement.png"),
        },
        {
          type: "Carte Bancaire",
          image: require("@/assets/icons_payments/CB.png"),
        },
        {
          type: "Chèque Comptant",
          image: require("@/assets/icons_payments/CHQ.png"),
        },
        {
          type: "Reste dû",
          image: require("@/assets/icons_payments/resteDu.png"),
        },
        {
          type: "Rendu",
          image: require("@/assets/icons_payments/rendu.png"),
        },
      ],

      documents: [],
      documentsLength: 0,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,

      showModifDoc: false,
      tabModifDoc: [],

      selectAll: false,
      allStatus: "",

      statusColors: {
        Éditée: "text-blue-400",
        Émise: "text-blue-500",
        Rejetée: "text-red-600",
        Reçue: "text-blue-600",
        Refusée: "text-red-600",
        Suspendue: "text-orange-600",
        "Paiement en cours": "text-green-400",
        Encaissée: "text-green-500",
        Comptabilisée: "text-green-600",
        Archivée: "text-cyan-600",
      },
    };
  },
  components: {
    pageNumber,
    PulseLoader,
    backButton,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    setTotalTTC() {
      return this.tabModifDoc.TotalPrice + this.tabModifDoc.TotalPrice * 0.2;
    },
    setRestant() {
      var paye = 0;
      var restant = 0;
      this.tabModifDoc.reglement.forEach((element) => {
        if (
          element.placeholder != "Reste dû" &&
          element.placeholder != "Rendu"
        ) {
          paye = paye + parseFloat(element.model);
        }
      });

      restant = parseFloat(parseFloat(this.setTotalTTC).toFixed(2)) - paye;
      if (restant < 0) {
        restant = 0;
      }

      return restant;
    },
    setRendu() {
      var paye = 0;
      var rendu = 0;
      this.tabModifDoc.reglement.forEach((element) => {
        if (
          element.placeholder != "Rendu" &&
          element.placeholder != "Reste dû"
        ) {
          paye = paye + parseFloat(element.model);
        }
      });

      if (paye > parseFloat(parseFloat(this.setTotalTTC).toFixed(2))) {
        rendu = Math.abs(
          paye - parseFloat(parseFloat(this.setTotalTTC).toFixed(2))
        );
      }
      return rendu;
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getDateISO(date) {
      return getDateToISO(date);
    },
    getDocuments(skipValue, limitValue) {
      this.documents = [];
      this.documentsLength = 0;
      this.loadingSearch = true;
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/facture/searchDocMoneyBox`, {
          plateform: this.user.proprietaire,
          searchIn: this.searchIn,
          nomClient: this.searchClient,
          numBC: this.searchNumBC,
          numF: this.searchNumF,
          date: new Date(this.searchDate),
          dateF: new Date(this.searchDateFin),
          mode: this.searchMode,
          dateR: this.searchDateR,
          totalHT: this.searchTotalHT,
          totalTTC: this.searchTotalTTC,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data.tab);
          this.documents = response.data.tab;
          this.documentsLength = response.data.length;
          this.loadingSearch = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingSearch = false;
        });
    },
    // jeux d'affichage
    getStatusColorClass(status) {
      return this.statusColors[status];
    },
    displayedDate(fullDate) {
      if (
        !fullDate ||
        fullDate === "1970-01-01T00:00:00.000+00:00" ||
        fullDate === "1970-01-01T00:00:00.000Z" ||
        fullDate === "1970-01-01T00:00"
      ) {
        return "";
      } else {
        // console.log(fullDate);
        // console.log(this.getFRDate(fullDate));
        return this.getFRDate(fullDate);
      }
    },
    updateDate(line, value) {
      console.log("line : ", line);
      console.log("value : ", value);
      line.date = value;
    },
    formatPrice(price) {
      if (!price) return "";
      let priceString = parseFloat(price).toFixed(2).toString();
      let [integerPart, decimalPart] = priceString.split(".");
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return `${integerPart}.${decimalPart}`;
    },
    // tout sélectionner
    selectAllDoc() {
      for (const line of this.documents) {
        const allValuesTrue = line.reglementBanque.every(
          (reg) => reg.value === true
        );

        if (this.selectAll && allValuesTrue) {
          for (const reg of line.reglementBanque) {
            reg.value = true;
            this.changeReglement(line, reg);
          }
        } else {
          for (const reg of line.reglementBanque) {
            reg.value = false;
            this.changeReglement(line, reg);
          }
        }
      }
    },
    // modal encaissement
    openModalDetail(data) {
      this.tabModifDoc = data;
      this.showModifDoc = true;
    },
    deleteLine(index) {
      console.log(
        "placeholder : ",
        this.tabModifDoc.reglement[index].placeholder
      );
      console.log("model : ", this.tabModifDoc.reglement[index].model);
      console.log("date : ", this.tabModifDoc.reglement[index].date);
      console.log(this.tabModifDoc.reglementBanque);
      const indRegB = this.tabModifDoc.reglementBanque.findIndex(
        (item) =>
          item.moyen === this.tabModifDoc.reglement[index].placeholder &&
          item.totalTTC === this.tabModifDoc.reglement[index].model
      );
      console.log(this.tabModifDoc.reglementBanque[indRegB]);
      this.tabModifDoc.reglementBanque.splice(indRegB, 1);
      this.tabModifDoc.reglement.splice(index, 1);

      const indReste = this.tabModifDoc.reglement.findIndex(
        (item) => item.placeholder === "Reste dû"
      );

      this.tabModifDoc.reglement[indReste].model = parseFloat(
        this.setRestant
      ).toFixed(2);

      this.saveModif(this.tabModifDoc._id, this.tabModifDoc);
    },
    updateResteDuModel(value, index) {
      this.$set(this.tabModifDoc.reglement, index, {
        ...this.tabModifDoc.reglement[index],
        model: parseFloat(value).toFixed(2),
      });
    },
    // enregistrement banque
    async changeReglement(line, reg) {
      if (reg.value === false) {
        var dateOriginale = new Date();
        var dd = String(dateOriginale.getDate()).padStart(2, "0");
        var mm = String(dateOriginale.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = dateOriginale.getFullYear();
        var hh = dateOriginale.getHours();
        var ss = dateOriginale.getSeconds();
        var mmm = dateOriginale.getMinutes();
        var dateISO8601 =
          yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + mmm + ":" + ss;

        reg.value = true;
        reg.date = new Date(dateISO8601);
      } else {
        reg.value = false;
        reg.date = "1970-01-01T00:00:00.000+00:00";
      }

      // Vérifie si chaque value du tableau reglementBanque est true
      const allValuesTrue = line.reglementBanque.every(
        (reg) => reg.value === true
      );

      if (allValuesTrue) {
        line.status = "Comptabilisée";
      } else {
        line.status = "Encaissée";
      }

      await this.saveModif(line._id, line);
    },
    // enregistrement encaissement
    async setReglement(method) {
      var dateOriginale = new Date();
      console.log("dateOriginale : ", dateOriginale);
      // Extraction des composants de la date
      var dd = String(dateOriginale.getDate()).padStart(2, "0");
      var mm = String(dateOriginale.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = dateOriginale.getFullYear();
      var hh = dateOriginale.getHours();
      var ss = dateOriginale.getSeconds();
      var mmm = dateOriginale.getMinutes();
      var dateISO8601 =
        yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + mmm + ":" + ss;
      console.log("DATE ISO : ", dateISO8601);

      // const formattedDate =
      //   `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      //     .toString()
      //     .padStart(2, "0")}-${currentDate
      //     .getDate()
      //     .toString()
      //     .padStart(2, "0")}T` +
      //   `${currentDate.getHours().toString().padStart(2, "0")}:${currentDate
      //     .getMinutes()
      //     .toString()
      //     .padStart(2, "0")}:${currentDate
      //     .getSeconds()
      //     .toString()
      //     .padStart(2, "0")}.${currentDate
      //     .getMilliseconds()
      //     .toString()
      //     .padStart(3, "0")}` +
      //   `${offsetHours >= 0 ? "+" : "-"}${Math.abs(offsetHours)
      //     .toString()
      //     .padStart(2, "0")}:00`;

      const existingEntry = this.tabModifDoc.reglement.find(
        (entry) => entry.placeholder === method
      );

      if (!existingEntry) {
        switch (method) {
          case "Reste dû":
          case "Rendu":
            this.tabModifDoc.reglement.unshift({
              img: require(`@/assets/icons_payments/${
                method === "Reste dû" ? "resteDu" : "rendu"
              }.png`),
              icon: "",
              placeholder: method,
              model: parseFloat(this.setRestant).toFixed(2),
              date: dateISO8601,
            });
            break;
        }
      }

      switch (method) {
        case "Espèce":
          this.tabModifDoc.reglementBanque.push({
            value: false,
            moyen: method,
            totalTTC: parseFloat(this.setRestant).toFixed(2),
            date: "1970-01-01T00:00:00.000+00:00",
          });
          this.tabModifDoc.reglement.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: dateISO8601,
          });
          break;
        case "Carte Bancaire":
          this.tabModifDoc.reglementBanque.push({
            value: false,
            moyen: method,
            totalTTC: parseFloat(this.setRestant).toFixed(2),
            date: "1970-01-01T00:00:00.000+00:00",
          });
          this.tabModifDoc.reglement.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: dateISO8601,
          });
          break;
        case "Chèque Comptant":
          this.tabModifDoc.reglementBanque.push({
            value: false,
            moyen: method,
            totalTTC: parseFloat(this.setRestant).toFixed(2),
            date: "1970-01-01T00:00:00.000+00:00",
          });
          this.tabModifDoc.reglement.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: dateISO8601,
          });
          break;
        case "Virement":
          this.tabModifDoc.reglementBanque.push({
            value: false,
            moyen: method,
            totalTTC: parseFloat(this.setRestant).toFixed(2),
            date: "1970-01-01T00:00:00.000+00:00",
          });
          this.tabModifDoc.reglement.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: dateISO8601,
          });
          break;
        case "LCR":
          this.tabModifDoc.reglementBanque.push({
            value: false,
            moyen: method,
            totalTTC: parseFloat(this.setRestant).toFixed(2),
            date: "1970-01-01T00:00:00.000+00:00",
          });
          this.tabModifDoc.reglement.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: dateISO8601,
          });
          break;
      }

      const indReste = this.tabModifDoc.reglement.findIndex(
        (item) => item.placeholder === "Reste dû"
      );

      this.tabModifDoc.reglement[indReste].model = parseFloat(
        this.setRestant
      ).toFixed(2);

      if (this.setRestant < 0.01) {
        this.tabModifDoc.status = "Encaissée";
      }

      await this.saveModif(this.tabModifDoc._id, this.tabModifDoc);

      // if (this.setRendu >= 0.01) {
      //   console.log("on ajoute un rendu");
      //   var today = new Date();
      //   var dd = String(today.getDate()).padStart(2, "0");
      //   var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      //   var yyyy = today.getFullYear();
      //   today = yyyy + "-" + mm + "-" + dd;
      //   this.tabModifDoc.reglement.push({
      //     img: require("@/assets/icons_payments/rendu.png"),
      //     icon: "",
      //     placeholder: "Rendu",
      //     model: parseFloat(this.setRendu).toFixed(2),
      //     date: today,
      //   });
      // }
    },
    saveReglements(index) {
      // if (index - 1<0) {
      this.tabModifDoc.reglementBanque[index - 1].totalTTC =
        this.tabModifDoc.reglement[index].model;
      // }

      const indReste = this.tabModifDoc.reglement.findIndex(
        (item) => item.placeholder === "Reste dû"
      );

      this.tabModifDoc.reglement[indReste].model = parseFloat(
        this.setRestant
      ).toFixed(2);

      this.saveModif(this.tabModifDoc._id, this.tabModifDoc);
    },
    saveDateReglements(index) {
      this.tabModifDoc.reglement[index].date = new Date(
        this.tabModifDoc.reglement[index].date
      );
      console.log(this.tabModifDoc[index].date);
    },
    saveModif(id, data) {
      axios.put(`${process.env.VUE_APP_API}/facture/${id}`, data).then(() => {
        this.success("Enregistré !");
      });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getDocuments(1, 10);
    this.pageSwitch();
  },
};
</script>
