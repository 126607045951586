import axios from "axios";

export async function getStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Code_marque: string
) {
  const stock = await axios.post(
    `${process.env.VUE_APP_API}/infoArticle/stockEANArticle`,
    {
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
    }
  );
  return stock.data;
}

export async function getAddressArticle(plateform: string) {
  const addressArticle = await axios.post(`${process.env.VUE_APP_API}/stock/`, {
    plateform: plateform,
  });
  return addressArticle.data;
}

export async function getStockFiliale(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Code_marque: string
) {
  const filialeStock = await axios.post(
    `${process.env.VUE_APP_API}/infoArticle/stockEANArticleGroupement`,
    {
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
    }
  );
  return filialeStock.data;
}

export async function getStockFournisseur(
  article: object,
  Ref_fournisseur: string,
  Code_marque: string,
  Prefixe_tarif: string,
  logCode: number,
  Quantity: number,
  login: string,
  plateform: string
) {
  const fournisseurStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/getFournisseurDispo`,
    {
      article: article,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Prefixe_tarif: Prefixe_tarif,
      logCode: logCode,
      Quantity: Quantity,
      login: login,
      plateform: plateform,
    }
  );
  return fournisseurStock.data;
}

export async function updateStock(
  Code_EAN: string,
  stock: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string,
  min: number,
  max: number
) {
  const updateStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/updateStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        min: min,
        max: max,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return updateStock.data;
}
export async function setStock(
  Code_EAN: string,
  stock: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const setStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/setStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return setStock.data;
}
// export async function reserveStock(
//   id: string,
//   Code_EAN: string,
//   stock: number,
//   reservedClient: number,
//   plateform: string,
//   Ref_fournisseur: string,
//   Prefixe_tarif: string,
//   Code_marque: string,
//   User: string,
//   Description: string,
//   client: string,
//   numeroDoc: string
// ) {
//   const reserveStock = await axios.post(
//     `${process.env.VUE_APP_API}/stock/reserveStock`,
//     {
//       plateform: plateform,
//       dataUpdate: {
//         id: id,
//         stock: stock,
//         reservedClient: reservedClient,
//         Ref_fournisseur: Ref_fournisseur,
//         Prefixe_tarif: Prefixe_tarif,
//         Code_EAN: Code_EAN,
//         Code_marque: Code_marque,
//       },
//       dataMvt: {
//         User: User,
//         Description: Description,
//         client: client,
//         numeroDoc: numeroDoc,
//       },
//     }
//   );

//   return reserveStock.data;
// }
export async function incrementStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  Code_marque: string,
  stock: number,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const incrementStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/incrementStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return incrementStock.data;
}
export async function decrementStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  Code_marque: string,
  stock: number,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const decrementStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/decrementStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return decrementStock.data;
}

export async function mvStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  User: string,
  Description: string,
  OldStock: number,
  NewStock: number
) {
  const mvStock = await axios.post(`${process.env.VUE_APP_API}/mvStock`, {
    plateform: plateform,
    Date: new Date(),
    ArticleRef: Ref_fournisseur,
    ArticleEAN: Code_EAN,
    Prefixe_tarif: Prefixe_tarif,
    Depot: plateform,
    User: User,
    Description: Description,
    OldStock: OldStock,
    NewStock: NewStock,
  });

  return mvStock.data;
}

export async function esstock(
  plateform: string,
  dataUpdate: Array<object>,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string,
  userID: string
) {
  const esstock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/esstock`,
    {
      plateform: plateform,
      Date: new Date(),
      dataUpdate: dataUpdate,
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );

  return esstock.data;
}

export async function importStock(
  plateform: string,
  dataUpdate: Array<object>,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string,
  userID: string
) {
  const importStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/importStock`,
    {
      plateform: plateform,
      Date: new Date(),
      dataUpdate: dataUpdate,
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );

  return importStock.data;
}

export async function stockMiseANiveau(
  plateform: string,
  dataUpdate: Array<object>,
  inventaireID: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const stockMiseANiveau = await axios.post(
    `${process.env.VUE_APP_API}/stock/StockMiseANiveau`,
    {
      plateform: plateform,
      dataUpdate: dataUpdate,
      inventaireID: inventaireID,
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return stockMiseANiveau.data;
}

export async function importEsStock(
  plateform: string,
  dataUpdate: Array<object>
) {
  const importEsStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/importEsStock`,
    {
      plateform: plateform,
      dataUpdate: dataUpdate,
    }
  );

  return importEsStock.data;
}

export async function setAdresse(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  adresse: string
) {
  const setAdresse = await axios.post(
    `${process.env.VUE_APP_API}/stock/setAdresse`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
      adresse: adresse,
    }
  );

  return setAdresse.data;
}

export async function checkStock(
  plateform: string,
  Ref_fournisseur: string,
  article_id: string,
  Code_marque: string,
  Prefixe_tarif: string,
  quantity: number,
  userID: string
) {
  const checkStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/checkStock`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      article_id: article_id,
      Code_marque: Code_marque,
      Prefixe_tarif: Prefixe_tarif,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );

  return checkStock.data;
}
// Enlever stock et réserver
export async function decrementStockIncrementReserved(
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const decrementStockIncrementReserved = await axios.post(
    `${process.env.VUE_APP_API}/stock/decrementStockIncrementReserved`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return decrementStockIncrementReserved.data;
}
// Enlever réservé et ajouter en stock
export async function decrementReservedStock(
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const decrementReservedStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/decrementReservedStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return decrementReservedStock.data;
}
// Enlever du stock reservé
export async function incrementStockDecrementReserved(
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const incrementStockDecrementReserved = await axios.post(
    `${process.env.VUE_APP_API}/stock/incrementStockDecrementReserved`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return incrementStockDecrementReserved.data;
}

// NEW !!!

// Récupère tout le stock de la plateforme avec un skip et limit
export async function getStocks(
  user_id: string,
  plateform: string,
  skip: number,
  limit: number,
  sort_by: string,
  sort_order: string
) {
  const getStocks = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/getStocks`,
    {
      plateform: plateform,
      skip: skip,
      limit: limit,
      sort_by: sort_by,
      sort_order: sort_order,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return getStocks.data;
}

// Récupère le stock par EAN
export async function scanStock(
  user_id: string,
  plateform: string,
  Code_EAN: string,
  Code_marque: string,
  Prefixe_tarif: string
) {
  const scanStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/scanStock`,
    {
      plateform: plateform,
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      Prefixe_tarif: Prefixe_tarif,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return scanStock.data;
}

// Récupère le stock par Référence et Marque
export async function findStock(
  user_id: string,
  plateform: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Prefixe_tarif: string
) {
  const findStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/findStock`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Prefixe_tarif: Prefixe_tarif,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return findStock.data;
}

// Donne le stock et le stock filiale à partir de la quantité demandée
export async function checkStockByRefBrand(
  user_id: string,
  plateform: string,
  Ref_fournisseur: string,
  Code_marque: string,
  quantity: number
) {
  const checkStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/checkStock`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return checkStock.data;
}

// Créer le stock s'il n'existe pas sinon on update (on connait le stock)
export async function updateOrCreateStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: string,
  doc_type: string,
  movement_type: string
) {
  const updateStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/updateStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return updateStock.data;
}

// Créer le stock s'il n'existe pas sinon on update (on ne connait pas le stock, on déduit quantity du stock)
export async function updateStockByQuantity(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: string,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const updateStockByQuantity = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/updateStockByQuantity`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return updateStockByQuantity.data;
}

// Ajout stock
export async function addStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: string,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const addStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/addStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return addStock.data;
}

// Supprimer stock
export async function removeStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: string,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const removeStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/removeStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return removeStock.data;
}

// Modifie l'adresse de l'article dans le stock
export async function updateAddress(
  user_id: string,
  _id: string,
  address: string,
  user: string
) {
  const updateAddress = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/updateAddress`,
    {
      _id: _id,
      address: address,
      user: user,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return updateAddress.data;
}

// Résèrve le stock
export async function reserveStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const reserveStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/reserveStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return reserveStock.data;
}

// Enlève du stock réservé
export async function unreserveStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const unreserveStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/unreserveStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return unreserveStock.data;
}

// Récupère un réservé
export async function getReserved(
  user_id: string,
  stock_id: string,
  bc_number: string
) {
  const getReserved = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/getReserved`,
    {
      stock_id: stock_id,
      bc_number: bc_number,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return getReserved.data;
}

// Récupère tout les réservés
export async function getAllReserved(
  user_id: string,
  plateform: string,
  skip: number,
  limit: number,
  sort_by: string,
  sort_order: string
) {
  const getAllReserved = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/getAllReserved`,
    {
      plateform: plateform,
      skip: skip,
      limit: limit,
      sort_by: sort_by,
      sort_order: sort_order,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return getAllReserved.data;
}

// Modifie le stock réservé
export async function updateReservation(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const updateReservation = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/updateReservation`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return updateReservation.data;
}

// Ajoute dans commande fournisseur
export async function backorderStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const backorderStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/backorderStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return backorderStock.data;
}

// Enlève de commande fournisseur
export async function unbackorderStock(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const unbackorderStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/unbackorderStock`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return unbackorderStock.data;
}

// Récupère un commande fournisseur
export async function getBackorder(
  user_id: string,
  stock_id: string,
  order_number: string
) {
  const getBackorder = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/getBackorder`,
    {
      stock_id: stock_id,
      order_number: order_number,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return getBackorder.data;
}

// Annuler une commande
export async function cancelOrder(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const cancelOrder = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/cancelOrder`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return cancelOrder.data;
}

// Reprendre une commande
export async function resumeOrder(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const resumeOrder = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/resumeOrder`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return resumeOrder.data;
}

// Récupère tout les commandes fournisseur
export async function getAllBackorder(
  user_id: string,
  plateform: string,
  skip: number,
  limit: number,
  sort_by: string,
  sort_order: string
) {
  const getAllBackorder = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/getAllBackorder`,
    {
      plateform: plateform,
      skip: skip,
      limit: limit,
      sort_by: sort_by,
      sort_order: sort_order,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return getAllBackorder.data;
}

// Modifie le stock commande fournisseur
export async function updateBackorder(
  user_id: string,
  plateform: string,
  article_id: string,
  Ref_fournisseur: string,
  Code_marque: string,
  Code_EAN: string,
  stock: number,
  min: number,
  max: number,
  address: string,
  user: string,
  Description: string,
  Prefixe_tarif: string,
  client: string,
  doc_number: number,
  doc_type: string,
  movement_type: string,
  quantity: number
) {
  const updateBackorder = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/updateBackorder`,
    {
      plateform: plateform,
      article_id: article_id,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Code_EAN: Code_EAN,
      stock: stock,
      min: min,
      max: max,
      address: address,
      user: user,
      Description: Description,
      Prefixe_tarif: Prefixe_tarif,
      client: client,
      doc_number: doc_number,
      doc_type: doc_type,
      movement_type: movement_type,
      quantity: quantity,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return updateBackorder.data;
}

export async function checkEmptyLines(
  user_id: string,
  plateform: string,
  data: Array<object>
) {
  const checkEmptyLines = await axios.post(
    `${process.env.VUE_APP_STOCK}/stock/checkEmptyLines`,
    {
      plateform: plateform,
      data: data,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return checkEmptyLines.data;
}
