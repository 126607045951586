<template>
  <div>
    <div class="mt-8">
      <span class="flex justify-center text-xl font-bold"
        >Comptoir Session</span
      >
    </div>
    <div class="flex flex-row justify-end space-x-2">
      <span
        class="material-icons-round text-blue-500 text-3xl cursor-pointer"
        @click="getallcomptoirsession()"
      >
        refresh
      </span>
    </div>
    <!-- Detail Modal -->
    <div
      class="fixed inset-0 flex items-center justify-center z-50"
      v-if="showDetail"
    >
      <div
        class="absolute w-screen h-screen top-0 inset-0 bg-black opacity-50"
        @click="CloseShowDetail()"
      ></div>
      <div class="bg-white rounded-lg shadow-lg p-6 max-w-4xl relative z-10">
        <div class="flex items-center justify-between pb-3">
          <p class="text-lg font-medium">Panier</p>
          <div class="cursor-pointer" @click="CloseShowDetail()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="overflow-y-auto max-h-[25rem]">
          <table class="min-w-full mt-3">
            <thead class="bg-gray-50 tableHEADTR">
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="px-6 py-3 w-[24rem] max-w-24 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total HT
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr
                v-for="(art, ind) in detailTab.actionArticle"
                :key="ind"
                class="tableHEADTR"
              >
                <!-- ref -->
                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <p>{{ art.Ref_fournisseur }}</p>
                </td>

                <!-- desc -->
                <td
                  class="px-6 max-w-24 w-[24rem] border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <p
                    class="overflow-y-auto text-sm leading-5 whitespace-normal w-[24rem] max-w-24"
                  >
                    {{ art.Description }}
                  </p>
                </td>

                <!-- qté -->
                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <p>{{ art.quantity }}</p>
                </td>

                <!-- p.v -->
                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <p>{{ financial(art.Prix_euro) }} €</p>
                </td>

                <!-- remise -->
                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <p v-if="art.PrixFournisseurType != 'prixnet'">
                    {{ art.remise }} %
                  </p>
                  <p v-else>prix net</p>
                </td>

                <!-- px final -->
                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="art.PrixFournisseurType != 'prixnet'"
                    >{{
                      financial(
                        art.Prix_euro - art.Prix_euro * (art.remise / 100)
                      )
                    }}
                    €
                  </span>
                  <span v-else>
                    <span> {{ art.Prix_vente }} €</span>
                  </span>
                </td>

                <!-- total ht -->
                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="art.PrixFournisseurType != 'prixnet'">
                    {{
                      financial(
                        (art.Prix_euro - art.Prix_euro * (art.remise / 100)) *
                          art.quantity *
                          0.2 +
                          (art.Prix_euro - art.Prix_euro * (art.remise / 100)) *
                            art.quantity
                      )
                    }}
                    €
                  </span>
                  <span v-else>
                    {{
                      financial(
                        parseFloat(art.Prix_vente) * art.quantity * 0.2 +
                          parseFloat(art.Prix_vente) * art.quantity
                      )
                    }}
                    €
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pt-4">
          <div class="flex justify-end space-x-6">
            <div class="text-sm">
              <div class="flex space-x-2">
                <p>Total HT :</p>
                <p>{{ financial(parseFloat(setTotal)) }}</p>
              </div>
              <div class="flex space-x-2">
                <p>Total TTC :</p>
                <p>{{ financial(parseFloat(setTotalTTC)) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Detail Modal -->

    <div class="flex justify-center grid grid-cols-3 m-auto gap-y-6">
      <div v-for="(session, index) in comptoirSession" :key="index">
        <div
          class="relative flex bg-gray-100 space-x-4 border-2 rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-green-500"
          @click="openComptoirSession(session)"
          v-if="session.actif"
        >
          <div class="absolute right-0 h-fit mr-3 mt-2">
            <span
              class="material-icons-round text-red-500 cursor-pointer hover:text-red-400 text-[21px]"
              @click.stop="removecomptoirsession(session)"
              title="Supprimer la session"
            >
              cancel
            </span>
          </div>
          <div
            class="mx-2 my-4 w-24 h-24 bg-white rounded-full grid place-items-center border-2 border-green-500"
          >
            <span class="text-blue-600 text-4xl">{{ session.Numero }}</span>
          </div>
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> {{ session.Client[0].Name }} </span>
              <span class="text-gray-600 pt-2 w-full">
                {{ session.refClient }}
              </span>
              <div class="flex flex-row">
                <span class="text-gray-600">
                  <span class="text-blue-500">{{
                    session.actionArticle.length
                  }}</span>
                  article en cours </span
                ><span
                  class="material-icons-outlined text-blue-500 cursor-pointer hover:text-blue-400 text-xl ml-2"
                  @click.stop="OpenShowDetail(session)"
                  title="Détail des articles"
                >
                  info
                </span>
              </div>
              <!-- <button
                class="bg-red-500 text-white rounded shadow"
                @click="removecomptoirsession(session)"
              >
                Supprimer
              </button> -->
            </div>
          </div>
        </div>
        <div
          class="relative flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-gray-600"
          v-else
          @click="openComptoirSession(session)"
        >
          <div class="absolute right-0 h-fit mr-3 mt-2">
            <span
              class="material-icons-round text-red-500 cursor-pointer hover:text-red-400 text-[21px]"
              @click.stop="removecomptoirsession(session)"
              title="Supprimer la session"
            >
              cancel
            </span>
          </div>

          <div
            class="mx-2 my-4 w-24 h-24 bg-white rounded-full grid place-items-center border border-gray-600"
          >
            <span class="text-blue-600 text-4xl">{{ session.Numero }}</span>
          </div>

          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> {{ session.Client[0].Name }} </span>
              <span class="text-gray-600 pt-2 w-full">
                {{ session.refClient }}
              </span>
              <div class="flex flex-row">
                <span class="text-gray-600">
                  <span class="text-blue-500">{{
                    session.actionArticle.length
                  }}</span>
                  article en cours </span
                ><span
                  class="material-icons-outlined text-blue-500 cursor-pointer hover:text-blue-400 text-xl ml-2"
                  @click="OpenShowDetail(session)"
                  title="Détail des articles"
                >
                  info
                </span>
              </div>
              <!-- <button
                class="bg-red-500 text-white rounded shadow"
                @click="removecomptoirsession(session)"
              >
                Supprimer
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import actionClientVue from "./actionClient.vue";
export default {
  name: "comptoirSession",
  data: () => ({
    showDetail: false,
    detailTab: {},
  }),
  computed: {
    ...mapGetters(["comptoirSession"]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totalRemiseGen = 0.0;
      var totaldebut = 0.0;

      if (this.detailTab.actionArticle.length > 0) {
        this.detailTab.actionArticle.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            totalRemise = parseFloat(element.Prix_vente);
          } else {
            totalRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) *
                (this.detailTab.actionArticle[index].remise / 100);
          }
          totaldebut =
            totaldebut +
            totalRemise * this.detailTab.actionArticle[index].quantity;
          this.totalDebutFrais = totaldebut;
        });

        if (
          this.detailTab.Client[0].professionnel == true &&
          (this.detailTab.documentType === "Devis" ||
            this.detailTab.documentType === "Facture")
        ) {
          if (parseFloat(this.detailTab.francodeport) >= 0) {
            if (parseFloat(this.detailTab.totalPanier) > 0.0) {
              if (
                parseFloat(this.detailTab.totalPanier) + totaldebut <
                parseFloat(this.detailTab.francodeport)
              ) {
                this.setfraisdeport({
                  value: this.detailTab.fraisdeportsave,
                });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              }
            } else {
              if (
                parseFloat(totaldebut) < parseFloat(this.detailTab.francodeport)
              ) {
                this.setfraisdeport({
                  value: this.detailTab.fraisdeportsave,
                });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total += totaldebut;
              }
            }
          } else {
            total = totaldebut;
          }
        } else {
          total = totaldebut;
        }
      } else {
        total += totaldebut;
      }

      if (
        this.detailTab.documentType === "Devis" ||
        this.detailTab.documentType === "Facture"
      ) {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) * (this.detailTab.remisegenerale / 100) +
          parseFloat(this.detailTab.fraisdeportsave);
      } else {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) * (this.detailTab.remisegenerale / 100);
      }

      if (totaldebut >= 0) {
        if (
          this.detailTab.remisegenerale &&
          this.detailTab.remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          return total;
        }
      } else {
        if (
          this.detailTab.remisegenerale &&
          this.detailTab.remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          this.setfraisdeport({ value: "0" });
          return totaldebut;
        }
      }
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  methods: {
    ...mapActions([
      "getallcomptoirsession",
      "createcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
    ]),
    openComptoirSession(session) {
      this.setcomptoirsession(session);
      // window.location.href = `/actionclient`;
      this.$router.push("/actionClient");
    },
    OpenShowDetail(session) {
      this.detailTab = session;
      this.showDetail = true;
    },
    CloseShowDetail() {
      this.showDetail = false;
    },

    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.getallcomptoirsession();
  },
};
</script>
