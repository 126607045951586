<template>
  <div id="app" class="container mx-auto">
    <!-- Formulaire pour ajouter une nouvelle tâche -->

    <!-- Formulaire pour ajouter une nouvelle tâche -->
    <div class="flex justify-end">
      <div class="flex space-x-8 bg-white p-2 rounded-md shadow-lg">
        <div class="my-auto">
          <label for="newTaskTime" class="my-auto mr-2">Heure :</label>
          <input
            type="time"
            v-model="newTaskTime"
            id="newTaskTime"
            placeholder="HH:MM"
            class="border rounded-md shadow outline-2 focus:outline-sky-300 py-0.5 px-2"
          />
        </div>

        <div class="my-auto">
          <label for="newTaskActivity" class="my-auto mr-2">Commentaire :</label>
          <input
            type="text"
            v-model="newTaskActivity"
            id="newTaskActivity"
            placeholder="Commentaire"
            class="border rounded-md shadow outline-2 focus:outline-sky-300 py-0.5 px-2"
          />
        </div>

        <button
          @click="addTask('Lundi', 0)"
          class="bg-bluevk hover:bg-blue-500 text-white px-4 py-2 rounded-md shadow"
        >
          Ajouter
        </button>
      </div>
    </div>

    <div class="flex">
      <!-- Colonne pour les heures -->
      <div class="flex-none">
        <div
          v-for="(hour, indexH) in hours"
          :key="indexH"
          class="bg-white p-2 mb-2 rounded-md shadow-lg border"
        >
          <p class="text-lg font-semibold text-bluevk">{{ hour }}</p>
        </div>
      </div>

      <!-- Colonne pour chaque jour -->
      <div v-for="(day, dayIndex) in days" :key="dayIndex" class="flex-1 ml-4">
        <h2 class="text-xl font-semibold mb-2">{{ day.name }}</h2>
        <div
          v-for="(task, taskIndex) in day.tasks"
          :key="taskIndex"
          :style="{ gridRow: getTimeIndex(task.time) }"
          class="bg-gray-200 p-4 mb-4 rounded"
        >
          <p class="text-lg font-semibold">
            {{ task.time }} - {{ task.activity }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hours: [
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00"
      ],
      days: [
        {
          name: "",
          tasks: [],
        },
        // ... Ajoutez les autres jours de la semaine
      ],
      newTaskTime: "",
      newTaskActivity: "",
    };
  },
  methods: {
    addTask(day, dayIndex) {
      if (this.newTaskTime && this.newTaskActivity) {
        const indexOfHour = this.hours.indexOf(this.newTaskTime);

        console.log("indexOfHour ", indexOfHour);

        console.log("dayIndex", dayIndex);

        this.days[dayIndex].tasks.splice(indexOfHour, 0, {
          time: this.newTaskTime,
          activity: this.newTaskActivity,
        });
        console.log(this.days[dayIndex].tasks);
        // Réinitialiser les champs du formulaire
        this.newTaskTime = "";
        this.newTaskActivity = "";
      }
    },
    getTimeIndex(time) {
      const index = this.hours.findIndex((hour) => hour === time);
      return index + 1; // Ajoute 1 pour compenser l'index 0 de JavaScript
    },
  },
};
</script>
<style></style>
