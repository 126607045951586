<template>
  <!-- Breadcrumb -->
  <div>
    <router-link to="/detailFournisseur">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8">Retour</span>
      </div>
    </router-link>
    <div class="mt-4">
      <div class="bg-white px-4 py-4 rounded shadow">
        <input
          type="file"
          accept=".xlsx"
          @change="readExcel(), (showPrefix = true)"
          class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border border-gray-400 rounded-md shadow appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
        />
      </div>
    </div>
    <div v-show="loadingFile" class="flex flex-row items-center space-x-2 mt-4">
      <span>Fichier en cours de chargement</span>
      <PulseLoader color="#2d74da" />
    </div>
    <div class="mt-4" v-show="isLoading">
      <loadingArticle />
    </div>
    <div class="mt-4">
      <input
        type="text"
        v-show="showPrefix"
        class="p-2 rounded shadow focus:outline-2 outline-sky-300"
        v-model="fName"
        placeholder="Fournisseur"
      />
      <input
        type="text"
        v-show="showPrefix"
        class="ml-2 p-2 rounded shadow focus:outline-2 outline-sky-300"
        v-model="fPrefix"
        placeholder="Prefix"
      />
      <input
        type="text"
        v-show="showPrefix"
        class="ml-2 p-2 rounded shadow focus:outline-2 outline-sky-300"
        v-model="fPrefixTarif"
        placeholder="Prefix Tarif"
      />
      <span v-show="errorPrefix">Veuillez remplir les prefixes !</span>
      <span
        v-for="(title, index1) in excelTitle"
        :key="index1"
        class="text-sm font-medium text-gray-600 px-2"
      >
        <span v-if="title != ''">{{ title }}</span>
      </span>
      <button
        @click="importPrices()"
        v-show="!showFinished && !isLoading"
        class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded shadow hover:bg-gray-400"
        v-if="excelTitle.length > 0"
      >
        Importer
      </button>
      <span class="ml-4 text-green-500" v-show="showFinished">
        Les Articles sont importés
      </span>
      <div class="flex flex-col mt-4" style="height: 65vh">
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="shadow rounded">
              <thead>
                <tr>
                  <th
                    class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    v-for="(titleTab, index2) in excelTitleTab"
                    :key="index2"
                  >
                    {{ titleTab }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="(prices, index3) in excelData" :key="index3">
                  <td
                    v-for="(pricesRow, index4) in prices"
                    :key="index4"
                    class="p-2 border-b border-r border-gray-200 whitespace-nowrap"
                  >
                    <span>
                      {{ pricesRow }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import loadingArticle from "../components/importFournisseur/loadingArticle.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "MyimportFournisseur",
  components: {
    PulseLoader,
    loadingArticle,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      isLoading: false,
      excelData: [],
      excelTitle: [],
      excelTitleTab: [],
      stateImportTab: [],
      file: null,
      importFiletoSend: [],
      showFinished: false,
      fPrefix: "",
      fPrefixTarif: "",
      fName: "",
      showPrefix: false,
      errorPrefix: false,
      loadingFile: false,
    };
  },
  methods: {
    readExcel() {
      this.loadingFile = true;
      this.file = event.target.files ? event.target.files[0] : null;

      readXlsxFile(this.file).then((data) => {
        this.excelTitle = data[0];
        this.excelTitleTab = data[1];
        this.excelData = data;
        this.excelData.splice(0, 2);
        this.loadingFile = false;
      });
    },
    async importPrices() {
      if (this.fPrefix != "" && this.fPrefixTarif != "" && this.fName != "") {
        this.errorPrefix = false;
        this.isLoading = true;
        var coderem = "99";
        await this.excelData.forEach((element) => {
          if (element[18] != null) {
            coderem = element[18];
          }
          if (element[2] != "") {
            this.importFiletoSend.push({
              fournisseur: this.fPrefix.toString(),
              plateform: this.user.proprietaire,
              Code_EAN: element[1],
              Ref_fournisseur: element[2].toString(),
              Prefixe_tarif: this.fPrefixTarif.toString(),
              Description: element[5],
              Prix_euro: element[8],
              PNF: element[10],
              PNT1: element[11],
              PNT2: element[12],
              PNT3: element[13],
              PNT4: element[14],
              Code_remise: coderem,
              Code_marque: this.fName,
            });
            console.log(this.importFiletoSend);
          }
        });
        axios
          .post(
            `${process.env.VUE_APP_API}/article/importOne`,
            this.importFiletoSend
          )
          .then((res) => {
            if (res.data == true) {
              this.addfournisseur();
            } else {
              this.errorPrefix = true;
            }
          });
      } else {
        this.errorPrefix = true;
      }
    },
    addfournisseur() {
      axios
        .put(
          `${process.env.VUE_APP_API}/fournisseur/${this.fName.toString()}`,
          {
            CompanyName: this.fName.toString(),
            Number: Math.floor(Math.random() * (100000 - 0 + 1) + 0).toString(),
            Prefix: this.fPrefix,
            Prefixe_tarif: this.fPrefixTarif,
            plateform: this.user.proprietaire,
          }
        )
        .then(() => {
          this.isLoading = false;
          this.showPrefix = false;
          this.showFinished = true;
        });
    },
  },
};
</script>

<style></style>
