<template>
  <div class="relative">
    <button
      @click="isOpen = !isOpen"
      class="flex flex-row items-center space-x-2 px-4 py-2 text-black border shadow rounded-md focus:outline-2 outline-sky-300"
    >
      {{ selectedOption }}
      <svg class="w-4 h-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 12l-6-6h12l-6 6z" />
      </svg>
    </button>

    <div
      v-if="isOpen"
      class="absolute z-10 mt-2 bg-white border border-gray-300 rounded-md shadow-lg"
    >
      <ul class="py-1">
        <li
          v-for="option in options"
          :key="option"
          @click="selectOption(option)"
          class="px-4 py-2 hover:bg-gray-300 cursor-pointer"
        >
          {{ option }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownVue",
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => ["Option 1", "Option 2", "Option 3"],
    },
  },
  computed: {
    selectedOption: {
      get() {
        return this.defaultValue;
      },
      set(value) {
        this.$emit("update:modelValue", value); // Émet l'événement pour mettre à jour le v-model dans le parent
      },
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit("vmodel", option);
    },
  },
  mounted() {
    this.selectedOption = this.defaultValue;
  },
};
</script>

<style>
/* Add any additional styles here */
</style>
