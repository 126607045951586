<template>
  <div class="flex-wrap flex justify-end my-6">
    <nav class="space-x-2">
      <router-link to="/matriceprix">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Conditions
        </a>
      </router-link>
      <router-link to="/Clients">
        <a
          href="#"
          name="Prixnet"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Prix net
        </a>
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
