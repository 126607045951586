import axios from "axios";

export async function createDepot(plateform: string, fournisseur: string, prefixeTarif: string, loginDepot: string, mdpDepot: string, nomDepot: string, codeDepot: string, priorite: number) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/depotFournisseur`, {
        plateform: plateform,
        fournisseur: fournisseur,
        prefixe_tarif: prefixeTarif,
        login: loginDepot,
        mdp: mdpDepot,
        nom_depot: nomDepot,
        code_depot: codeDepot,
        priorite: priorite,
    }
    );
    return response.data;
}

export async function getAllByPlateform(plateform: string) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/depotFournisseur/getAllByPlateform`, {
        plateform: plateform,
    }
    );
    return response.data;
}

export async function getAllBySupplier(plateform: string, prefixe_tarif: string) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/depotFournisseur/getAllBySupplier`, {
        plateform: plateform,
        prefixe_tarif: prefixe_tarif,
    }
    );
    return response.data;
}

export async function updateOne(id: string, data: object) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/depotFournisseur/updateOne`, {
        id: id,
        data: data,
    }
    );
    return response.data;
}

export async function removeOne(id: string) {
    const response = await axios.put(
        `${process.env.VUE_APP_API}/depotFournisseur/removeOne`, {
        id: id,
    }
    );
    return response.data;
}