<template>
  <div class="w-full">
    <div>Comparaison</div>
    <div class="flex flex-row space-x-8 w-fit mx-auto">
      <div v-for="(article, indexArticle) in compareTab" :key="indexArticle">
        <div class="h-36">
          <img
            v-if="article.image.length > 0 && article.image[0].imageURL200"
            :src="article.image[0].imageURL200"
            alt="image"
            class="max-h-full my-auto"
          />
        </div>
        <div>{{ article.dbArticle.Ref_fournisseur }}</div>
        <div>
          <div v-for="(inf, indexInf) in article.info" :key="indexInf">
            {{ inf.criteriaDescription }} : {{ inf.formattedValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "compareStructure",
  props: {
    compareTab: Array,
  },
};
</script>
<style></style>
