<template>
    <div
      class="flex flex-col items-center px-5 py-2 bg-white border-t"
    >
      <span class="text-sm text-bluevk xs:text-sm"
        >{{ nbrStart }} à {{ nbrEnd }} sur {{ tabList }} {{ typeList }}</span
      >
  
      <div class="inline-flex mt-2 xs:mt-0">
        <button
          class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
          @click="pageDown()"
        >
          Précédent
        </button>
        <div
          v-for="(page, index) in pageTab"
          :key="index"
          @click="clickFunction(page.pageNumber, perPage)"
          @change="indexCurrentPage = index"
        >
          <button
            v-if="page.pageNumber <= pageTotal"
            :class="{ 'active-page': page.pageNumber === currentPage }"
            class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-200"
          >
            {{ page.pageNumber }}
          </button>
        </div>
        <button
          @click="pageUp()"
          class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
        >
          Suivant
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "pageNumber",
    data() {
      return {
        indexCurrentPage: 0,
      };
    },
    props: {
      // Nombre de page total
      pageTotal: {
        type: Number,
        required: true,
      },
      // Fonction de mise à jour de la page
      clickFunction: {
        type: Function,
        required: true,
      },
      // Page de départ
      pageStart: {
        type: Number,
        required: true,
      },
      // Page suivante
      pageNext: {
        type: Number,
        required: true,
      },
      // Nombre total d'éléments
      tabList: {
        type: Number,
        required: true,
      },
      // Type de liste (commande, bdl, etc...)
      typeList: {
        type: String,
        required: true,
      },
      // Tableau des pages
      pageTab: {
        type: Array,
        required: true,
      },
      // Page Actuelle
      currentPage: {
        type: Number,
        required: true,
      },
      // Nombre d'éléments par page
      perPage: {
        type: Number,
        required: true
      }
    },
    computed: {
      nbrStart() {
        return (this.currentPage - 1) * this.perPage + 1;
      },
      nbrEnd() {
        const lastCommandIndex = this.currentPage * this.perPage;
        return Math.min(lastCommandIndex, this.tabList);
      },
    },
    methods: {
      pageUp() {
        if (this.pageNext <= this.pageTotal) {
          this.$emit("update:start", this.pageNext);
          this.$emit(
            "update:tab",
            this.pageTab.map((element, index) => ({
              pageNumber: this.pageNext + index,
            }))
          );
          this.$emit("update:next", this.pageNext + this.pageTab.length);
        }
      },
      pageDown() {
        if (this.pageNext !== 11) {
          const newPageNext = this.pageNext - 20;
          this.$emit("update:start", newPageNext);
          this.$emit(
            "update:tab",
            this.pageTab.map((element, index) => ({
              pageNumber: newPageNext + index,
            }))
          );
          this.$emit("update:next", newPageNext + this.pageTab.length);
        } else if (this.pageNext === 11) {
          this.$emit("update:start", 1);
          this.$emit(
            "update:tab",
            this.pageTab.map((element, index) => ({
              pageNumber: 1 + index,
            }))
          );
          this.$emit("update:next", 11);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .active-page {
    background-color: rgb(0, 162, 255); /* Couleur de fond pour la page active */
  }
  </style>
  