<template>
  <div class="flex flex-col">
    <back />
    <div class="flex justify-center"><menuConfiguration /></div>
    <div class="flex justify-center animate-pulse" v-if="loadingConfiguration">
      Recherche des Familles en cours...
    </div>
    <div class="mt-6 h-[78vh]">
      <div class="h-1/3 p-2 flex flex-col space-y-4">
        <div class="flex justify-center" v-if="confPriorityPrice.length == 0">
          <button
            :disabled="loadingInit"
            class="p-2 bg-blue-500 text-white rounded shadow"
            @click="initPriorityPrice()"
          >
            Activer le choix automatique
          </button>
        </div>
        <div class="flex flex-col space-y-4" v-else>
          <div class="grid grid-cols-4 gap-4 w-3/4 mx-auto">
            <div
              v-for="(conf, indexC) in confPriorityPrice"
              :key="indexC"
              :class="
                conf.actif
                  ? 'w-full border border-gray-400 bg-white text-gray-600 p-2 rounded shadow hover:shadow-lg hover:border-gray-500 transition-all duration-300 ease-in-out'
                  : 'w-full border border-gray-400 bg-gray-400 text-white bg-white p-2 rounded shadow hover:shadow-lg hover:border-gray-400 transition-all duration-300 ease-in-out'
              "
            >
              <div class="flex justify-between">
                <div class="flex flex-row space-x-1 items-center">
                  <div class="text-lg">
                    <select
                      v-model="conf.position"
                      :class="
                        conf.actif
                          ? 'bg-white text-gray-600 transition-all duration-300 ease-in-out'
                          : 'bg-gray-400 text-white transition-all duration-300 ease-in-out'
                      "
                      @change="
                        changePositionPriorityPrice(conf._id, conf.position)
                      "
                    >
                      <option v-for="i in confPriorityPrice" :key="i">
                        {{ i.position }}
                      </option>
                    </select>
                  </div>
                  <button
                    @click="changeStatePriorityPrice(conf._id, !conf.actif)"
                    v-if="!conf.actif"
                    class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                  >
                    Inactif
                  </button>
                  <button
                    v-else
                    @click="changeStatePriorityPrice(conf._id, !conf.actif)"
                    class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                  >
                    Actif
                  </button>
                </div>
                <div class="flex flex-row items-center space-x-2">
                  <span class="text-lg">{{ conf.Description }}</span>
                  <span class="material-symbols-rounded text-md">
                    {{ conf.icon }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-col space-y-2 w-3/12">
              <div
                :class="
                  errorGroupeClient
                    ? 'flex flex-row items-center justify-between text-sm border border-red-500 rounded p-1 transition-all duration-100 ease-in-out'
                    : 'flex flex-row items-center justify-between text-sm p-1 rounded transition-all duration-100 ease-out-in'
                "
              >
                <div>
                  <input
                    list="clientInput"
                    type="text"
                    v-model="nomClient"
                    @change="setClient()"
                    placeholder="Client"
                    class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
                  />
                  <datalist id="clientInput">
                    <option
                      v-for="(c, index) in Clients"
                      :key="index"
                      :value="c.Name"
                    ></option>
                  </datalist>
                </div>
                <div class="flex flex-row items-center space-x-2">
                  <div
                    :class="
                      cond.Name == selectedGroup
                        ? 'p-1 text-white rounded bg-blue-500 cursor-pointer'
                        : 'p-1 border border-gray-500 text-gray-500 rounded hover:bg-gray-200 cursor-pointer'
                    "
                    v-for="(cond, indexCD) in conditionList"
                    :key="indexCD"
                    @click="selectGroup(cond.Name)"
                  >
                    {{ cond.Name }}
                  </div>
                </div>
              </div>
              <div
                class="flex items-center border border-gray-300 rounded-lg p-2 bg-white shadow-sm w-full"
              >
                <input
                  type="text"
                  placeholder="Rechercher un article pour tester..."
                  class="ml-2 flex-grow border-none focus:ring-0 focus:outline-none"
                  v-model="searchArticle"
                  v-on:keyup.enter="searchArticlePriority()"
                /><svg
                  class="w-6 h-6 text-gray-500 cursor-pointer"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="searchArticlePriority()"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-4.35-4.35M16.65 16.65A7.92 7.92 0 0018 10a8 8 0 10-8 8 7.92 7.92 0 006.65-3.35z"
                  ></path>
                </svg>
              </div>
              <div
                class="flex flex-row items-center justify-between bg-white p-1 rounded shadow h-8 animate-pulse"
                v-if="loadingSearchArticle"
              >
                <div
                  class="w-16 h-full border border-gray-300 rounded bg-gray-100"
                ></div>
                <div
                  class="w-16 h-full border border-gray-300 rounded bg-gray-100"
                ></div>
              </div>
              <div
                v-if="articleTab.length > 0"
                class="flex flex-col space-y-1 max-h-28 overflow-y-auto bg-white p-1 rounded shadow"
              >
                <div
                  v-for="(art, indexArt) in articleTab"
                  :key="indexArt"
                  @click="selectArticle(art)"
                  :class="
                    selectedRef_fournisseur == art.Ref_fournisseur
                      ? 'flex flex-row items-center justify-between cursor-pointer p-1 rounded bg-blue-200'
                      : 'flex flex-row items-center justify-between cursor-pointer hover:bg-gray-100 p-1 rounded'
                  "
                >
                  <div>{{ art.Ref_fournisseur }}</div>
                  <div>{{ art.Code_marque }}</div>
                </div>
              </div>
            </div>
            <div class="w-8/12">
              <div v-if="loadingSearchPriority" class="grid grid-cols-3 gap-4">
                <div
                  class="flex flex-col space-y-1 p-1 bg-white shadow rounded animate-pulse"
                  v-for="i in 3"
                  :key="i"
                >
                  <div
                    class="w-full h-8 border border-gray-300 rounded bg-gray-100"
                  ></div>
                  <div
                    class="w-full h-8 border border-gray-300 rounded bg-gray-100"
                  ></div>
                  <div
                    class="w-full h-8 border border-gray-300 rounded bg-gray-100"
                  ></div>
                  <div
                    class="w-full h-8 border border-gray-300 rounded bg-gray-100"
                  ></div>
                  <div
                    class="w-full h-8 border border-gray-300 rounded bg-gray-100"
                  ></div>
                </div>
              </div>
              <div v-else class="grid grid-cols-3 gap-4">
                <div
                  :class="
                    art.selected
                      ? 'flex flex-col space-y-3 p-2 border border-blue-300 rounded bg-white shadow'
                      : 'flex flex-col space-y-3 p-2 border border-gray-300 rounded bg-white'
                  "
                  v-for="(art, indexArt) in articlePriorityTab"
                  :key="indexArt"
                >
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center space-x-2">
                      <span>{{ art.Fournisseur }}</span>
                      <span
                        class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                        >{{ art.type_condition }}</span
                      >
                    </div>
                    <div>
                      <span
                        v-if="art.selected"
                        class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                        >Proposé</span
                      >
                    </div>
                  </div>
                  <div class="flex flex-row justify-between items-center">
                    <div>Ppc : {{ parseFloat(art.Prix.ppc).toFixed(2) }} €</div>
                    <div>
                      Achat : {{ parseFloat(art.Prix.achat).toFixed(2) }} €
                    </div>
                    <div>
                      Vente : {{ parseFloat(art.Prix.vente).toFixed(2) }} €
                    </div>
                  </div>
                  <div class="flex flex-row justify-between items-center">
                    <div>Marge : {{ parseInt(art.Marge.pourcent) }} %</div>
                    <div>
                      Marge : {{ parseFloat(art.Marge.euro).toFixed(2) }} €
                    </div>
                  </div>
                  <div class="flex flex-row justify-between items-center">
                    <div>Dispo : {{ art.Dispo }}</div>
                    <div>Transport : {{ art.Transport }} €</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-2/3 p-2 flex flex-col space-y-4">
        <div class="flex justify-center text-xl">Liste des marques</div>
        <div class="grid grid-cols-4 gap-4">
          <div
            class="w-full border border-gray-400 bg-white text-gray-600 p-2 rounded shadow flex flex-row items-center justify-between"
            v-for="(conf, index) in confPriorityBrand"
            :key="index"
          >
            <div>{{ conf.Code_marque }}</div>
            <div>
              <select
                class="bg-orange-100 text-orange-800 text-sm font-medium me-2 px-2.5 py-1 rounded"
                v-model="conf.type"
                @change="updateConf(conf._id, conf)"
              >
                <option value="tout">tout</option>
                <option value="stock">stock</option>
              </select>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <span
                class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
                >{{ conf.CompanyName }}</span
              >

              <button
                @click="deletePriorityBrand(conf._id)"
                class="material-symbols-rounded text-red-500"
              >
                delete
              </button>
            </div>
          </div>
          <div
            class="w-full border border-gray-400 bg-white text-gray-600 p-2 rounded shadow flex flex-row items-center justify-between"
            v-if="ajoutState"
          >
            <div
              :class="
                marqueState == false
                  ? 'w-5/12 flex flex-row bg-gray-100 text-red-500 text-sm font-medium me-2 px-2.5 py-0.5 rounded'
                  : 'w-5/12 flex flex-row bg-gray-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded'
              "
            >
              <input
                type="text"
                class="bg-gray-100 w-full"
                placeholder="Marque"
                v-model="searchMarque"
                v-on:keyup.enter="checkBrand()"
              />
              <button
                @click="checkBrand()"
                :disabled="loadingChkBrand"
                :class="
                  loadingChkBrand
                    ? 'material-symbols-rounded text-blue-500 text-base animate-spin'
                    : 'material-symbols-rounded text-blue-500 text-base'
                "
              >
                search
              </button>
            </div>
            <div class="flex flex-row items-center space-x-2 w-1/2">
              <input
                list="fournisseurToAdd"
                type="text"
                class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded w-36"
                placeholder="Fournisseur"
                v-model="addFournisseur"
              />
              <datalist id="fournisseurToAdd">
                <option
                  v-for="(c, index) in fournisseurList"
                  :key="index"
                  :value="c.CompanyName"
                ></option>
              </datalist>

              <button
                v-if="marqueState && addFournisseur != ''"
                class="material-symbols-rounded text-blue-500"
                @click="addPriorityBrand()"
              >
                add
              </button>
            </div>
          </div>
          <div class="flex">
            <button
              @click="openAjoutMarque()"
              class="my-auto px-2 bg-blue-500 text-white rounded shadow"
            >
              Ajouter une marque
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import back from "@/components/Button/back.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
export default {
  data() {
    return {
      conditionList: [],
      confPriorityPrice: [],
      confPriorityBrand: [],
      loadingConf: false,
      loadingInit: false,
      loadingChkBrand: false,
      loadingSearchArticle: false,
      loadingSearchPriority: false,
      searchMarque: "",
      marqueState: null,
      ajoutState: false,
      fournisseurList: [],
      addFournisseur: "",
      selectedGroup: "",
      searchArticle: "",
      selectedArticle: {},
      articleTab: [],
      selectedRef_fournisseur: "",
      errorGroupeClient: false,
      articlePriorityTab: [],
      Clients: [],
      nomClient: "",
      clientChoosed: {},
    };
  },
  components: {
    back,
    menuConfiguration,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getCondtion() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.conditionList = condition.data;
    },
    async getPriorityPrice() {
      this.loadingConf = true;
      const response = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getPriorityPrice`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.confPriorityPrice = response.data;
      this.loadingConf = false;
    },
    async initPriorityPrice() {
      this.loadingInit = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/initPriorityPrice`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.loadingInit = false;
      this.getPriorityPrice();
    },
    async changeStatePriorityPrice(id, state) {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/changeStatePriorityPrice`,
        {
          id: id,
          state: state,
        }
      );
      this.getPriorityPrice();
    },
    async changePositionPriorityPrice(id, position) {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/changePositionPriorityPrice`,
        {
          plateform: this.user.proprietaire,
          id: id,
          position: position,
        }
      );
      this.getPriorityPrice();
    },
    async openAjoutMarque() {
      this.ajoutState = !this.ajoutState;
      await this.getFournisseur();
    },
    async checkBrand() {
      this.loadingChkBrand = true;
      this.searchMarque = this.searchMarque.toUpperCase();
      const chkBrand = await axios.post(
        `${process.env.VUE_APP_API}/inventaire/checkBrand`,
        {
          plateform: this.user.proprietaire,
          brand: this.searchMarque,
        }
      );
      chkBrand.data ? (this.marqueState = true) : (this.marqueState = false);
      this.loadingChkBrand = false;
    },
    async getFournisseur() {
      const fourn = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getall`,
        {
          plateform: this.user.proprietaire,
        }
      );

      this.fournisseurList = fourn.data;
    },
    async getPriorityBrand() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getPriorityBrand`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.confPriorityBrand = response.data;
    },
    async addPriorityBrand() {
      const add = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addPriorityBrand`,
        {
          plateform: this.user.proprietaire,
          brand: this.searchMarque,
          fournisseur: this.addFournisseur,
        }
      );
      this.confPriorityBrand = add.data;
    },
    async deletePriorityBrand(id) {
      const del = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deletePriorityBrand`,
        {
          id: id,
        }
      );
      this.confPriorityBrand = del.data;
    },
    async updateConf(id, data) {
      const update = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/updateConf`,
        {
          id: id,
          data: data,
        }
      );
      this.getPriorityBrand();
    },
    selectGroup(group) {
      if (
        this.selectedGroup != "" &&
        this.selectedGroup != group &&
        this.selectedRef_fournisseur != "" &&
        this.clientChoosed != {} &&
        this.selectedArticle != {}
      ) {
        this.selectedGroup = group;
        this.selectArticle(this.selectedArticle);
      } else {
        this.selectedGroup = group;
      }
    },
    async searchArticlePriority() {
      if (this.searchArticle != "") {
        this.loadingSearchArticle = true;
        this.articleTab = [];
        const search = await axios.post(
          `${process.env.VUE_APP_API}/searchEngine/searchArticlePriority`,
          {
            plateform: this.user.proprietaire,
            Ref_fournisseur: this.searchArticle,
          }
        );
        this.articleTab = search.data;
        this.loadingSearchArticle = false;
      }
    },
    async selectArticle(article) {
      if (this.selectedGroup == "") {
        this.errorGroupeClient = true;
        setTimeout(() => {
          this.errorGroupeClient = false;
        }, 300);
      } else {
        this.articlePriorityTab = [];
        this.errorGroupeClient = false;
        this.loadingSearchPriority = true;
        this.selectedRef_fournisseur = article.Ref_fournisseur;
        this.selectedArticle = article;
        const simulatePriorityArticle = await axios.post(
          `${process.env.VUE_APP_API}/searchEngine/simulatePriorityArticle`,
          {
            Article: article,
            plateform: this.user.proprietaire,
            Client: this.clientChoosed.Name,
            groupeClient: this.selectedGroup,
          }
        );
        this.articlePriorityTab = simulatePriorityArticle.data;
        this.loadingSearchPriority = false;
      }
    },
    async getClients() {
      await axios
        .get(`${process.env.VUE_APP_API}/client/${this.user.proprietaire}`)
        .then((response) => {
          this.Clients = response.data;
        });
    },
    async setClient() {
      // find Client by Name in this.Clients
      this.clientChoosed = this.Clients.find(
        (client) => client.Name === this.nomClient
      );
      this.selectedGroup = this.clientChoosed.Group.Name;
    },
  },
  mounted() {
    this.getPriorityPrice();
    this.getPriorityBrand();
    this.getCondtion();
    this.getClients();
  },
};
</script>
<style></style>
