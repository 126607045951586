
import { useToast, POSITION } from 'vue-toastification';

export function success(msg: string) {
    const toast = useToast();
    return toast.success(msg, {
        position: POSITION.BOTTOM_RIGHT,
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
    });
}

export function warning(msg: string) {
    const toast = useToast();
    return toast.warning(msg, {
        position: POSITION.BOTTOM_RIGHT,
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
    });
}

export function error(msg: string) {
    const toast = useToast();
    return toast.error(msg, {
        position: POSITION.BOTTOM_RIGHT,
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
    });
}
