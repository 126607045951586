<template>
  <div class="flex flex-col space-y-8">
    <!-- <div id="retour" class="flex justify-start">
      <backButton :route="'/configurationhub'" />
    </div> -->

    <div class="flex flex-col space-y-3">
      <div class="flex justify-center"><menuConfiguration /></div>

      <div class="grid grid-cols-3 gap-4">
        <div
          class="p-3 bg-white hover:bg-gray-100 shadow rounded flex flex-col space-y-2 cursor-pointer"
          @click="changeCatalogueType('AG')"
        >
          <div class="flex flex-row justify-center mt-6">
            <div class="text-xl text-bluevk">
              Catalogue <span class="text-bluevk text-2xl font-bold">AG</span>
            </div>
          </div>
          <div class="w-full h-56 mx-auto">
            <img
              class="h-full mx-auto"
              src="@/assets/catalogue/Catalogue-1-noir-50-orange.png"
            />
          </div>
          <div class="flex justify-end">
            <span
              v-if="plateform.catalogue_type == 'AG'"
              class="px-2 border border-gray-500 text-gray-500 rounded-full"
              >Selectionné</span
            >
          </div>
        </div>
        <div
          class="p-3 bg-white hover:bg-gray-100 shadow rounded flex flex-col space-y-2 cursor-pointer"
          @click="changeCatalogueType('SA')"
        >
          <div class="flex flex-row justify-center mt-6">
            <div class="text-xl text-bluevk">
              Catalogue <span class="text-bluevk text-2xl font-bold">SA</span>
            </div>
          </div>
          <div class="w-full h-56 mx-auto">
            <img
              class="h-full mx-auto"
              src="@/assets/catalogue/Catalogue-2-noir-50-orange.png"
            />
          </div>
          <div class="flex justify-end">
            <span
              v-if="plateform.catalogue_type == 'SA'"
              class="px-2 border border-gray-500 text-gray-500 rounded-full"
              >Selectionné</span
            >
          </div>
        </div>
        <div
          class="p-3 bg-white hover:bg-gray-100 shadow rounded flex flex-col space-y-2 cursor-pointer"
          @click="changeCatalogueType('CA')"
        >
          <div class="flex flex-row justify-center mt-6">
            <div class="text-xl text-bluevk">
              Catalogue <span class="text-bluevk text-2xl font-bold">CA</span>
            </div>
          </div>
          <div class="w-full h-56 mx-auto">
            <img
              class="h-full mx-auto"
              src="@/assets/catalogue/Catalogue-3-noir-50-orange.png"
            />
          </div>
          <div class="flex justify-end">
            <span
              v-if="plateform.catalogue_type == 'CA'"
              class="px-2 border border-gray-500 text-gray-500 rounded-full"
              >Selectionné</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col space-y-3">
      <div class="grid grid-cols-2 gap-4">
        <div class="p-2 bg-white shadow rounded flex flex-col space-y-4">
          <div class="flex flex-row justify-between items-center">
            <img class="h-10" src="@/assets/tecdoc_logo.jpg" />
            <span
              v-show="loadingTD"
              class="material-icons-outlined text-orangevk animate-spin"
            >
              sync
            </span>
          </div>
          <div class="flex flex-col space-y-4">
            <div class="relative">
              <input
                type="text"
                id="floating_outlined_api"
                class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                v-model="plateform.td_api_key"
                @change="updateApiKey()"
              />
              <label
                for="floating_outlined_api"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Clé d'api</label
              >
            </div>
            <div class="relative">
              <input
                type="Number"
                id="floating_outlined_provider"
                class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                v-model="plateform.td_provider"
                @change="updateProvider()"
              />
              <label
                for="floating_outlined_provider"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Provider</label
              >
            </div>
          </div>
          <div>
            <h2 class="mb-2 text-lg font-semibold text-gray-900">
              Filiales connectées :
            </h2>
            <ul class="max-w-md space-y-1 ml-4 text-gray-500 list-inside">
              <li class="flex items-center">
                <svg
                  class="w-3.5 h-3.5 me-2 text-green-500 flex-shrink-0 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
                  />
                </svg>
                ACP
              </li>
              <li class="flex items-center">
                <svg
                  class="w-3.5 h-3.5 me-2 text-gray-500 flex-shrink-0 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
                  />
                </svg>
                ACP EPERNAY
              </li>
            </ul>
          </div>
        </div>
        <div class="p-2 bg-white shadow rounded flex flex-col space-y-2">
          <div class="flex flex-row justify-between">
            <span>Statistiques</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
// import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
export default {
  data() {
    return {
      loadingTD: false,
    };
  },
  components: {
    // backButton,
    menuConfiguration,
  },
  computed: {
    ...mapGetters(["plateform"]),
  },
  methods: {
    ...mapActions(["getplateform"]),
    async setCatType(type) {
      await axios
        .put(`${process.env.VUE_APP_API}/plateform/${this.plateform._id}`, {
          typeCat: type,
        })
        .then(() => {
          this.getplateform();
        });
    },
    async changeCatalogueType(type) {
      await axios
        .put(`${process.env.VUE_APP_API}/plateform/${this.plateform._id}`, {
          catalogue_type: type,
        })
        .then(() => {
          this.getplateform();
        });
    },
    async updateApiKey() {
      this.loadingTD = true;
      await axios
        .put(`${process.env.VUE_APP_API}/plateform/${this.plateform._id}`, {
          td_api_key: this.plateform.td_api_key,
        })
        .then(() => {
          this.getplateform();
        });
      this.loadingTD = false;
    },
    async updateProvider() {
      this.loadingTD = true;
      await axios
        .put(`${process.env.VUE_APP_API}/plateform/${this.plateform._id}`, {
          td_provider: this.plateform.td_provider,
        })
        .then(() => {
          this.getplateform();
        });
      this.loadingTD = false;
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style></style>
