<template>
  <div>
    <div
      v-if="showFile"
      :class="`modal ${
        !showFile && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="closeImport()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Tableau de condition importé</p>
            <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
            v-show="showFileContent"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-vk"
                    >
                      Référence
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-vk"
                    >
                      Prefixe_tarif
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-vk"
                    >
                      Prix_vente
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFile" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      {{ readCondi[1] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      <!-- <span class="w-1/4">{{ readCondi[1] }}</span> -->
                      <input
                        type="text"
                        class="w-56"
                        v-model="readFile[index][2]"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end mt-4 mb-2" v-show="showFileContent">
            <button
              class="p-2 bg-blue-500 text-white rounded"
              @click="importerFile()"
            >
              Importer
            </button>
          </div>
          <div v-show="importLoading">
            <div class="animate-pulse">Importation en cours...</div>
          </div>
          <div v-show="showFinished">
            <div>Importation réussie !</div>
            <div class="flex justify-end">
              <button
                class="p-2 bg-blue-500 text-white rounded"
                @click="closeImport()"
              >
                Fermer
              </button>
            </div>
          </div>

          <!-- End Body -->
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <!-- Back Button -->
      <div class="flex justify-between">
        <div id="retour" class="flex justify-start">
          <backButton :route="'/detailClient'" />
        </div>
      </div>
      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Prix net</span>
          <span class="text-green-600"> {{ clientChoosedTab.Name }} </span>
          <span class="text-green-600">
            <!-- {{ clientChoosedTab.Prefixe_tarif }} -->
          </span>
        </h2>
      </div>
      <div class="flex justify-end mt-2 space-x-2">
        <label
          id="dropzone-file"
          @click="exportTemplate()"
          title="Télécharger un template Excel"
          class="cursor-pointer bg-orange-600 hover:bg-orange-500 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Template</span>
          <span class="material-icons-outlined m-auto">download</span>
        </label>
        <label
          id="dropzone-file"
          title="Importer Excel"
          class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Importer Excel</span>
          <span class="material-icons-outlined m-auto">file_upload</span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx"
            @change="readExcel()"
          />
        </label>
        <label
          id="dropzone-file"
          @click="exportToExcel()"
          title="Exporter Excel"
          class="cursor-pointer bg-sky-700 hover:bg-sky-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Exporter Excel</span>
          <span class="material-icons-outlined m-auto">file_download</span>
        </label>
      </div>
    </div>
    <div>
      <input
        v-on:keyup.enter="getOneArticle()"
        v-model="articleSearch"
        placeholder="Entrer un code article"
        class="focus:outline-2 outline-sky-300 mt-4 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
      />
      <ul
        v-show="showArticleList"
        class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <!-- Table pour trouver un produit -->
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf./Equipe.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Descr./Fourn.
              </th>
              <!-- <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock
                </th> -->
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                P.P.C.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                P.V.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Remise
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Px final
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total
              </th>
              <th
                class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <button class="cursor-pointer" @click="showArticleList = false">
                  <span
                    class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25"
                    >close</span
                  >
                </button>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white" v-if="comptoirsearchtab.length > 0">
            <tr
              v-for="(csArticle, index) in comptoirsearchtab[0].article"
              :key="index"
              @click="handlerArticle(csArticle, comptoirsearchtab[0].stockData)"
              class="bg-green-300 hover:bg-opacity-25 cursor-pointer"
              style="max-height: 10px"
            >
              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ csArticle.Ref_fournisseur }}
                </div>
                <div class="text-sm leading-5 text-gray-900">
                  {{ csArticle.Code_marque }}
                </div>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-500">
                  {{ csArticle.Description }}
                </div>
                <div
                  class="text-sm leading-5 bg-blue-500 text-white p-1 rounded w-fit"
                >
                  {{ csArticle.Prefixe_tarif }}
                </div>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <span v-if="comptoirsearchtab[0].stockData.length > 0">
                  {{ comptoirsearchtab[0].stockData[0].stock }}
                </span>
                <span v-else>0</span>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                0 €
              </td>

              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ financial(csArticle.Prix_euro) }} €
              </td>
              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                0 %
              </td>
              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ financial(csArticle.Prix_euro) }} €
              </td>
              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ financial(csArticle.Prix_euro) }} €
              </td>
              <td
                class="w-12 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              ></td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
            </tr>
          </thead>
        </table>
        <!-- Fin Table pour trouver un produit -->
      </ul>
      <div>
        <div class="flex flex-col mt-2">
          <div
            class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
            >
              <table class="min-w-full" style="height: 70vh">
                <thead class="tableHEADTR">
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Réf./Equipe
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Descr./Fourn.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Code/Famille
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      PV
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Prix net
                    </th>
                    <th
                      class="w-12 px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                    ></th>
                  </tr>
                </thead>

                <tbody class="bg-white tableBODY" style="height: 70vh">
                  <tr
                    v-for="(articleAct, index) in tabArticles"
                    :key="index"
                    class="tableHEADTR text-blue-700 font-medium"
                  >
                    <td
                      class="flex flex-col px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <span>{{ articleAct.Ref_fournisseur }}</span>
                      <span>{{ articleAct.Code_marque }}</span>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5">
                        {{ articleAct.Description }}
                      </div>
                      <div class="">
                        <span class="">{{ articleAct.Prefixe_tarif }}</span>
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5">
                        {{ articleAct.Code_remise }}
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-2">
                        <span class="cursor-pointer"
                          ><input
                            disabled
                            class="focus:outline-2 outline-sky-300 p-1 w-2/5 rounded-md"
                            type="Number"
                            v-model="articleAct.Prix_euro"
                          />
                          €</span
                        >
                      </div>
                    </td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex space-x-2">
                        <div class="font-medium">
                          <input
                            type="Number"
                            class="focus:outline-2 outline-sky-300 p-1 w-2/5 border border-gray-500 rounded-md font-medium"
                            v-model="pvPrixNet[index]"
                            @keyup.enter="
                              savePrixNet(
                                articleAct,
                                articleAct.Ref_fournisseur,
                                articleAct.Prefixe_tarif,
                                pvPrixNet[index],
                                index
                              )
                            "
                          />
                          €
                        </div>
                        <div
                          class="cursor-pointer rounded-full bg-green-400 shadow-sm text-white hover:bg-green-300 p-0.5 w-7 h-7"
                          @click="
                            savePrixNet(
                              articleAct,
                              articleAct.Ref_fournisseur,
                              articleAct.Prefixe_tarif,
                              pvPrixNet[index],
                              index
                            )
                          "
                        >
                          <span class="material-icons-outlined">done</span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="w-12 px-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center space-x-1">
                        <span
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer"
                          @click="tabArticles.splice(index, 1), saveChange()"
                        >
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>

                  <!-- clientChoosedTab -->
                  <tr
                    v-for="(tabSaved, index) in clientChoosedTab.prixNet"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <span>{{ tabSaved.Ref_fournisseur }}</span>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="">
                        <span class="text-blue-500">{{
                          tabSaved.Prefixe_tarif
                        }}</span>
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5"></div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    ></td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="m-auto">
                        <input
                          type="Number"
                          class="focus:outline-2 outline-sky-300 p-1 w-2/5 border border-gray-500 rounded-md text-gray-900 font-medium"
                          v-model="tabSaved.Prix_vente"
                          @change="
                            savePrixNetSaved(
                              tabSaved.Ref_fournisseur,
                              tabSaved.Prefixe_tarif,
                              tabSaved.Prix_vente,
                              index
                            )
                          "
                        />
                        <span class="m-auto">€</span>
                      </div>
                    </td>

                    <td
                      class="w-12 px-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center space-x-1">
                        <span
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer"
                          @click="
                            clientChoosedTab.prixNet.splice(index, 1),
                              saveChange()
                          "
                        >
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import backButton from "@/components/Button/back.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showArticleList: false,
      tabArticles: [],
      articleSearch: "",
      showPA: [],
      showMarge: [],
      pvPrixNet: [],
      file: null,
      readFile: null,
      showFile: false,
      showFileContent: true,
      importLoading: false,
      showFinished: false,
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user", "comptoirsearchtab", "clientChoosedTab"]),
  },
  methods: {
    ...mapActions([
      "searchTranslatedDirect",
      "getclientchoosedtab",
      "getclientchoosedtabprixnet",
      "getclientchoosedtabprixnetsaved",
    ]),
    back() {
      this.$router.back();
    },
    setRemiseArticle(ind, rem, qty, prix) {
      const PrixFinal = parseFloat(prix) - parseFloat(prix) * (rem / 100);
      const prixTotal = PrixFinal * qty;
      const dataArt = {
        indexArt: ind,
        quantity: qty,
        remise: rem,
        prixtotal: prixTotal,
        prixfinal: PrixFinal,
      };
      console.log(dataArt);
    },
    async getOneArticle() {
      this.showArticleList = true;
      if (this.articleSearch != "") {
        await this.searchTranslatedDirect({
          artNum: this.articleSearch,
          marqueName: "*",
          plateform: this.user.proprietaire,
        });
        this.articleSearch = "";
      } else {
        console.log("vide");
        // if (this.comptoirsearchtab.length == 1) {
        //   this.handlerArticle(
        //     this.comptoirsearchtab[0].article[0],
        //     this.comptoirsearchtab[0].stockData
        //   );
        // }
        this.showArticleList = false;
        this.articleSearch = "";
      }
    },
    async handlerArticle(data, stockData) {
      console.log(data);
      console.log(stockData);
      var existArticle = false;
      var existArticleSaved = false;
      for (let i = 0; i < this.tabArticles.length; i++) {
        if (
          this.tabArticles[i].Ref_fournisseur == data.Ref_fournisseur &&
          this.tabArticles[i].Prefixe_tarif == data.Prefixe_tarif
        ) {
          existArticle = true;
          break;
        }
      }
      for (let i = 0; i < this.clientChoosedTab.prixNet.length; i++) {
        if (
          this.clientChoosedTab.prixNet[i].Ref_fournisseur ==
            data.Ref_fournisseur &&
          this.clientChoosedTab.prixNet[i].Prefixe_tarif == data.Prefixe_tarif
        ) {
          existArticleSaved = true;
          break;
        }
      }
      if (existArticle == false && existArticleSaved == false) {
        this.tabArticles.push(data);
      } else if (existArticle == true) {
        console.log("article existe");
        this.toast.warning("Référence déjà choisie !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else if (existArticleSaved == true) {
        this.toast.warning("Référence déjà enregistrée !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        //     document.getElementById(
        //   `prixNetSaved${indexSaved}`
        // ).style.animationDuration = "10s";
      }

      this.showArticleList = false;
      this.articleSearch = "";
    },
    saveChange() {
      axios.put(
        `${process.env.VUE_APP_API}/client/modifyClient/${this.clientChoosedTab._id}`,
        {
          data: this.clientChoosedTab,
        }
      );
    },
    savePrixNet(tab, ref, prefixe, pv, ind) {
      if (pv) {
        this.getclientchoosedtabprixnet({
          Ref_fournisseur: ref,
          Prefixe_tarif: prefixe,
          Prix_vente: pv,
        });
        this.pvPrixNet[ind] = "";
        this.tabArticles.splice(ind, 1);
        this.saveChange();
      }
    },
    savePrixNetSaved(ref, prefixe, pv, ind) {
      if (pv) {
        this.getclientchoosedtabprixnetsaved({
          Ref_fournisseur: ref,
          Prefixe_tarif: prefixe,
          Prix_vente: pv,
          index: ind,
        });
        this.saveChange();
      }
    },
    async readExcel() {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.readFile.splice(0, 1);
      this.showFile = true;
    },
    closeImport() {
      this.showFile = false;
      this.readFile = null;
      this.file = null;
      this.showFinished = false;
      this.showFileContent = true;
    },
    // importExcel() {
    //   this.importLoading = true;
    //   // this.showFileContent = false;
    //   axios
    //     .post(`${process.env.VUE_APP_API}/conditionFournisseur/importExcel`, {
    //       plateform: this.user.proprietaire,
    //       data: this.readFile,
    //     })
    //     .then(() => {
    //       this.importLoading = false;
    //       this.showFinished = true;
    //     });
    // },
    importerFile() {
      this.importLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/client/importExcel`, {
          data: this.readFile,
          plateform: this.user.proprietaire,
          Client: this.clientChoosedTab.Name,
        })
        .then((response) => {
          console.log("response   :");
          console.log(response.data);
          this.clientChoosedTab = response.data;
          this.getclientchoosedtab(response.data);
          this.importLoading = false;
          this.showFinished = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportTemplate() {
      const dataToExport = [
        { Ref_fournisseur: "", Prefixe_tarif: "", Prix_vente: "" },
      ];

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "prixNet");

      XLSX.writeFile(wb, "PrixNetClientTemplate" + ".xlsx");
    },
    exportToExcel() {
      const dataToExport = this.clientChoosedTab.prixNet.map((row) => {
        const { [Object.keys(row).pop()]: last, ...rest } = row;
        return rest;
      });

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "prixNet");

      XLSX.writeFile(
        wb,
        "PrixNetClient_" +
          this.user.proprietaire +
          "_" +
          this.clientChoosedTab.Name +
          ".xlsx"
      );
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style scoped>
.bg-vk {
  background-color: #2262b3;
}
</style>
