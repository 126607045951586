<template>
  <div class="flex flex-row space-x-2" v-if="information.length > 0">
    <span>{{ information[0].genericArticleDescription }}</span>
    <span>{{ information[0].genericArticleId }}</span>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      information: [],
    };
  },
  props: ["Ref_fournisseur", "Code_marque"],
  methods: {
    async getInformationArticle() {
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/infoGenericArticleId`, {
          Ref_fournisseur: this.Ref_fournisseur,
          Code_marque: this.Code_marque,
        })
        .then((res) => {
          this.information = res.data;
        });
    },
  },
  beforeMount() {
    this.getInformationArticle();
  },
};
</script>
<style></style>
