<template>
  <div>
    <!-- Modal Ref Article -->
    <div
    v-if="openRefArticle"
      :class="`modal ${
        !openRefArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openRefArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openRefArticle = false"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Références</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openRefArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Réf
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock
                </th>
              </tr>
            </thead>
            <tbody v-if="RefSim[indexSelectedPL]">
              <tr
                v-for="(ref, index) in RefSim[indexSelectedPL]"
                :key="index"
                @click="
                  (openRefArticle = false),
                    (Quantity = QuantityTMP),
                    changeReference(indexSelectedPL, ref.article[0].Ref_fournisseur)
                "
                class="cursor-pointer hover:bg-slate-100 hover:shadow"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ ref.article[0].Ref_fournisseur }}
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ ref.article[0].Description }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ ref.article[0].Code_marque }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ ref.article[0].Prix_euro }} €
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-if="ref.stock.length>0"
                >
                  {{ ref.stock[0].stock }}
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-else
                >
                  0
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openRefArticle = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Ref Article -->
    <!-- Modal Détails dispo -->
    <div
    v-if="openRefSimil"
      :class="`modal ${
        !openRefSimil && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openRefSimil = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openRefSimil = false"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Remises fournisseurs</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openRefSimil = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full mt-4">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté limite inférieure
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté limite supérieure
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Prix
                </th>
              </tr>
            </thead>

            <tbody class="bg-white w-fit" v-if="PL_selected">
              <tr
                v-for="(r, index) in PL_selected"
                :key="index"
                class="tableHEADTR cursor-pointer"
                @click="modifyQuantityPL(r.$.QtyLowerLimit)"
              >
                <td
                  class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-center">{{ r.$.QtyLowerLimit }} pcs</div>
                </td>

                <td
                  class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-center">{{ r.$.QtyUpperLimit }} pcs</div>
                </td>

                <td
                  class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-center">{{ r.$.Price }} €</div>
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openRefSimil = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Détails dispo -->

    <div
      class="flex justify-end mt-4 space-x-4"
      v-show="!commandState"
      v-if="VH_a02Tab.length > 0"
    >
      <div class="flex space-x-4">
        <div
          class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer"
          @click="changeDepot('STANDARD', -1,  plateform.grossierID)"
        >
          Standard
        </div>
        <div v-for="(depotFound, indexdepot) in depotTab" :key="indexdepot">
          <div
            class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer"
            @click="changeDepot(depotFound.name, indexdepot, depotFound.code)"
          >
            {{ depotFound.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-8">
      <div v-show="errorChamps">
        <span class="text-red-500">Veuillez remplir les champs</span>
      </div>
      <div
        class="flex flex-row items-center mt-2 space-x-4"
        v-show="!commandState"
      >
        <input
          type="text"
          v-model="Ref_fournisseur"
          placeholder="Référence article"
          class="p-2 focus:outline-2 outline-sky-300"
          @keydown.enter="focusNext"
        />
        <input
          type="Number"
          v-model="Quantity"
          placeholder="Quantité"
          class="p-2 focus:outline-2 outline-sky-300"
          ref="qty"
          @keydown.enter="checkArticle()"
        />
        <button
          @click="checkArticle()"
          class="p-2 bg-blue-500 text-white rounded shadow"
        >
          Ajouter
        </button>
        <PulseLoader color="#3b82f6" v-show="loading" />
      </div>
    </div>

    <div class="flex space-x-1 justify-center" v-show="!commandState">
      <div
        class="py-2 mt-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        v-if="VH_a02Tab[indexDepot].lines.length > 0"
      >
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full" style="max-height: 18vh">
            <thead>
              <tr>
                <th
                  class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Ref
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  style="width: 3%"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Qté
                </th>
                <th
                  style="width: 10%"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dispo
                </th>
                <th
                  style="width: 5%"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(line, index) in VH_a02Tab[indexDepot].lines"
                :key="index"
              >
                <td
                  class="px-2 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="flex flex-col">
                      <span
                        class="material-icons-outlined m-auto cursor-pointer text-green-700 text-base hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                        @click="
                          getRefSimilaire(
                            line.B1_Artikelnummer[0],
                            index,
                            parseInt(line.B1_LA_Aantal_response[0]) +
                              parseInt(line.B1_LB_Aantal_response[0])
                          )
                        "
                      >
                        sync
                      </span>
                      <span
                        @click="
                          getSchiedam(line, index), (showDepot[index] = true)
                        "
                        id="selected"
                        class="material-icons-outlined m-auto cursor-pointer text-green-700 text-base hover:bg-gray-100 rounded hover:shadow"
                      >
                        reply
                      </span>
                      <div
                        class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
                        v-show="showDepot[index]"
                        @mouseleave="showDepot[index] = false"
                      >
                        <div
                          v-for="(depotFound, indexdepot) in depotTab"
                          :key="indexdepot"
                        >
                          <div
                            class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer"
                            @click="
                              sendRef(
                                indexdepot,
                                index,
                                line.B1_Artikelnummer[0],
                                parseInt(line.B1_LA_Aantal_response[0]) +
                                  parseInt(line.B1_LB_Aantal_response[0])
                              )
                            "
                          >
                            {{ depotFound.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="items-center">
                      <span class="items-center">{{
                        line.B1_Artikelnummer[0]
                      }}</span>
                      <span
                        v-if="VH_PrixLot[index]"
                        class="material-icons-outlined text-green-700 text-base cursor-pointer"
                        @click="setPrixLot(index, line.B1_Artikelnummer[0])"
                      >
                        auto_graph
                      </span>
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-red-500 rounded-full"
                        style="cursor: pointer"
                        @click="deleteLine(index)"
                      >
                        <span
                          class="material-icons-round bg-red-500 rounded-sm text-white"
                          style="font-size: small"
                        >
                          clear
                        </span>
                      </span>
                    </div>

                    <!-- <div
                      class="w-24 flex justify-end"
                      v-if="line.B1_Artikelnummer[0]"
                    >
                      <input
                        type="checkbox"
                        class="items-center"
                        v-model="selected"
                        :value="line"
                      />
                    </div> -->
                    <!-- <span v-for="(l, indexx) in selected" :key="indexx">{{l.Klantnummer_bij_leverancier[0]}}</span> -->
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <span>{{ line.B1_Omschrijving[0] }}</span>
                    <span class="text-gray-500">{{
                      line.B1_Artikelgroep[0]
                    }}</span>
                  </div>
                </td>

                <td
                  style="width: 3%"
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <div class="flex flex-row items-center space-x-2">
                      <span v-show="!showQtyTab[index]">{{
                        parseInt(line.B1_LA_Aantal_response[0]) +
                        parseInt(line.B1_LB_Aantal_response[0])
                      }}</span>
                      <input
                        v-show="showQtyTab[index]"
                        :placeholder="
                          parseInt(line.B1_LA_Aantal_response[0]) +
                          parseInt(line.B1_LB_Aantal_response[0])
                        "
                        v-model="setQtyTab[index]"
                        type="Number"
                        v-on:keyup.enter="
                          modifyQuantity(index, line.B1_Artikelnummer[0])
                        "
                      />
                      <span
                        class="material-icons-round text-green-500 text-sm cursor-pointer"
                        v-show="showQtyTab[index]"
                        @click="modifyQuantity(index, line.B1_Artikelnummer[0])"
                      >
                        done
                      </span>
                      <span
                        class="material-icons-round text-orange-500 text-sm cursor-pointer"
                        v-show="!showQtyTab[index]"
                        @click="showQtyTab[index] = !showQtyTab[index]"
                      >
                        edit
                      </span>
                      <span
                        class="material-icons-round animate-spin text-orange-500 text-base"
                        v-show="loadingQtyTab[index]"
                      >
                        sync
                      </span>
                    </div>
                  </div>
                </td>
                <td
                  style="width: 10%"
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="space-y-1">
                    <div v-if="line.B1_LA_DatumTijd[0]" class="flex">
                      <div
                        class="flex border border-blue-700 p-0.5 rounded shadow items-center"
                      >
                        <span
                          class="material-icons-outlined text-blue-700"
                          style="font-size: 15px"
                          >nightlight</span
                        >
                        <span class="text-xs text-black">
                          {{ line.B1_LA_Aantal_response[0] }}
                        </span>
                        <span
                          class="material-icons-round animate-spin text-orange-500 text-base"
                          v-show="VH_RefreshLoading[index]"
                        >
                          sync
                        </span>
                      </div>
                      <div class="ml-2">le {{ line.B1_LA_DatumTijd[0] }}</div>
                    </div>

                    <div
                      v-if="parseInt(line.B1_LB_Aantal_response[0]) > 0"
                      class="flex"
                    >
                      <div
                        v-if="line.B1_LB_DatumTijd[0]"
                        class="flex border border-yellow-500 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-yellow-500"
                          style="font-size: 15px"
                          >light_mode</span
                        >
                        <span class="text-xs text-black">
                          {{ line.B1_LB_Aantal_response[0] }}
                        </span>
                      </div>
                      <div class="ml-2">le {{ line.B1_LB_DatumTijd[0] }}</div>
                    </div>
                  </div>
                  <!-- <div>
                  Livraison A :
                  <span
                    >{{ line.B1_LA_Aantal_response[0] }} pièces le
                    {{ line.B1_LA_DatumTijd[0] }}</span
                  >
                </div>
                <div>
                  Livraison B :
                  <span v-if="parseInt(line.B1_LB_Aantal_response[0]) > 0"
                    >{{ line.B1_LB_Aantal_response[0] }} pièces le
                    {{ line.B1_LB_DatumTijd[0] }}</span
                  >
                  <span v-else>Non proposé</span>
                </div> -->
                </td>

                <td
                  style="width: 5%"
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <div>
                      {{ parseFloat(line.B1_Brutoprijs[0]).toFixed(2) }} €
                    </div>

                    <div
                      class="flex flex-col"
                      v-if="parseFloat(line.B1_Korting[0]) > 0"
                    >
                      <span class="text-gray-800 text-xs"
                        >PA :
                        {{
                          parseFloat(
                            parseFloat(line.B1_Brutoprijs[0]) -
                              parseFloat(line.B1_Brutoprijs[0]) *
                                (parseFloat(line.B1_Korting[0]) / 100)
                          ).toFixed(2)
                        }}
                        €</span
                      >
                      <span class="text-gray-800 text-xs"
                        >( {{ line.B1_Korting[0] }} % )</span
                      >
                    </div>

                    <span
                      class="text-gray-800 text-xs"
                      v-else-if="line.B1_Nettoprijs[0]"
                      >PA : {{ line.B1_Nettoprijs[0] }} €</span
                    >

                    <span class="text-gray-800 text-xs" v-else
                      >Pas de remise</span
                    >

                    <span
                      class="text-gray-800 text-xs"
                      v-if="parseFloat(line.B1_Korting[0]) == 0"
                      >(prix net)</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="py-2 mt-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          v-if="VH_a02Tab[indexDepot].lines.length > 0"
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full" style="max-height: 18vh">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equiv. 1
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dispo
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(line, index) in VH_a02Tab[indexDepot].lines"
                :key="index"
              >
                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="cursor-pointer"
                    @click="changeReference(index, line.B2_Artikelnummer[0])"
                    >{{ line.B2_Artikelnummer[0] }}</span
                  >
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="space-y-1">
                    <div v-if="line.B2_LA_DatumTijd[0]" class="flex">
                      <div
                        class="flex border border-blue-700 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-blue-700"
                          style="font-size: 15px"
                          >nightlight</span
                        >
                        <span class="text-xs text-black">
                          {{ line.B2_LA_Aantal_response[0] }}
                        </span>
                      </div>
                      <!-- <div class="ml-2">le {{ line.B2_LA_DatumTijd[0] }}</div> -->
                    </div>

                    <div
                      v-if="parseInt(line.B2_LB_Aantal_response[0]) > 0"
                      class="flex"
                    >
                      <div
                        v-if="line.B2_LB_DatumTijd[0]"
                        class="flex border border-yellow-500 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-yellow-500"
                          style="font-size: 15px"
                          >light_mode</span
                        >
                        <span class="text-xs text-black">
                          {{ line.B2_LB_Aantal_response[0] }}
                        </span>
                      </div>
                      <!-- <div class="ml-2">le {{ line.B2_LB_DatumTijd[0] }}</div> -->
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <div>
                      {{ parseFloat(line.B2_Brutoprijs[0]).toFixed(2) }} €
                    </div>

                    <div
                      class="flex flex-col"
                      v-if="parseFloat(line.B2_Korting[0]) > 0"
                    >
                      <span class="text-gray-800 text-xs"
                        >PA :
                        {{
                          parseFloat(
                            parseFloat(line.B2_Brutoprijs[0]) -
                              parseFloat(line.B2_Brutoprijs[0]) *
                                (parseFloat(line.B2_Korting[0]) / 100)
                          ).toFixed(2)
                        }}
                        €</span
                      >
                    </div>

                    <span
                      class="text-gray-800 text-xs"
                      v-else-if="line.B2_Nettoprijs[0]"
                      >PA : {{ line.B2_Nettoprijs[0] }} €</span
                    >

                    <span class="text-gray-800 text-xs" v-else
                      >Pas de remise</span
                    >

                    <span
                      class="text-gray-800 text-xs"
                      v-if="line.B2_Nettoprijs[0]"
                      >(prix net)</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="py-2 mt-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          v-if="VH_a02Tab[indexDepot].lines.length > 0"
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full" style="max-height: 18vh">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equiv. 2
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dispo
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(line, index) in VH_a02Tab[indexDepot].lines"
                :key="index"
              >
                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="cursor-pointer"
                    @click="changeReference(index, line.B3_Artikelnummer[0])"
                    >{{ line.B3_Artikelnummer[0] }}</span
                  >
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="space-y-1">
                    <div v-if="line.B3_LA_DatumTijd[0]" class="flex">
                      <div
                        class="flex border border-blue-700 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-blue-700"
                          style="font-size: 15px"
                          >nightlight</span
                        >
                        <span class="text-xs text-black">
                          {{ line.B3_LA_Aantal_response[0] }}
                        </span>
                      </div>
                      <!-- <div class="ml-2">le {{ line.B3_LA_DatumTijd[0] }}</div> -->
                    </div>

                    <div
                      v-if="parseInt(line.B3_LB_Aantal_response[0]) > 0"
                      class="flex"
                    >
                      <div
                        v-if="line.B3_LB_DatumTijd[0]"
                        class="flex border border-yellow-500 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-yellow-500"
                          style="font-size: 15px"
                          >light_mode</span
                        >
                        <span class="text-xs text-black">
                          {{ line.B3_LB_Aantal_response[0] }}
                        </span>
                      </div>
                      <!-- <div class="ml-2">le {{ line.B3_LB_DatumTijd[0] }}</div> -->
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <div>
                      {{ parseFloat(line.B3_Brutoprijs[0]).toFixed(2) }} €
                    </div>

                    <div
                      class="flex flex-col"
                      v-if="parseFloat(line.B3_Korting[0]) > 0"
                    >
                      <span class="text-gray-800 text-xs">
                        PA :
                        {{
                          parseFloat(
                            parseFloat(line.B3_Brutoprijs[0]) -
                              parseFloat(line.B3_Brutoprijs[0]) *
                                (parseFloat(line.B3_Korting[0]) / 100)
                          ).toFixed(2)
                        }}
                        €</span
                      >
                    </div>

                    <span
                      class="text-gray-800 text-xs"
                      v-else-if="line.B3_Nettoprijs[0]"
                      >PA : {{ line.B3_Nettoprijs[0] }} €</span
                    >

                    <span class="text-gray-800 text-xs" v-else
                      >Pas de remise</span
                    >

                    <span
                      class="text-gray-800 text-xs"
                      v-if="line.B3_Nettoprijs[0]"
                      >(prix net)</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      class="mt-6 flex flex-row space-x-4 justify-center"
      v-if="VH_a02Tab[indexDepot].lines.length > 0"
      v-show="!loadingCommand"
    >
      <div class="flex flex-col">
        <select v-model="selectedDoc">
          <option :value="doc" v-for="(doc, index) in docTab" :key="index">
            {{ doc.type }} N° {{ doc.Numero }} - {{ doc.Client }}
          </option>
        </select>
        <span class="text-red-500" v-show="showDocCommandError"
          >Veuillez choisir un document pour commander !</span
        >
      </div>
      <button
        class="p-2 bg-blue-400 text-white rounded shadow hover:shadow-lg"
        @click="commanderArticle()"
        v-show="!commandState"
      >
        Commander
      </button>
      <button
        class="p-2 bg-green-500 text-white rounded shadow hover:shadow-lg"
        @click="refreshDispo()"
        v-show="!commandState"
      >
        Rafraichir
      </button>
      <button
        v-show="!commandState"
        @click="clearVH_a02Tab({ name: depot })"
        class="p-2 bg-red-500 text-white rounded shadow"
      >
        Vider le panier
      </button>
    </div>
    <div
      class="mt-6 flex flex-row space-x-4 justify-center items-center"
      v-show="loadingCommand"
    >
      <span>Commande en cours...</span>
      <PulseLoader color="#3b82f6" v-show="true" />
    </div>

    <div class="w-1/2" v-show="commandState">
      <button
        class="flex flex-row items-center cursor-pointer w-54 h-8 bg-amber-400 rounded-full hover:bg-opacity-25 shadow px-2 text-white"
        @click="getVrac()"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2">Retour</span>
      </button>
    </div>
    <div class="m-auto flex justify-center" v-show="commandState">
      <div class="flex flex-col rounded-md bg-white p-2">
        <div class="flex justify-center space-x-1">
          <span>Commande passée</span>
          <span class="material-icons-outlined text-green-600"> task_alt </span>
        </div>
        <div v-if="VH_a02Tab[indexDepot].lines">
          <div class="mt-6">
            <div
              class="flex justify-center m-auto space-x-1"
              v-if="VH_a02Tab[0]"
            >
              <span class="text-sm">N° client : </span>
              <span
                class="text-sm"
                v-if="VH_a02Tab[0].Klantnummer_bij_leverancier"
                >{{ VH_a02Tab[0].Klantnummer_bij_leverancier[0] }}</span
              >
            </div>
            <div class="mt-8 flex justify-center">
              <table class="min-w-full" style="max-height: 18vh">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Commande n°
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Réf./Descr.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Livraison
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix brut
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix net
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr v-for="(cmd, index) in savea02Tab" :key="index">
                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div v-if="cmd.Partsnet_ordernummer">
                        <span>{{ cmd.Partsnet_ordernummer[0] }}</span>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex flex-col">
                        <div v-if="cmd.B1_Artikelnummer">
                          <span>{{ cmd.B1_Artikelnummer[0] }}</span>
                        </div>
                        <div v-if="cmd.B1_Omschrijving">
                          <span class="text-gray-700">{{
                            cmd.B1_Omschrijving[0]
                          }}</span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="space-y-1">
                        <div v-if="cmd.B1_LA_DatumTijd[0]" class="flex">
                          <div
                            class="flex border border-blue-700 p-0.5 rounded shadow"
                          >
                            <span
                              class="material-icons-outlined text-blue-700"
                              style="font-size: 15px"
                              >nightlight</span
                            >
                            <span class="text-xs text-black">
                              {{ cmd.B1_LA_Aantal_response[0] }}
                            </span>
                          </div>
                          <div class="ml-2">
                            le {{ cmd.B1_LA_DatumTijd[0] }}
                          </div>
                        </div>

                        <div
                          v-if="parseInt(cmd.B1_LB_Aantal_response[0]) > 0"
                          class="flex"
                        >
                          <div
                            v-if="cmd.B1_LB_DatumTijd[0]"
                            class="flex border border-yellow-500 p-0.5 rounded shadow"
                          >
                            <span
                              class="material-icons-outlined text-yellow-500"
                              style="font-size: 15px"
                              >light_mode</span
                            >
                            <span class="text-xs text-black">
                              {{ cmd.B1_LB_Aantal_response[0] }}
                            </span>
                          </div>
                          <div class="ml-2">
                            le {{ cmd.B1_LB_DatumTijd[0] }}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div>
                        <span v-if="cmd.B1_Brutoprijs"
                          >{{ cmd.B1_Brutoprijs[0] }} €</span
                        >
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div>
                        <span v-if="cmd.B1_Nettoprijs"
                          >{{ cmd.B1_Nettoprijs[0] }} €</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      Ref_fournisseur: "",
      Quantity: "",
      // logCode: "9980177",
      errorChamps: false,
      loading: false,
      showDispo1: [],
      showDispo2: [],
      index: 0,
      resultA05: [],
      savea02Tab: [],
      loadingCommand: false,
      commandState: false,
      blCommandTab: [],
      factureCommandTab: [],
      docTab: [],
      selectedDoc: [],
      showDocCommandError: false,
      showQtyTab: [],
      loadingQtyTab: [],
      setQtyTab: [],
      PrixLot: [],
      indexSelectedPL: 0,
      refSelectedPL: "",
      PL_selected: [],
      openRefSimil: false,
      openRefArticle: false,
      RefSim: [],
      QuantityTMP: 0,
      depot: "STANDARD",
      depotTab: [],
      selected: [],
      indexDepot: 0,
      TabSchiedam: [],
      showDepot: [],
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters([
      "user",
      "plateform",
      "VH_a02Tab",
      "VH_articleTab",
      "VH_PrixLot",
      "VH_RefreshLoading",
    ]),
  },
  methods: {
    ...mapActions([
      "addVH_a02Tab",
      "suppVH_a02Tab",
      "clearVH_a02Tab",
      "addVH_PrixLot",
      "suppVH_PrixLot",
      "refresh_a02Tab",
      "addtabdepot",
      "getpanierfournisseur",
    ]),
    async getDocBLF() {
      const doc_bl = await axios.post(
        `${process.env.VUE_APP_API}/bdl/getCommandVH`,
        {
          plateform: this.user.proprietaire,
        }
      );
      const doc_facture = await axios.post(
        `${process.env.VUE_APP_API}/facture/getCommandVH`,
        {
          plateform: this.user.proprietaire,
        }
      );
      for (const bl of doc_bl.data) {
        this.docTab.push(bl);
      }
      for (const facture of doc_facture.data) {
        this.docTab.push(facture);
      }
    },
    focusNext() {
      this.$refs.qty.focus();
    },
    getSchiedam(artSelected, index) {
      // artSelected.Klantnummer_bij_leverancier[0] = "9980824";
      this.TabSchiedam = [];
      this.showDepot[index] = true;
      this.TabSchiedam.push(artSelected);
      console.log(this.TabSchiedam);
    },
    sendRef(indexdepotNew, indexLineTab, ref, qty) {
      this.showDepot[indexLineTab] = false;
      this.deleteLine(indexLineTab);
      this.changeDepot(
        this.depotTab[indexdepotNew].name,
        indexdepotNew,
        this.depotTab[indexdepotNew].code
      );
      this.Ref_fournisseur = ref;
      this.Quantity = qty;
      this.checkArticle();
    },
    checkArticle() {
      if (this.Ref_fournisseur && this.Quantity) {
        this.commandState = false;
        this.errorChamps = false;
        this.loading = true;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: this.Ref_fournisseur,
              Quantity: this.Quantity,
              logCode: this.plateform.grossierID,
              login: this.plateform.grossierID,
            }
          )
          .then((res) => {
            this.loading = false;
            // this.addVH_a02Tab(res.data.Regels[0].A02Regel[0]);
            this.getPrixLot(this.Ref_fournisseur);
            var dataTab = res.data;
            dataTab["name"] = this.depot;
            this.addVH_a02Tab(dataTab);
          });
      } else {
        this.errorChamps = true;
      }
    },
    changeReference(index, article) {
      if (article != "") {
        this.Ref_fournisseur = article;
        this.loading = true;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: article,
              Quantity: this.Quantity,
              logCode: this.plateform.grossierID,
              login: this.plateform.grossierID,
            }
          )
          .then((res) => {
            this.loading = false;
            this.deleteLine(index);
            // this.addVH_a02Tab(res.data.Regels[0].A02Regel[0]);
            this.getPrixLot(article);
            var dataTab = res.data;
            dataTab["name"] = this.depot;
            this.addVH_a02Tab(dataTab);
          });
      } else {
        alert("vide");
      }
    },
    commanderArticle() {
      if (this.selectedDoc.type) {
        this.commandState = false;
        this.loadingCommand = true;
        axios
          .post(`${process.env.VUE_APP_API}/commandeFournisseur/commanderA05`, {
            infoCommande: this.VH_a02Tab[this.indexDepot].lines,
            plateform: this.user.proprietaire,
            Document: this.selectedDoc,
            Articles: this.VH_a02Tab[this.indexDepot].Articles,
            User: this.user.username,
            login: this.plateform.grossierID,
          })
          .then((res) => {
            this.loadingCommand = false;
            this.commandState = true;
            this.resultA05 = res.data;
            this.savea02Tab = this.VH_a02Tab[this.indexDepot].lines;

            this.showDocCommandError = false;
            this.clearVH_a02Tab({ name: this.depot });
            if (this.selectedDoc.type == "BL") {
              axios
                .put(`${process.env.VUE_APP_API}/bdl/${this.selectedDoc._id}`, {
                  command_VH: true,
                })
                .then(() => {
                  this.selectedDoc = [];
                });
            } else if (this.selectedDoc.type == "Facture") {
              axios
                .put(
                  `${process.env.VUE_APP_API}/facture/${this.selectedDoc._id}`,
                  {
                    command_VH: true,
                  }
                )
                .then(() => {
                  this.selectedDoc = [];
                });
            }
          });
      } else {
        this.showDocCommandError = true;
      }
    },
    deleteLine(index) {
      this.suppVH_a02Tab({
        index: index,
        name: this.depot,
        indexDepot: this.indexDepot,
      });
      this.suppVH_PrixLot({ index: index });
    },
    getVrac() {
      window.location.reload();
    },
    modifyQuantity(index, Ref_fournisseur) {
      this.loadingQtyTab[index] = true;
      if (this.setQtyTab[index]) {
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: Ref_fournisseur,
              Quantity: this.setQtyTab[index],
              logCode: this.plateform.grossierID,
              login: this.plateform.grossierID,
            }
          )
          .then((res) => {
            this.loading = false;
            this.deleteLine(index);
            // this.addVH_a02Tab(res.data.Regels[0].A02Regel[0]);
            this.getPrixLot(Ref_fournisseur);
            var dataTab = res.data;
            dataTab["name"] = this.depot;
            this.addVH_a02Tab(dataTab);
            this.showQtyTab[index] = !this.showQtyTab[index];
            this.loadingQtyTab[index] = false;
          });
      }
    },
    modifyQuantityPL(qty) {
      qty = parseInt(qty);
      this.loadingQtyTab[this.indexSelectedPL] = true;
      if (qty) {
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: this.refSelectedPL,
              Quantity: qty,
              logCode: this.plateform.grossierID,
              login: this.plateform.grossierID,
            }
          )
          .then((res) => {
            this.loading = false;
            this.deleteLine(this.indexSelectedPL);
            // this.addVH_a02Tab(res.data.Regels[0].A02Regel[0]);
            this.getPrixLot(this.refSelectedPL);
            var dataTab = res.data;
            dataTab["name"] = this.depot;
            this.addVH_a02Tab(dataTab);
            this.loadingQtyTab[this.indexSelectedPL] = false;
            this.openRefSimil = false;
          });
      }
    },
    getPrixLot(ref) {
      axios
        .get(`${process.env.VUE_APP_API}/vanheck/getPrixLot/${ref}`)
        .then((response) => {
          this.addVH_PrixLot(response.data);
        });
    },
    setPrixLot(index, Ref_fournisseur) {
      this.indexSelectedPL = index;
      this.refSelectedPL = Ref_fournisseur;
      this.PL_selected = this.VH_PrixLot[index];
      this.openRefSimil = true;
    },
    getRefSimilaire(ref, index, qty) {
      this.indexSelectedPL = index;
      this.QuantityTMP = qty;
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: ref,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.RefSim[index] = response.data;
          console.log(this.RefSim[index]);
          this.openRefArticle = true;
        });
    },
    getFournisseursDepot() {
      axios
        .post(`${process.env.VUE_APP_API}/panierFournisseur/getactivated`, {
          plateform: this.user.proprietaire,
          fournisseur: "VAN HECK",
        })
        .then((res) => {
          // this.fournisseurs = res.data;
          if (res.data[0].accountActivated) {
            console.log(res.data[0].Credential[0].entrepot);
            this.depotTab = res.data[0].Credential[0].entrepot;
          }
        });
    },
    changeDepot(depotToChange, indexToChange, codeDepot) {
      this.depot = depotToChange;
      this.plateform.grossierID = codeDepot;
      this.indexDepot = indexToChange + 1;
      this.getpanierfournisseur({ name: depotToChange });
    },
    async refreshDispo() {
      await this.refresh_a02Tab({ index: this.indexDepot });
    },
  },
  beforeMount() {
    this.getpanierfournisseur({ name: this.depot });
    this.getFournisseursDepot();
  },
  mounted() {
    this.getDocBLF();
  },
};
</script>
<style></style>
