import axios from "axios";

export async function getInventaireInfos(id: any) {
  const inventaireInfos = await axios.get(
    `${process.env.VUE_APP_API}/inventaire/getInventoryInfos/${id}`
  );
  return inventaireInfos.data;
}

export async function updateManualyQty(
  _id: string,
  quantity_inventory: number,
  userID: string
) {
  const updateManualyQty = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/update-line`,
    {
      _id: _id,
      data: {
        quantity_inventory: quantity_inventory,
      },
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );
  return updateManualyQty.data;
}

export async function createInventory(
  name: string,
  inventory_type: string,
  init_type: string,
  address: string,
  Prefixe_tarif: string,
  brand: Array<string>,
  family: string,
  family_name: string,
  plateform: string,
  userID: string
) {
  //axios call to create a new inventaire
  const createInventory = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/create`,
    {
      name: name,
      inventory_type: inventory_type,
      init_type: init_type,
      address: address,
      Prefixe_tarif: Prefixe_tarif,
      brand: brand,
      family: family,
      family_name: family_name,
      plateform: plateform,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );

  return createInventory.data;
}

export async function getInventory(
  plateform: string,
  state: string,
  skip: number,
  limit: number,
  sort_by: string,
  sort_order: string,
  userID: string
) {
  const getInventory = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/get`,
    {
      plateform: plateform,
      state: state,
      skip: skip,
      limit: limit,
      sort_by: sort_by,
      sort_order: sort_order,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );

  return getInventory.data;
}

export async function getInventoryLines(
  inventory_id: string,
  skip: number,
  limit: number,
  sort_by: string,
  sort_order: string,
  userID: string
) {
  const getInventoryLines = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/get-line`,
    {
      inventory_id: inventory_id,
      skip: skip,
      limit: limit,
      sort_by: sort_by,
      sort_order: sort_order,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );

  return getInventoryLines.data;
}

export async function addInventoryLine(
  plateform: string,
  inventory_id: string,
  article_id: string,
  inventory_number: number,
  Ref_fournisseur: string,
  Code_EAN: string,
  Code_marque: string,
  Prefixe_tarif: string,
  Prix_euro: number,
  quantity_theo: number,
  quantity_inventory: number,
  userID: string
) {
  const addInventoryLine = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/add-line`,
    {
      plateform: plateform,
      inventory_id: inventory_id,
      article_id: article_id,
      inventory_number: inventory_number,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      Prefixe_tarif: Prefixe_tarif,
      Prix_euro: Prix_euro,
      quantity_theo: quantity_theo,
      quantity_inventory: quantity_inventory,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );
  return addInventoryLine.data;
}

export async function updateInventory(
  _id: string,
  data: object,
  userID: string
) {
  const updateInventory = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/update`,
    {
      _id: _id,
      data: data,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );
  return updateInventory.data;
}

export async function updateInventoryStock(inventory: object, userID: string) {
  const updateInventoryStock = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/updateInventoryStock`,
    {
      inventory: inventory,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );
  return updateInventoryStock.data;
}

export async function getNoScannedArticles(inventory: string, userID: string) {
  const getNoScannedArticles = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/getNoScannedArticles`,
    {
      inventory: inventory,
    },
    {
      headers: {
        Authorization: userID,
      },
    }
  );
  return getNoScannedArticles.data;
}

export async function searchRef(
  user_id: string,
  Ref_fournisseur: string,
  Brand: Array<string>,
  Prefixe_tarif: string,
  plateform: string
) {
  const searchRef = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/searchRef`,
    {
      Ref_fournisseur: Ref_fournisseur,
      Brand: Brand,
      Prefixe_tarif: Prefixe_tarif,
      plateform: plateform,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return searchRef.data;
}

export async function scanRef(
  user_id: string,
  Code_EAN: string,
  Brand: Array<string>,
  Prefixe_tarif: string,
  plateform: string
) {
  const scanRef = await axios.post(
    `${process.env.VUE_APP_STOCK}/inventory/scanRef`,
    {
      Code_EAN: Code_EAN,
      Brand: Brand,
      Prefixe_tarif: Prefixe_tarif,
      plateform: plateform,
    },
    {
      headers: {
        Authorization: user_id,
      },
    }
  );

  return scanRef.data;
}
