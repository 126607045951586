<template>
  <div
    v-if="openImage && Images.length > 0 && Images[0].imageURL800"
    :class="`modal ${
      !openImage && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openImage = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; height: 60vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openImage = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex flex-row items-center space-x-2 h-[45vh] justify-center"
        >
          <div class="flex flex-col space-y-1 h-full overflow-y-auto">
            <div
              v-for="(img, indexImg) in Images"
              :key="indexImg"
              class="bg-orange-100 w-20 cursor-pointer"
            >
              <img
                :src="img.imageURL200"
                alt="image"
                class="w-full"
                @click="selectedImage = img.imageURL800"
              />
            </div>
          </div>
          <div class="w-3/4">
            <img :src="selectedImage" alt="image" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="openToDBArt && Images.length > 0 && Images[0].imageURL800"
    :class="`modal ${
      !openToDBArt && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openToDBArt = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; height: 50vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openToDBArt = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col space-y-2 h-[45vh] justify-center">
          <div class="grid grid-cols-4 gap-4">
            <div>Ref : {{ Ref_fournisseur }}</div>
            <div>
              EAN :
              <div v-if="tdArticle.gtins && tdArticle.gtins.length > 0">
                {{ tdArticle.gtins[0] }}
              </div>
              <div v-else>
                <input
                  class="border border-gray-300 rounded"
                  type="text"
                  v-model="addEAN"
                />
              </div>
            </div>
            <div>Description : {{ Description }}</div>
            <div>Marque : {{ Code_marque }}</div>
          </div>
          <div class="grid grid-cols-4 gap-4">
            <div>
              <input
                list="fournisseurToAdd"
                class="border border-gray-300 rounded"
                @change="setFournisseurInfos()"
                v-model="addFournisseur"
              />
              <datalist id="fournisseurToAdd">
                <option
                  v-for="(c, index) in fournisseurList"
                  :key="index"
                  :value="c.CompanyName"
                ></option>
              </datalist>
            </div>
            <div>Prefixe_tarif : {{ Prefixe_tarif_to_add }}</div>
            <div>
              <div v-if="errorCodeRemise" class="text-red-500">
                Veuillez ajouter un code remise
              </div>
              <div>Code_remise :</div>
              <div v-if="condTab.length > 0">
                <div v-if="fournisseurTypeCondition != 'prixnet'">
                  <input
                    list="condToAdd"
                    class="border border-gray-300 rounded"
                    v-model="addCondition"
                  />
                  <datalist id="condToAdd">
                    <option
                      v-for="(c, index) in condTab"
                      :key="index"
                      :value="c.familleFournisseur"
                    ></option>
                  </datalist>
                </div>
                <div v-else>Prix Net</div>
              </div>
              <div v-else-if="fournisseurTypeCondition == 'prixnet'">
                Prix Net
              </div>
            </div>
            <div>
              <div v-if="fournisseurTypeCondition != 'prixnet'">
                Prix public :
                <input
                  class="border border-gray-300 rounded"
                  type="Number"
                  v-model="Prix_euro_to_add"
                />
              </div>
              <div v-else class="flex flex-col space-x-1">
                <div>
                  Prix public :
                  <input type="Number" v-model="Prix_euro_to_add" />
                </div>
                <div>
                  PNF :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNF_to_add"
                  />
                </div>
                <div>
                  PNT1 :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNT1_to_add"
                  />
                </div>
                <div>
                  PNT2 :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNT2_to_add"
                  />
                </div>
                <div>
                  PNT3 :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNT3_to_add"
                  />
                </div>
                <div>
                  PNT4 :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNT4_to_add"
                  />
                </div>
                <div>
                  PNT5 :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNT5_to_add"
                  />
                </div>
                <div>
                  PNT6 :
                  <input
                    class="border border-gray-300 rounded"
                    type="Number"
                    v-model="PNT6_to_add"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="addFournisseur != ''" class="flex justify-end">
            <button
              v-if="!loadingAddToDBArt"
              class="p-1 bg-blue-500 text-white rounded shadow"
              @click="addArticle()"
            >
              Ajouter
            </button>
            <button
              v-else
              class="p-1 bg-gray-300 text-white rounded shadow animate-pulse"
              disabled
            >
              Ajout en cours
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row p-2 bg-white rounded shadow w-full h-fit">
    <div class="hidden md:flex my-auto bg-blue-100">
      <input
        type="checkbox"
        disabled
        v-if="compareTabLength > 2 && compareTabLength <= 3 && !checkbox_value"
        v-model="checkbox_value"
        @change="$emit('checkedArticle')"
      />
      <input
        type="checkbox"
        v-else
        v-model="checkbox_value"
        @change="$emit('checkedArticle')"
      />
    </div>
    <div class="hidden md:flex md:flex-col my-auto space-y-2 w-10">
      <span
        class="material-icons-round cursor-pointer text-blue-500"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
            genArt: genericArticleId,
          }),
            $emit('checkCross')
        "
      >
        sync
      </span>
    </div>
    <div class="w-2/12">
      <img
        v-if="Images.length > 0 && Images[0].imageURL200"
        @click="openImages()"
        :src="Images[0].imageURL200"
        alt="image"
        class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-34 lg:h-34 cursor-pointer"
      />
      <img
        v-else
        src="@/assets/not-found.png"
        alt="image"
        class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-34 lg:h-34"
      />
    </div>
    <div class="flex flex-col w-2/5 md:w-2/12 text-sm md:text-base">
      <div class="w-fit text-blue-500 cursor-pointer" @click="openImages()">
        {{ Ref_fournisseur }}
      </div>
      <div class="flex lg:hidden font-bold">{{ Code_marque }}</div>
      <div class="flex md:hidden">
        <!-- <stockvue
          class="my-auto"
          :Stock="tmp_stock"
          :Ref_fournisseur="Ref_fournisseur"
          :Code_marque="Code_marque"
        /> -->
      </div>
    </div>
    <div class="w-1/12 hidden lg:flex lg:flex-col">
      <div class="font-bold">{{ Code_marque }}</div>
      <div></div>
    </div>
    <div class="w-2/12 hidden lg:flex">{{ Description }}</div>
    <div class="w-2/12 lg:1/12 hidden md:flex md:flex-col md:space-y-2 mx-auto">
      <span class="w-full">{{ systemeFreinage }}</span>
      <span class="w-full">{{ coteAssemblage }}</span>
      <span class="w-full">{{ typeMontage }}</span>
    </div>
    <div class="w-3/12 lg:3/12 hidden md:flex md:flex-col md:space-y-2">
      <!-- <span v-if="Systeme.formattedValue">{{ Systeme.formattedValue }}</span>
      <span v-if="Location.formattedValue">{{ Location.formattedValue }}</span> -->
      <button
        class="flex flex-row items-center space-x-1 mx-auto text-sm text-white bg-blue-500 py-1 px-2 rounded shadow"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
            genArt: genericArticleId,
          }),
            $emit('checkCross')
        "
      >
        <span>Voir alternatif</span>
        <span class="material-icons-round"> search </span>
      </button>
    </div>
    <div class="w-3/12 lg:3/12 hidden md:flex md:flex-col md:space-y-2">
      <button
        @click="openAddToDBArt()"
        class="p-1 bg-blue-500 text-white rounded shadow w-fit"
      >
        Ajouter à la base article
      </button>
    </div>
  </div>
</template>
<script>
import stockvue from "@/components/Article/stock.vue";
import prixvue from "@/components/Article/prix.vue";
// import paniervue from "@/components/Article/panier.vue";
import { checkStock } from "@/hooks/stock/stock.ts";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "catalogueTD",
  data() {
    return {
      tmp_stock: {},
      checkbox_value: false,
      openImage: false,
      selectedImage: "",
      openToDBArt: false,
      fournisseurList: [],
      addFournisseur: "",
      addCondition: "",
      addEAN: "",
      Prefixe_tarif_to_add: "",
      condTab: [],
      fournisseurTypeCondition: "",
      errorCodeRemise: false,
      loadingAddToDBArt: false,
      Prix_euro_to_add: 0.0,
      PNF_to_add: 0.0,
      PNT1_to_add: 0.0,
      PNT2_to_add: 0.0,
      PNT3_to_add: 0.0,
      PNT4_to_add: 0.0,
      PNT5_to_add: 0.0,
      PNT6_to_add: 0.0,
      systemeFreinage: "",
      coteAssemblage: "",
      typeMontage: "",
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    tdArticle: { type: Object, default: () => ({}) },
    Ref_fournisseur: String,
    Description: String,
    Code_marque: String,
    genericArticleId: Number,
    mfrId: Number,
    Images: Array,
    Location: Object,
    Systeme: Object,
    Stock: {
      type: Object,
      default: () => ({
        state: "En rupture",
        stock: 0,
        stockFournisseur: 0,
        adresse: "",
        min: 0,
        max: 0,
        filliale: [],
      }),
    },
    Prix: {
      type: Object,
      default: () => ({
        promo: false,
        prix_achat: 0.0,
        prix_brut: 0.0,
        prix_net: 0.0,
      }),
    },
    quantity: Number,
    compareTabLength: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // stockvue,
    // prixvue,
    // paniervue,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["searchRefFournisseurGen"]),
    async checkQuantity(n) {
      this.tmp_stock = await checkStock(
        this.user.proprietaire,
        this.article.Ref_fournisseur,
        this.article._id,
        this.article.Code_marque,
        this.article.Prefixe_tarif,
        n,
        this.user._id
      );
    },
    openImages() {
      this.openImage = true;
      this.selectedImage = this.Images[0].imageURL800;
    },
    openAddToDBArt() {
      this.openToDBArt = true;
    },
    async getFournisseur() {
      const fourn = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getall`,
        {
          plateform: this.user.proprietaire,
        }
      );

      this.fournisseurList = fourn.data;
    },
    async setFournisseurInfos() {
      const fourn = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getFournisseurPTCR`,
        {
          plateform: this.user.proprietaire,
          fournisseur: this.addFournisseur,
        }
      );
      this.Prefixe_tarif_to_add = fourn.data.Prefixe_tarif;
      this.condTab = fourn.data.condTab;
      this.fournisseurTypeCondition = fourn.data.typeCondition;
    },
    async addArticle() {
      this.loadingAddToDBArt = true;
      let regexRef = this.Ref_fournisseur.toString();
      regexRef = regexRef.replace(/\s/g, "");
      regexRef = regexRef.replace(/[^\w\s]/gi, "");
      regexRef = regexRef.toUpperCase();
      if (this.fournisseurTypeCondition == "prixnet") {
        const addArt = await axios.post(`${process.env.VUE_APP_API}/article`, {
          plateform: this.user.proprietaire,
          Ref_fournisseur: this.Ref_fournisseur,
          Ref_fournisseur_trim: regexRef,
          Code_EAN:
            this.tdArticle.gtins && this.tdArticle.gtins.length > 0
              ? this.tdArticle.gtins[0].toString()
              : this.addEAN,
          Description: this.Description,
          Code_marque: this.Code_marque,
          Prefixe_tarif: this.Prefixe_tarif_to_add,
          Code_remise: this.addCondition,
          Prix_euro: this.Prix_euro_to_add,
          PNF: this.PNF_to_add,
          PNT1: this.PNT1_to_add,
          PNT2: this.PNT2_to_add,
          PNT3: this.PNT3_to_add,
          PNT4: this.PNT4_to_add,
          PNT5: this.PNT5_to_add,
          PNT6: this.PNT6_to_add,
        });
      } else {
        if (this.addCondition != "") {
          const addArt = await axios.post(
            `${process.env.VUE_APP_API}/article`,
            {
              plateform: this.user.proprietaire,
              Ref_fournisseur: this.Ref_fournisseur,
              Ref_fournisseur_trim: regexRef,
              Code_EAN:
                this.tdArticle.gtins && this.tdArticle.gtins.length > 0
                  ? this.tdArticle.gtins[0].toString()
                  : this.addEAN,
              Description: this.Description,
              Code_marque: this.Code_marque,
              fournisseur: this.addFournisseur,
              Prefixe_tarif: this.Prefixe_tarif_to_add,
              Code_remise: this.addCondition,
              Prix_euro: this.Prix_euro_to_add,
            }
          );
          this.errorCodeRemise = false;
        } else {
          this.errorCodeRemise = true;
        }
      }

      this.loadingAddToDBArt = false;
      this.openToDBArt = false;
    },
  },
  mounted() {
    this.tmp_stock = this.Stock;
    this.getFournisseur();
    if (this.tdArticle != {} && this.tdArticle.articleCriteria) {
      for (const criteria of this.tdArticle.articleCriteria) {
        if (criteria.criteriaDescription === "Système de freinage") {
          this.systemeFreinage = criteria.formattedValue;
        }
        if (criteria.criteriaDescription === "Côté d'assemblage") {
          this.coteAssemblage = criteria.formattedValue;
        }
        if (criteria.criteriaDescription === "Type de montage") {
          this.typeMontage = criteria.formattedValue;
        }
      }
    }
  },
};
</script>
<style></style>
