import axios from "axios";

export async function getPanier(plateform: string) {
  const panier = await axios.post(
    `${process.env.VUE_APP_API}/panierFournisseurV2/getPanier`,
    {
      plateform: plateform,
    }
  );
  return panier.data;
}

export async function addPanierInst(
  plateform: string,
  article: object,
  quantity: number
) {
  const addPanierInst = await axios.post(
    `${process.env.VUE_APP_API}/panierFournisseurV2/addpanierinst`,
    {
      plateform: plateform,
      data: article,
      quantity: quantity,
    }
  );
  return addPanierInst.data;
}

export async function removePanierInst(plateform: string, article: object) {
  const removePanierInst = await axios.put(
    `${process.env.VUE_APP_API}/panierFournisseurV2/removeInst/${plateform}`,
    article
  );

  return removePanierInst.data;
}

export async function emptyPanierInst(plateform: string) {
  const emptyPanierInst = await axios.put(
    `${process.env.VUE_APP_API}/panierFournisseurV2/emptyPanierInst/${plateform}`
  );

  return emptyPanierInst.data;
}

export async function savePanier(plateform: string, panier: object) {
  const savePanier = await axios.post(
    `${process.env.VUE_APP_API}/panierFournisseurV2/savePanier`,
    {
      plateform: plateform,
      panier: panier,
    }
  );

  return savePanier.data;
}

export async function prepareCommande(
  plateform: string,
  commande: Array<object>,
  fournisseurSelected: string
) {
  const prepareCommande = await axios.post(
    `${process.env.VUE_APP_API}/preparationFournisseurV2/prepareCommandeInst`,
    {
      plateform: plateform,
      commande: commande,
      fournisseurSelected: fournisseurSelected,
    }
  );
  return prepareCommande.data;
}

export async function getCommandeInst(plateform: string) {
  const getCommandeInst = await axios.get(
    `${process.env.VUE_APP_API}/preparationFournisseurV2/getCommandeInst/${plateform}`
  );
  return getCommandeInst.data;
}

export async function prepareReception(
  plateform: string,
  commande: Array<object>,
  user: string,
  fournisseur: string,
  type: string,
  refCommande: string,
  comCommande: string,
  Regels: Array<any>,
  logCode: string,
  login: string
) {
  const prepareReception = await axios.post(
    `${process.env.VUE_APP_API}/preparationFournisseurV2/prepareReception`,
    {
      plateform: plateform,
      commande: commande,
      user: user,
      fournisseur: fournisseur,
      type: type,
      refCommande: refCommande,
      comCommande: comCommande,
      Regels: Regels,
      logCode: logCode,
      login: login,
    }
  );
  return prepareReception.data;
}

export async function updatePreparation(plateform: string, commande: object) {
  const updatePreparation = await axios.post(
    `${process.env.VUE_APP_API}/preparationFournisseurV2/updatePreparation`,
    {
      plateform: plateform,
      commande: commande,
    }
  );
  return updatePreparation.data;
}

export async function refreshDispo(
  plateform: string,
  fournisseur: string,
  Ref_fournisseur: string,
  quantity: number,
  logCode: string,
  login: string
) {
  try {
    const refreshDispo = await axios.post(
      `${process.env.VUE_APP_API}/preparationFournisseurV2/refreshDispo`,
      {
        plateform: plateform,
        fournisseur: fournisseur,
        Ref_fournisseur: Ref_fournisseur,
        quantity: quantity,
        logCode: logCode,
        login: login,
      }
    );
    return refreshDispo.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function prepareChangeDepotVH(
  plateform: string,
  articles: Array<object>,
  depot: object,
  indexDepot: number
) {
  const prepareChangeDepotVH = await axios.post(
    `${process.env.VUE_APP_API}/preparationFournisseurV2/prepareChangeDepotVH`,
    {
      plateform: plateform,
      articles: articles,
      depot: depot,
      indexDepot: indexDepot,
    }
  );
  return prepareChangeDepotVH.data;
}

export async function changeDepotVH(
  plateform: string,
  articles: Array<object>
) {
  const changeDepotVH = await axios.post(
    `${process.env.VUE_APP_API}/preparationFournisseurV2/changeDepotVH`,
    {
      plateform: plateform,
      articles: articles,
    }
  );
  return changeDepotVH.data;
}

export async function createReception(
  User: string,
  plateform: string,
  fournisseur: string,
  Articles: Array<object>,
  Name: string,
  Type: string,
  Document: Array<object>,
  Regels: Array<object>,
  id_commande: string
) {
  const createRecep = await axios.post(
    `${process.env.VUE_APP_API}/commandeFournisseur`,
    {
      plateform: plateform,
      User: User,
      Articles: Articles,
      Date: Date.now(),
      Type: Type,
      Name: Name,
      fournisseur: fournisseur,
      Document: Document,
      Regels: Regels,
      id_commande: id_commande,
    }
  );
  return createRecep.data;
}

export async function validateReception(
  plateform: string,
  id_commande: string,
  Articles: Array<object>,
  receptionneur: string
) {
  const validateReception = await axios.post(
    `${process.env.VUE_APP_API}/commandeFournisseur/validateReception`,
    {
      plateform: plateform,
      id_commande: id_commande,
      Articles: Articles,
      receptionneur: receptionneur,
    }
  );
  return validateReception.data;
}
