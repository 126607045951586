<template>
  <div
    v-if="showEmailIn"
    :class="`modal ${
      !showEmailIn && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="getInfosCommandes(), (showEmailIn = false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold text-black">
            Envoyer par e-mail le document
          </p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showEmailIn = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->

        <div class="flex justify-center text-gray-900 bg-gray-200 rounded-md">
          <div class="flex flex-col space-y-2">
            <div class="space-y-1">
              <p v-if="errors.length">
                <span
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-red-600"
                  >{{ error }}</span
                >
              </p>
              <p v-else></p>
              <div class="flex m-auto space-x-1">
                <label for="emailFrom" class="m-auto w-6 text-xs">De :</label>
                <input
                  @change="errors = []"
                  disabled
                  type="email"
                  id="emailFrom"
                  name="emailFrom"
                  placeholder="Expéditeur"
                  style="width: 22rem"
                  class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  v-model="plateform.Email"
                />
              </div>

              <div>
                <div>
                  <div
                    v-if="
                      tabInfosCommandes.Emails &&
                      tabInfosCommandes.Emails.length > 0
                    "
                  >
                    <div class="flex flex-col space-y-2">
                      <div
                        v-for="(email, index) in tabInfosCommandes.Emails"
                        :key="index"
                      >
                        <div class="flex m-auto space-x-1">
                          <label for="emailTo" class="m-auto w-6 text-xs"
                            >À :</label
                          >
                          <div class="relative">
                            <input
                              @change="
                                (errors = []),
                                  checkForm(plateform.Email, email.address)
                              "
                              required
                              type="email"
                              id="emailTo"
                              name="emailTo"
                              placeholder="Destinataire"
                              style="width: 22rem"
                              class="w-full border border-gray-600 focus:outline-2 outline-sky-300 w-full px-2 py-1 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              v-model="email.address"
                            />
                            <span
                              @click="removeEmail(tabInfosCommandes, index)"
                              style="font-size: 15px"
                              class="absolute my-2 -mx-5 material-icons-outlined cursor-pointer rounded-full hover:bg-red-100 text-white hover:from-transparent text-red-500"
                              title="Supprimer"
                              >cancel</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-end mt-2">
                        <button
                          @click="addEmail(tabInfosCommandes)"
                          class="w-fit p-1 font-medium tracking-wide text-white bg-cyan-600 rounded-md hover:bg-cyan-500 focus:outline-none"
                        >
                          Ajouter un e-mail
                        </button>
                      </div>
                    </div>
                    <!--Footer-->
                    <div class="flex justify-center space-x-1 mt-4">
                      <button
                        @click="getInfosCommandes(), (showEmailIn = false)"
                        class="px-6 py-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                      >
                        Annuler
                      </button>
                      <button
                        class="w-fit cursor-pointer px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
                        @click="
                          sendEmail(
                            tab,
                            index,
                            plateform.Email,
                            tabInfosCommandes.Emails
                          )
                        "
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>

                  <div v-else>
                    <div class="flex m-auto space-x-1">
                      <label for="emailTo" class="m-auto w-6 text-xs"
                        >À :</label
                      >
                      <input
                        @change="
                          (errors = []),
                            checkForm(plateform.Email, tabInfosCommandes.email)
                        "
                        required
                        type="email"
                        id="emailTo"
                        name="emailTo"
                        placeholder="Destinataire"
                        style="width: 22rem"
                        class="w-full border border-gray-600 focus:outline-2 outline-sky-300 w-full px-2 py-1 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        v-model="tabInfosCommandes.email"
                      />
                    </div>
                    <!--Footer-->
                    <div class="flex justify-center space-x-1 mt-4">
                      <button
                        @click="getInfosCommandes(), (showEmailIn = false)"
                        class="px-6 py-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                      >
                        Annuler
                      </button>
                      <button
                        class="w-fit cursor-pointer px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
                        @click="
                          sendEmail(
                            tab,
                            index,
                            plateform.Email,
                            tabInfosCommandes.email
                          )
                        "
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { sendemailcommande } from "@/hooks/email/emailCommande";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showEmailIn: [],
      tabInfosCommandes: [],
      tab: [],
      index: 0,
      infosCommandes: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  props: ["showEmail", "ind", "objet", "tabCommandes", "tabTempInfosCommandes"],
  methods: {
    getInfosCommandes() {
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getByOwner`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.infosCommandes = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailFrom, emailTo) {
      console.log(emailTo);
      this.errors = [];
      if (!emailFrom || !emailTo) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailFrom) || !this.validEmail(emailTo)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    addEmail(objet) {
      objet.Emails.push({
        address: "",
        devis: false,
        bondecommande: false,
        bondetransport: false,
        bondelivraison: false,
        facture: false,
      });
      console.log(objet);
    },
    removeEmail(objet, index) {
      this.errors = [];
      console.log(objet);
      objet.Emails.splice(index, 1);
    },
    sendEmail(objet, index, emailFrom, emailTo) {
      console.log(emailTo);
      if (this.errors.length > 0) {
        this.toast.info("Vérifiez les adresses e-mails !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        sendemailcommande(objet, emailFrom, emailTo);
        this.toast.success("Bon de commande envoyé !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.showEmailIn = false;
        this.getInfosCommandes();
      }
    },
  },
  mounted() {
    this.tab = this.objet;
    console.log(this.objet);
    this.index = this.ind;
    this.tabInfosCommandes = this.tabCommandes;
    this.showEmailIn = this.showEmail;
    console.log(this.tabCommandes);
    this.infosCommandes = this.tabTempInfosCommandes;
  },
};
</script>

<style></style>
