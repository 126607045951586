<template>
  <div id="body" class="bg-white p-2 rounded-md">
    <div class="flex justify-between">
      <div class="text-2xl font-medium ml-2">
        <h2>Créer un fournisseur</h2>
      </div>
      <router-link to="/Fournisseurs">
        <div class="flex justify-end">
          <span
            style="font-size: 30px"
            class="material-icons-outlined cursor-pointer rounded-full text-red-700 hover:text-red-600"
            >close</span
          >
        </div>
      </router-link>
    </div>
    <div class="flex justify-center mt-8" id="someid">
      <div class="flex flex-col">
        <menuCreerFournisseur />
        <ul class="flex flex-col align-center space-y-20">
          <!-- INFORMATIONS GENERALES -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="
                (showInformationsGenerales = !showInformationsGenerales),
                  (showAccesFournisseur = false),
                  (showTransport = false),
                  (showFinance = false),
                  (showPersonnel = false)
              "
            >
              <a class="text-xl"> Informations générales </a>
              <span
                v-show="!showInformationsGenerales"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showInformationsGenerales"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showInformationsGenerales"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="informationsgenerales"
              >
                <div class="grid grid-cols-2 justify-between">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Statut juridique</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="statutjuridique"
                        type="text"
                        ref="statutjuridique"
                        @keydown.enter="this.$refs.Name.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div
                    class="w-full px-5 py-2 text-gray-700 bg-gray-200 border-b"
                  >
                    <label class="text-xs">Nom</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="Name"
                        type="text"
                        ref="Name"
                        @keydown.enter="this.$refs.Numero.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Numéro de compte client</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="Numero"
                        type="text"
                        ref="Numero"
                        @keydown.enter="this.$refs.Prefix.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Préfixe</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="Prefix"
                        type="text"
                        ref="Prefix"
                        @keydown.enter="this.$refs.PrefixTarif.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Préfixe tarif</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="PrefixTarif"
                        type="text"
                        ref="PrefixTarif"
                        @keydown.enter="this.$refs.Adresse.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Adresse</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model.trim="Adresse"
                      ref="Adresse"
                      @keydown.enter="this.$refs.Complement.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Complément d'adresse</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model.trim="Complement"
                      ref="Complement"
                      @keydown.enter="this.$refs.PostalCode.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code Postal</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="PostalCode"
                        ref="PostalCode"
                        @keydown.enter="this.$refs.City.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Ville</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="City"
                        ref="City"
                        @keydown.enter="this.$refs.Country.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label for="Country" class="text-xs">Pays</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        name="Country"
                        v-model.trim="Country"
                        ref="Country"
                        @keydown.enter="this.$refs.telephone.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                      >
                        <option value="ALLEMAGNE">ALLEMAGNE</option>
                        <option value="AUTRICHE">AUTRICHE</option>
                        <option value="BELGIQUE">BELGIQUE</option>
                        <option value="ESPAGNE">ESPAGNE</option>
                        <option selected value="FRANCE">FRANCE</option>
                        <option value="ITALIE">ITALIE</option>
                        <option value="LUXEMBOURG">LUXEMBOURG</option>
                        <option value="MAROC">MAROC</option>
                        <option value="PAYS-BAS">PAYS-BAS</option>
                        <option value="SUISSE">SUISSE</option>
                        <option value="TURQUIE">TURQUIE</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél n°1</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="tel"
                        v-model.trim="telephone"
                        ref="telephone"
                        @keydown.enter="this.$refs.telephone2.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél n°2</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="telephone2"
                        type="text"
                        ref="telephone2"
                        @keydown.enter="this.$refs.fax.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Fax</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fax"
                        type="text"
                        ref="fax"
                        @keydown.enter="this.$refs.email.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Email</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="email"
                      v-model.trim="email"
                      ref="email"
                      @keydown.enter="this.$refs.siret.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Siret</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="siret"
                        type="text"
                        ref="siret"
                        @keydown.enter="this.$refs.nafape.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">NAF-APE</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="nafape"
                        type="text"
                        ref="nafape"
                        @keydown.enter="this.$refs.numerotva.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Numéro T.V.A.</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="numerotva"
                        type="text"
                        ref="numerotva"
                        @keydown.enter="this.$refs.rcsrm.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-2">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">RCS-RM</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="rcsrm"
                        type="text"
                        ref="rcsrm"
                        @keydown.enter="this.$refs.typecondition.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label for="typecondition" class="text-xs"
                      >Type de condition</label
                    >

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        name="typecondition"
                        v-model.trim="typecondition"
                        ref="typecondition"
                        @keydown.enter="this.$refs.nomcommercial.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                      >
                        <option value="prixnet">Prix net</option>
                        <option value="remise">Remise</option>
                      </select>
                    </div>
                  </div>
                </div>

                <h5 class="px-5 py-2 text-gray-700 bg-gray-200 underline">
                  Commercial
                </h5>
                <div
                  class="grid grid-cols-3 justify-between"
                  style="margin-top: -10px"
                >
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Nom/Prénom du commercial</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        v-model.trim="nomcommercial"
                        ref="nomcommercial"
                        @keydown.enter="this.$refs.telcommercial.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél. commercial</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        v-model.trim="telcommercial"
                        ref="telcommercial"
                        @keydown.enter="this.$refs.emailcommercial.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Email commercial</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        v-model.trim="emailcommercial"
                        ref="emailcommercial"
                        @keydown.enter="
                          (showAccesFournisseur = true),
                            (showInformationsGenerales = false)
                        "
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- ACCES FOURNISSEUR -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="
                (showAccesFournisseur = !showAccesFournisseur),
                  (showTransport = false),
                  (showFinance = false),
                  (showPersonnel = false),
                  (showInformationsGenerales = false)
              "
            >
              <a class="text-xl"> Accès fournisseur </a>
              <span
                v-show="!showAccesFournisseur"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showAccesFournisseur"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showAccesFournisseur"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="accesfournisseur"
              >
                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code fournisseur</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="codefournisseur"
                        type="text"
                        ref="codefournisseur"
                        @keydown.enter="this.$refs.fournissidentifiant.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Identifiant</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournissidentifiant"
                        type="text"
                        ref="fournissidentifiant"
                        @keydown.enter="this.$refs.fournissmotdepasse.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Mot de passe</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournissmotdepasse"
                        :type="Typepassword"
                        ref="fournissmotdepasse"
                        @keydown.enter="
                          (showAccesFournisseur = false), (showTransport = true)
                        "
                        class="focus:outline-2 pr-10 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                      <span
                        class="absolute inset-y-0 left-0 flex justfiy-end items-center ml-72 text-gray-600"
                      >
                        <span
                          v-show="passShow"
                          class="absolute cursor-pointer material-icons-round"
                          @click="
                            (Typepassword = 'text'), (passShow = !passShow)
                          "
                          >visibility</span
                        >
                        <span
                          v-show="!passShow"
                          class="absolute cursor-pointer material-icons-round"
                          @click="
                            (Typepassword = 'password'), (passShow = !passShow)
                          "
                          >visibility_off</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- TRANSPORT -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="
                (showTransport = !showTransport),
                  (showFinance = false),
                  (showPersonnel = false),
                  (showInformationsGenerales = false),
                  (showAccesFournisseur = false)
              "
            >
              <a class="text-xl"> Transport </a>
              <span
                v-show="!showTransport"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showTransport"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showTransport"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="transport"
              >
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Livré par transporteur ?</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="livrepartransporteur"
                        id="livrepartransporteur"
                        name="livrepartransporteur"
                        value="true"
                      />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="livrepartransporteur"
                        id="livrepartransporteur"
                        name="livrepartransporteur"
                        value="false"
                      />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>
                </div>

                <div v-if="livrepartransporteur">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Frais de port</label>

                    <div
                      class="flex relative mt-2 rounded-md shadow-sm bg-white"
                    >
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          v-model.trim="fraisdeportchoix"
                          id="fraisdeportchoix"
                          name="fraisdeportchoix"
                          value="true"
                        />
                        <label class="pl-2 checkbox">Oui</label>
                      </div>

                      <div
                        class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          v-model.trim="fraisdeportchoix"
                          id="fraisdeportchoix"
                          name="fraisdeportchoix"
                          value="false"
                        />
                        <label class="pl-2 checkbox">Non</label>
                      </div>
                    </div>

                    <div
                      v-if="fraisdeportchoix"
                      class="relative mt-2 rounded-md shadow-sm"
                    >
                      <input
                        v-model.trim="fraisdeport"
                        type="text"
                        ref="fraisdeport"
                        @keydown.enter="this.$refs.transporteur.focus()"
                        class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Transporteur</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="transporteur"
                          type="text"
                          ref="transporteur"
                          @keydown.enter="this.$refs.schematransport.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Schéma de transport</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="schematransport"
                          type="text"
                          ref="schematransport"
                          @keydown.enter="
                            (showTransport = false), (showFinance = true)
                          "
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- FINANCE -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="
                (showFinance = !showFinance),
                  (showPersonnel = false),
                  (showInformationsGenerales = false),
                  (showAccesFournisseur = false),
                  (showTransport = false)
              "
            >
              <a class="text-xl"> Finance </a>
              <span
                v-show="!showFinance"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showFinance"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showFinance"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="finance"
              >
                <div class="container">
                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">IBAN</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="iban"
                          type="text"
                          ref="iban"
                          @keydown.enter="this.$refs.swift.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">SWIFT/BIC</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="swift"
                          type="text"
                          ref="swift"
                          @keydown.enter="this.$refs.moyendepaiement.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Moyen de paiement</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="moyendepaiement"
                          ref="moyendepaiement"
                          @keydown.enter="this.$refs.delaidepaiement.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="LCR">LCR</option>
                          <option value="Virement">Virement</option>
                          <option value="Chèque">Chèque</option>
                          <option value="CB">CB</option>
                          <option value="Espèce">Espèce</option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Délai de paiement</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="delaidepaiement"
                          ref="delaidepaiement"
                          @keydown.enter="this.$refs.nomdelabanque.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="IMMEDIAT">IMMEDIAT</option>
                          <option value="30 JOURS FIN DE MOIS">
                            30 JOURS FIN DE MOIS
                          </option>
                          <option value="45 JOURS FIN DE MOIS">
                            45 JOURS FIN DE MOIS
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Nom de la banque</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="nomdelabanque"
                        type="text"
                        ref="nomdelabanque"
                        @keydown.enter="this.$refs.ncomptecomptable.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">N° compte comptable</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="ncomptecomptable"
                        type="text"
                        ref="ncomptecomptable"
                        @keydown.enter="
                          (showFinance = false), (showPersonnel = true)
                        "
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- PERSONNEL -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="
                (showPersonnel = !showPersonnel),
                  (showFinance = false),
                  (showInformationsGenerales = false),
                  (showAccesFournisseur = false),
                  (showTransport = false)
              "
            >
              <a class="text-xl"> Personnel </a>
              <span
                v-show="!showPersonnel"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showPersonnel"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showPersonnel"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="personnel"
              >
                <div class="flex flex-col mt-8">
                  <div
                    class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                  >
                    <div
                      class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                    >
                      <table class="min-w-full">
                        <thead>
                          <tr>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              <button class="">
                                <span
                                  class="w-6 h-6 material-icons-round rounded-full"
                                  style="color: orange"
                                >
                                  add_circle_outline
                                </span>
                              </button>
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Poste/service
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Prénom
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Nom
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Tél n°1
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Tél n°2
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Email
                            </th>
                          </tr>
                        </thead>

                        <tbody class="bg-white">
                          <tr>
                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            ></td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="personnelposte"
                                ref="personnelposte"
                                @keydown.enter="
                                  this.$refs.personnelprenom.focus()
                                "
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="personnelprenom"
                                ref="personnelprenom"
                                @keydown.enter="this.$refs.personnelnom.focus()"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="personnelnom"
                                ref="personnelnom"
                                @keydown.enter="
                                  this.$refs.personneltel1.focus()
                                "
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="personneltel1"
                                ref="personneltel1"
                                @keydown.enter="
                                  this.$refs.personneltel2.focus()
                                "
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="personneltel2"
                                ref="personneltel2"
                                @keydown.enter="
                                  this.$refs.personnelemail.focus()
                                "
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="personnelemail"
                                ref="personnelemail"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>
        </ul>
        <div class="flex justify-end mt-8">
          <button
            @click="saveNewFournisseur()"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import menuCreerFournisseur from "../components/Fournisseur/menuCreerFournisseur.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showInformationsGenerales: true,
      showAccesFournisseur: false,
      showTransport: false,
      showFinance: false,
      showPersonnel: false,

      Numero: "",
      Prefix: "",
      PrefixTarif: "",
      codefournisseur: "",
      fournissidentifiant: "",
      fournissmotdepasse: "",
      Typepassword: "password",
      passShow: true,
      Name: "",
      statutjuridique: "",
      Adresse: "",
      Country: "",
      City: "",
      PostalCode: "",
      Complement: "",
      telephone: "",
      email: "",
      siret: "",
      nafape: "",
      numerotva: "",
      rcsrm: "",
      commander: true,
      nomcommercial: "",
      telcommercial: "",
      emailcommercial: "",
      telephone2: "",
      fax: "",
      livrepartransporteur: false,
      transporteur: "",
      schematransport: "",
      fraisdeportchoix: false,
      fraisdeport: "",
      francodeport: "",
      iban: "",
      swift: "",
      nomdelabanque: "",
      moyendepaiement: "",
      delaidepaiement: "",
      ncomptecomptable: "",
      personnelposte: "",
      personnelprenom: "",
      personnelnom: "",
      personneltel1: "",
      personneltel2: "",
      personnelemail: "",
      typecondition: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    menuCreerFournisseur,
  },
  methods: {
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
      axios
        .get(`${process.env.VUE_APP_API}/fournisseur/count`)
        .then((response) => {
          this.fournisseurNumber = response.data;
          console.log(parseInt(this.fournisseurNumber / 50));
        });
    },
    saveNewFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur`, {
          CompanyName: this.Name,
          plateform: this.user.proprietaire,
          Number: this.Numero,
          Prefix: this.Prefix,
          Prefixe_tarif: this.PrefixTarif,
          Id: this.Id,
          RowVersion: this.RowVersion,
          StringValue: this.StringValue,
          Address: {
            Name: this.Adresse,
            Country: this.Country,
            City: this.City,
            PostalCode: this.PostalCode,
            Complement: this.Complement,
            ContactDetail: {
              Email: this.email,
              Fax: this.fax,
              Id: this.Id,
              Phone: this.telephone,
            },
          },
          statutjuridique: this.statutjuridique,
          Archived: this.Archived,
          CanOrder: this.CanOrder,
          siret: this.siret,
          nafape: this.nafape,
          numerotva: this.numerotva,
          rcsrm: this.rcsrm,
          commander: this.commander,
          nomcommercial: this.nomcommercial,
          telcommercial: this.telcommercial,
          emailcommercial: this.emailcommercial,
          telephone2: this.telephone2,
          livrepartransporteur: this.livrepartransporteur,
          transporteur: this.transporteur,
          schematransport: this.schematransport,
          fraisdeportchoix: this.fraisdeportchoix,
          fraisdeport: this.fraisdeport,
          iban: this.iban,
          swift: this.swift,
          nomdelabanque: this.nomdelabanque,
          moyendepaiement: this.moyendepaiement,
          delaidepaiement: this.delaidepaiement,
          ncomptecomptable: this.ncomptecomptable,
          personnelposte: this.personnelposte,
          personnelprenom: this.personnelprenom,
          personnelnom: this.personnelnom,
          personneltel1: this.personneltel1,
          personneltel2: this.personneltel2,
          personnelemail: this.personnelemail,
          typeCondition: this.typecondition,
          Credential: [
            {
              code: this.codefournisseur,
              login: this.fournissidentifiant,
              password: this.fournissmotdepasse,
            },
          ],
          choixPrixNet: [
            {
              applyForAll: true,
              clientPNT1: [],
              clientPNT2: [],
              clientPNT3: [],
            },
          ],
          prixNet: [],
        })
        .then(() => {
          (this.showInformationsGenerales = false),
            (this.showAccesFournisseur = false),
            (this.showTransport = false),
            (this.showFinance = false),
            (this.showPersonnel = false);
          this.toast.success("Fournisseur ajouté !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$router.push("/Fournisseurs");
        });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.75s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
