<template>
  <!-- BODY -->
  <div class="flex justify-between">
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/hubInventaireStock'" />
      </div>
    </div>
    <span class="xs:text-xs sm:text-base"
      >Inventaire n° {{ inventaireProcessing.inventory_number }}</span
    >
    <button
      class="p-1 rounded shadow bg-bluevk hover:bg-opacity-60 text-white"
      @click="valideInventaire()"
    >
      Valider l'inventaire
    </button>
  </div>
  <div class="flex flex-row space-x-1">
    <div class="rounded shadow uppercase w-fit mt-2 p-1">
      {{ inventaireProcessing.Prefixe_tarif }}
    </div>
    <div
      class="rounded shadow uppercase w-fit mt-2 p-1"
      v-if="inventaireProcessing.brand"
    >
      <span
        v-for="(bd, indexBd) in inventaireProcessing.brand"
        :key="indexBd"
        >{{ bd }}</span
      >
    </div>
    <div
      class="rounded shadow uppercase w-fit mt-2 p-1"
      v-if="inventaireProcessing.address"
    >
      Adresse: {{ inventaireProcessing.address }}
    </div>
  </div>

  <!-- MESSAGE -->
  <div v-if="errorBrand">
    <span class="text-red-400">
      Attention, mauvaise marque ou mauvais fournisseur scanné
    </span>
  </div>
  <div v-show="loadingValideInventaire">
    <span class="text-blue-500 text-xl animate-pulse">
      Validation en cours...
    </span>
  </div>

  <div class="relative mt-2 rounded-md shadow-sm">
    <span
      class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
    >
      <span class="material-icons-round">qr_code_scanner</span>
    </span>
    <div>
      <input
        type="text"
        ref="inputEAN"
        placeholder="Code-barres"
        v-model="articleSearchTerm"
        v-on:keyup.enter="getOneArticlebyEAN()"
        class="border w-full focus:outline-2 outline-sky-300 px-9 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
    </div>
  </div>
  <div class="relative mt-2 rounded-md shadow-sm">
    <span
      class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
    >
      <span class="material-icons-round">keyboard</span>
    </span>
    <div>
      <input
        type="text"
        ref="inputRef"
        placeholder="Référence"
        v-model="refSearchTerm"
        v-on:keyup.enter="getOneArticlebyRef()"
        class="border w-full focus:outline-2 outline-sky-300 px-9 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
    </div>
  </div>

  <!-- CHANGEMENT STOCK -->
  <div v-if="scannedData">
    <div class="flex space-x-2 sm:justify-center">
      <!-- PARTIE GAUCHE -->
      <div class="flex flex-col">
        <div class="flex flex-col items-center rotate-90">
          <span class="text-blue-800">+</span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" v-model="stateNumber" class="sr-only peer" />
            <div
              class="h-7 w-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-y-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
            ></div>
          </label>
          <span class="text-orange-500">=</span>
        </div>
        <div
          class="flex flex-col space-y-1 overflow-y-auto border border-gray-200 p-0.5 pr-1.5 rounded-md shadow-lg"
          style="max-height: 200px"
        >
          <div v-for="(num, index) in numbers" :key="index">
            <button>
              <p
                class="w-8 border rounded-md shadow-lg font-medium text-white bg-gray-500 hover:bg-gray-800 border border-gray-800"
                @click="setNumberStock(num)"
              >
                {{ num }}
              </p>
              <p v-if="index < numbers.length - 1" class="text-center">--</p>
            </button>
          </div>
        </div>
      </div>

      <!-- PARTIE DROITE -->
      <div class="flex my-auto">
        <div class="flex flex-col space-y-2 align-center">
          <p class="font-medium">
            {{ scannedData.Ref_fournisseur }}
          </p>
          <div
            class="flex bg-green-500 w-full rounded shadow cursor-pointer"
            @click="
              updateManualyQuantity(
                scannedData._id,
                scannedData.quantity_inventory + 1
              ),
                (scannedData.quantity_inventory =
                  scannedData.quantity_inventory + 1)
            "
          >
            <span class="material-symbols-rounded text-7xl text-white mx-auto">
              keyboard_arrow_up
            </span>
          </div>
          <div class="w-full relative rounded-md shadow-sm">
            <input
              type="text"
              v-model="scannedData.quantity_inventory"
              class="w-full text-center focus:outline-2 outline-sky-300 py-2 pl-2 pr-12 text-white text-5xl bg-bluevk shadow rounded"
              @keyup.enter="
                updateManualyQuantity(
                  scannedData._id,
                  scannedData.quantity_inventory
                )
              "
            />
            <button
              class="absolute material-icons-round my-2 py-3 px-1 text-4xl right-0 hover:bg-white hover:bg-opacity-60 mr-0.5 border text-white shadow-sm px-1 rounded-md"
            >
              arrow_forward_ios
            </button>
          </div>
          <div
            class="flex bg-red-500 w-full rounded shadow cursor-pointer"
            @click="
              updateManualyQuantity(
                scannedData._id,
                scannedData.quantity_inventory - 1
              ),
                (scannedData.quantity_inventory =
                  scannedData.quantity_inventory - 1)
            "
          >
            <span class="material-symbols-rounded text-7xl text-white mx-auto">
              keyboard_arrow_down
            </span>
          </div>
          <div v-if="tableauInverse.length > 1">
            <p
              v-if="
                scannedData.Ref_fournisseur == tableauInverse[0].Ref_fournisseur
              "
              class="font-medium"
            >
              {{ tableauInverse[1].Ref_fournisseur }}
            </p>
            <p v-else class="font-medium">
              {{ tableauInverse[0].Ref_fournisseur }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!-- <input
      placeholder="Rechercher Référence"
      v-model="articleSearchEAN"
      class="block w-full focus:outline-2 outline-sky-300 py-1 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-2 border-gray-300 rounded-md focus:bg-white shadow"
    /> -->

    <!-- TABLEAU -->
    <div class="py-2 overflow-x-auto sm:mx-6 sm:px-6 lg:mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-2 rounded-md border-gray-200 shadow"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf.
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité
              </th>
            </tr>
          </thead>

          <tbody
            class="bg-white overflow-y-auto max-h-54"
            v-if="tableauInverse.length > 0"
          >
            <tr
              v-for="ligne in tableauInverse"
              :key="ligne._id"
              class="odd:bg-white even:bg-gray-100"
            >
              <td class="px-2 py-1 border-b border-gray-200 whitespace-nowrap">
                {{ ligne.Ref_fournisseur }}
              </td>

              <td class="px-2 py-1 border-b border-gray-200 whitespace-nowrap">
                <input
                  class="w-20 border border-gray-300 rounded-md"
                  type="number"
                  v-model="ligne.quantity_inventory"
                  @change="
                    updateManualyQuantity(ligne._id, ligne.quantity_inventory)
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>

        <pagination
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="inventoryInfosLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(inventoryInfosLength / 250)"
          :clickFunction="getInventoryInfos"
          :currentPage="currentPage"
          :typeList="'articles'"
          :perPage="250"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  updateManualyQty,
  getInventoryLines,
  addInventoryLine,
  updateInventory,
  searchRef,
  scanRef,
} from "@/hooks/stock/inventaire.ts";
import { scanStock, findStock } from "@/hooks/stock/stock.ts";
import pagination from "../components/pagination.vue";
import sonTest1 from "../assets/sontest1.mp3";
import sonTest2 from "../assets/sontest2.mp3";
import sonTest3 from "../assets/sontest3.mp3";
import backButton from "@/components/Button/back.vue";

export default {
  data() {
    return {
      articleSearchTerm: "",
      refSearchTerm: "",
      scanStarted: false,
      rescanned: false,
      art_qty_theo: 0,
      adresse_article: "",
      inputQuantity: 0,
      resultUpdatedInventory: 0,
      inventoryInfos: {},
      inventoryInfosLength: 0,
      scannedRef: null,
      scannedProducts: [],
      loadingValideInventaire: false,
      compteurScan: 0,
      errorBrand: false,
      dernierIndexTab: 0,

      scannedData: {},

      selectedArticle: {},
      numbers: [2, 5, 10, 20, 50, 100],
      stateNumber: false,

      inventaire: {},
      tabInventaire: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
    };
  },
  components: {
    pagination,
    backButton,
    // setInventoryArticle,
    // notExistInventory,
    // emptyInventory,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters(["user", "IDinventory", "inventaireProcessing"]),
    tableauInverse() {
      if (this.inventoryInfos.ligne && this.inventoryInfos.ligne.length > 0) {
        return this.inventoryInfos.ligne;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "removeIDinventory",
      "setinventoryprocessing",
      "removeinventoryprocessing",
    ]),
    async getOneArticlebyEAN() {
      if (this.articleSearchTerm !== "") {
        //initialisation des Variables
        this.errorBrand = false;
        const son1 = new Audio(sonTest1);
        const son2 = new Audio(sonTest2);
        const son3 = new Audio(sonTest3);

        //chercher la ref dans la base de donnée
        const res = await scanRef(
          this.user._id,
          this.articleSearchTerm,
          this.inventaireProcessing.brand,
          this.inventaireProcessing.Prefixe_tarif,
          this.user.proprietaire
        );

        // analyser si la ref existe ou pas
        if (res.message == "not found") {
          son2.play();
          this.error("L'article recherché n'existe pas !");
          this.openRefQty = false;
          this.articleSearchTerm = "";
          this.focusInput();
        } else if (res.message == "found") {
          //Commencer par init la ref recherché
          this.articleSearchTerm = "";

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.inventoryInfos.ligne.findIndex(
            (element) =>
              element.Ref_fournisseur === res.data.article.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
          } else {
            son1.play();
          }

          //Ajouter l'article dans l'inventaire
          this.scannedRef = res.data.article.Ref_fournisseur;
          await this.setInventaire(res.data.article);

          //Récupérer les lignes d'inventaire
          await this.getInventoryInfos(1, 250);
          // await this.setDernierIndex();
        } else if (res.message == "found Article") {
          //Commencer par init la ref recherché
          this.articleSearchTerm = "";

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.inventoryInfos.ligne.findIndex(
            (element) => element.Ref_fournisseur === res.data.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
          } else {
            son1.play();
          }

          //Ajouter l'article dans l'inventaire
          this.scannedRef = res.data.Ref_fournisseur;
          await this.setInventaire(res.data);

          //Récupérer les lignes d'inventaire
          await this.getInventoryInfos(1, 250);
          // await this.setDernierIndex();
        }
        this.articleSearchTerm = "";
      }
    },
    async getOneArticlebyRef() {
      if (this.refSearchTerm !== "") {
        //initialisation des Variables
        this.errorBrand = false;
        const son1 = new Audio(sonTest1);
        const son2 = new Audio(sonTest2);
        const son3 = new Audio(sonTest3);

        //chercher la ref dans la base de donnée
        const res = await searchRef(
          this.user._id,
          this.refSearchTerm,
          this.inventaireProcessing.brand,
          this.inventaireProcessing.Prefixe_tarif,
          this.user.proprietaire
        );

        // analyser si la ref existe ou pas
        if (res.message == "not found") {
          son2.play();
          this.error("L'article recherché n'existe pas !");
          this.openRefQty = false;
          this.refSearchTerm = "";
          this.focusInput();
        } else if (res.message == "found") {
          //Commencer par init la ref recherché
          this.refSearchTerm = "";

          //Verifier si l'article existe deja dans le tableau
          //IF this.inventoryInfos.ligne not empty
          const foundIndex = this.inventoryInfos.ligne?.findIndex(
            (element) =>
              element.Ref_fournisseur === res.data.article.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
          } else {
            son1.play();
          }

          //Ajouter l'article dans l'inventaire
          this.scannedRef = res.data.article.Ref_fournisseur;
          await this.setInventaire(res.data.article);

          //Récupérer les lignes d'inventaire
          await this.getInventoryInfos(1, 250);

          await this.setDernierIndex();
        } else if (res.message == "found Article") {
          //Commencer par init la ref recherché
          this.refSearchTerm = "";

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.inventoryInfos.ligne?.findIndex(
            (element) => element.Ref_fournisseur === res.data.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
          } else {
            son1.play();
          }

          //Ajouter l'article dans l'inventaire
          this.scannedRef = res.data.Ref_fournisseur;
          await this.setInventaire(res.data);

          //Récupérer les lignes d'inventaire
          await this.getInventoryInfos(1, 250);

          await this.setDernierIndex();
        }
        this.refSearchTerm = "";
      }
    },
    async getInventoryInfos(skipValue, limitValue) {
      this.currentPage = skipValue;

      const Lines = await getInventoryLines(
        this.inventaireProcessing._id,
        (skipValue - 1) * 250,
        limitValue,
        "_id",
        -1,
        this.user._id
      );

      this.inventoryInfos["ligne"] = Lines.data;
      this.inventoryInfosLength = Lines.count;
    },
    async setInventaire(article) {
      // if (this.inventoryInfos.ligne.length > 0) {
      //   const dernierIndex = this.inventoryInfos.ligne.length - 1;
      //   if (this.inventoryInfos.ligne[dernierIndex].id) {
      //     this.compteurScan = parseInt(
      //       this.inventoryInfos.ligne[dernierIndex].id
      //     );
      //   } else {
      //     this.compteurScan = 0;
      //   }
      // } else {
      //   this.compteurScan = 0;
      // }

      const addOne = await addInventoryLine(
        this.user.proprietaire,
        this.inventaireProcessing._id,
        article._id,
        this.inventaireProcessing.inventory_number,
        article.Ref_fournisseur,
        article.Code_EAN,
        article.Code_marque,
        this.inventaireProcessing.Prefixe_tarif,
        article.Prix_euro,
        0,
        1,
        this.user._id
      );

      this.scannedData = addOne.data;
      // find the index of addOne._id in inventoryInfos.ligne
      const foundIndex = this.inventoryInfos.ligne.findIndex(
        (element) => element._id === addOne._id
      );
      if (foundIndex !== -1) {
        this.inventoryInfos.ligne[foundIndex] = addOne.data;
      }
      this.scanStarted = true;
    },
    async setDernierIndex() {
      if (this.inventoryInfos.ligne && this.inventoryInfos.ligne.length > 0) {
        const foundIndex = this.inventoryInfos.ligne.findIndex(
          (element) => element.Ref_fournisseur === this.scannedRef
        );
        if (foundIndex !== -1) {
          this.dernierIndexTab = foundIndex;
        } else {
          this.dernierIndexTab =
            this.inventoryInfos.ligne[this.inventoryInfos.ligne.length - 1];
        }
      } else {
        this.dernierIndexTab = 0;
      }
    },
    async updateManualyQuantity(id, qty) {
      await updateManualyQty(id, qty, this.user._id);
      this.focusInput();
      //find the index in this.inventoryInfos.ligne of id and change the quantity_inventory
      console.log(id, qty);
      const foundIndex = this.inventoryInfos.ligne.findIndex(
        (element) => element._id === id
      );
      if (foundIndex !== -1) {
        this.inventoryInfos.ligne[foundIndex].quantity_inventory = qty;
      }
    },
    async valideInventaire() {
      this.loadingValideInventaire = true;
      await updateInventory(
        this.inventaireProcessing._id,
        { state: "finished" },
        this.user._id
      );

      this.loadingValideInventaire = false;
      this.backToHub();
    },
    async setNumberStock(number) {
      if (!this.stateNumber) {
        this.scannedData.quantity_inventory =
          parseInt(this.scannedData.quantity_inventory) + number;
      } else {
        this.scannedData.quantity_inventory = number;
      }

      await this.updateManualyQuantity(
        this.scannedData._id,
        this.scannedData.quantity_inventory
      );
    },

    // MESSAGE TOAST
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.error(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    focusInput() {
      this.$refs.inputEAN.focus();
    },
    pageSwitch() {
      this.pageTab = new Array(250);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  async mounted() {
    await this.getInventoryInfos(1, 250);
    this.pageSwitch();
  },
};
</script>

<style scoped>
tbody {
  display: block;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Pour que les colonnes gardent la même largeur */
}
</style>
