import axios from "axios";

export async function getPlateform() {
  const plateform = await axios.get(
    `${process.env.VUE_APP_API}/plateform/getPlateform`
  );

  return plateform.data;
}

export async function verifyClient(plateform: string, client: string) {
  const verify = await axios.post(
    `${process.env.VUE_APP_API}/plateform/verifyClient`,
    {
      plateform: plateform,
      client: client,
    }
  );

  return verify.data;
}

export async function getFournisseur(plateform: string) {
  const fournisseur = await axios.post(
    `${process.env.VUE_APP_API}/fournisseur/getall`,
    {
      plateform: plateform,
    }
  );

  return fournisseur.data;
}

export async function importCondition(
  plateform: string,
  new_plateform: string,
  marge: object,
  fournisseur: string,
  conditions: Array<number>
) {
  const condition = await axios.post(
    `${process.env.VUE_APP_API}/fournisseur/importFromPlateform`,
    {
      plateform: plateform,
      new_plateform: new_plateform,
      marge: marge,
      fournisseur: fournisseur,
      conditions: conditions,
    }
  );

  return condition.data;
}

export async function importConditionOnly(
  plateform: string,
  new_plateform: string,
  marge: object,
  fournisseur: string,
  conditions: Array<number>
) {
  const condition = await axios.post(
    `${process.env.VUE_APP_API}/fournisseur/importConditionFromPlateform`,
    {
      plateform: plateform,
      new_plateform: new_plateform,
      marge: marge,
      fournisseur: fournisseur,
      conditions: conditions,
    }
  );

  return condition.data;
}
