<template>
  <div class="flex">
    <backButton />
  </div>
  <div class="mt-2">
    <div class="text-xl">
      {{ familleTab.name }}
    </div>
  </div>
  <div class="mt-4 flex flex-row space-x-2">
    <div class="w-1/6 flex flex-col space-y-2 p-2 bg-white rounded shadow">
      <div v-if="selectedBrand" class="mx-auto my-2">
        <span class="p-1 rounded shadow">{{ selectedBrand }}</span>
      </div>
      <div
        v-if="selectedBrand"
        class="flex flex-row items-center justify-between mb-2"
      >
        <button
          class="text-xs text-bluevk hover:underline"
          @click="checkAllChild()"
        >
          Tout changer
        </button>
      </div>
      <div
        v-for="(child, indexChild) in familleTab.sousFamille"
        :key="indexChild"
      >
        <div class="flex flex-row items-center space-x-2">
          <div v-if="selectedBrand" class="flex items-center">
            <button
              v-if="child.brandPrimary.includes(selectedBrand)"
              @click="checkChildSecondary(indexChild, selectedBrand, child._id)"
              class="text-sm p-1 bg-blue-500 text-white rounded shadow"
            >
              primaire
            </button>
            <button
              v-if="child.brandSecondary.includes(selectedBrand)"
              @click="checkChildPrimary(indexChild, selectedBrand, child._id)"
              class="text-sm p-1 bg-orange-500 text-white rounded shadow"
            >
              secondaire
            </button>
          </div>
          <span> {{ child.name }}</span>
          <span
            v-show="child.loading"
            class="material-icons-outlined text-orangevk animate-spin"
          >
            sync
          </span>
        </div>
      </div>
    </div>
    <div class="w-5/6 flex flex-col">
      <div class="flex flex-row items-center space-x-2">
        <span>Marques activées : </span
        ><span
          v-show="familleTab.loading"
          class="material-icons-outlined text-orangevk animate-spin"
        >
          sync
        </span>
      </div>
      <div class="grid grid-cols-6 gap-1">
        <div
          v-for="(brandPrimary, indexBP) in familleTab.brandPrimary"
          :key="indexBP"
        >
          <div
            class="flex flex-row p-1 bg-blue-500 text-white rounded shadow-xl justify-between"
            v-if="brandPrimary == selectedBrand"
          >
            <span @click="selectBrand(brandPrimary)" class="cursor-pointer">{{
              brandPrimary
            }}</span>
            <span
              class="material-icons-round text-red-500 cursor-pointer"
              @click="removeBrand(brandPrimary)"
            >
              delete
            </span>
          </div>
          <div
            class="flex flex-row p-1 bg-white rounded justify-between"
            v-else
          >
            <span @click="selectBrand(brandPrimary)" class="cursor-pointer">{{
              brandPrimary
            }}</span>
            <span
              class="material-icons-round text-red-500 cursor-pointer"
              @click="removeBrand(brandPrimary)"
            >
              delete
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-2 mt-2">
        <div>Marque secondaire</div>
        <div class="bg-white rounded p-1">
          <div class="grid grid-cols-6 gap-1">
            <div
              v-for="(brandSecondary, indexBS) in familleTab.brandSecondary"
              :key="indexBS"
            >
              <div
                class="p-1 bg-blue-500 text-white rounded shadow flex flex-row justify-between"
                v-if="brandSecondary == selectedBrand"
              >
                <span
                  class="cursor-pointer"
                  @click="selectBrand(brandSecondary)"
                  >{{ brandSecondary }}</span
                >
                <span
                  class="material-icons-round text-red-500 cursor-pointer"
                  @click="removeBrandSecondary(brandSecondary)"
                >
                  delete
                </span>
              </div>
              <div
                class="p-1 bg-beigevk rounded shadow flex flex-row justify-between"
                v-else
              >
                <span
                  class="cursor-pointer"
                  @click="selectBrand(brandSecondary)"
                  >{{ brandSecondary }}</span
                >
                <span
                  class="material-icons-round text-red-500 cursor-pointer"
                  @click="removeBrandSecondary(brandSecondary)"
                >
                  delete
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Recherche de marque à ajouter -->
      <div v-if="brandTab.error" class="mt-2">
        <div class="flex flex-col">
          <input
            type="text"
            v-model="searchMarque"
            placeholder="Search"
            class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
          />
          <div class="grid grid-cols-6 gap-1">
            <div
              class="flex flex-col space-y-1 bg-white p-1 rounded"
              v-for="(brandE, indexE) in filteredMarque"
              :key="indexE"
            >
              <div
                @click="
                  showBrandTypeChoice[indexE] = !showBrandTypeChoice[indexE]
                "
                class="cursor-pointer"
              >
                {{ brandE.mfrName }}
              </div>
              <div
                v-show="showBrandTypeChoice[indexE]"
                class="flex flex-row justify-between items-center"
              >
                <div
                  class="cursor-pointer px-4 bg-blue-500 text-white rounded"
                  @click="
                    addBrand(brandE.mfrName),
                      (showBrandTypeChoice[indexE] = false)
                  "
                >
                  Primaire
                </div>
                <div
                  class="cursor-pointer px-4 bg-blue-500 text-white rounded"
                  @click="
                    addBrandSecondary(brandE.mfrName),
                      (showBrandTypeChoice[indexE] = false)
                  "
                >
                  Secondaire
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import backButton from "@/components/Button/back.vue";
import axios from "axios";
export default {
  data() {
    return {
      brandTab: [],
      familleTab: [],
      searchMarque: "",
      searchMarqueF: "",
      selectedBrand: "",
      selectedBrandSecondary: "",
      checkedChildTMP: [],
      showBrandTypeChoice: [],
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters([
      "assemblyGroupSelected",
      "user",
      "familleCatalogueSelected",
    ]),
    filteredMarque() {
      if (this.searchMarque) {
        return this.brandTab.data.filter((item) => {
          return item.mfrName.startsWith(this.searchMarque.toUpperCase());
        });
      } else {
        return this.brandTab.data;
      }
    },
    filteredMarqueFound() {
      if (this.searchMarqueF) {
        return this.brandTab.data.filter((item) => {
          return item.startsWith(this.searchMarqueF.toUpperCase());
        });
      } else {
        return this.brandTab.data;
      }
    },
  },
  methods: {
    ...mapActions(["setagselected"]),
    async getFamille() {
      const gf = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getFamille`,
        {
          id: this.familleCatalogueSelected._id,
        }
      );
      this.familleTab = gf.data;
    },
    async addBrand(brand) {
      this.familleTab.loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setFamilleBrand`,
        {
          id: this.familleCatalogueSelected._id,
          brandName: brand,
          type: "primary",
        }
      );
      this.getFamille();
      this.familleTab.loading = false;
    },
    async addBrandSecondary(brandSecondary) {
      this.familleTab.loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setFamilleBrand`,
        {
          id: this.familleCatalogueSelected._id,
          brandName: brandSecondary,
          type: "secondary",
        }
      );
      this.getFamille();
      this.familleTab.loading = false;
    },
    async removeBrand(brand) {
      this.familleTab.loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setFamilleBrand`,
        {
          id: this.familleCatalogueSelected._id,
          brandName: brand,
          type: "primary",
        }
      );
      this.getFamille();
      this.familleTab.loading = false;
    },
    async removeBrandSecondary(brandSecondary) {
      this.familleTab.loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setFamilleBrand`,
        {
          id: this.familleCatalogueSelected._id,
          brandName: brandSecondary,
          type: "secondary",
        }
      );
      this.getFamille();
      this.familleTab.loading = false;
    },
    selectBrand(agBrand) {
      this.selectedBrand = agBrand;
      for (const [
        index,
        childBrand,
      ] of this.assemblyGroupSelected.child.entries()) {
        if (childBrand.blacklistBrand.includes(this.selectedBrand)) {
          this.checkedChildTMP[index] = false;
        } else {
          this.checkedChildTMP[index] = true;
        }
      }
    },
    async checkAllChild() {
      for (const [index, ch] of this.familleTab.sousFamille.entries()) {
        this.familleTab.sousFamille[index].loading = true;
        await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
          {
            id: ch._id,
            brandName: this.selectedBrand,
            type: "primary",
          }
        );
        this.getFamille();
        this.familleTab.sousFamille[index].loading = false;
      }
    },
    async checkChildPrimary(indexChild, brandName, id) {
      this.familleTab.sousFamille[indexChild].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: id,
          brandName: brandName,
          type: "primary",
        }
      );
      this.getFamille();
      this.familleTab.sousFamille[indexChild].loading = false;
    },
    async checkChildSecondary(indexChild, brandName, id) {
      this.familleTab.sousFamille[indexChild].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: id,
          brandName: brandName,
          type: "secondary",
        }
      );
      this.getFamille();
      this.familleTab.sousFamille[indexChild].loading = false;
    },
    async setAssemblyGroup() {
      await axios
        .post(
          `${process.env.VUE_APP_API}/configurationCatalogue/getGenArtLinked`,
          {
            assemblyGroup: this.assemblyGroupSelected.assemblyGroupName,
          }
        )
        .then((res) => {
          this.brandTab = res.data;
        });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
    this.setAssemblyGroup();
  },
  mounted() {
    this.getFamille();
  },
};
</script>
<style></style>
