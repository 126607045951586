<template>
  <div class="flex items-center justify-center h-screen px-6 bg-gray-200">
    <div class="w-full max-w-sm p-6 bg-white rounded-md shadow-md">
      <div class="text-center items-center justify-center">
        <img src="../assets/Vekteur.png" class="" />
        <!-- <span class="text-2xl font-semibold text-gray-700">DISTRIBUTEUR</span> -->
      </div>

      <form class="mt-4" @submit.prevent="login">
        <div v-show="clienterror" class="flex justify-center">
          <div
            class="flex items-center text-red-600 rounded-lg p-2 text-sm space-x-2"
          >
            <i class="material-icons-outlined">error</i>
            <span class="text-red-600">Ceci est un compte Client</span>
          </div>
        </div>
        <div v-show="errormdp" class="flex justify-center">
          <div
            class="flex items-center text-red-600 rounded-lg p-2 text-sm space-x-2"
          >
            <i class="material-icons-outlined">error</i>
            <span class="text-red-600">Mot de passe incorrect</span>
          </div>
        </div>
        <label class="block">
          <span class="text-sm text-gray-700">Email</span>
          <input
            type="email"
            class="w-full focus:outline-2 outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
            v-model="email"
          />
        </label>

        <label class="block mt-3">
          <span class="text-sm text-gray-700">Mot de passe</span>

          <div class="relative flex mt-2 rounded-md shadow-sm">
            <input
              @keydown.enter.prevent="submitForm"
              v-model="password"
              :type="Typepassword"
              class="w-full focus:outline-2 outline-sky-300 pl-2 pr-8 py-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
            />
            <div class="absolute my-2 right-0">
              <button
                v-if="passShow"
                class="material-icons-round flex text-gray-600 mr-2"
                @click="(Typepassword = 'text'), (passShow = !passShow)"
              >
                visibility
              </button>
              <button
                v-else
                class="material-icons-round flex text-gray-600 mr-2"
                @click="(Typepassword = 'password'), (passShow = !passShow)"
              >
                visibility_off
              </button>
            </div>
          </div>
        </label>

        <div class="flex float-right items-center justify-between my-4">
          <div>
            <a
              class="block text-sm text-indigo-700 fontme hover:underline"
              href="#"
              >Mot de passe oublié ?</a
            >
          </div>
        </div>

        <div class="mt-6">
          <div v-if="connexion == true" class="pl-24">
            <img
              src="@/assets\LoadingGIF\load-animasyon.gif"
              alt="loading..."
            />
          </div>
          <button
            v-else
            value="connexion"
            type="submit"
            @click="submitForm"
            class="w-full px-4 py-2 text-sm text-center text-white bg-blue-800 rounded-md focus:outline-none hover:bg-blue-700"
          >
            Connexion
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      clienterror: false,
      errormdp: false,

      Typepassword: "password",
      passShow: true,
      connexion: false,
    };
  },
  methods: {
    ...mapActions(["connecter", "updateUid"]),
    login() {
      const auth = getAuth();
      console.log(this.email);
      this.connexion = true;
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          axios
            .get(`${process.env.VUE_APP_API}/user/getone/${user.uid}`)
            .then((res) => {
              if (res.data.team == "Client Pro") {
                this.clienterror = true;
              } else {
                this.clienterror = false;
                this.connecter();
                this.updateUid(user.uid);
                this.$router.push("/dashboard");
                this.errormdp = false;
              }
              this.connexion = false;
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          if (errorCode == "auth/wrong-password") {
            this.errormdp = true;
          }
          this.connexion = false;
        });
    },
    submitForm() {
      this.login()
    }
  },
};
</script>
