<template>
  <div class="flex flex-col space-y-4 h-85">
    <div class="flex justify-between">
      <!-- Back Button -->
  <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/detailClient'" />
      </div>
    </div>
      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Conditions</span>
          <span class="text-green-600"> {{ clientChoosedTab.Name }} </span>
        </h2>
      </div>
      <div></div>
    </div>
    <div class="flex flex-col space-y-2 h-full">
      <div class="h-1/2 p-2 bg-gray-100 rounded shadow overflow-y-auto">
        <div class="grid grid-cols-6 gap-4">
          <div
            class="flex flex-row justify-between p-2 bg-white rounded shadow"
          >
            <input
              list="fournisseurToAdd"
              v-model="addFournisseur"
              placeholder="Nom du fournisseur"
            />
            <datalist id="fournisseurToAdd">
              <option
                v-for="(c, index) in fournisseurList"
                :key="index"
                :value="c.CompanyName"
              ></option>
            </datalist>
            <div
              @click="createSpecifiqueClientFournisseur()"
              class="px-2 bg-green-500 rounded shadow text-white cursor-pointer"
            >
              Ajouter
            </div>
          </div>
          <div
            v-for="(cond, indexCond) in specConditionList"
            :key="indexCond"
            class="flex flex-row justify-between p-2 bg-white rounded shadow"
          >
            <div class="flex flex-row space-x-2 items-center">
              <div>{{ cond.fournisseur }}</div>
              <div>
                <span
                  class="material-icons-round bg-red-500 rounded-sm text-white cursor-pointer"
                  style="font-size: small"
                  @click="deleteSpecifiqueClientFournisseur(cond._id)"
                >
                  clear
                </span>
              </div>
            </div>
            <div
              v-if="cond.type == 'remise'"
              @click="updateSpecifiqueClientFournisseur(cond._id, 'prixnet')"
              class="px-2 bg-blue-500 rounded shadow text-white cursor-pointer"
            >
              remise
            </div>
            <div
              v-else
              @click="updateSpecifiqueClientFournisseur(cond._id, 'remise')"
              class="px-2 bg-blue-500 rounded shadow text-white cursor-pointer"
            >
              prixnet
            </div>
          </div>
        </div>
      </div>
      <div class="h-1/2 p-2 bg-gray-100 rounded shadow overflow-y-auto">
        <div class="grid grid-cols-3 gap-4">
          <div
            class="flex flex-row justify-between p-2 bg-white rounded shadow"
          >
            <input
              list="fournisseurToAddcond"
              v-model="addFournisseurCond"
              @change="getCondition()"
              placeholder="Nom du fournisseur"
            />
            <datalist id="fournisseurToAddcond">
              <option
                v-for="(c, index) in fournisseurList"
                :key="index"
                :value="c.CompanyName"
              ></option>
            </datalist>
            <div class="flex flex-col">
              <input
                list="codeToAdd"
                v-model="searchConditionInput"
                placeholder="Nom du code de remise"
              />
              <ul
                class="absolute z-10 mt-6 bg-white rounded shadow p-2 max-h-32 w-1/6 overflow-y-auto"
                v-if="conditionList.length > 0"
              >
                <li
                  v-for="(c, index) in conditionList"
                  :key="index"
                  class="hover:bg-gray-300 p-1 cursor-pointer"
                  @click="selectCodeFamilyRemise(c)"
                >
                  {{ c.familleFournisseur }} {{ c.famille }}
                </li>
              </ul>
            </div>
            <!-- <datalist id="codeToAdd">
              <option
                v-for="(c, index) in conditionList"
                :key="index"
                :value="c._id"
              >
                {{ c.familleFournisseur }} {{ c.famille }}
              </option>
            </datalist> -->
            <div
              @click="createSpecifiqueRemiseClientFournisseur()"
              class="px-2 bg-green-500 rounded shadow text-white cursor-pointer"
            >
              Ajouter
            </div>
          </div>
          <div
            v-for="(remise, indexRemise) in specRemiseList"
            :key="indexRemise"
            class="flex flex-row justify-between p-2 bg-white rounded shadow"
          >
            <div class="flex flex-row space-x-2 items-center">
              <div>{{ remise.fournisseur }}</div>
              <div>
                <span
                  class="material-icons-round bg-red-500 rounded-sm text-white cursor-pointer"
                  style="font-size: small"
                  @click="deleteSpecifiqueRemiseClientFournisseur(remise._id)"
                >
                  clear
                </span>
              </div>
            </div>
            <div>{{ remise.code }}</div>
            <div>
              <input
                type="Number"
                class="w-20 border-2 px-1 rounded-md"
                @change="
                  updateSpecifiqueRemiseClientFournisseur(
                    remise._id,
                    specRemiseList[indexRemise].remise
                  )
                "
                v-model="specRemiseList[indexRemise].remise"
              />
              %
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import backButton from "@/components/Button/back.vue"
export default {
  name: "MySpecCondClient",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      fournisseurList: [],
      specConditionList: [],
      specRemiseList: [],
      conditionList: [],
      conditionSelected: [],
      searchConditionInput: "",
      addFournisseur: "",
      addFournisseurCond: "",
    };
  },
  components:{
    backButton,
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab"]),
    filteredcondition() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
  },
  methods: {
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    getCondition() {
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/searchOne`, {
          plateform: this.user.proprietaire,
          fournisseur: this.addFournisseurCond,
        })
        .then((response) => {
          this.conditionList = response.data.data;
        });
    },
    createSpecifiqueClientFournisseur() {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/createSpecifiqueClientFournisseur`,
          {
            plateform: this.user.proprietaire,
            client: this.clientChoosedTab.Name,
            fournisseur: this.addFournisseur,
            type: "remise",
          }
        )
        .then(() => {
          this.getSpecifiqueClientFournisseur();
        });
    },
    selectCodeFamilyRemise(condition) {
      this.conditionSelected = condition;
      if (condition.famille) {
        this.searchConditionInput = condition.famille;
      } else {
        this.searchConditionInput = condition.familleFournisseur;
      }

      this.conditionList = [];
    },
    createSpecifiqueRemiseClientFournisseur() {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/createSpecifiqueRemiseClientFournisseur`,
          {
            plateform: this.user.proprietaire,
            client: this.clientChoosedTab.Name,
            fournisseur: this.addFournisseurCond,
            code: this.searchConditionInput,
          }
        )
        .then(() => {
          this.getSpecifiqueRemiseClientFournisseur();
        });
    },
    getSpecifiqueClientFournisseur() {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/getSpecifiqueClientFournisseur`,
          {
            plateform: this.user.proprietaire,
            client: this.clientChoosedTab.Name,
          }
        )
        .then((res) => {
          this.specConditionList = res.data;
        });
    },
    getSpecifiqueRemiseClientFournisseur() {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/getSpecifiqueRemiseClientFournisseur`,
          {
            plateform: this.user.proprietaire,
            client: this.clientChoosedTab.Name,
          }
        )
        .then((res) => {
          this.specRemiseList = res.data;
        });
    },
    deleteSpecifiqueClientFournisseur(id) {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/deleteSpecifiqueClientFournisseur`,
          {
            id: id,
          }
        )
        .then(() => {
          this.getSpecifiqueClientFournisseur();
        });
    },
    deleteSpecifiqueRemiseClientFournisseur(id) {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/deleteSpecifiqueRemiseClientFournisseur`,
          {
            id: id,
          }
        )
        .then(() => {
          this.getSpecifiqueRemiseClientFournisseur();
        });
    },
    updateSpecifiqueClientFournisseur(id, type) {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/updateSpecifiqueClientFournisseur`,
          {
            id: id,
            type: type,
          }
        )
        .then(() => {
          this.getSpecifiqueClientFournisseur();
        });
    },
    updateSpecifiqueRemiseClientFournisseur(id, remise) {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/updateSpecifiqueRemiseClientFournisseur`,
          {
            id: id,
            remise: remise,
          }
        )
        .then(() => {
          this.getSpecifiqueRemiseClientFournisseur();
        });
    },

    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getFournisseur();
    this.getSpecifiqueClientFournisseur();
    this.getSpecifiqueRemiseClientFournisseur();
  },
};
</script>
