<template>
  <div class="bg-white rounded-sm shadow p-2 w-11/12">
    <div
      class="border border-gray-200 rounded bg-gray-100 animate-pulse w-24 h-8"
    ></div>
    <div class="flex flex-col space-y-1 ml-2 mt-2">
      <div class="px-1 hover:text-bluevk cursor-pointer">
        <div class="flex flex-row space-x-2 items-center text-blue-500">
          <span
            class="w-4 h-4 border border-gray-300 rounded bg-gray-100 animate-pulse"
          ></span>
          <div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-36 h-8"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "familleList",
};
</script>
<style></style>
