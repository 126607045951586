<template>
  <div class="flex-wrap flex justify-center mb-6">
    <nav class="space-x-2">
      <router-link to="/caisse">
        <a
          href="#"
          name="caisse"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Caisse du jour
        </a>
      </router-link>
      <router-link to="/encaissement">
        <a
          href="#"
          name="encaissement"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Règlement
        </a>
      </router-link>
      <router-link to="/historiqueCaisse">
        <a
          href="#"
          name="historique"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Historique caisse
        </a>
      </router-link>
    </nav>
  </div>
</template>
