<template>
  <!-- titre de la page -->
  <div class="flex text-gray-700 flex-row mx-auto">
    <div
      class="flex text-gray-700 text-xl md:text-3xl md:font-medium font-bold flex-row my-2 mx-auto sm: text-bluevk"
    >
      <div class="p-1">Configurations</div>
    </div>
  </div>

  <!-- encarts -->

  <div
    class="grid grid-cols-1 mt-6 md:grid-cols-2 lg:grid-cols-2 gap-3 mx-2 md:mx-5 lg:mx-5 xl:mx-25"
  >
    <!-- ConfigMarques -->
    <!-- <div class="flex justify-center p-3">
      <div
        class="md:ml-auto w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      >
        <div class="flex flex-col items-center pb-5">
          <router-link to="/configurationFiltre">
            <div
              class="flex flex-col text-center p-3 hover:scale-110 duration-300 hover:text-gray-600"
            >
              <img
                src="../assets/configuration/ConfigMarquesV2.png"
                alt="Pictogramme Configuration marques"
                class="md:mx-10"
              />
            </div>
            <h5
              class="text-xl md:text-l font-medium lg:font-medium lg:text-xl text-gray-500 w-full text-center p-15"
            >
              Configuration <span class="text-orange-500">des marques</span>
            </h5>
            <div
              class="text-l font-medium md:font-bold text-bluevk w-full text-center"
            >
              -
            </div>
            <div
              class="text-s font-medium text-gray-500 w-full text-center p-15"
            >
              dans le catalogue de pièces
            </div>
          </router-link>
        </div>
      </div>
    </div> -->
    <!-- ConfigPièces -->
    <div class="flex justify-center p-3">
      <div
        class="md:ml-auto w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      >
        <div class="flex flex-col items-center pb-5">
          <router-link to="/configurationCatalogueHub">
            <div
              class="flex flex-col text-center p-3 hover:scale-110 duration-300 hover:text-gray-600"
            >
              <img
                src="../assets/configuration/ConfigPiecesV2.png"
                alt="Pictogramme configuration pièces"
                class="md:mx-10"
              />
            </div>
            <h5
              class="text-xl md:text-l font-medium lg:font-medium lg:text-xl text-gray-500 w-full text-center p-15"
            >
              Configuration <span class="text-orange-500">du catalogue</span>
            </h5>
            <div
              class="text-l font-medium md:font-bold text-bluevk w-full text-center"
            >
              -
            </div>
            <div
              class="text-s font-medium text-gray-500 w-full text-center p-15"
            >
              dans le catalogue de pièces
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- ConfigPneus -->
    <div class="flex justify-center p-3">
      <div
        class="md:mr-auto w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      >
        <div class="flex flex-col items-center pb-5">
          <router-link to="/configurationFiltrePneu">
            <div
              class="flex flex-col text-center p-3 hover:scale-110 duration-300 hover:text-gray-600"
            >
              <img
                src="../assets/configuration/ConfigPneusV2.png"
                alt="Pictogramme Pneus"
                class="md:mx-10"
              />
            </div>
            <h5
              class="text-xl md:text-l hidden lg:block font-medium lg:font-medium lg:text-xl text-gray-500 w-full text-center p-15"
            >
              Configuration
              <span class="text-orange-500">des marques de pneus</span>
            </h5>
            <h5
              class="text-xl md:text-l sm:block lg:hidden md:block font-medium lg:font-medium lg:text-xl text-gray-500 w-full text-center p-15"
            >
              Configuration des marques <br />
              <span class="text-orange-500">de pneus</span>
            </h5>
            <div
              class="text-l font-medium md:font-bold text-bluevk w-full text-center"
            >
              -
            </div>
            <div
              class="text-s font-medium text-gray-500 w-full text-center p-15"
            >
              dans le catalogue de pneus
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- ConfigRéseau -->
    <div class="flex justify-center p-3">
      <div
        class="md:ml-auto w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      >
        <div class="flex flex-col items-center pb-5">
          <router-link to="/configurationConnexion">
            <div
              class="flex flex-col text-center p-3 hover:scale-110 duration-300 hover:text-gray-600"
            >
              <img
                src="../assets/configuration/ConfigreseauV2.png"
                alt="Pictogramme Configuration connexions"
                class="md:mx-10"
              />
            </div>
            <h5
              class="text-xl md:text-l font-medium lg:font-medium lg:text-xl text-gray-500 w-full text-center p-15"
            >
              Configuration <span class="text-orange-500">des connexions</span>
            </h5>
            <div
              class="text-l font-medium md:font-bold text-bluevk w-full text-center"
            >
              -
            </div>
            <div
              class="text-s font-medium text-gray-500 w-full text-center p-15"
            >
              aux outils partenaires
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigurationHub",
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style></style>
