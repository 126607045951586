import axios from "axios";

export async function getLastFive(plateform: string) {
  const lastFive = await axios.post(
    `${process.env.VUE_APP_API}/savedSearch/getLastFive`,
    {
      plateform: plateform,
    }
  );

  return lastFive.data;
}

export async function getSavedSearch(
  plateform: string,
  skip: number,
  limit: number,
  filter: object
) {
  const savedSearch = await axios.post(
    `${process.env.VUE_APP_API}/savedSearch/getSavedSearch`,
    {
      plateform: plateform,
      skip: skip,
      limit: limit,
      filter: filter,
    }
  );

  return savedSearch.data;
}

export async function getById(id: string) {
  const savedSearch = await axios.get(
    `${process.env.VUE_APP_API}/savedSearch/getById/${id}`
  );

  return savedSearch.data;
}

export async function MisePanier(id: string, ref: string) {
  const savedSearch = await axios.post(
    `${process.env.VUE_APP_API}/savedSearch/MisePanier`,
    {
      id: id,
      ref: ref,
    }
  );

  return savedSearch.data;
}

export async function searchInPlateform(
  plateform: string,
  searchTerm: string,
  skip: number,
  limit: number,
  filter: object
) {
  const searchInPlateform = await axios.post(
    `${process.env.VUE_APP_API}/savedSearch/searchInPlateform`,
    {
      plateform: plateform,
      searchTerm: searchTerm,
      skip: skip,
      limit: limit,
      filter: filter,
    }
  );

  return searchInPlateform.data;
}
