<template>
  <div id="retour" class="flex justify-start">
    <backButton :route="'/statistiques'" />
  </div>

  <div>
    <button
      v-if="!loadingExport"
      class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
      @click="exportExcel()"
    >
      <span class="material-icons-outlined text-xl mr-2"> download </span>
      <span>Exporter en Excel</span>
    </button>
    <button
      v-else
      class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
    >
      <PulseLoader color="#ffff" class="mr-2" />
      <span>Export en cours </span>
    </button>
  </div>

  <div class="w-fit bg-white p-6 rounded shadow-md">
    <form @submit.prevent="getAllBdlByClient(1, 10)" class="flex">
      <div class="mr-4">
        <label for="client" class="block text-gray-700 font-bold"
          >Client:</label
        >
        <input
          list="client"
          v-model="client"
          @click="client = null"
          placeholder="Clients"
          class="w-full px-3 py-2 border rounded"
        />
        <datalist id="client">
          <option
            v-for="(c, index) in Clients"
            :key="index"
            :value="c.Name"
          ></option>
        </datalist>
      </div>

      <div class="mr-4">
        <label for="commercial" class="block text-gray-700 font-bold"
          >Commercial:</label
        >
        <input
          list="commercial"
          v-model="commercial"
          @click="commercial = null"
          placeholder="Commercial"
          class="w-full px-3 py-2 border rounded"
        />
      </div>

      <div class="mr-4">
        <label for="dateDebut" class="block text-gray-700 font-bold"
          >Date Début:</label
        >
        <input
          type="date"
          id="dateDebut"
          v-model="dateDebut"
          class="w-full px-3 py-2 border rounded"
        />
      </div>

      <div class="mr-4">
        <label for="dateFin" class="block text-gray-700 font-bold"
          >Date Fin:</label
        >
        <input
          type="date"
          id="dateFin"
          v-model="dateFin"
          class="w-full px-3 py-2 border rounded"
        />
      </div>

      <div class="mr-4">
        <label for="archived" class="block text-gray-700 font-bold"
          >Archivés ?</label
        >
        <div class="flex">
          <input
            type="checkbox"
            id="archived"
            v-model="archived"
            class="my-auto w-full px-3 py-2 border rounded"
          />
          <p v-if="archived">Oui</p>
          <p v-else>Non</p>
        </div>
      </div>

      <div class="mr-4">
        <label for="active" class="block text-gray-700 font-bold"
          >Actifs ?</label
        >
        <div class="flex">
          <input
            type="checkbox"
            id="active"
            v-model="active"
            class="my-auto w-full px-3 py-2 border rounded"
          />
          <p v-if="active">Oui</p>
          <p v-else>Non</p>
        </div>
      </div>

      <div
        v-if="loadingSearch"
        class="ml-12 flex my-auto bg-blue-500 text-white px-4 py-2 rounded"
      >
        <PulseLoader color="#ffff" class="mr-2" />
        <p>Recherche en cours</p>
      </div>

      <button
        v-else
        type="submit"
        class="ml-12 flex my-auto bg-blue-500 text-white px-4 py-2 rounded"
      >
        <p>Rechercher</p>
      </button>
    </form>
  </div>

  <!-- Total commander -->
  <!-- <div>
    <input
      list="client"
      v-model="selectedClient"
      @click="selectedClient = null"
      placeholder="Clients"
      style="height: 1.25rem; width: auto"
      class="my-1 m-auto w-full text-black border border-gray-400 rounded-md focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 hover:bg-white focus:outline-2 outline-sky-300"
    />
    <datalist id="client">
      <option
        v-for="(c, index) in Clients"
        :key="index"
        :value="c.Name"
      ></option>
    </datalist>

    <label for="month">Mois :</label>
    <select v-model="selectedMonth" id="month">
      <option value="1">Janvier</option>
      <option value="2">Février</option>
      <option value="3">Mars</option>
      <option value="4">Avril</option>
      <option value="5">Mai</option>
      <option value="6">Juin</option>
      <option value="7">Juillet</option>
      <option value="8">Août</option>
      <option value="9">Septembre</option>
      <option value="10">Octobre</option>
      <option value="11">Novembre</option>
      <option value="12">Décembre</option>
    </select>

    <label for="year">Année :</label>
    <input v-model="selectedYear" type="number" id="year" />

    <button @click="getPanierTransport">Obtenir les données mensuelles</button>

    <div v-if="panierTransport">
      <p>Résultat :</p>
      <ul>
        <li v-for="item in panierTransport.result" :key="item._id">
          {{ item.date }} - Valeur : {{ item.valeur }}
        </li>
      </ul>
      <p>Total valeur : {{ panierTransport.totalValeur }}</p>
    </div>
  </div> -->

  <div
    class="mt-2 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
  >
    <div
      class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
    >
      <table class="min-w-full">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Client
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Commercial
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total HT
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total TTC
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total Marge €
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total Marge %
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr v-for="(u, index) in tabStats.totalByClient" :key="index">
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ u._id }}
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ u.commercial }}
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ parseFloat(u.TotalPrice).toFixed(2) }} €
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ parseFloat(u.TotalMarge).toFixed(2) }} €
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ parseFloat(u.TotalMargePourcentage).toFixed(2) }} %
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            ></th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            ></th>
            <th
              v-if="tabStats.totalAllBdl"
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total HT :
              {{ parseFloat(tabStats.totalAllBdl.TotalPrice).toFixed(2) }} €
            </th>
            <th
              v-if="tabStats.totalAllBdl"
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total TTC :
              {{ parseFloat(tabStats.totalAllBdl.TotalPriceTTC).toFixed(2) }} €
            </th>
            <th
              v-if="tabStats.totalAllBdl"
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total Marge :
              {{ parseFloat(tabStats.totalAllBdl.TotalMarge).toFixed(2) }} €
            </th>
            <th
              v-if="tabStats.totalAllBdl"
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total Marge :
              {{
                parseFloat(tabStats.totalAllBdl.TotalMargePourcentage).toFixed(
                  2
                )
              }}
              %
            </th>
          </tr>
        </tfoot>
      </table>

      <pageNumber
        :pageStart="pageStart"
        :pageNext="pageNext"
        :tabList="tabLength"
        :pageTab="pageTab"
        :pageTotal="Math.ceil(tabLength / 10)"
        :clickFunction="(arg1, arg2) => getAllBdlByClient(arg1, arg2)"
        :currentPage="currentPage"
        :typeList="''"
        @update:start="pageStart = $event"
        @update:tab="pageTab = $event"
        @update:next="pageNext = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { getByClientMonthly } from "@/hooks/transport/panierTransport.ts";
import { getAllByPlateform } from "@/hooks/clients/clients.ts";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import * as XLSX from "xlsx";
import { useToast } from "vue-toastification";
import pageNumber from "@/components/pageNumber.vue";
import backButton from "@/components/Button/back.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyStatsBl",
  data() {
    return {
      selectedMonth: new Date().getMonth() + 1, // Mois actuel par défaut
      selectedYear: new Date().getFullYear(), // Année actuelle par défaut
      panierTransport: null,
      totalValeur: null,
      selectedClient: null,

      tabStats: {},
      tabExcel: [],

      Clients: [],
      client: null,
      commercial: null,
      archived: false,
      active: true,
      dateDebut: null,
      dateFin: null,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      tabLength: 0,

      loadingExport: false,
      loadingSearch: false,
    };
  },
  components: {
    pageNumber,
    PulseLoader,
    backButton,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getAllBdlByClient(skipValue, limitValue) {
      this.tabStats = {};
      this.tabLength = 0;
      this.tabExcel = [];
      this.currentPage = skipValue;
      this.loadingSearch = true;
      await axios
        .post(`${process.env.VUE_APP_API}/venteStat/getAllBdlByClient`, {
          plateform: this.user.proprietaire,
          client: this.client,
          commercial: this.commercial,
          archived: this.archived,
          active: this.active,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.tabStats = response.data;
          this.tabLength = response.data.totalByClientLength;
          this.tabExcel = response.data.totalExcel;
          this.loadingSearch = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingSearch = false;
        });
    },
    // getBdls() {
    //   this.seriesbdl = [];
    //   axios
    //     .post(`${process.env.VUE_APP_API}/venteStat/getBdls`, {
    //       plateform: this.user.proprietaire,
    //     })
    //     .then((res) => {
    //       this.tabStats = res.data.data;
    //     });
    // },
    async getClients() {
      this.Clients = await getAllByPlateform(
        this.user.proprietaire,
        true,
        false
      );
    },
    // async getPanierTransport() {
    //   this.panierTransport = await getByClientMonthly(
    //     this.user.proprietaire,
    //     this.selectedClient,
    //     this.selectedMonth,
    //     this.selectedYear
    //   );
    // },
    async exportExcel() {
      this.loadingExport = true;
      // await this.getAllBdlByClient(1, 10, true);

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.tabExcel);
      XLSX.utils.book_append_sheet(wb, ws, "Total BDL");

      XLSX.writeFile(wb, "Total_BDL_" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      this.success("Le fichier a été téléchargé");
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getAllBdlByClient(1, 10);
    this.getClients();
    this.pageSwitch();
  },
};
</script>
