<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/articlehub')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
  <div>
    <div class="flex flex-row items-center justify-between">
      <span></span>
      <button
        class="p-1 rounded shadow bg-blue-500 text-white"
        @click="$router.push('/initInventaire')"
      >
        Démarrer un inventaire
      </button>
    </div>
    <div class="flex flex-row items-center mt-4 space-x-2">
      <button
        class="p-1 rounded shadow bg-orange-500 text-white"
        @click="(initialState = 'init'), getInventaire()"
      >
        En cours
      </button>
      <button
        class="p-1 rounded shadow bg-red-500 text-white"
        @click="(initialState = 'error'), getInventaire()"
      >
        En erreur
      </button>
      <button
        class="p-1 rounded shadow bg-green-500 text-white"
        @click="(initialState = 'finished'), getInventaire()"
      >
        Terminé
      </button>
    </div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Numéro
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Adresse/marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Initié par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Débuté le
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Etat
                </th>

                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                class="cursor-pointer"
                v-for="(u, index) in inventaire"
                :key="index"
                @click="setInventaire(u)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.inventory_number }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span v-for="(bd, indexBd) in u.brand" :key="indexBd">{{
                    bd
                  }}</span>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.created_by }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ getFRDate(u.Date) }}
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                >
                  <span v-if="u.state == 'init'" class="text-orange-600"
                    >En cours</span
                  >
                  <span v-else-if="u.state == 'finished'" class="text-green-600"
                    >Terminé</span
                  >
                  <span v-else-if="u.state == 'error'" class="text-red-600"
                    >En erreur</span
                  >
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-center">
                    Nbr article en stock : 1
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-center">Nbr de réf. : 1</div>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { getFrDate } from "@/hooks/tools/date.ts";
import { getInventory } from "@/hooks/stock/inventaire.ts";
export default {
  data() {
    return {
      inventaire: [],
      initialState: "init",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setinventoryprocessing", "removeinventoryprocessing"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    async getInventaire() {
      const inventories = await getInventory(
        this.user.proprietaire,
        this.initialState,
        0,
        1000,
        "Date",
        1,
        this.user._id
      );
      this.inventaire = inventories.data;
    },
    setInventaire(u) {
      if (this.initialState == "init") {
        this.setinventoryprocessing(u);
        this.$router.push("/inventaireStock");
      } else if (this.initialState == "finished") {
        this.setinventoryprocessing(u);
        this.$router.push("/inventaireRecap");
      }
    },
  },
  mounted() {
    this.getInventaire();
  },
};
</script>
<style></style>
