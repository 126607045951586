<template>
  <div>
    <div
      v-if="showFile"
      :class="`modal ${
        !showFile && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="closeImport()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Tableau de condition importé</p>
            <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
            v-show="showFileContent"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Marque
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Designation
                    </th>
                    <!-- <th
                  class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code Remise
                </th> -->
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Famille
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Remise
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      RFA 1
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      RFA 2
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      RFA 3
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                      v-for="(confCond, indexCC) in configurationConditionList"
                      :key="indexCC"
                    >
                      {{ confCond.Name }}
                    </th>
                    <!-- <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Pro 2
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Pro 3
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Part 1
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Part 2
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Part 3
                    </th> -->
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                    >
                      Status
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFile" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      <!-- <span class="w-1/4">{{ readCondi[1] }}</span> -->
                      <input
                        type="text"
                        class="w-56"
                        v-model="readFile[index][1]"
                      />
                    </td>

                    <!-- <td
                  class="px-2 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  {{ condi.code }}
                </td> -->

                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[2] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">type : {{ readCondi[3] }}</div>
                      <div class="w-24">{{ readCondi[4] }} %</div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[5] }} %</div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[6] }} %</div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex flex-col">
                        <div class="w-24">{{ readCondi[7] }} %</div>

                        <span class="text-base">Inactif</span>
                      </div>
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                      v-for="(confCond, indexCC) in configurationConditionList"
                      :key="indexCC"
                    >
                      <div class="w-24">
                        {{ readCondi[7 + (indexCC + 1)] }} %
                      </div>
                    </td>
                    <!--<td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[9] }} %</div>
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      <div class="w-24">{{ readCondi[10] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <div class="w-24">{{ readCondi[11] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <div class="w-24">{{ readCondi[12] }} %</div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <div class="w-24">{{ readCondi[13] }} %</div>
                    </td> -->
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                      style="width: 8%"
                    >
                      <span
                        class="p-1 bg-green-500 text-white text-base rounded"
                        >Actif</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end mt-4 mb-2" v-show="showFileContent">
            <button
              class="p-2 bg-blue-500 text-white rounded"
              @click="importExcel()"
            >
              Importer
            </button>
          </div>
          <div v-show="importLoading">
            <div class="animate-pulse">Importation en cours...</div>
          </div>
          <div v-show="showFinished">
            <div>Importation réussie !</div>
            <div class="flex justify-end">
              <button
                class="p-2 bg-blue-500 text-white rounded"
                @click="closeImport()"
              >
                Fermer
              </button>
            </div>
          </div>

          <!-- End Body -->
        </div>
      </div>
    </div>

    <div
      v-if="showUpdateCodeRemise"
      :class="`modal ${
        !showUpdateCodeRemise && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="closeUpdateCodeRemise()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 70vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Mise à jour des code remise</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="closeUpdateCodeRemise()"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div class="grid grid-cols-10 gap-2" v-show="!LoadingUpdateCR">
            <div
              v-for="(ucl, indexUCL) in updateCodeList.codeRemise"
              :key="indexUCL"
            >
              {{ ucl }}
            </div>
          </div>
          <div v-show="LoadingUpdateCR">
            Mise à jour des code remise en cours...
          </div>
          <div class="flex justify-end">
            <button
              class="bg-bluevk rounded shadow px-2 py-1 mb-2 text-white"
              @click="updateRemiseImported()"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit h-fit hover:bg-white border border-blue-400 cursor-pointer"
        @click="back()"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8" class="m-auto">Retour</span>
      </div>
      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Conditions</span>
          <span class="text-green-600"> {{ fournisseurTab.CompanyName }} </span>
          <span class="text-orange-600">
            {{ fournisseurTab.Prefixe_tarif }}
          </span>
        </h2>
      </div>
      <div class="flex flex-row justify-end space-x-2">
        <span
          title="Rafraîchir"
          class="material-icons-round m-auto text-blue-500 cursor-pointer"
          @click="getCondition()"
        >
          refresh
        </span>
        <span
          title="Ajouter une condition manuellement"
          class="material-icons-round m-auto text-blue-500 cursor-pointer"
          @click="showManualAdd = !showManualAdd"
        >
          add_circle
        </span>
        <button
          class="flex flex-row space-x-2 items-center bg-bluevk rounded shadow p-1 text-white"
          @click="openUpdateCodeRemise()"
        >
          <span class="material-icons-outlined"> update </span
          ><span>code remise</span>
        </button>
        <label
          id="dropzone-file"
          title="Importer Excel"
          class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Importer Excel</span>
          <span class="material-icons-outlined m-auto">file_upload</span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx"
            @change="readExcel()"
          />
        </label>
        <!-- <label for="dropzone-file">
          <span class="material-icons-round text-blue-500 cursor-pointer">
            upload_file
          </span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx"
            @change="readExcel()"
          />
        </label> -->
      </div>
    </div>

    <div class="flex flex-row space-x-2 items-center">
      <searchInput
        placeholder="Rechercher dans conditions"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <div class="flex flex-row space-x-2 items-center">
        <div>Remise sur le ppc :</div>
        <input
          class="w-16"
          type="number"
          v-model="fournisseurTab.remisePPC"
          @change="saveChangeFournisseur()"
        />
        <span>%</span>
      </div>
    </div>

    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Designation
              </th>
              <!-- <th
                  class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code Remise
                </th> -->
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Code
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Famille
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                <span v-if="fournisseurTab.typeCondition == 'remise'"
                  >Remise</span
                >
                <span v-else>Coef</span>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                RFA 1
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                RFA 2
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                RFA 3
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
                v-for="(confCond, indexCC) in configurationConditionList"
                :key="indexCC"
              >
                {{ confCond.Name }}
                <!-- <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.T3"
                /> -->
              </th>
              <!-- <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Pro 1
                <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.T1"
                />
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Pro 2
                <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.T2"
                />
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Pro 3
                <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.T3"
                />
              </th> -->
              <!-- <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Part 1
                <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.Part1"
                />
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Part 2
                <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.Part2"
                />
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Part 3
                <input
                  type="Number"
                  class="text-black w-10"
                  v-model="importMarge.Part3"
                />
              </th> -->
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-vk text-white"
              >
                Status
                <button
                  v-show="loadingImportMarge"
                  class="p-1 bg-white text-black text-xs rounded shadow"
                  @click="updateConditionM()"
                >
                  valider
                </button>
              </th>
              <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
            </tr>
          </thead>

          <tbody class="bg-white">
            <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
            <tr v-for="(condi, index) in conditionList" :key="index">
              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span
                  class="p-1 bg-red-500 text-white text-sm rounded shadow cursor-pointer"
                  @click="deleteCondition(condi._id)"
                  >supp</span
                >
                <input
                  list="brow2"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].fournisseur"
                  class="w-28 block w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                />
                <datalist id="brow2">
                  <option
                    v-for="(c, index) in fournisseurList"
                    :key="index"
                    :value="c.CompanyName"
                  ></option>
                </datalist>
              </td>

              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
              >
                <input
                  type="text"
                  class="w-56"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].designation"
                />
              </td>

              <!-- <td
                  class="px-2 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  {{ condi.code }}
                </td> -->

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div
                  class="flex flex-col"
                  v-if="
                    !conditionList[index].familleFournisseur &&
                    !conditionList[index].famille
                  "
                >
                  <span class="material-icons-round text-xs text-red-500">
                    warning
                  </span>
                </div>
                <div class="flex flex-col">
                  <input
                    type="text"
                    class="w-20 border-2 px-1 rounded-md"
                    @change="updateCondtion(condi._id, condi)"
                    v-model="conditionList[index].familleFournisseur"
                  />
                  <div class="flex flex-row space-x-1 items-center">
                    ppc :
                    <input
                      type="text"
                      class="w-20 border-2 px-1 rounded-md"
                      @change="updateCondtion(condi._id, condi)"
                      v-model="conditionList[index].conditionPPC"
                    />%
                  </div>
                </div>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div
                  class="flex flex-col"
                  v-if="
                    !conditionList[index].familleFournisseur &&
                    !conditionList[index].famille
                  "
                >
                  <span class="material-icons-round text-xs text-red-500">
                    warning
                  </span>
                </div>
                <div>
                  <input
                    list="fami"
                    v-model="conditionList[index].famille"
                    @change="updateCondtion(condi._id, condi)"
                    class="w-28 block text-xs w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                  />
                  <datalist id="fami">
                    <option
                      v-for="(f, index) in familleList"
                      :key="index"
                      :value="f.Name"
                    ></option>
                  </datalist>
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].remiseFournisseur"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].rfa1"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].rfa2"
                />
                %
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col" v-if="conditionList[index].rfa3">
                  <div class="flex">
                    <input
                      type="Number"
                      class="w-10"
                      @change="updateCondtion(condi._id, condi)"
                      v-model="conditionList[index].rfa3.value"
                    />
                    %
                  </div>
                  <div class="" v-if="conditionList[index].rfa3">
                    <select
                      v-model="conditionList[index].rfa3.state"
                      @change="updateCondtion(condi._id, condi)"
                    >
                      <option value="false">Inactif</option>
                      <option value="true">Actif</option>
                    </select>
                  </div>
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                v-for="(condT, indexCT) in condi.conditions"
                :key="indexCT"
              >
                <span v-if="fournisseurTab.typeCondition == 'coef1'"> x </span>
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].conditions[indexCT]"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>
              <!-- <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span v-if="fournisseurTab.typeCondition == 'coef1'"> x </span>
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].T2"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span v-if="fournisseurTab.typeCondition == 'coef1'"> x </span>
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].T3"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span v-if="fournisseurTab.typeCondition == 'coef1'"> x </span>
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].P1"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span v-if="fournisseurTab.typeCondition == 'coef1'"> x </span>
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].P2"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span v-if="fournisseurTab.typeCondition == 'coef1'"> x </span>
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondtion(condi._id, condi)"
                  v-model="conditionList[index].P3"
                />
                <span v-if="fournisseurTab.typeCondition == 'remise'">%</span>
              </td> -->
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <div class="flex flex-row space-x-1">
                  <div>
                    <span
                      v-if="conditionList[index].actif"
                      @click="
                        updateCondtionActif(condi._id, condi, index, false)
                      "
                      class="p-1 bg-green-500 text-white text-xs rounded cursor-pointer"
                      >Actif</span
                    ><span
                      v-else
                      @click="
                        updateCondtionActif(condi._id, condi, index, true)
                      "
                      class="p-1 bg-red-500 text-white text-xs rounded cursor-pointer"
                      >Inactif</span
                    >
                  </div>

                  <div>
                    <span
                      class="p-1 bg-blue-500 text-white rounded shadow text-xs cursor-pointer"
                      @click="
                        updateCondtionType(condi._id, condi, index, condi.type)
                      "
                      >{{ conditionList[index].type }}</span
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="bg-white" v-show="showManualAdd">
            <tr>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <input
                  list="brow3"
                  v-model="addFournisseur"
                  placeholder="Fournisseur"
                  class="w-28 block w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                />
                <datalist id="brow3">
                  <option
                    v-for="(c, index) in fournisseurList"
                    :key="index"
                    :value="c.CompanyName"
                  ></option>
                </datalist>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 18%"
              >
                <input
                  type="text"
                  placeholder="Désignation"
                  v-model="addDesignation"
                />
              </td>

              <!-- <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 12%"
                >
                  <input
                    type="text"
                    placeholder="Code remise"
                    v-model="addCode"
                  />
                </td>  -->

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="text"
                  placeholder="Famille Fournisseur"
                  v-model="addFamilleFournisseur"
                />
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="text"
                  placeholder="Famille Fournisseur"
                  v-model="addFamille"
                />
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addRemise"
                />
                %
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addRfa1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addRfa2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addRfa3"
                />
                %
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
                v-for="(confCond, indexCC) in configurationConditionList"
                :key="indexCC"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addConfigCondition[indexCC]"
                />
                %
              </td>
              <!-- <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addT2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addT3"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addP1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addP2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  class="w-4/6 focus:outline-2 outline-sky-300"
                  type="Number"
                  v-model="addP3"
                />
                %
              </td> -->
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <span>Actif</span>
                <span>Inactif</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="flex justify-end" v-show="showManualAdd">
    <button
      class="px-6 py-3 mt-4 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      @click="postCondition()"
    >
      Ajouter
    </button>
  </div>
  <div class="flex flex-col mt-2">
    <pageNumber
      :pageStart="pageStart"
      :pageNext="pageNext"
      :tabList="amountLines"
      :pageTab="pageTab"
      :pageTotal="Math.ceil(amountLines / 10)"
      :clickFunction="getCondition"
      :currentPage="currentPage"
      :typeList="'conditions'"
      @update:start="pageStart = $event"
      @update:tab="pageTab = $event"
      @update:next="pageNext = $event"
    />
  </div>
  <div class="flex justify-end" v-if="conditionList.length > 0">
    <button
      class="p-2 bg-red-500 text-white rounded shadow"
      @click="deleteAllCondition()"
    >
      Tout supprimer
    </button>
  </div>
  <div v-if="conditionList.length == 0" class="mt-4">
    <button
      @click="getRemiseList()"
      class="p-2 bg-blue-500 text-white rounded shadow"
    >
      Rechercher les codes remise
    </button>
    <span v-if="loadingRemiseList">Chargement...</span>
    <div class="flex flex-col space-y-2">
      <div class="grid grid-cols-10 gap-2">
        <div v-for="(rem, indexRem) in remiseList.codeRemise" :key="indexRem">
          <span>{{ rem }}</span>
        </div>
      </div>
      <div v-if="remiseList.length != 0">
        <button
          @click="setRemiseImported()"
          class="p-2 bg-blue-500 text-white rounded shadow"
        >
          Importer les remises
        </button>
      </div>
    </div>
    <div>--- ou ---</div>
    <div class="flex flex-col space-y-2">
      <div>Importer depuis fournisseur :</div>
      <div>
        <select
          v-model="importPlateformSelected"
          @change="checkClient()"
          class="p-2"
        >
          <option v-for="item in importPlateform" :key="item._id" :value="item">
            {{ item.Name }}
          </option>
        </select>
        <span v-show="importErrorClient" class="text-red-500"
          >Vous n'êtes pas client de cette plateform!</span
        >
        <button
          class="p-2 bg-blue-500 text-white rounded shadow"
          @click="importConditionFromPlateform()"
        >
          Valider
        </button>
        <div v-show="loadingImportFP">Importation en cours...</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import readXlsxFile from "read-excel-file";
import {
  deleteAll,
  updateConditionFromPlateform,
} from "@/hooks/fournisseur/conditionFournisseur";
import {
  getPlateform,
  verifyClient,
  importConditionOnly,
} from "@/hooks/fournisseur/importConditionFournisseur";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import * as XLSX from "xlsx";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  name: "MyFournisseurs",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      lock: true,
      showManualAdd: false,
      showEdit: true,
      showFile: false,
      showFileContent: true,
      importLoading: false,
      showFinished: false,
      conditionList: [],
      fournisseurList: [],
      familleList: [],
      addFournisseur: "",
      addDesignation: "",
      addFamilleFournisseur: "",
      addFamille: "",
      addCode: "",
      addRemise: 0,
      addRfa1: 0,
      addRfa2: 0,
      addRfa3: 0,
      addT1: 0,
      addT2: 0,
      addT3: 0,
      addP1: 0,
      addP2: 0,
      addP3: 0,
      addActif: true,
      file: null,
      readFile: null,
      searchInFournisseurList: "",
      activePage: 0,
      pageAmount: 0,
      amountLines: 0,
      paginateArray: [],
      paginateNextStep: 11,
      startPaginate: 1,
      loadingRemiseList: false,
      remiseList: [],
      importPlateform: [],
      importPlateformSelected: null,
      importErrorClient: false,
      loadingImportFP: false,
      importMarge: {
        T1: 0,
        T2: 0,
        T3: 0,
        Part1: 0,
        Part2: 0,
        Part3: 0,
      },
      loadingImportMarge: true,
      addConfigCondition: [],
      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      showUpdateCodeRemise: false,
      updateCodeList: [],
      LoadingUpdateCR: false,
      configurationConditionList: [],
      fournisseurTab: {},
    };
  },
  components: {
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["user", "fournisseurID"]),
  },
  methods: {
    back() {
      this.$router.back();
    },
    async getFournisseurByID() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    getFamilleArticle() {
      axios
        .get(`${process.env.VUE_APP_API}/sousfamillearticle`)
        .then((response) => {
          this.familleList = response.data;
        });
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
      for (const conf of this.configurationConditionList) {
        this.addConfigCondition.push(0);
      }
    },
    async getCondition(skipValue, limitValue) {
      this.currentPage = skipValue;
      await axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOne`, {
          plateform: this.user.proprietaire,
          fournisseur: this.fournisseurTab.CompanyName,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response);
          this.conditionList = response.data.data;
          this.pageAmount = response.data.page;
          this.amountLines = response.data.count;
        });
    },
    getRemiseList() {
      this.loadingRemiseList = true;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getRemiseList`, {
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        })
        .then((response) => {
          this.loadingRemiseList = false;
          this.remiseList = response.data;
        });
    },
    setRemiseImported() {
      let condTab = [];
      for (const cond of this.configurationConditionList) {
        condTab.push(0);
      }
      this.loadingRemiseList = true;
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/setRemiseImported`,
          {
            plateform: this.user.proprietaire,
            Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
            fournisseur: this.fournisseurTab.CompanyName,
            remiseList: this.remiseList,
            conditions: condTab,
          }
        )
        .then(() => {
          this.loadingRemiseList = false;
          this.remiseList = [];
          this.getCondition();
        });
    },
    updateRemiseImported() {
      this.LoadingUpdateCR = true;
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/setRemiseImported`,
          {
            plateform: this.user.proprietaire,
            Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
            fournisseur: this.fournisseurTab.CompanyName,
            remiseList: this.updateCodeList,
          }
        )
        .then(() => {
          this.LoadingUpdateCR = false;
          this.showUpdateCodeRemise = false;
          this.updateCodeList = [];
          this.getCondition();
        });
    },
    selectPage(nb) {
      console.log(nb);
      this.activePage = nb;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOne`, {
          plateform: this.user.proprietaire,
          fournisseur: this.fournisseurTab.CompanyName,
          skip: this.activePage,
        })
        .then((response) => {
          this.conditionList = response.data.data;
        });
    },
    previousPage() {
      this.activePage = this.activePage - 1;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOne`, {
          plateform: this.user.proprietaire,
          fournisseur: this.fournisseurTab.CompanyName,
          skip: this.activePage,
        })
        .then((response) => {
          this.conditionList = response.data.data;
        });
    },
    pageUp() {
      if (this.pageNext <= this.pageAmount) {
        this.pageStart = this.pageNext;
        this.paginateArray.forEach((element, index) => {
          this.paginateArray[index] = { pageNumber: this.pageNext };
          this.pageNext = this.pageNext + 1;
        });
      }
    },
    pageDown() {
      if (this.pageNext != 11) {
        this.pageNext = this.pageNext - 20;
        this.pageStart = this.pageNext;
        this.paginateArray.forEach((element, index) => {
          this.paginateArray[index] = { pageNumber: this.pageNext };
          this.pageNext = this.pageNext + 1;
        });
      }
    },
    initPaginateArray() {
      this.paginateArray = new Array(10);
      this.paginateArray = Array.from(this.paginateArray);
      var i = 1;
      this.paginateArray.forEach((element, index) => {
        this.paginateArray[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    postCondition() {
      // if (this.addFournisseur && this.addDesignation && this.addCode) {
      if (this.addFournisseur && this.addDesignation) {
        axios
          .post(`${process.env.VUE_APP_API}/conditionFournisseur`, {
            fournisseur: this.addFournisseur,
            plateform: this.user.proprietaire,
            // code: this.addCode,
            designation: this.addDesignation,
            familleFournisseur: this.addFamilleFournisseur,
            famille: this.addFamille,
            remiseFournisseur: this.addRemise,
            rfa1: this.addRfa1,
            rfa2: this.addRfa2,
            rfa3: { value: this.addRfa3, state: false },
            conditions: this.addConfigCondition,
            T1: this.addT1,
            T2: this.addT2,
            T3: this.addT3,
            P1: this.addP1,
            P2: this.addP2,
            P3: this.addP3,
            actif: this.addActif,
          })
          .then(() => {
            this.getCondition();
            this.showManualAdd = false;
            this.success("La ligne a bien été ajouté");
          })
          .catch((err) => {
            this.error(err);
          });
      } else {
        this.error("Veuillez remplir les champs");
      }
    },
    updateCondtion(id, data) {
      axios.put(`${process.env.VUE_APP_API}/conditionFournisseur/${id}`, data);
    },
    updateCondtionActif(id, data, index, state) {
      this.conditionList[index].actif = state;
      axios.put(`${process.env.VUE_APP_API}/conditionFournisseur/${id}`, data);
    },
    updateCondtionType(id, data, index, type) {
      if (type == "remise") {
        this.conditionList[index].type = "prixnet";
      } else {
        this.conditionList[index].type = "remise";
      }
      axios.put(`${process.env.VUE_APP_API}/conditionFournisseur/${id}`, data);
    },
    async readExcel() {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.readFile.splice(0, 1);
      this.showFile = true;
    },
    closeImport() {
      this.showFile = false;
      this.readFile = null;
      this.file = null;
      this.showFinished = false;
      this.showFileContent = true;
      this.getCondition();
    },
    importExcel() {
      this.importLoading = true;
      this.showFileContent = false;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/importExcel`, {
          plateform: this.user.proprietaire,
          data: this.readFile,
          configCondition: this.configurationConditionList,
        })
        .then(() => {
          this.importLoading = false;
          this.showFinished = true;
        });
    },
    deleteCondition(id) {
      axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/deleteCondition`,
          {
            id: id,
          }
        )
        .then(() => {
          this.success("La ligne a bien été supprimé");
          this.getCondition();
        });
    },
    async deleteAllCondition() {
      await deleteAll(this.user.proprietaire, this.fournisseurTab.CompanyName);
      this.getCondition();
    },
    async checkClient() {
      this.importErrorClient = !(await verifyClient(
        this.importPlateformSelected.Name,
        this.user.proprietaire
      ));
    },
    async importConditionFromPlateform() {
      let condTab = [];
      for (const cond of this.configurationConditionList) {
        condTab.push(0);
      }
      this.loadingImportFP = true;
      await importConditionOnly(
        this.importPlateformSelected.Name,
        this.user.proprietaire,
        {
          T1: 0,
          T2: 0,
          T3: 0,
          Part1: 0,
          Part2: 0,
          Part3: 0,
        },
        this.fournisseurTab.CompanyName,
        condTab
      );
      this.loadingImportFP = false;
      this.getCondition();
    },
    async updateConditionM() {
      this.loadingImportMarge = false;
      await updateConditionFromPlateform(
        this.user.proprietaire,
        this.importMarge,
        this.fournisseurTab.CompanyName
      );
      this.loadingImportMarge = true;
      this.getCondition();
    },
    saveChangeFournisseur() {
      axios.put(
        `${process.env.VUE_APP_API}/fournisseur/modifyFournisseur/${this.fournisseurTab._id}`,
        {
          data: this.fournisseurTab,
        }
      );
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getCondition(skipValue, limitValue);
        this.getFournisseurLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/conditionFournisseur/searchCondition`,
            {
              plateform: this.user.proprietaire,
              fournisseur: this.fournisseurTab.CompanyName,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.conditionList = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    async openUpdateCodeRemise() {
      this.showUpdateCodeRemise = true;
      const toUpdateCode = await axios.post(
        `${process.env.VUE_APP_API}/conditionFournisseur/updateCodeRemise`,
        {
          plateform: this.user.proprietaire,
          fournisseur: this.fournisseurTab.CompanyName,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        }
      );
      this.updateCodeList = toUpdateCode.data;
    },
    closeUpdateCodeRemise() {
      this.updateCodeList = [];
      this.showUpdateCodeRemise = false;
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getFournisseurByID();
    this.getConfigurationCondition();
    this.getCondition(1, 10);
    this.pageSwitch();
    this.initPaginateArray();
    this.getFournisseur();
    this.getFamilleArticle();
    this.importPlateform = await getPlateform();
  },
};
</script>

<style scoped>
.bg-vk {
  background-color: #2262b3;
}
</style>
