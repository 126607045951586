<template>
  <div class="flex flex-col">
    <p class="text-2xl font-extrabold text-dark-grey-900 mx-auto">
      {{ contact.name }}
    </p>
    <p class="text-2xl font-bold text-gray-400 mx-auto">
      {{ contact.position }}
    </p>
  </div>
  <div class="container flex flex-col mx-auto bg-white">
    <div class="w-full draggable">
      <div class="container flex flex-col items-center gap-16 mx-auto">
        <div class="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
          <div
            class="flex flex-col items-center gap-3 px-8 py-10 bg-white rounded-3xl shadow-main"
          >
            <span class="material-symbols-rounded text-5xl text-blue-500">
              drafts
            </span>
            <p class="text-2xl font-extrabold text-dark-grey-900">Email</p>
            <a
              class="text-lg font-bold text-purple-blue-500"
              href="mailto: hello@loopple.com"
              >{{ contact.email }}</a
            >
          </div>
          <div
            class="flex flex-col items-center gap-3 px-8 py-10 bg-white rounded-3xl shadow-main"
          >
            <span class="material-symbols-rounded text-5xl text-blue-500">
              call
            </span>
            <p class="text-2xl font-extrabold text-dark-grey-900">Téléphone</p>
            <a
              class="text-lg font-bold text-purple-blue-500"
              href="tel:+516-486-5135"
              >{{ contact.phone }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contact: {
      type: Object,
      default: () => ({
        id: "oguz",
        name: "M. Oguz Yildiz",
        email: "moguzyildizh@example.com",
        phone: "098-765-4321",
        position: "Client Representative",
      }),
    },
  },
};
</script>
<style></style>
