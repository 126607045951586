<template>
  {{ infoRetour }}
  <div
    v-if="infoInRetour"
    :class="`modal ${
      !infoInRetour && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInRetour = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 78rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-center modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Détails du retour</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInRetour = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div
          class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          style="height: 30vh"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Réf
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Nom
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Quantité
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Prix Brut HT
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Prix net HT
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Prix total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="cursor: pointer"
                  class="hover:bg-blue-50"
                  v-for="(a, index) in detailRetour.pdfData"
                  :key="index"
                >
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{ a.ref }}
                  </td>
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{ detailRetour.Name }}
                  </td>
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{ a.quantite }}
                  </td>
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{
                      parseFloat(
                        detailRetour.Articles[index].Prix_euro
                      ).toFixed(2)
                    }}
                  </td>
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{ a.remise }}
                  </td>
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{ parseFloat(a.prixU).toFixed(2) }}
                  </td>
                  <td
                    class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                  >
                    {{ parseFloat(a.total).toFixed(2) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- Breadcrumb -->
    <button
      @click="open = true"
      class="px-6 py-2 mt-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
    >
      Créer
    </button>

    <div
      v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un article</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">N° Article</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">qr_code_2</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Dépot</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">warehouse</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Quantité</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">inventory</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Description</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">description</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="open = false"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:bg-white"
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border-t border-b border-r border-gray-400 rounded-r appearance-none sm:rounded-r-none sm:border-r-0 focus:border-l focus:border-r focus:bg-white"
          >
            <option>All</option>
            <option>Active</option>
            <option>Inactive</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="relative block mt-2 sm:mt-0">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-gray-500 fill-current">
            <path
              d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
            />
          </svg>
        </span>

        <input
          placeholder="Search"
          class="block w-full focus:outline-2 outline-sky-300 py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none sm:rounded-l-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>
    </div> -->
    <div class="flex flex-col mt-8">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type de document
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total Prix
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Validation
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Pdf
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Validé le retour
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in infosRetour" :key="index">
                <td
                  class="px-6 py-4 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.type }} - {{ u.NumeroBl }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ getFRDate(u.Date) }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <span> {{ u.Totalretour.toFixed(2) }} € </span>
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    v-if="u.status === 'Retour validé'"
                    ><span
                      class="material-icons-outlined"
                      title="Retour validé"
                    >
                      done
                    </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-orange-800 bg-orange-100 rounded-full"
                    v-if="u.status === 'Retour accepté'"
                    ><span
                      class="material-icons-outlined"
                      title="En attente de validation"
                    >
                      schedule
                    </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                    v-if="u.status === 'Demande de retour'"
                    ><span
                      class="material-icons-outlined"
                      title="Retour demandé"
                      >close</span
                    ></span
                  >
                </td>
                <td
                  v-if="u.status == 'Retour validé'"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <div
                    v-if="u.type == 'Facture'"
                    class="flex flex-col relative"
                  >
                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPdfFacture(u)"
                    >
                      download
                    </span>
                  </div>
                  <div v-if="u.type == 'BL'" class="flex flex-col relative">
                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                  </div>
                </td>
                <td
                  v-if="u.status != 'Retour validé'"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <button
                    v-if="u.status === 'Demande de retour'"
                    class="w-fit px-2 py-1 h-fit text-blue-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
                    @click="valideRetour(u._id)"
                  >
                    <span class="material-icons-outlined m-auto"
                      >double_arrow</span
                    >
                    <p class="m-auto">Accepté le retour</p>
                    <!-- <span class="m-auto">Supprimer</span> -->
                  </button>
                  <button
                    v-if="u.status === 'Retour accepté'"
                    class="w-fit px-2 py-1 h-fit text-green-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
                    @click="activeRetour(u)"
                  >
                    <span class="material-icons-outlined m-auto">done</span>
                    <p class="m-auto">Accepté le retour</p>
                    <!-- <span class="m-auto">Supprimer</span> -->
                  </button>
                  <p
                    v-if="u.status === 'Retour validé'"
                    class="w-fit px-2 py-1 m-auto h-fit text-green-600 rounded-md hover:bg-gray-100 focus:outline-none text-center"
                  >
                    Retour Validé
                  </p>
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <span
                      @click="(infoInRetour = true), getDetailRetour(u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600 cursor-pointer text-3xl mr-4"
                    >
                      info
                    </span>
                    <button
                      class="flex w-fit px-2 py-1 h-fit text-red-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
                      @click="removeRetour(u._id)"
                    >
                      <span class="material-icons-outlined m-auto">delete</span>
                      <!-- <span class="m-auto">Supprimer</span> -->
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex flex-col items-center px-5 py-5 bg-white border-t xs:flex-row xs:justify-between"
          >
            <span class="text-xs text-gray-900 xs:text-sm"
              >1 à 4 sur 50 Articles</span
            >

            <div class="inline-flex mt-2 xs:mt-0">
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
              >
                Précédent
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                1
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                2
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                3
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                4
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  createlivraisonAvoir,
  createlivraisonAvoirDuplicata,
} from "@/hooks/pdf/livraison";
import {
  createfactureAvoir,
  createfactureAvoirDuplicata,
} from "@/hooks/pdf/facture";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyRetour",
  data: () => ({
    infosRetour: [],
    open: false,
    infoInRetour: false,
    detailRetour: [],
    isProcessed: false,
    numRetour: 0,
    documentType: "Bon de livraison",
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosRetour() {
      axios
        .post(`${process.env.VUE_APP_API}/retourConsigne/getByOwner`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.infosRetour = response.data;
          console.log("Les retours", this.infosRetour);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeRetour(objet) {
      axios
        .put(`${process.env.VUE_APP_API}/retourConsigne/removeRetour/${objet}`)
        .then(() => {
          this.toast.success("Retour supprimé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.getInfosRetour();
        });
    },
    getDetailRetour(u) {
      this.detailRetour = u;
    },
    createPdfFacture(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createfactureAvoir({
          Name: this.user.username,
          Owner: this.user.proprietaire,
          Date: objet.Date,
          Numero: objet.NumeroBl,
          TotalPrice: objet.Totalretour,
          Client: objet.Client,
          IsProcessed: false,
          Note: objet.Note,
          Articles: objet.Articles,
          reglement: objet.reglement,
          pdfData: objet.pdfData,
          clientInfo: objet.clientInfo,
          Transport: 0,
        });
      } else {
        createfactureAvoirDuplicata({
          Name: this.user.username,
          Owner: this.user.proprietaire,
          Date: objet.Date,
          Numero: objet.NumeroBl,
          TotalPrice: objet.Totalretour,
          Client: objet.Client,
          IsProcessed: false,
          Note: objet.Note,
          Articles: objet.Articles,
          reglement: objet.reglement,
          pdfData: objet.pdfData,
          clientInfo: objet.clientInfo,
          Transport: 0,
        });
      }
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraisonAvoir({
          Name: this.user.username,
          Owner: this.user.proprietaire,
          Date: objet.Date,
          Numero: objet.NumeroBl,
          Archived: false,
          TotalPrice: objet.Totalretour,
          Client: objet.Client,
          IsProcessed: false,
          Note: objet.Note,
          Articles: objet.Articles,
          reglement: [],
          pdfData: objet.pdfData,
          clientInfo: objet.clientInfo,
          Transport: 0,
        });
      } else {
        createlivraisonAvoirDuplicata({
          Name: this.user.username,
          Owner: this.user.proprietaire,
          Date: objet.Date,
          Numero: objet.NumeroBl,
          Archived: false,
          TotalPrice: objet.Totalretour,
          Client: objet.Client,
          IsProcessed: false,
          Note: objet.Note,
          Articles: objet.Articles,
          reglement: [],
          pdfData: objet.pdfData,
          clientInfo: objet.clientInfo,
          Transport: 0,
        });
      }
    },
    valideRetour(objet) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      axios
        .put(`${process.env.VUE_APP_API}/retourConsigne/${objet}`, {
          status: "Retour accepté",
          Date: today,
        })
        .then((response) => {
          this.toast.success("Retour accepté !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.getInfosRetour();
        });
    },
    activeRetour(objet) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      axios
        .put(`${process.env.VUE_APP_API}/retourConsigne/${objet._id}`, {
          IsProcessed: true,
          status: "Retour validé",
          Date: today,
        })
        .then((response) => {
          this.numRetour = response.data + 1;
          this.success("Retour validé !");
          if (objet.type === "BL") {
            this.saveBdl(objet);
          } else if (objet.type === "Facture") {
            this.saveFacture(objet);
          }

          this.getInfosRetour();
          // createlivraisonAvoir({
          //   Name: this.user.username,
          //   Owner: this.user.proprietaire,
          //   Date: today,
          //   Numero: this.numRetour,
          //   TotalPrice: "-" + objet.Totalretour,
          //   Client: objet.Client,
          //   pdfData: objet.Articles,
          //   Articles: objet.Articles,
          //   clientInfo: objet.clientInfo,
          //   Transport: "0",
          // });
        });
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async saveFacture(objet) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      var numDoc = 0;
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          numDoc = response.data + 1;
        });
      await axios
        .post(`${process.env.VUE_APP_API}/facture`, {
          Name: this.user.username,
          Owner: this.user.proprietaire,
          Date: today,
          Numero: numDoc,
          TotalPrice: objet.Totalretour,
          Client: objet.Client,
          IsProcessed: false,
          Note: objet.Note,
          Articles: objet.Articles,
          reglement: [],
          pdfData: objet.pdfData,
          clientInfo: objet.clientInfo,
          Transport: 0,
        })
        .then(() => {
          createfactureAvoir({
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: today,
            Numero: numDoc,
            TotalPrice: objet.Totalretour,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.Articles,
            reglement: [],
            clientInfo: objet.clientInfo,
            pdfData: objet.pdfData,
            Transport: 0,
          });
        });
    },
    async saveBdl(objet) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      var numDoc = 0;
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          numDoc = response.data + 1;
        });
      await axios
        .post(`${process.env.VUE_APP_API}/bdl`, {
          Name: this.user.username,
          Owner: this.user.proprietaire,
          Date: today,
          Archived: false,
          Numero: numDoc,
          TotalPrice: objet.Totalretour,
          Client: objet.Client,
          IsProcessed: false,
          Note: objet.Note,
          Articles: objet.Articles,
          reglement: [],
          pdfData: objet.pdfData,
          clientInfo: objet.clientInfo,
          Transport: 0,
        })
        .then(() => {
          createlivraisonAvoir({
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: today,
            Numero: numDoc,
            Archived: false,
            TotalPrice: objet.Totalretour,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.Articles,
            reglement: [],
            pdfData: objet.pdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          });
        });
    },
  },
  mounted() {
    this.getInfosRetour();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
