<template>
  <div>
    <div class="flex flex-col space-y-2 items-center w-fit">
      <div>
        <input
          type="number"
          v-model="tmp_quantity"
          @change="$emit('updateQuantity', tmp_quantity)"
          class="w-8 border border-gray-200 rounded"
        />
      </div>
      <div>
        <button
          class="material-icons-round text-bluevk"
          @click="
            handlerArticle(
              {
                article: article,
                stockData: [],
              },
              tmp_quantity
            )
          "
          :disabled="loadingAddCart"
        >
          add_shopping_cart
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { prepareArticle } from "@/hooks/searchEngine/articles";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "componentPanier",
  data() {
    return {
      tmp_quantity: 1,
      loadingAddCart: false,
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    quantity: Number,
    savedSearch: {
      type: String,
      default: "",
    },
    prixData: {},
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters([
      "user",
      "plateform",
      "actionArticle",
      "comptoirSessionActive",
    ]),
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
      "savecomptoirsession",
    ]),
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.comptoirSessionActive[0].dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id + "Consigne",
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        // if (this.comptoirSessionActive[0].Client[0].professionnel == true) {
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
      } else {
        if (
          this.comptoirSessionActive[0].Client[0].numcomptedasilva &&
          this.comptoirSessionActive[0].Client[0].numcomptedasilva != ""
        ) {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    async handlerArticle(structure, qtyToPanier) {
      this.loadingAddCart = true;
      var existArticle = false;
      //   await MisePanier(this.savedSearch, structure.article.Ref_fournisseur);
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) +
            parseInt(qtyToPanier);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        // var dataTab = await prepareArticle(
        //   structure,
        //   plateform_mere,
        //   this.comptoirSessionActive[0].Client[0].Name,
        //   this.comptoirSessionActive[0].Client[0].Group.Name,
        //   parseInt(qtyToPanier)
        // );

        let dataTab = this.article;
        console.log(dataTab);
        dataTab.PrixFournisseurType = this.prixData.PrixFournisseurType;
        dataTab.remise = this.prixData.remise;
        dataTab.remiseFournisseur = this.prixData.remiseFournisseur;
        dataTab.Prix_vente = this.prixData.prix_net;
        dataTab.Prix_euro = this.prixData.prix_brut;
        dataTab.Prix_achat = this.prixData.prix_achat;
        dataTab.FournisseurSelected = this.prixData.FournisseurSelected;
        dataTab.Promo_set = this.prixData.promo;
        dataTab.quantity = qtyToPanier;
        dataTab.quantityPrep = 0;
        dataTab.quantityReturned = 0;

        await this.addConsigne(dataTab);
        if (structure.article.fraisdeport) {
          if (structure.article.fraisdeport > 0) {
            var countArticle = 0;
            for (const [index, actArticle] of this.actionArticle.entries()) {
              if (
                actArticle.Code_marque == structure.article.Code_marque &&
                actArticle.Ref_fournisseur !=
                  "Transport " + structure.article.Code_marque
              ) {
                countArticle++;
              }
            }
            if (countArticle % 5 == 0) {
              this.addactionArticle({
                _id: "fraisdeport" + structure.article.Code_marque,
                Ref_fournisseur: "Transport " + structure.article.Code_marque,
                Prix_euro: structure.article.fraisdeport,
                Prix_vente: structure.article.fraisdeport,
                PrixFournisseurType: "prixnet",
                disabled: true,
                quantity: 1,
                remise: 0,
                prixtotal: structure.article.fraisdeport,
                prixfinal: structure.article.fraisdeport,
                Code_marque: structure.article.Code_marque,
                Description:
                  "Transport dépannage fournisseur " +
                  structure.article.Code_marque,
                commentaire: "",
              });
            }
          }
        }
        await this.addactionArticle(dataTab);

        this.refClient = this.noteDocument;

        this.savecomptoirsession({
          id: 6,
          clientFocus: this.comptoirSessionActive[0].Client,
          refClient: this.comptoirSessionActive[0].refClient,
          consigneData: this.comptoirSessionActive[0].consigneData,
          consigneNonData: this.comptoirSessionActive[0].consigneNonData,
          dsvCheck: this.comptoirSessionActive[0].dsvCheck,
        });
      }
      if (this.savedSearch) {
        await axios.post(
          `${process.env.VUE_APP_API}/searchEngine/savedSearchInCard`,
          {
            id: this.savedSearch,
            Ref_fournisseur: structure.article.Ref_fournisseur,
          }
        );
      }
      this.loadingAddCart = false;
      this.success();
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.tmp_quantity = this.quantity;
  },
};
</script>
<style></style>
