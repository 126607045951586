<template>
  <div>
    <div
      v-if="infoInBdl"
      :class="`modal ${
        !infoInBdl && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="infoInBdl = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif du bon de livraison</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="infoInBdl = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/3 ml-2">
              Référence client:
              {{ detailArticle.Note }}
            </div>

            <div class="flex flex-col my-auto items-center ml-auto mx-10">
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="createPDF(detailArticle)"
              >
                download
              </span>
              <div>BL n° {{ detailArticle.Numero }}</div>
            </div>
          </div>

          <table v-if="detailArticle.groupedTab" class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="detailArticle.groupedTab.length > 0"
                class="bg-white w-fit"
              >
                <div
                  v-for="(bdl, ind) in detailPdf"
                  :key="ind"
                  class="tableHEADTR"
                >
                  <br />
                  <div class="bg-gray-100">BL n°{{ bdl.Numero }}</div>

                  <tr
                    v-for="(r, index) in detailArticle.groupedTab[ind].pdfData"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center">
                        <span class="ml-4 text-sm leading-5 text-gray-900">
                          {{ r.ref }}
                        </span>
                      </div>
                    </td>

                    <td
                      class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5 text-gray-500">
                        {{ r.description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-5">
                        {{ r.quantite }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        parseFloat(
                          detailArticle.groupedTab[ind].Articles[index]
                            .Prix_euro
                        ).toFixed(2)
                      }}
                      €
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailArticle.groupedTab[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{ r.remise }} %
                      </div>
                      <div v-else>prix net</div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailArticle.groupedTab[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{
                          parseFloat(
                            detailArticle.groupedTab[ind].Articles[index]
                              .Prix_euro -
                              detailArticle.groupedTab[ind].Articles[index]
                                .Prix_euro *
                                (r.remise / 100)
                          ).toFixed(2)
                        }}
                        €
                      </div>

                      <div v-else>
                        {{
                          parseFloat(
                            detailArticle.groupedTab[ind].Articles[index]
                              .Prix_vente
                          ).toFixed(2)
                        }}
                        €
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{ parseFloat(r.total).toFixed(2) }}€
                    </td>
                  </tr>
                </div>
              </tbody>
              <!-- 2 eme  -->
              <tbody
                v-else-if="detailArticle.groupedTab.length == 0"
                class="bg-white w-fit"
              >
                <tr
                  v-for="(r, index) in detailPdf"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.ref }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantite }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      parseFloat(
                        detailArticle.Articles[index].Prix_euro
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailArticle.Articles[index].PrixFournisseurType !=
                        'prixnet'
                      "
                    >
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailArticle.Articles[index].PrixFournisseurType !=
                        'prixnet'
                      "
                    >
                      {{
                        parseFloat(
                          detailArticle.Articles[index].Prix_euro -
                            detailArticle.Articles[index].Prix_euro *
                              (r.remise / 100)
                        ).toFixed(2)
                      }}
                      €
                    </div>

                    <div v-else>
                      {{
                        parseFloat(
                          detailArticle.Articles[index].Prix_vente
                        ).toFixed(2)
                      }}
                      €
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.total).toFixed(2) }}€
                  </td>
                </tr>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT :
                    {{ parseFloat(detailArticle.TotalPrice).toFixed(2) }} €
                  </div>
                  <div
                    v-if="detailArticle.TotalPriceTTC"
                    class="text-sm leading-5 text-gray-500"
                  >
                    Total TTC :
                    {{ parseFloat(detailArticle.TotalPriceTTC).toFixed(2) }} €
                  </div>
                  <div v-else class="text-sm leading-5 text-gray-500">
                    Total TTC :
                    {{
                      parseFloat(
                        detailArticle.TotalPrice +
                          detailArticle.TotalPrice * 0.2
                      ).toFixed(2)
                    }}
                    €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <!-- modif modal -->
    <div
      v-if="showModifBL"
      :class="`modal ${
        !showModifBL && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showModifBL = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 88rem; min-height: 56rem; overflow: auto"
        class="z-50 w-11/12 mx-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">
              BL n°{{ tabModifBL.Numero }} - Client {{ tabModifBL.Client }}
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showModifBL = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!-- Modal Detail Articles -->
          <div
            v-show="showModifBL"
            style="max-width: 80rem"
            class="m-auto mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
          >
            <button
              class="p-1 my-2 rounded shadow bg-blue-500 text-white text-sm flex flex-row items-center"
              @click="showSaisieModifBL = !showSaisieModifBL"
            >
              <span class="material-icons-outlined"> border_color </span>
              <span> Ajout rapide </span>
            </button>
            <!--Body-->
            <table class="min-w-full">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white w-fit" v-if="tabModifBL.Articles">
                <tr
                  v-for="(art, index) in tabModifBL.Articles"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ art.Ref_fournisseur }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-900">
                      {{ art.Code_marque }}
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      {{ art.Description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <!-- {{ art.quantity }} -->
                    <input
                      class="text-sm leading-5 w-3/5 p-2 focus:outline-2 outline-sky-300 border rounded-md"
                      type="Number"
                      @change="
                        tabModifBL.pdfData[index].quantite = parseInt(
                          tabModifBL.Articles[index].quantity
                        )
                      "
                      v-model="tabModifBL.Articles[index].quantity"
                    />
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      class="text-sm leading-5 w-3/5 p-2 focus:outline-2 outline-sky-300 border rounded-md"
                      type="Number"
                      @change="
                        tabModifBL.pdfData[index].prixU = parseInt(
                          tabModifBL.Articles[index].Prix_euro
                        )
                      "
                      v-model="tabModifBL.Articles[index].Prix_euro"
                    />
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      class="text-sm leading-5 w-3/5 p-2 focus:outline-2 outline-sky-300 border rounded-md"
                      type="Number"
                      style="width: 12vh"
                      min="0"
                      max="100"
                      maxlength="3"
                      step="5"
                      @change="
                        tabModifBL.pdfData[index].remise = parseInt(
                          tabModifBL.Articles[index].remise
                        )
                      "
                      v-model="tabModifBL.Articles[index].remise"
                    />
                    %
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        parseFloat(art.Prix_euro) -
                        parseFloat(art.Prix_euro) * (art.remise / 100)
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        (parseFloat(art.Prix_euro) -
                          parseFloat(art.Prix_euro) * (art.remise / 100)) *
                        art.quantity
                      ).toFixed(2)
                    }}
                    €
                    <span
                      style="font-size: 15px"
                      class="material-icons-outlined text-red-600 cursor-pointer items-center rounded-full hover:bg-gray-200 p-1 w-6 h-6 float-right hover:shadow hover:text-red-500"
                      @click="deleteLine(index)"
                    >
                      cancel
                    </span>
                  </td>
                </tr>
                <tr class="tableHEADTR" v-show="showSaisieModifBL">
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        <input
                          class="text-sm leading-5 w-3/5 shadow focus:outline-2 outline-sky-300 border rounded-md"
                          type="text"
                          placeholder="Ref"
                          v-model="saisieModifBL_Ref"
                        />
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-900">
                      <input
                        class="text-sm leading-5 w-3/5 shadow focus:outline-2 outline-sky-300 border rounded-md"
                        type="text"
                        placeholder="Marque"
                        v-model="saisieModifBL_Marque"
                      />
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      <input
                        class="text-sm leading-5 w-3/5 shadow focus:outline-2 outline-sky-300 border rounded-md"
                        type="text"
                        placeholder="Description"
                        v-model="saisieModifBL_Desc"
                      />
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <!-- {{ art.quantity }} -->
                    <input
                      class="text-sm leading-5 w-3/5 shadow focus:outline-2 outline-sky-300 border rounded-md"
                      type="Number"
                      placeholder="Quantité"
                      v-model="saisieModifBL_quantity"
                    />
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      class="text-sm leading-5 w-3/5 shadow focus:outline-2 outline-sky-300 border rounded-md"
                      type="Number"
                      placeholder="PPC"
                      v-model="saisieModifBL_Prix"
                    />
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      class="text-sm leading-5 w-3/5 shadow focus:outline-2 outline-sky-300 border rounded-md"
                      type="Number"
                      placeholder="Remise"
                      style="width: 12vh"
                      min="0"
                      max="100"
                      maxlength="3"
                      step="5"
                      v-model="saisieModifBL_remise"
                    />
                    %
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        parseFloat(saisieModifBL_Prix) -
                        parseFloat(saisieModifBL_Prix) *
                          (saisieModifBL_remise / 100)
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row space-x-2 items-center">
                      <span
                        >{{
                          (
                            (parseFloat(saisieModifBL_Prix) -
                              parseFloat(saisieModifBL_Prix) *
                                (saisieModifBL_remise / 100)) *
                            saisieModifBL_quantity
                          ).toFixed(2)
                        }}
                        €</span
                      >
                      <button
                        class="p-1 rounded shadow bg-blue-500 text-white text-sm flex flex-row items-center"
                        @click="addManualLine()"
                      >
                        Ajouter
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <thead class="tableHEADTR" v-if="tabModifBL.pdfData">
                <tr
                  class="tableHEADTR"
                  v-if="tabModifBL.pdfData[0].remisegenerale"
                >
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="flex justify-center items-center px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-900 border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise générale :
                    <input
                      class="text-sm leading-5 shadow focus:outline-2 outline-sky-300 border rounded-md pl-1 py-0.5"
                      type="Number"
                      style="width: 12vh"
                      min="0"
                      max="100"
                      maxlength="3"
                      step="5"
                      @change="
                        tabModifBL.pdfData[0].remisegenerale = parseInt(
                          tabModifBL.pdfData[0].remisegenerale
                        )
                      "
                      v-model="tabModifBL.pdfData[0].remisegenerale"
                    />
                    %
                  </th>
                </tr>
                <tr class="tableHEADTR" v-else>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                  <th
                    class="flex justify-center items-center px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-900 border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise générale :
                    <input
                      class="text-sm leading-5 shadow focus:outline-2 outline-sky-300 border rounded-md pl-1 py-0.5"
                      type="Number"
                      style="width: 12vh"
                      min="0"
                      max="100"
                      maxlength="3"
                      step="5"
                      v-model="saisieModifBL_remisegenerale"
                    />
                    %
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="flex space-x-2 justify-end">
            <button
              class="my-4 p-2 bg-red-500 text-white rounded shadow"
              @click="removeBDL()"
            >
              Supprimer le BL
            </button>
            <button
              class="my-4 p-2 bg-gray-500 text-white rounded shadow"
              @click="archiveBDL()"
            >
              Archiver le BL
            </button>
            <button
              class="my-4 p-2 bg-blue-500 text-white rounded shadow"
              @click="updateBL()"
            >
              Valider
            </button>
          </div>
          <!-- Fin Detail Articles -->
        </div>
      </div>
    </div>

    <div
      v-if="showDetailArticles[index]"
      :class="`modal ${
        !showDetailArticles[index] && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDetailArticles[index] = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 55rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif BL</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDetailArticles[index] = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!-- Modal Detail Articles -->
          <div
            v-show="showDetailArticles[index]"
            style="max-width: 52rem"
            class="m-auto mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
          >
            <!--Body-->
            <table class="min-w-full">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white w-fit" v-if="Articles">
                <tr
                  v-for="(art, index) in Articles"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ art.Ref_fournisseur }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-900">
                      {{ art.Code_marque }}
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      {{ art.Description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ art.quantity }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(art.Prix_euro).toFixed(2) }} €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ art.remise }} %
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        parseFloat(art.Prix_euro) -
                        parseFloat(art.Prix_euro) * (art.remise / 100)
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        (parseFloat(art.Prix_euro) -
                          parseFloat(art.Prix_euro) * (art.remise / 100)) *
                        art.quantity
                      ).toFixed(2)
                    }}
                    €
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fin Detail Articles -->
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div>
        <searchInput
          v-if="tabState == 'actif'"
          placeholder="Rechercher dans bons de livraison"
          @searchWord="search(1, 10, false, $event)"
          @catchFalse="searchCatchError = $event"
          :searchStarted="searchInProgress"
          :catchError="searchCatchError"
        />
        <searchInput
          v-else
          placeholder="Rechercher dans bons de livraison archivés"
          @searchWord="search(1, 10, true, $event)"
          @catchFalse="searchCatchError = $event"
          :searchStarted="searchInProgress"
          :catchError="searchCatchError"
        />
      </div>

      <div class="flex flex-row items-center space-x-2 mt-2">
        <div
          :class="[
            selectedCount > 0
              ? 'p-1 text-sm text-blue-800 rounded-lg bg-blue-100'
              : 'p-1 text-sm text-gray-800 rounded-lg bg-gray-100',
          ]"
        >
          {{ selectedCount }} sel.
        </div>
        <button
          @click="selectAllBDL()"
          class="p-1 text-sm bg-green-500 text-white rounded"
        >
          Tout sel.
        </button>
        <button
          @click="unselectAllBDL()"
          class="p-1 text-sm bg-red-500 text-white rounded"
        >
          Tout desel.
        </button>
      </div>

      <div v-if="tabState == 'actif'">
        <button
          v-if="selectedCount > 1 && loadingRegrouper"
          class="shadow-lg text-white my-auto bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          <PulseLoader color="#ffffff" class="my-auto" />
        </button>
        <button
          v-else-if="selectedCount > 1 && !loadingRegrouper"
          @click="regrouper()"
          class="shadow-lg text-white my-auto bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5"
        >
          Regrouper {{ selectedCount }} docs
        </button>
      </div>
      <div class="">
        <router-link to="/Facturation">
          <button
            class="px-6 py-1 flex flex-row item-center font-medium space-x-2 text-white bg-blue-500 rounded-md hover:bg-blue-400 outline-sky-300"
            @click="redirigerFacturation"
          >
            <span class="m-auto"> Facturation FDM </span>
            <span
              class="material-icons-outlined m-auto"
              style="font-size: 20px"
            >
              arrow_forward
            </span>
          </button>
        </router-link>
      </div>
      <div class="flex flex-row space-x-2">
        <button
          class="w-32 bg-blue-600 p-1 text-white text-sm font-medium rounded-md shadow-lg border-2 border-orange-600"
          v-if="tabState == 'actif'"
          @click="getInfosBdl(1, 10), (selectedDoc = [])"
        >
          Actif
        </button>
        <button
          class="w-32 bg-blue-600 hover:bg-blue-500 hover:border-orange-600 p-1 text-white text-sm rounded-md shadow-lg border-2 border-gray-500"
          v-else
          @click="getInfosBdl(1, 10), (selectedDoc = [])"
        >
          Actif
        </button>
        <button
          class="w-32 bg-blue-600 p-1 text-white text-sm font-medium rounded-md shadow-lg border-2 border-orange-600"
          v-if="tabState == 'archived'"
          @click="getInfosBdlArchived(1, 10), (selectedDoc = [])"
        >
          Archivé
        </button>
        <button
          class="w-32 bg-blue-600 hover:bg-blue-500 hover:border-orange-600 p-1 text-white text-sm rounded-md shadow-lg border-2 border-gray-500"
          v-else
          @click="getInfosBdlArchived(1, 10), (selectedDoc = [])"
        >
          Archivé
        </button>
        <button
          class="w-32 bg-blue-600 hover:bg-blue-500 hover:border-orange-600 p-1 text-white text-sm rounded-md shadow-lg border-2 border-gray-500"
          v-if="user.accountType == 'Administrateur'"
          @click="$router.push('/deletedBdl')"
        >
          Supprimé
        </button>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  v-if="tabState == 'actif'"
                  style="width: 1rem"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex space-x-0.5">
                    <span>Sélect.</span>
                  </div>
                </th>
                <th
                  style="width: 1em"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Réf. client</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Date</span>
                  <span
                    v-if="showSortDate"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByDate(),
                        (showReverseDate = true),
                        (showSortDate = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseDate"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByDate(),
                        (showSortDate = true),
                        (showReverseDate = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro</span>
                  <span
                    v-if="showSortNumero"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByNumero(),
                        (showReverseNumero = true),
                        (showSortNumero = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseNumero"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByNumero(),
                        (showSortNumero = true),
                        (showReverseNumero = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                  <span
                    v-if="showSortAlpha"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByAlpha(),
                        (showReverseAlpha = true),
                        (showSortAlpha = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseAlpha"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByAlpha(),
                        (showSortAlpha = true),
                        (showReverseAlpha = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Créé par</span>
                  <span
                    v-if="showSortUsername"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByUsername(),
                        (showReverseUsername = true),
                        (showSortUsername = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseUsername"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByUsername(),
                        (showSortUsername = true),
                        (showReverseUsername = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>HT</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>TTC</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BDL
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BDT
                </th>
                <th
                  v-if="tabState == 'actif'"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Modfier
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in infosBdl"
                :key="index"
                :class="[
                  'cursor-pointer hover:shadow',
                  u.selected ? 'bg-blue-100' : 'hover:bg-gray-50',
                ]"
              >
                <td
                  v-if="tabState == 'actif'"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                    v-model="u.selected"
                    @change="selectOne(u._id, u.selected, u)"
                  />
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1em"
                >
                  {{ u.Note }}
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 1em"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="text-sm leading-5 text-gray-900">
                      {{ getFRDate(u.Date) }}
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                    <span
                      @click="getDetailArticle(u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600"
                    >
                      info
                    </span>
                  </div>
                  <div
                    class="text-sm leading-5 text-gray-900"
                    v-if="
                      u.textFile &&
                      u.textFile.codeClient != plateform.dasilva.codeClient
                    "
                  >
                    {{ u.Fournisseur }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Name }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>

                <td
                  v-if="u.TotalPriceTTC"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <!-- <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    v-if="u.IsProcessed === true"
                    ><span class="material-icons-outlined"> done </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                    v-else
                    ><span class="material-icons-outlined">close</span></span
                  > -->
                  <div class="flex">
                    <div class="flex flex-row relative">
                      <span
                        class="material-icons-outlined mr-4 cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="openBdl(u)"
                      >
                        visibility
                      </span>
                      <span
                        class="material-icons-outlined mr-4 cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="setEmails(u, index)"
                      >
                        email
                      </span>
                      <modalLivraison
                        v-if="showEmailLivraison[index]"
                        :showEmail="showEmailLivraison[index]"
                        :ind="index"
                        :objet="u"
                        :tabBdl="infosBdl[index].clientInfo[0]"
                        :tabTempInfosBdl="infosBdl"
                        :tabState="tabState"
                      />
                      <!-- <div
                        v-show="showEmailLivraison[index]"
                        class="flex justify-center -ml-20 absolute mt-4 bg-gray-200 p-2 rounded shadow-lg z-20 text-sm"
                      >
                        <div class="flex flex-col space-y-2">
                          <form
                            @change="
                              checkForm(plateform.Email, u.clientInfo[0].email)
                            "
                            class="space-y-1"
                          >
                            <p v-if="errors.length">
                              <span
                                v-for="(error, index) in errors"
                                :key="index"
                                class="text-red-600"
                                >{{ error }}</span
                              >
                            </p>
                            <p v-else></p>
                            <div class="flex m-auto space-x-1">
                              <label for="emailFrom" class="m-auto w-6 text-xs"
                                >De :</label
                              >
                              <input
                                @change="errors == []"
                                disabled
                                type="email"
                                id="emailFrom"
                                name="emailFrom"
                                placeholder="Expéditeur"
                                class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                v-model="plateform.Email"
                              />
                            </div>

                            <div class="flex m-auto space-x-1">
                              <label for="emailTo" class="m-auto w-6 text-xs"
                                >À :</label
                              >
                              <input
                                @change="errors == []"
                                type="email"
                                id="emailTo"
                                name="emailTo"
                                placeholder="Destinataire"
                                class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                v-model="u.clientInfo[0].email"
                              />
                            </div>
                          </form>
                          <div class="flex justify-center space-x-1">
                            <button
                              @click="showEmailLivraison[index] = false"
                              class="px-6 py-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                            >
                              Annuler
                            </button>
                            <input
                              type="submit"
                              value="Envoyer"
                              class="w-fit cursor-pointer px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
                              @click="
                                sendEmailLivraison(
                                  u,
                                  index,
                                  plateform.Email,
                                  u.clientInfo[0].email
                                )
                              "
                            />
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex space-x-4">
                    <div class="flex flex-col relative">
                      <span
                        class="material-icons-outlined cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="setEmails1(u, index)"
                      >
                        email
                      </span>
                      <modalTransport
                        v-if="showEmailTransport[index]"
                        :showEmail="showEmailTransport[index]"
                        :ind="index"
                        :objet="u"
                        :tabBdl="infosBdl[index].clientInfo[0]"
                        :tabTempInfosBdl="infosBdl"
                        :tabState="tabState"
                      />
                      <!-- <div
                        v-show="showEmailTransport[index]"
                        class="flex justify-center -ml-20 absolute mt-4 bg-gray-200 p-2 rounded shadow-lg z-20 text-sm"
                      >
                        <div class="flex flex-col space-y-2">
                          <form
                            @change="
                              checkForm(plateform.Email, u.clientInfo[0].email)
                            "
                            class="space-y-1"
                          >
                            <p v-if="errors.length">
                              <span
                                v-for="(error, index) in errors"
                                :key="index"
                                class="text-red-600"
                                >{{ error }}</span
                              >
                            </p>
                            <p v-else></p>
                            <div class="flex m-auto space-x-1">
                              <label for="emailFrom" class="m-auto w-6 text-xs"
                                >De :</label
                              >
                              <input
                                @change="errors == []"
                                disabled
                                type="email"
                                id="emailFrom"
                                name="emailFrom"
                                placeholder="Expéditeur"
                                class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                v-model="plateform.Email"
                              />
                            </div>

                            <div class="flex m-auto space-x-1">
                              <label for="emailTo" class="m-auto w-6 text-xs"
                                >À :</label
                              >
                              <input
                                @change="errors == []"
                                type="email"
                                id="emailTo"
                                name="emailTo"
                                placeholder="Destinataire"
                                class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                v-model="u.clientInfo[0].email"
                              />
                            </div>
                          </form>
                          <div class="flex justify-center space-x-1">
                            <button
                              @click="showEmailTransport[index] = false"
                              class="px-6 py-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                            >
                              Annuler
                            </button>
                            <input
                              type="submit"
                              value="Envoyer"
                              class="w-fit cursor-pointer px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
                              @click="
                                sendEmailTransport(
                                  u,
                                  index,
                                  plateform.Email,
                                  u.clientInfo[0].email
                                )
                              "
                            />
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF1(u)"
                    >
                      download
                    </span>
                  </div>
                </td>
                <td
                  v-if="tabState == 'actif'"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div
                    v-if="u.groupedTab.length > 0"
                    class="text-xs w-24 h-6 p-1"
                  ></div>
                  <div
                    v-else
                    class="flex text-xs w-24 h-6 p-1 cursor-pointer rounded-md bg-orange-400 hover:bg-orange-300 text-white mt-1"
                    @click="showModalModifBL(u)"
                  >
                    <span class="material-icons-round text-xs">
                      keyboard_double_arrow_right
                    </span>
                    <span class="ml-2"> Modifier </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="tabState == 'actif' && !searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosBdlLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosBdlLength / 10)"
            :clickFunction="getInfosBdl"
            :currentPage="currentPage"
            :typeList="'bons de livraison'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="tabState == 'archived' && !searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosBdlArchivedLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosBdlArchivedLength / 10)"
            :clickFunction="getInfosBdlArchived"
            :currentPage="currentPage"
            :typeList="'bons de livraison'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="tabState == 'actif' && searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="
              (arg1, arg2) => search(arg1, arg2, false, searchTerm)
            "
            :currentPage="currentPage"
            :typeList="'bons de livraison'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="tabState == 'archived' && searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="
              (arg1, arg2) => search(arg1, arg2, true, searchTerm)
            "
            :currentPage="currentPage"
            :typeList="'bons de livraison'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createlivraison } from "@/hooks/pdf/livraison";
import { createtransport } from "@/hooks/pdf/transport";
import { useToast } from "vue-toastification";
import modalLivraison from "@/components/Email/modalLivraison";
import modalTransport from "@/components/Email/modalTransport";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { groupedDocByClient } from "@/hooks/regroupeDoc/regroupeDoc.ts";
import { createGroupedDoc } from "@/hooks/pdf/groupedDoc.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyBdl",
  data: () => ({
    showModifBL: false,
    showSaisieModifBL: false,
    saisieModifBL_Ref: "",
    saisieModifBL_Marque: "",
    saisieModifBL_Desc: "",
    saisieModifBL_quantity: 0,
    saisieModifBL_Prix: 0,
    saisieModifBL_remise: 0,
    saisieModifBL_remisegenerale: 0,
    tabModifBL: [],
    infosBdl: [],
    infosBdlTmp: [],
    groupeState: false,
    Clients: [],
    clientChosed: [],
    dateFrom: "",
    dateTo: "",
    alerteRegroup: false,
    validerRegroupement: false,
    numDoc: 0,
    showDetailArticles: [],
    index: 0,
    showReprise: [],
    Articles: [],
    showSortDate: true,
    showReverseDate: false,
    showSortNumero: true,
    showReverseNumero: false,
    showSortAlpha: true,
    showReverseAlpha: false,
    showSortUsername: true,
    showReverseUsername: false,
    showSortTotalPrice: true,
    showReverseTotalPrice: false,

    tabState: "",
    transport: "",

    showEmailLivraison: [],
    showEmailTransport: [],
    errors: [],

    infoInBdl: false,
    detailArticle: [],
    detailPdf: [],

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    infosBdlLength: 0,
    infosBdlArchivedLength: 0,
    currentPage: 1,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    selectAll: false,
    selectedDoc: [],
    loadingSelection: false,
    loadingRegrouper: false,

    selectedCount: 0,
  }),
  components: {
    modalLivraison,
    modalTransport,
    pageNumber,
    searchInput,
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    ...mapActions([
      "updateactionArticle",
      "addclientfocus",
      "addModifyDoc",
      "createcomptoirsession",
      "setbdlselected",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosBdl(skipValue, limitValue) {
      this.currentPage = skipValue;
      this.tabState = "actif";
      this.searchActive = false;
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyowner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data);
          this.infosBdl = response.data;
          // this.Articles = response.data[index].Articles;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosBdlArchived(skipValue, limitValue) {
      this.currentPage = skipValue;
      console.log(this.currentPage);
      this.tabState = "archived";
      this.searchActive = false;
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyownerarchived/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.infosBdl = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosBdlLength() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyowner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosBdlLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosBdlArchivedLength() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyownerarchived/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosBdlArchivedLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClients() {
      axios
        .get(`${process.env.VUE_APP_API}/client/${this.user.proprietaire}`)
        .then((response) => {
          this.Clients = response.data;
        });
    },
    async showModalModifBL(BL) {
      // this.showModifBL = true;
      // this.tabModifBL = BL;
      this.addModifyDoc(BL);
      this.$router.push("/setActionClient");
    },
    async updateBL() {
      var totalRemise = 0.0;
      var totalRemiseGen = 0.0;
      var newtotalRemiseGen = 0.0;
      await this.tabModifBL.Articles.forEach((element) => {
        totalRemise =
          (parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) * (element.remise / 100)) *
          element.quantity;
        totalRemiseGen =
          parseFloat(totalRemise) -
          parseFloat(totalRemise) *
            (this.tabModifBL.pdfData[0].remisegenerale / 100);
        newtotalRemiseGen =
          parseFloat(totalRemise) -
          parseFloat(totalRemise) * (this.saisieModifBL_remisegenerale / 100);
      });
      if (this.tabModifBL.pdfData[0].remisegenerale) {
        this.tabModifBL.TotalPrice = totalRemiseGen;
      } else {
        this.tabModifBL.pdfData.forEach((element) => {
          element.remisegenerale = parseFloat(
            this.saisieModifBL_remisegenerale
          );
          element.totalSansRemise = totalRemise;
        });
        this.tabModifBL.TotalPrice = newtotalRemiseGen;
      }
      //   totalRemise =
      //     (parseFloat(element.Prix_euro) -
      //       parseFloat(element.Prix_euro) * (element.remise / 100)) *
      //     element.quantity;

      // });
      // this.tabModifBL.TotalPrice = totalRemise;
      axios
        .put(
          `${process.env.VUE_APP_API}/bdl/${this.tabModifBL._id}`,
          this.tabModifBL
        )
        .then(() => {
          this.showModifBL = false;
          // this.getInfosBdl();
        });
    },
    removeBDL() {
      axios
        .put(
          `${process.env.VUE_APP_API}/bdl/removeBdl/${this.tabModifBL._id}`,
          this.tabModifBL
        )
        .then(() => {
          this.showModifBL = false;
          this.getInfosBdl(this.currentPage, 10);
        });
    },
    archiveBDL() {
      axios
        .put(`${process.env.VUE_APP_API}/bdl/archiveBDL/${this.tabModifBL._id}`)
        .then(() => {
          this.showModifBL = false;
          this.getInfosBdl(this.currentPage, 10);
        });
    },
    addManualLine() {
      this.tabModifBL.Articles.push({
        Ref_fournisseur: this.saisieModifBL_Ref,
        Code_marque: this.saisieModifBL_Marque,
        Description: this.saisieModifBL_Desc,
        Prix_euro: this.saisieModifBL_Prix,
        remise: this.saisieModifBL_remise,
        quantity: this.saisieModifBL_quantity,
      });
      this.tabModifBL.pdfData.push({
        description: this.saisieModifBL_Desc,
        ref: this.saisieModifBL_Ref,
        quantite: this.saisieModifBL_quantity,
        remise: this.saisieModifBL_remise,
        prixU:
          this.saisieModifBL_Prix -
          this.saisieModifBL_Prix * (this.saisieModifBL_remise / 100),
        total:
          (this.saisieModifBL_Prix -
            this.saisieModifBL_Prix * (this.saisieModifBL_remise / 100)) *
          this.saisieModifBL_quantity,
      });
      this.showSaisieModifBL = false;
    },
    deleteLine(index) {
      this.tabModifBL.Articles.splice(index, 1);
      this.tabModifBL.pdfData.splice(index, 1);
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    async setInComptoir(bdlData) {
      await axios
        .post(`${process.env.VUE_APP_API}/client/getone/${bdlData.Client}`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.createcomptoirsession({
            clientFocus: response.data,
            refClient: bdlData.Note,
            Articles: bdlData.Articles,
            reprise: true,
          });
        });

      // this.savecomptoirsession({
      //     clientFocus: devisData.Client,
      //     refClient: devisData.Note,
      //   });
    },
    getDatePaiement() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 2).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getDetailArticle(data) {
      this.detailArticle = data;
      if (data.groupedTab && data.groupedTab.length > 0) {
        this.detailPdf = data.groupedTab;
      } else {
        this.detailPdf = data.pdfData;
      }
      this.infoInBdl = true;
    },
    formatDate(date) {
      var fDate = date.split(" ")[0];
      const [day, month, year] = fDate.split("/");
      const newDate = new Date(+year, month - 1, +day);
      return newDate;
    },
    regrouperBL() {
      if (this.clientChosed != "" && this.dateFrom != "" && this.dateTo != "") {
        this.alerteRegroup = false;
        this.validerRegroupement = true;
        this.infosBdl = this.infosBdl.filter(
          (u) =>
            u.Client === this.clientChosed &&
            Date.parse(this.formatDate(u.Date)) >= Date.parse(this.dateFrom) &&
            Date.parse(this.formatDate(u.Date)) <= Date.parse(this.dateTo)
        );
      } else {
        this.alerteRegroup = true;
        this.validerRegroupement = false;
      }
    },
    setTotalBdl() {
      var total = 0;
      this.infosBdl.forEach((u) => {
        total = total + u.TotalPrice;
      });
      return total;
    },
    setTotalSelectedBdl() {
      var total = 0;
      this.selectedDoc.forEach((u) => {
        total = total + u.TotalPrice;
      });
      return total;
    },
    setTotalRemise() {
      var total = 0.0;
      var prixFinal = 0.0;
      var transport = 0.0;
      this.infosBdl.forEach((e) => {
        transport = transport + e.Transport;
        this.transport = transport;
        e.pdfData.forEach((pdf) => {
          prixFinal =
            (pdf.prixU - pdf.prixU * (pdf.remise / 100)) * pdf.quantite;
          total = total + prixFinal;
          console.log("valeur unitaire : " + pdf.prixU);
        });
      });
      console.log("valeur total : " + total);
      return total;
    },
    setTotalSelectedRemise() {
      var total = 0.0;
      var prixFinal = 0.0;
      var transport = 0.0;
      this.selectedDoc.forEach((e) => {
        transport = transport + e.Transport;
        this.transport = transport;
        e.pdfData.forEach((pdf) => {
          prixFinal =
            (pdf.prixU - pdf.prixU * (pdf.remise / 100)) * pdf.quantite;
          total = total + prixFinal;
          console.log("valeur unitaire : " + pdf.prixU);
        });
      });
      console.log("valeur total : " + total);
      return total;
    },
    async valideRegrouperBL() {
      this.validerRegroupement = false;
      this.alerteRegroup = false;
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      await axios
        .post(`${process.env.VUE_APP_API}/facture/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.numDoc = response.data + 1;
        });
      axios
        .post(`${process.env.VUE_APP_API}/groupebdl`, {
          Name: this.user.username,
          Date: today,
          Numero: this.numDoc,
          TotalPrice: this.setTotalBdl(),
          TotalPriceTTC: this.setTotalBdl() + this.setTotalBdl() * 0.2,
          Client: this.clientChosed,
          clientInfo: this.infosBdl[0].clientInfo,
          Owner: this.user.proprietaire,
          IsProcessed: false,
          Bdls: this.infosBdl,
          totalSansRemise: this.setTotalRemise(),
          Transport: this.transport,
        })
        .then(() => {
          axios.post(`${process.env.VUE_APP_API}/facture`, {
            Name: this.user.username,
            Date: today,
            Numero: this.numDoc,
            TotalPrice: this.setTotalBdl(),
            TotalPriceTTC: this.setTotalBdl() + this.setTotalBdl() * 0.2,
            Client: this.clientChosed,
            clientInfo: this.infosBdl[0].clientInfo,
            Owner: this.user.proprietaire,
            IsProcessed: false,
            Bdls: this.infosBdl,
            totalSansRemise: this.setTotalRemise(),
            Transport: this.transport,
          });
          this.archiveFiltredSelection(this.infosBdl);
          this.toast.success("Facture créée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    async regrouperSelection() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      await axios
        .post(`${process.env.VUE_APP_API}/facture/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.numDoc = response.data + 1;
        });
      axios
        .post(`${process.env.VUE_APP_API}/groupebdl`, {
          Name: this.user.username,
          Date: today,
          Numero: this.numDoc,
          TotalPrice: this.setTotalSelectedBdl(),
          TotalPriceTTC:
            this.setTotalSelectedBdl() + this.setTotalSelectedBdl() * 0.2,
          Client: this.selectedDoc[0].Client,
          clientInfo: this.selectedDoc[0].clientInfo,
          Owner: this.user.proprietaire,
          IsProcessed: false,
          Bdls: this.selectedDoc,
          totalSansRemise: this.setTotalSelectedRemise(),
          Transport: this.transport,
        })
        .then(() => {
          axios.post(`${process.env.VUE_APP_API}/facture`, {
            Name: this.user.username,
            Date: today,
            Numero: this.numDoc,
            TotalPrice: this.setTotalSelectedBdl(),
            TotalPriceTTC:
              this.setTotalSelectedBdl() + this.setTotalSelectedBdl() * 0.2,
            Client: this.selectedDoc[0].Client,
            clientInfo: this.selectedDoc[0].clientInfo,
            Owner: this.user.proprietaire,
            IsProcessed: false,
            Bdls: this.selectedDoc,
            totalSansRemise: this.setTotalSelectedRemise(),
            Transport: this.transport,
          });
          this.archiveSelection();
          this.toast.success("Facture créée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    archiveSelection() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/archiveManyBDL`, {
          data: this.selectedDoc,
        })
        .then(() => {
          this.getInfosBdl(this.currentPage, 10);
          this.selectedDoc = [];
        });
    },
    archiveFiltredSelection(tabBDL) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/archiveManyBDL`, {
          data: tabBDL,
        })
        .then(() => {
          this.getInfosBdl(this.currentPage, 10);
          this.selectedDoc = [];
        });
    },
    setEmails(objet, index) {
      this.getInfosBdl(this.currentPage, 10);
      if (
        objet.clientInfo[0] &&
        objet.clientInfo[0].Emails &&
        objet.clientInfo[0].Emails.length > 0
      ) {
        objet.clientInfo[0].Emails.forEach((element, index) => {
          if (element.bondelivraison == false) {
            objet.clientInfo[0].Emails.splice(index, 1);
          } else {
            this.checkForm(this.plateform.Email, element.address);
          }
        });
        this.showEmailLivraison[index] = !this.showEmailLivraison[index];
      } else {
        this.checkForm(this.plateform.Email, objet.clientInfo[0].email);
        this.showEmailLivraison[index] = !this.showEmailLivraison[index];
      }
    },
    setEmails1(objet, index) {
      this.getInfosBdl(this.currentPage, 10);
      if (
        objet.clientInfo[0] &&
        objet.clientInfo[0].Emails &&
        objet.clientInfo[0].Emails.length > 0
      ) {
        objet.clientInfo[0].Emails.forEach((element, index) => {
          if (element.bondetransport == false) {
            objet.clientInfo[0].Emails.splice(index, 1);
          } else {
            this.checkForm(this.plateform.Email, element.address);
          }
        });
        this.showEmailTransport[index] = !this.showEmailTransport[index];
      } else {
        this.checkForm(this.plateform.Email, objet.clientInfo[0].email);
        this.showEmailTransport[index] = !this.showEmailTransport[index];
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailFrom, emailTo) {
      console.log(emailTo);
      this.errors = [];
      if (!emailFrom || !emailTo) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailFrom) || !this.validEmail(emailTo)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    createPDF(objet) {
      if (objet.groupedTab && objet.groupedTab.length > 0) {
        this.openGroupedPDF(objet);
      } else {
        var today = new Date();
        var da = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var dateedition = getFrDateSansH(objet.Date);
        var datepdf = da + "/" + mm + "/" + yyyy;
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createlivraison(objet, false, false);
          } else {
            createlivraison(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createlivraison(objet, true, false);
        } else {
          createlivraison(objet, true, true);
        }
      }
    },
    openGroupedPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, "Bon de livraison", "BL", false);
      } else {
        createGroupedDoc(objet, "Bon de livraison", "BL", true);
      }
    },
    createPDF1(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createtransport(objet, false);
      } else {
        createtransport(objet, true);
      }
    },
    sortByDate() {
      this.infosBdl.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    reverseByDate() {
      this.sortDate = this.infosBdl.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
      this.sortDate.reverse((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    sortByNumero() {
      this.infosBdl.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
    },
    reverseByNumero() {
      this.sortNumero = this.infosBdl.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
      this.sortNumero.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByAlpha() {
      this.infosBdl.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    reverseByAlpha() {
      this.sortAlpha = this.infosBdl.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
      this.sortAlpha.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByUsername() {
      this.infosBdl.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    reverseByUsername() {
      this.sortUsername = this.infosBdl.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
      this.sortUsername.reverse((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    sortByTotalPrice() {
      this.infosBdl.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    reverseByTotalPrice() {
      this.sortTotalPrice = this.infosBdl.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
      this.sortTotalPrice.reverse((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    filterByValue(value) {
      var filterTab = [];
      if (value != "") {
        this.infosBdl = this.infosBdlTmp;
        this.infosBdl.filter((obj) => {
          //loop through each object
          for (const key in obj) {
            if (
              obj[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filterTab.push(obj);
              break;
            }
          }
        });
        this.infosBdl = filterTab;
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, archive, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        if (archive == false) {
          this.getInfosBdl(skipValue, limitValue);
          this.getInfosBdlLength();
        } else {
          this.getInfosBdlArchived(skipValue, limitValue);
          this.getInfosBdlArchivedLength();
        }
        this.pageSwitch();
      } else {
        if (archive == false) {
          this.tabState = "actif";
        } else {
          this.tabState = "archived";
        }
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/bdl/searchInPlateform`, {
            plateform: this.user.proprietaire,
            archive: archive,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosBdl = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    openBdl(bdl) {
      this.setbdlselected(bdl);
      this.$router.push("/detailBdl");
    },

    // Selection
    async selectAllDoc() {
      this.selectedDoc = [];
      this.loadingSelection = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.selectAll) {
        this.selectedDoc = [...this.infosCommandes];
      } else {
        this.selectedDoc = [];
      }
      this.loadingSelection = false;
    },
    // Regroupement
    async regrouper() {
      if (this.selectedCount > 1) {
        if (this.selectedCount <= 4) {
          this.loadingRegrouper = true;
          for (const bl of this.selectedDoc) {
            this.selectOne(bl._id, false, bl);
          }
          const tabGrouped = await groupedDocByClient(
            this.user.proprietaire,
            this.user.username,
            "bdl",
            this.selectedDoc
          );
          this.loadingRegrouper = false;
          if (tabGrouped.length == 0) {
            this.error("Impossible de regrouper !");
          } else {
            this.selectedDoc = [];
            this.success("Créé !");
            this.currentPage = 1;
            this.getInfosBdl(this.currentPage, 10);
            this.getInfosBdlLength();
          }
        } else {
          this.warning("Sélectionnez au maximum 4 documents !");
        }
      } else {
        this.warning("Sélectionnez au moins deux documents !");
      }
    },
    async countSelected() {
      let countS = await axios.post(
        `${process.env.VUE_APP_API}/bdl/countSelected`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.selectedCount = countS.data;
    },
    async selectAllBDL() {
      await axios.post(`${process.env.VUE_APP_API}/bdl/selectAll`, {
        plateform: this.user.proprietaire,
        archived: false,
      });
      this.countSelected();
      this.getInfosBdl(this.currentPage, 10);
    },
    async unselectAllBDL() {
      await axios.post(`${process.env.VUE_APP_API}/bdl/unselectAll`, {
        plateform: this.user.proprietaire,
        archived: false,
      });
      this.countSelected();
      this.getInfosBdl(this.currentPage, 10);
    },
    async selectOne(_id, value, bdl) {
      await axios.post(`${process.env.VUE_APP_API}/bdl/selectOne`, {
        _id: _id,
        value: value,
      });
      if (value) {
        this.selectedDoc.push(bdl);
      } else {
        this.selectedDoc = this.selectedDoc.filter((doc) => doc._id != _id);
      }
      this.countSelected();
    },
    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(msg) {
      this.toast.warning(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getInfosBdl(this.currentPage, 10);
    this.getInfosBdlLength();
    this.getInfosBdlArchivedLength();
    this.pageSwitch();
    this.getClients();
    this.countSelected();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
