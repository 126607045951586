<template>
  <div class="flex flex-row justify-between p-2">
    <div class="w-1/2">
      <div>GenArt :</div>
      <div class="flex flex-col space-y-2">
        <div
          v-for="(GA, indexGA) in sousFamilleTmp.genericArticleId"
          :key="indexGA"
          class="flex flex-row items-center space-x-2 p-1 border border-gray-300 rounded-md w-fit"
        >
          <div>
            {{ GA }} - {{ sousFamilleTmp.genericArticleId_name[indexGA] }}
          </div>
          <button
            @click="setGenArt(sousFamilleTmp._id, GA)"
            class="material-icons-round text-sm text-red-500"
          >
            clear
          </button>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <button
            class="p-1 bg-blue-500 text-white rounded-md shadow"
            @click="getGenArt()"
          >
            Ajouter un GenArt
          </button>
          <div v-show="showGAListe">
            <input
              list="genartList"
              type="text"
              v-model="selectedGenArt"
              @change="setGenArt(sousFamilleTmp._id, selectedGenArt)"
              class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            />
            <datalist id="genartList">
              <option
                v-for="(c, index) in genArtTab"
                :key="index"
                :value="c.genericArticleId"
              >
                {{ c.designation }}
              </option>
            </datalist>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/2">
      <div>Marques cat :</div>
      <div class="flex flex-col space-y-2">
        <div
          v-for="(Brand, indexBrand) in sousFamilleTmp.brandPrimary"
          :key="indexBrand"
          class="flex flex-row items-center space-x-2 p-1 border border-gray-300 rounded-md w-fit"
        >
          <div>{{ Brand }}</div>
          <button
            @click="deleteBrand(sousFamilleTmp._id, Brand)"
            class="material-icons-round text-sm text-red-500"
          >
            clear
          </button>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <button
            class="p-1 bg-blue-500 text-white rounded-md shadow"
            @click="getBrand('primary')"
          >
            Ajouter une marque
          </button>
          <div v-show="showBrandPrimary">
            <input
              list="primaryList"
              type="text"
              v-model="selectedBrandPrimary"
              @change="changeBrand(selectedBrandPrimary, sousFamilleTmp._id)"
              class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            />
            <datalist id="primaryList">
              <option
                v-for="(m, index) in Marque"
                :key="index"
                :value="m.mfrName"
              ></option>
            </datalist>
          </div>
        </div>
      </div>
      <div>Marques cross :</div>
      <div class="flex flex-col space-y-2">
        <div
          v-for="(BrandS, indexBrandS) in sousFamilleTmp.brandSecondary"
          :key="indexBrandS"
          class="flex flex-row items-center space-x-2 p-1 border border-gray-300 rounded-md w-fit"
        >
          <div>{{ BrandS }}</div>
          <button
            @click="deleteBrandScd(sousFamilleTmp._id, BrandS)"
            class="material-icons-round text-sm text-red-500"
          >
            clear
          </button>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <button
            class="p-1 bg-blue-500 text-white rounded-md shadow"
            @click="getBrand('secondary')"
          >
            Ajouter une marque
          </button>
          <div v-show="showBrandSecondary">
            <input
              list="secondaryList"
              type="text"
              v-model="selectedBrandSecondary"
              @change="
                changeBrandScd(selectedBrandSecondary, sousFamilleTmp._id)
              "
              class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
            />
            <datalist id="secondaryList">
              <option
                v-for="(m, index) in Marque"
                :key="index"
                :value="m.mfrName"
              ></option>
            </datalist>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "genArtConf",
  data() {
    return {
      sousFamilleTmp: this.sousFamille,
      genArtTab: [],
      Marque: [],
      showGAListe: false,
      showBrandPrimary: false,
      showBrandSecondary: false,
      selectedGenArt: 0,
      selectedBrandPrimary: "",
      selectedBrandSecondary: "",
    };
  },
  props: {
    sousFamille: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async getGenArt() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getGenArt`
      );
      this.genArtTab = response.data;
      this.showGAListe = true;
    },
    async addDirectGA(sousFamilleId, genericArticleId) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addGenArt`,
        {
          id: sousFamilleId,
          genArt: parseInt(genericArticleId),
        }
      );
      this.sousFamilleTmp = res.data;
      this.$emit("updateSousFamille");
    },
    async setGenArt(sousFamilleId, genericArticleId) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setGenArt`,
        {
          id: sousFamilleId,
          genericArticleId: parseInt(genericArticleId),
        }
      );

      this.sousFamilleTmp = res.data;
      this.showGAListe = false;
      this.$emit("updateSousFamille");
    },
    async deleteBrand(sousFamilleId, brand) {
      const delBd = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteBrandSousFamille`,
        {
          id: sousFamilleId,
          brand: brand,
        }
      );
      this.sousFamilleTmp = delBd.data;
      this.showBrandPrimary = false;
      this.$emit("updateSousFamille");
    },
    async deleteBrandScd(sousFamilleId, brand) {
      const delBd = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteBrandScdSousFamille`,
        {
          id: sousFamilleId,
          brand: brand,
        }
      );
      this.sousFamilleTmp = delBd.data;
      this.showBrandSecondary = false;
      this.$emit("updateSousFamille");
    },
    async getBrand(state) {
      if (this.Marque.length == 0) {
        await axios
          .get(`${process.env.VUE_APP_API}/articlecross/getambrand`)
          .then((res) => {
            this.Marque = res.data;
          });
      }
      state == "primary"
        ? (this.showBrandPrimary = true)
        : (this.showBrandSecondary = true);
    },
    async changeBrand(brand, idSF) {
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: idSF,
          brandName: brand,
          type: "primary",
        }
      );
      this.showBrandPrimary = false;
      this.sousFamilleTmp = updatedSF.data;

      this.$emit("updateSousFamille");
    },
    async changeBrandScd(brand, idSF) {
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: idSF,
          brandName: brand,
          type: "secondary",
        }
      );
      this.sousFamilleTmp = updatedSF.data;
      this.showBrandSecondary = false;
      this.$emit("updateSousFamille");
    },
  },
};
</script>
<style></style>
