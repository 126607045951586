<template>
  <div
    class="bg-white rounded-sm shadow p-2 w-11/12"
    v-if="familleTab.length > 0"
  >
    <div class="text-lg text-bluevk font-bold" v-if="familleTab.length > 0">
      Familles
    </div>
    <div class="flex flex-col space-y-1 ml-2 mt-2">
      <div v-for="(fam, indexFam) in familleTab" :key="indexFam">
        <div
          class="px-1 hover:text-bluevk cursor-pointer"
          @click="$emit('selectFamille', fam)"
        >
          <div
            v-if="selectedFamille.includes(fam)"
            class="flex flex-row space-x-2 items-center text-blue-500 bg-green-100 rounded-sm p-1"
          >
            <span>{{ fam }}</span>
          </div>
          <div v-else class="flex flex-row space-x-2 items-center">
            <span>{{ fam }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "familleList",
  props: {
    familleTab: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selectedFamille: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
};
</script>
<style></style>
