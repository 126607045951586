<template>
  <div class="flex flex-row items-center h-full">
    <div class="flex flex-col items-center">
      <div class="flex items-center">
        <div v-if="stock" class="flex relative space-x-5 items-center">
          <div class="relative space-y-2 flex flex-col">
            <span
              class="mx-auto material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
              :title="user.proprietaire + ' ' + qtyStock + '\n'"
              >check_circle
            </span>

            <span
              class="mx-auto text-sm font-bold"
              v-if="adresse_art != 'non renseigné'"
              >{{ adresse_art }}</span
            >
          </div>
          <div
            class="rounded-md drop-shadow-lg items-center"
            style="font-size: 15px"
          >
            <div class="flex space-x-1 flex items-center">
              <span class="text-xs">{{ user.proprietaire }}:</span>
              <span class="text-base font-bold">{{ qtyStock }}</span>
            </div>
            <div
              v-if="reservedStock != ''"
              class="bg-green-600 text-white text-xs px-0.5 space-x-1 items-center w-fit ml-auto"
            >
              <span class="">Réservé:</span>
              <span class="font-bold">{{ reservedStock }}</span>
            </div>
            <div v-if="dispoFiliale.length > 0">
              <div v-for="(df, indexDF) in dispoFiliale" :key="indexDF">
                <div
                  v-if="df.stock.length > 0 && df.stock[0].plateform.length > 0"
                  class="flex items-center space-x-1 h-fit"
                >
                  <span class="text-xs">{{ df.stock[0].plateform }}:</span>
                  <span class="text-base font-bold">{{
                    df.stock[0].stock
                  }}</span>
                </div>
                <!-- <div
                  v-if="df.stock.length > 0 && df.stock[0].plateform.length > 0"
                  class="flex items-center space-x-1 h-fit"
                >
                  <span class="text-xs">Réservé:</span>
                  <span class="text-base font-bold">{{
                    df.stock[0].reservedClient
                  }}</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div
          v-else-if="!stock && !stockFournisseur && !loadingStock"
          class="flex relative space-x-10 my-auto"
        >
          <span
            title="Sur commande"
            class="material-icons-outlined p-0.5 bg-orange-500 rounded-full text-white"
            >help_outline
          </span>

          <div
            class="absolute rounded-md drop-shadow-lg items-center"
            style="font-size: 15px"
          >
            <div class="flex space-x-1 flex items-center">
              <span class="text-xs">{{ user.proprietaire }}:</span>
              <span class="text-base font-bold" v-if="stockValue != ''">{{
                stockValue
              }}</span>
              <span class="text-base font-bold" v-else>0</span>
            </div>
            <div
              v-if="reservedValue != ''"
              class="bg-green-600 text-white text-xs px-0.5 space-x-1 items-center w-fit ml-auto"
            >
              <span class="">Réservé:</span>
              <span class="font-bold">{{ reservedValue }}</span>
            </div>
          </div>

          <div
            class="absolute px-1 rounded-md drop-shadow-lg bg-gray-100 inline-flex items-center flex flex-col align-center"
            style="font-size: 15px"
            v-if="dispoFiliale.length > 0"
          >
            <div v-for="(df, indexDF) in dispoFiliale" :key="indexDF">
              <div
                v-if="df.stock.length > 0"
                class="flex flex-row items-center space-x-1"
              >
                <span class="text-xs">{{ df.stock[0].plateform }}</span>
                <span class="text-base font-bold">{{ df.stock[0].stock }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else-if="!stock && stockFournisseur"
          class="flex relative space-x-10"
        >
          <span
            v-if="
              (qtyJour > 0 && qtySoir > 0) ||
              (qtyJour > 0 && qtySoir == 0) ||
              (qtyJour == 0 && qtySoir > 0)
            "
            title="Disponible filiale"
            class="material-icons-outlined p-0.5 rounded-full bg-blue-500 text-white"
            >check_circle
          </span>
          <span
            v-else
            title="Sur commande"
            class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
            >help_outline
          </span>
          <div
            class="absolute -mt-2 px-1 rounded-md drop-shadow-lg bg-gray-100 inline-flex items-center flex flex-col"
            style="font-size: 15px"
          >
            <div class="flex">
              <span
                class="material-icons-outlined m-auto text-yellow-500"
                style="font-size: 15px"
                >light_mode</span
              >
              <span>{{ qtySoir }}</span>
            </div>
            <div class="flex">
              <span
                class="material-icons-outlined m-auto text-blue-700"
                style="font-size: 15px"
                >nightlight</span
              >
              <span>{{ qtyJour }}</span>
            </div>
          </div>
        </div>

        <span v-else>chargement...</span>
      </div>

      <!-- <div>
        <span v-if="stock">Disponible</span
        ><span v-else-if="!stock && stockFournisseur">Dispo filiale</span
        ><span v-else-if="!stock && !stockFournisseur && !loadingStock"
          >Sur commande</span
        >
      </div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { getStockFiliale } from "@/hooks/stock/stock";
export default {
  props: ["Code_EAN", "qty", "Prefixe_tarif", "article", "marque"],
  data() {
    return {
      stock: null,
      qtyStock: "",
      stockFournisseur: null,
      qtyJour: "",
      qtySoir: "",
      loadingStock: true,
      dispoFiliale: [],
      groupTab: [],
      adresse_art: "",
      stockValue: "",
      reservedValue: "",
      reservedStock: "",
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    // articlesFusionnes() {
    //   let articles = [...this.disponibles, ...this.filiales, ...this.surCommande];
    //   let articlesDisponibles = articles.filter(a => this.disponibles.includes(a));
    //   let articlesFiliales = articles.filter(a => this.filiales.includes(a) && !this.disponibles.includes(a));
    //   let articlesSurCommande = articles.filter(a => this.surCommande.includes(a));
    //   return [...articlesDisponibles, ...articlesFiliales, ...articlesSurCommande];
    // }
  },
  methods: {
    getGroupement() {
      axios
        .get(
          `${process.env.VUE_APP_API}/plateform/getGroupement/${this.user.proprietaire}`
        )
        .then((res) => {
          if (!res.data.error) {
            this.groupTab = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async emitDispo() {
      if (this.stock) {
        await this.$emit("qtyDispo", this.qtyStock);
      } else {
        await this.$emit("qtyDispo", "0");
      }
    },
    setDispo() {
      var dispo = "";
      if (this.stock) {
        dispo = "disponible";
        this.$emit("dispo", dispo);
      } else if (!this.stock && !this.stockFournisseur && !this.loadingStock) {
        dispo = "sur commande";
        this.$emit("dispo", dispo);
      }
    },
    async getStock() {
      this.loadingStock = true;
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/stockEANArticle`, {
          Code_EAN: this.Code_EAN,
          Code_marque: this.marque,
          plateform: this.user.proprietaire,
          Ref_fournisseur: this.article,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.adresse_art = res.data[0].adresse_article;
            this.stockValue = res.data[0].stock;
            this.reservedValue = res.data[0].reservedClient;
            if (parseInt(res.data[0].stock) >= this.qty) {
              this.stock = true;
              this.qtyStock = res.data[0].stock;
              this.reservedStock = res.data[0].reservedClient;
            } else {
              this.stock = false;
            }
          } else {
            this.stock = false;
          }
          this.getFilialeStock();
          this.getStockFournisseur();
          this.setDispo();
          this.emitDispo();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getFilialeStock() {
      this.dispoFiliale = await getStockFiliale(
        this.user.proprietaire,
        this.article,
        this.Code_EAN,
        this.marque
      );
    },
    async getStockFournisseur() {
      if (this.Prefixe_tarif == "Vhi" && !this.stock) {
        axios
          .post(`${process.env.VUE_APP_API}/catalogue/getDispoFournisseur`, {
            Ref_fournisseur: this.article,
            Code_marque: this.marque,
            logCode: this.plateform.grossierID,
            Quantity: this.qty,
            login: this.plateform.grossierID,
            plateform: this.user.proprietaire,
          })
          .then((response) => {
            this.stockFournisseur = response.data.dispoFournisseur;
            this.qtyJour = response.data.qtyJour;
            this.qtySoir = response.data.qtySoir;
            this.loadingStock = false;
          });
      } else {
        this.stockFournisseur = false;
        this.loadingStock = false;
      }
    },
  },
  beforeMount() {
    this.getStock();
  },
  mounted() {
    this.getGroupement();
  },
};
</script>
<style></style>
