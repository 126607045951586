<template>
  <div
    v-if="openModalDepot"
    :class="`modal ${
      !openModalDepot && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalDepot = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalDepot = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div v-show="loadingDepot" class="animate-pulse">
          Chargement en cours...
        </div>
        <table class="min-w-full" v-if="tabChangeDepot.length > 0">
          <thead class="rounded-t-full">
            <tr class="rounded-t-full">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Déscription
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Prix
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Qté
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Dispo
              </th>
            </tr>
          </thead>
          <tbody class="h-24 bg-gray-50 space-y-2">
            <tr
              class="hover:shadow"
              v-for="(item, indexItem) in tabChangeDepot"
              :key="indexItem"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex flex-row space-x-2 items-center">
                  <div>
                    <input
                      type="checkbox"
                      class="form-checkbox"
                      v-model="item.checked"
                    />
                  </div>
                  <div class="flex flex-col space-y-1">
                    <span class="material-icons-round text-base"> sync </span>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ item.Ref_fournisseur }}
                  </div>
                  <div v-if="item.Prefixe_tarif == 'Vhi'">
                    <div
                      class="p-1 bg-blue-500 text-white rounded shadow cursor-pointer"
                    >
                      {{ item.depot.name }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ item.Description }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="leading-5 text-gray-900 flex flex-col">
                  <div class="text-sm">{{ item.prix_brut }} €</div>
                  <div
                    class="text-sm flex flex-row items-center space-x-1"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    <span>PA: </span>
                    <span>{{ item.prix_net }}€</span>
                  </div>
                  <div
                    class="text-xs"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{ item.remise }} %
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div
                  class="flex flex-row items-center space-x-2 text-sm leading-5 text-gray-900"
                >
                  {{ item.quantity_asked }}
                </div>
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex flex-row items-center space-x-2">
                  <div class="space-y-1">
                    <!-- Nuit -->
                    <div
                      v-if="parseInt(item.quantity_available.night) > 0"
                      class="flex"
                    >
                      <div
                        :title="item.quantity_available.night_date"
                        class="flex border border-blue-700 p-0.5 rounded shadow items-center"
                      >
                        <span
                          class="material-icons-outlined text-blue-700"
                          style="font-size: 15px"
                          >nightlight</span
                        >

                        <span
                          class="text-xs text-black"
                          v-if="parseInt(item.quantity_available.night)"
                        >
                          {{ parseInt(item.quantity_available.night) }}
                        </span>
                      </div>

                      <!-- <div v-if="item.quantity_available.night_date" class="ml-2"> le {{  }}</div> -->
                    </div>

                    <!-- Jour -->
                    <div
                      v-if="parseInt(item.quantity_available.day) > 0"
                      class="flex"
                    >
                      <div
                        :title="item.quantity_available.day_date"
                        class="flex border border-yellow-500 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-yellow-500"
                          style="font-size: 15px"
                          >light_mode</span
                        >

                        <span
                          class="text-xs text-black"
                          v-if="parseInt(item.quantity_available.day)"
                        >
                          {{ parseInt(item.quantity_available.day) }}
                        </span>
                      </div>

                      <!-- <div v-if="item.quantity_available.day_date" class="ml-2">le {{ item.quantity_available.day_date }}</div> -->
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-end mt-4">
          <div class="p-2 bg-white flex flex-row space-x-2 shadow">
            <input
              type="text"
              placeholder="Commentaire"
              v-model="comCommandeVH"
            />
            <input
              type="Number"
              placeholder="Ref commande"
              v-model="refCommandeVH"
            />
          </div>
          <button
            class="p-2 bg-blue-500 text-white rounded shadow"
            @click="valideChangeDepot()"
          >
            Commander
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col space-y-2 h-85 w-full">
    <div class="flex justify-between items-center">
      <div>
        <button
          @click="$router.back()"
          class="flex flex-row space-x-2 items-center bg-blue-400 text-white px-2 py-1 rounded-md"
        >
          <span class="material-icons-outlined text-sm"> arrow_back_ios </span>
          <span>retour</span>
        </button>
      </div>
      <div class="flex flex-row space-x-2"></div>
    </div>
    <div v-show="loadingPreparation" class="animate-pulse">
      Chargement en cours...
    </div>
    <div v-if="commande.preparation_inst">
      <div v-if="commande.preparation_inst[0]['VAN HECK'].length > 0">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center space-x-4">
            <span>VAN HECK</span
            ><span
              class="text-xs text-blue-500 cursor-pointer"
              @click="inverseCheckBox('VAN HECK')"
              >Tout de/selectionner</span
            >
            <span
              class="text-xs text-blue-500 cursor-pointer"
              @click="
                changeDepot(
                  commande.preparation_inst[0]['VAN HECK'],
                  dep,
                  indexDep
                )
              "
              v-for="(dep, indexDep) in commande.preparation_inst[0][
                'VAN HECK'
              ][0].depot_dispo"
              :key="indexDep"
              >Deplacer vers {{ dep.name }}</span
            >
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow animate-pulse"
              v-show="loadingCmdVH"
            >
              Commande en cours...
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="
                exportExcel(
                  commande.preparation_inst[0]['VAN HECK'],
                  'VAN HECK'
                )
              "
              v-show="!loadingCmdVH"
            >
              Exporter en excel
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="createPDF(commande.preparation_inst[0]['VAN HECK'])"
              v-show="!loadingCmdVH"
            >
              Exporter en pdf
            </button>
            <div>
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                @click="showRefVH = !showRefVH"
                v-show="!loadingCmdVH && !stateCmdVH"
              >
                Commander
              </button>
              <div
                class="absolute p-2 right-8 bg-white flex flex-row space-x-2 shadow"
                v-show="showRefVH"
              >
                <input
                  type="text"
                  placeholder="Commentaire"
                  v-model="comCommandeVH"
                />
                <input
                  type="Number"
                  placeholder="Ref commande"
                  v-model="refCommandeVH"
                />
                <button
                  class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                  @click="
                    sendCommandVH(commande.preparation_inst[0]['VAN HECK'])
                  "
                >
                  Commander
                </button>
              </div>
            </div>
          </div>
        </div>
        <tabCommandeF
          :article="commande.preparation_inst[0]['VAN HECK']"
          :Fournisseur="'VAN HECK'"
          typeFournisseur="configured"
          @deleteOne="deleteArticle"
          @changeQuantity="modifyQuantity"
          @refreshDispo="refreshDispo"
          @setPA="setPA"
        />
      </div>
      <div v-if="commande.preparation_inst[0]['DA SILVA'].length > 0">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center space-x-4">
            <span>DA SILVA</span
            ><span
              class="text-xs text-blue-500 cursor-pointer"
              @click="inverseCheckBox('DA SILVA')"
              >Tout de/selectionner</span
            >
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow animate-pulse"
              v-show="loadingCmdDSV"
            >
              Commande en cours...
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              v-show="!loadingCmdDSV"
              @click="
                exportExcel(
                  commande.preparation_inst[0]['DA SILVA'],
                  'DA SILVA'
                )
              "
            >
              Exporter en excel
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="createPDF(commande.preparation_inst[0]['DA SILVA'])"
              v-show="!loadingCmdDSV"
            >
              Exporter en pdf
            </button>
            <div>
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                v-show="!loadingCmdDSV && !stateCmdDSV"
                @click="showRefDSV = !showRefDSV"
              >
                Commander par mail
              </button>
              <div
                class="absolute p-2 right-8 bg-white flex flex-row space-x-2 shadow"
                v-show="showRefDSV"
              >
                <input
                  type="text"
                  placeholder="Commentaire"
                  v-model="comCommandeDSV"
                />
                <input
                  type="Number"
                  placeholder="Ref commande"
                  v-model="refCommandeDSV"
                />
                <button
                  class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                  @click="
                    sendCommandDSV(commande.preparation_inst[0]['DA SILVA'])
                  "
                >
                  Commander
                </button>
              </div>
            </div>
          </div>
        </div>
        <tabCommandeF
          :article="commande.preparation_inst[0]['DA SILVA']"
          :Fournisseur="'DA SILVA'"
          typeFournisseur="configured"
          @changeQuantity="modifyQuantity"
          @deleteOne="deleteArticle"
          @refreshDispo="refreshDispo"
          @setPA="setPA"
        />
      </div>
      <div v-if="commande.preparation_inst[0]['HOFFER'].length > 0">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center space-x-4">
            <span>HOFFER</span
            ><span
              class="text-xs text-blue-500 cursor-pointer"
              @click="inverseCheckBox('HOFFER')"
              >Tout de/selectionner</span
            >
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow animate-pulse"
              v-show="loadingCmdHOFFER"
            >
              Validation en cours...
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="
                exportExcel(commande.preparation_inst[0]['HOFFER'], 'HOFFER')
              "
              v-show="!loadingCmdHOFFER"
            >
              Exporter en excel
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="createPDF(commande.preparation_inst[0]['HOFFER'])"
              v-show="!loadingCmdHOFFER"
            >
              Exporter en pdf
            </button>
            <div>
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                @click="showRefHOFFER = !showRefHOFFER"
                v-show="!loadingCmdHOFFER && !stateCmdHOFFER"
              >
                Commander
              </button>
              <div
                class="absolute p-2 right-8 bg-white flex flex-row space-x-2 shadow"
                v-show="showRefHOFFER"
              >
                <input
                  type="text"
                  placeholder="Commentaire"
                  v-model="comCommandeHOFFER"
                />
                <input
                  type="Number"
                  placeholder="Ref commande"
                  v-model="refCommandeHOFFER"
                />
                <button
                  class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                  @click="
                    sendCommandHOFFER(commande.preparation_inst[0]['HOFFER'])
                  "
                >
                  Commander
                </button>
              </div>
            </div>
          </div>
        </div>
        <tabCommandeF
          :article="commande.preparation_inst[0]['HOFFER']"
          :Fournisseur="'HOFFER'"
          typeFournisseur="configured"
          @changeQuantity="modifyQuantity"
          @deleteOne="deleteArticle"
          @refreshDispo="refreshDispo"
          @setPA="setPA"
        />
      </div>
      <div v-if="commande.preparation_inst[0]['MEYLE'].length > 0">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center space-x-4">
            <span>MEYLE</span
            ><span
              class="text-xs text-blue-500 cursor-pointer"
              @click="inverseCheckBox('MEYLE')"
              >Tout de/selectionner</span
            >
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow animate-pulse"
              v-show="loadingCmdMEYLE"
            >
              Validation en cours...
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="
                exportExcel(commande.preparation_inst[0]['MEYLE'], 'MEYLE')
              "
              v-show="!loadingCmdMEYLE"
            >
              Exporter en excel
            </button>
            <button
              class="p-1 bg-blue-500 text-white text-sm rounded shadow"
              @click="createPDF(commande.preparation_inst[0]['MEYLE'])"
              v-show="!loadingCmdMEYLE"
            >
              Exporter en pdf
            </button>
            <div>
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                @click="showRefMEYLE = !showRefMEYLE"
                v-show="!loadingCmdMEYLE && !stateCmdMEYLE"
              >
                Commander
              </button>
              <div
                class="absolute p-2 right-8 bg-white flex flex-row space-x-2 shadow"
                v-show="showRefMEYLE"
              >
                <input
                  type="text"
                  placeholder="Commentaire"
                  v-model="comCommandeMEYLE"
                />
                <input
                  type="Number"
                  placeholder="Ref commande"
                  v-model="refCommandeMEYLE"
                />
                <button
                  class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                  @click="
                    sendCommandMEYLE(commande.preparation_inst[0]['MEYLE'])
                  "
                >
                  Commander
                </button>
              </div>
            </div>
          </div>
        </div>
        <tabCommandeF
          :article="commande.preparation_inst[0]['MEYLE']"
          :Fournisseur="'MEYLE'"
          typeFournisseur="configured"
          @changeQuantity="modifyQuantity"
          @deleteOne="deleteArticle"
          @refreshDispo="refreshDispo"
          @setPA="setPA"
        />
      </div>
      <div
        v-if="commande.preparation_inst[0]['DEFAULT'].fournisseur.length > 0"
      >
        <div
          v-for="(f, indexF) in commande.preparation_inst[0]['DEFAULT']
            .fournisseur"
          :key="indexF"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center space-x-4">
              <span>{{ f.name }}</span
              ><span
                class="text-xs text-blue-500 cursor-pointer"
                @click="inverseDefaultCheckBox(indexF)"
                >Tout de/selectionner</span
              >
            </div>
            <div class="flex flex-row space-x-2 items-center">
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow animate-pulse"
                v-show="loadingCmdDEFAULT"
              >
                Validation en cours...
              </button>
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                @click="exportExcel(f.data, f.name)"
                v-show="!loadingCmdDEFAULT"
              >
                Exporter en excel
              </button>
              <button
                class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                @click="createPDF(f.data)"
                v-show="!loadingCmdDEFAULT"
              >
                Exporter en pdf
              </button>
              <div>
                <button
                  class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                  @click="showRefDEFAULT[indexF] = !showRefDEFAULT[indexF]"
                  v-show="!loadingCmdDEFAULT && !stateCmdDEFAULT"
                >
                  Commander
                </button>
                <div
                  class="absolute p-2 right-8 bg-white flex flex-row space-x-2 shadow"
                  v-show="showRefDEFAULT[indexF]"
                >
                  <input
                    type="text"
                    placeholder="Commentaire"
                    v-model="comCommandeDEFAULT"
                  />
                  <input
                    type="Number"
                    placeholder="Ref commande"
                    v-model="refCommandeDEFAULT"
                  />
                  <button
                    class="p-1 bg-blue-500 text-white text-sm rounded shadow"
                    @click="sendCommandDEFAULT(f.data, f.name, indexF)"
                  >
                    Commander
                  </button>
                </div>
              </div>

              <!-- <button class="p-1 bg-blue-500 text-white text-sm rounded shadow">
                mail
              </button> -->
            </div>
          </div>
          <tabCommandeF
            :article="f.data"
            :Fournisseur="indexF"
            typeFournisseur="auto"
            @changeQuantity="modifyQuantity"
            @deleteOne="deleteArticle"
            @refreshDispo="refreshDispo"
            @setPA="setPA"
          />
        </div>
      </div>
      <div v-if="commande.preparation_inst[0]['DEFAULT'].default.length > 0">
        <span>Sans fournisseur</span>
        <tabCommandeF
          :article="commande.preparation_inst[0]['DEFAULT'].default"
          :Fournisseur="'DEFAULT'"
          typeFournisseur="default"
          @changeQuantity="modifyQuantity"
          @deleteOne="deleteArticle"
          @refreshDispo="refreshDispo"
          @setPA="setPA"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCommandeInst,
  prepareReception,
  refreshDispo,
  updatePreparation,
  prepareCommande,
  prepareChangeDepotVH,
  changeDepotVH,
} from "@/hooks/commandeFournisseur/commandeFournisseur";
import tabCommandeF from "@/components/commandeFournisseur/tabCommandeF.vue";
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";
import { commandeFournisseur } from "@/hooks/pdf/commandeFournisseur.js";
import { sendCommandDSV } from "@/hooks/partner/dasilva.ts";
import { sendCommandHoffer } from "@/hooks/partner/hoffer.ts";
import { sendA05 } from "@/hooks/partner/vanheck.ts";
import { backorderStock } from "@/hooks/stock/stock.ts";
export default {
  data() {
    return {
      commande: [],
      loadingPreparation: false,
      refCommandeDSV: 0,
      comCommandeDSV: "",
      showRefDSV: false,
      loadingCmdDSV: false,
      stateCmdDSV: false,
      refCommandeVH: 0,
      comCommandeVH: "",
      showRefVH: false,
      loadingCmdVH: false,
      stateCmdVH: false,
      refCommandeMEYLE: 0,
      comCommandeMEYLE: "",
      showRefMEYLE: false,
      loadingCmdMEYLE: false,
      stateCmdMEYLE: false,
      refCommandeHOFFER: 0,
      comCommandeHOFFER: "",
      showRefHOFFER: false,
      loadingCmdHOFFER: false,
      stateCmdHOFFER: false,
      refCommandeDEFAULT: 0,
      comCommandeDEFAULT: "",
      showRefDEFAULT: [],
      loadingCmdDEFAULT: false,
      stateCmdDEFAULT: false,
      openModalDepot: false,
      tabChangeDepot: [],
      loadingDepot: false,
    };
  },
  components: {
    tabCommandeF,
  },
  computed: {
    ...mapGetters(["user", "plateform", "preparationFournisseur"]),
  },
  methods: {
    exportExcel(toExportTab, fournisseur) {
      let tab = [];
      for (const item of toExportTab) {
        if (item.checked) {
          tab.push({
            Ref: item.Ref_fournisseur,
            EAN: item.Code_EAN,
            Desc: item.Description,
            Qte: item.quantity_asked,
          });
        }
      }
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(tab);
      XLSX.utils.book_append_sheet(wb, ws, "Commande");

      XLSX.writeFile(
        wb,
        "Commande_" + fournisseur + "_" + this.user.proprietaire + ".xlsx"
      );
    },
    createPDF(tab) {
      let ref = [];
      for (const refA of tab) {
        if (refA.checked) {
          ref.push([
            refA.Ref_fournisseur,
            refA.Description,
            refA.quantity_asked,
          ]);
        }
      }
      commandeFournisseur(ref);
    },
    async sendCommandVH(tab) {
      this.showRefVH = false;
      let tabCmd = [];
      this.loadingCmdVH = true;
      for (const art of tab) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      await sendA05(
        tabCmd,
        this.user.proprietaire,
        this.user.username,
        this.plateform.grossierID,
        this.refCommandeVH,
        this.comCommandeVH,
        this.plateform.grossierID
      );
      await prepareReception(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        "VAN HECK",
        "Inst",
        parseInt(this.refCommandeVH),
        this.comCommandeVH
      );

      await this.backorder(tabCmd, "VAN HECK", this.refCommandeVH);

      this.commande = await getCommandeInst(this.user.proprietaire);
      this.loadingCmdVH = false;
      this.stateCmdVH = true;
    },
    async sendCommandDSV(tab) {
      this.showRefDSV = false;
      let tabCmd = [];
      this.loadingCmdDSV = true;
      for (const art of tab) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      await sendCommandDSV(this.user.proprietaire, tabCmd);
      await prepareReception(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        "DA SILVA",
        "Inst",
        this.refCommandeDSV,
        this.comCommandeDSV
      );

      await this.backorder(tabCmd, "DA SILVA", this.refCommandeDSV);

      this.commande = await getCommandeInst(this.user.proprietaire);
      this.loadingCmdDSV = false;
      this.stateCmdDSV = true;
    },
    async sendCommandMEYLE(tab) {
      this.showRefMEYLE = false;
      let tabCmd = [];
      this.loadingCmdMEYLE = true;
      for (const art of tab) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      // await sendCommandDSV(this.user.proprietaire, tabCmd);
      await prepareReception(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        "MEYLE",
        "Inst"
      );

      await this.backorder(tabCmd, "MEYLE", this.refCommandeMEYLE);

      this.commande = await getCommandeInst(this.user.proprietaire);
      this.loadingCmdMEYLE = false;
      this.stateCmdMEYLE = true;
    },
    async sendCommandHOFFER(tab) {
      this.showRefHOFFER = false;
      let tabCmd = [];
      this.loadingCmdHOFFER = true;
      for (const art of tab) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      await sendCommandHoffer(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        this.refCommandeHOFFER
      );
      await prepareReception(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        "HOFFER",
        "Inst",
        this.refCommandeHOFFER,
        this.comCommandeHOFFER
      );

      await this.backorder(tabCmd, "HOFFER", this.refCommandeHOFFER);

      this.commande = await getCommandeInst(this.user.proprietaire);
      this.loadingCmdHOFFER = false;
      this.stateCmdHOFFER = true;
    },
    async sendCommandDEFAULT(tab, fournisseur, index) {
      this.showRefDEFAULT[index] = false;
      let tabCmd = [];
      this.loadingCmdDEFAULT = true;
      for (const art of tab) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      // await sendCommandDSV(this.user.proprietaire, tabCmd);
      await prepareReception(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        fournisseur,
        "Inst",
        this.refCommandeDEFAULT,
        this.comCommandeDEFAULT
      );

      await this.backorder(tabCmd, fournisseur, this.refCommandeDEFAULT);

      this.commande = await getCommandeInst(this.user.proprietaire);
      this.loadingCmdDEFAULT = false;
      this.stateCmdDEFAULT = true;
    },
    async backorder(tabArt, fournisseur, cmd_number) {
      for (const art of tabArt) {
        await backorderStock(
          this.user._id,
          this.user.proprietaire,
          art._id,
          art.Ref_fournisseur,
          art.Code_marque,
          art.Code_EAN,
          0,
          0,
          10,
          "",
          "Commande chez le fournisseur " + fournisseur,
          art.Prefixe_tarif,
          this.user.proprietaire,
          cmd_number,
          "Commande fournisseur",
          "Commande fournisseur",
          art.quantity_asked
        );
      }
    },
    async deleteArticle(n) {
      if (n.typeFournisseur == "configured") {
        this.commande.preparation_inst[0][n.fournisseur].splice(n.index, 1);
      } else if (n.typeFournisseur == "auto") {
        this.commande.preparation_inst[0]["DEFAULT"].fournisseur[
          n.fournisseur
        ].data.splice(n.indexItem, 1);
      } else {
        this.commande.preparation_inst[0]["DEFAULT"].default.splice(n.index, 1);
      }
      await updatePreparation(this.user.proprietaire, this.commande);
    },
    async modifyQuantity() {
      await updatePreparation(this.user.proprietaire, this.commande);
    },
    async setPA(n) {
      await updatePreparation(this.user.proprietaire, this.commande);
    },
    async refreshDispo(n) {
      if (n.typeFournisseur == "configured") {
        this.commande.preparation_inst[0][n.fournisseur][
          n.index
        ].quantity_available.night = n.data.data.night;
        this.commande.preparation_inst[0][n.fournisseur][
          n.index
        ].quantity_available.day = n.data.data.day;
        this.commande.preparation_inst[0][n.fournisseur][
          n.index
        ].quantity_available.night_date = n.data.data.night_date;
        this.commande.preparation_inst[0][n.fournisseur][
          n.index
        ].quantity_available.day_date = n.data.data.day_date;
        this.commande.preparation_inst[0][n.fournisseur][
          n.index
        ].quantity_asked = n.data.data.night + n.data.data.day;
      }
      await updatePreparation(this.user.proprietaire, this.commande);
    },
    inverseCheckBox(fournisseur) {
      for (const item of this.commande.preparation_inst[0][fournisseur]) {
        item.checked = !item.checked;
      }
    },
    inverseDefaultCheckBox(index) {
      for (const item of this.commande.preparation_inst[0]["DEFAULT"]
        .fournisseur[index].data) {
        item.checked = !item.checked;
      }
    },
    async changeDepot(tab, depot, indexDepot) {
      this.loadingDepot = true;
      this.tabChangeDepot = [];
      let tabCmd = [];
      for (const art of tab) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      this.openModalDepot = true;
      this.tabChangeDepot = await prepareChangeDepotVH(
        this.user.proprietaire,
        tabCmd,
        depot,
        indexDepot
      );
      this.loadingDepot = false;
    },
    async valideChangeDepot() {
      this.loadingDepot = true;
      let tabCmd = [];
      for (const art of this.tabChangeDepot) {
        if (art.checked) {
          tabCmd.push(art);
        }
      }
      let sd05 = await sendA05(
        tabCmd,
        this.user.proprietaire,
        this.user.username,
        this.plateform.grossierID,
        this.refCommandeVH,
        this.comCommandeVH
      );
      await prepareReception(
        this.user.proprietaire,
        tabCmd,
        this.user.username,
        "VAN HECK",
        "Inst",
        this.refCommandeVH,
        this.comCommandeVH,
        sd05
      );
      this.commande = await getCommandeInst(this.user.proprietaire);
      this.loadingDepot = false;
      this.openModalDepot = false;
    },
  },
  async mounted() {
    this.loadingPreparation = true;
    this.commande = await prepareCommande(
      this.user.proprietaire,
      this.preparationFournisseur.panier,
      this.preparationFournisseur.fournisseur
    );
    this.loadingPreparation = false;
  },
};
</script>
<style></style>
