<template>
  <router-link to="/setarticles">
    <div
      id="retour"
      class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    >
      <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
      <span>Retour</span>
    </div>
  </router-link>
  <div class="shadow-md sm:rounded-lg w-full mt-16">
    <table class="w-full text-sm text-left text-gray-500" v-if="stockTab">
      <thead class="text-xs text-white uppercase bg-bluevk">
        <tr>
          <th scope="col" class="px-6 py-3">Plateform</th>
          <th scope="col" class="px-6 py-3">Ref fournisseur</th>

          <th scope="col" class="px-6 py-3">Code_EAN</th>

          <th scope="col" class="px-6 py-3">Stock</th>
          <th scope="col" class="px-6 py-3">Repartition</th>
          <th scope="col" class="px-6 py-3">min</th>
          <th scope="col" class="px-6 py-3">max</th>
          <th scope="col" class="px-6 py-3">adresse</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b hover:bg-orangevk hover:text-white cursor-pointer"
          v-if="stockTab"
        >
          <td class="px-6 py-4">{{ stockTab.plateform }}</td>
          <td class="px-6 py-4">{{ stockTab.Ref_fournisseur }}</td>

          <td class="px-6 py-4">{{ stockTab.Code_EAN }}</td>

          <td class="px-6 py-4">{{ stockTab.stock }}</td>
          <td class="px-6 py-4">
            <div class="flex">
              <div
                v-for="(Divi, indexDivi) in stockTab.division"
                :key="indexDivi"
              >
                {{ Divi.Prefixe_tarif + " : " + Divi.stock }}
              </div>
            </div>
          </td>
          <td class="px-6 py-4">{{ stockTab.min }}</td>
          <td class="px-6 py-4">{{ stockTab.max }}</td>
          <td class="px-6 py-4">{{ stockTab.address }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="shadow-md sm:rounded-lg w-full mt-16">
    <table
      class="w-full text-sm text-left text-gray-500"
      v-if="mvtTab.length > 0"
    >
      <thead class="text-xs text-white uppercase bg-bluevk">
        <tr>
          <th scope="col" class="px-6 py-3">N° Document</th>

          <th scope="col" class="px-6 py-3">User</th>

          <th scope="col" class="px-6 py-3">Description</th>
          <th scope="col" class="px-6 py-3">Client</th>
          <th scope="col" class="px-6 py-3">Mouvement</th>
          <th scope="col" class="px-6 py-3">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b hover:bg-orangevk hover:text-white cursor-pointer"
          v-for="(mouvement, index) in mvtTab"
          :key="index"
        >
          <td class="px-6 py-4">{{ mouvement.doc_number }}</td>

          <td class="px-6 py-4">{{ mouvement.created_by }}</td>

          <td class="px-6 py-4">{{ mouvement.Description }}</td>
          <td class="px-6 py-4">{{ mouvement.client }}</td>
          <td class="px-6 py-4 flex items-center">
            <span class="text-red-500">{{ mouvement.old_stock }} </span
            ><span class="material-icons-outlined px-2"> east </span>
            <span class="text-green-500"> {{ mouvement.new_stock }}</span>
          </td>
          <td class="px-6 py-4">{{ getFRDate(mouvement.Date) }}</td>
        </tr>
      </tbody>
    </table>

    <pageNumber
      :pageStart="pageStart"
      :pageNext="pageNext"
      :tabList="mvtTabLength"
      :pageTab="pageTab"
      :pageTotal="Math.ceil(mvtTabLength / 10)"
      :clickFunction="getMvtArticle"
      :currentPage="currentPage"
      :typeList="'mouvements'"
      @update:start="pageStart = $event"
      @update:tab="pageTab = $event"
      @update:next="pageNext = $event"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import pageNumber from "@/components/pageNumber.vue";
import { getFrDate } from "@/hooks/tools/date.ts";
import { findStock } from "@/hooks/stock/stock.ts";

export default {
  name: "StockArticle",
  computed: {
    ...mapGetters(["article", "user"]),
  },
  data() {
    return {
      fournTab: [],
      mvtTab: [],
      stockTab: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      mvtTabLength: 0,
    };
  },
  components: {
    pageNumber,
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getMvtArticle(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/mvStock/getbyowner/getpage`, {
          Ref_fournisseur: this.article.Ref_fournisseur,
          Code_EAN: this.article.Code_EAN,
          Code_marque: this.article.Code_marque,
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((res) => {
          this.mvtTab = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getStockArticle() {
      const stock = await findStock(
        this.user._id,
        this.user.proprietaire,
        this.article.Ref_fournisseur,
        this.article.Code_marque,
        this.article.Prefixe_tarif
      );
      this.stockTab = stock.data;
    },
    async getMvtArticleLength() {
      const res = await axios.post(
        `${process.env.VUE_APP_API}/mvStock/getOneByEAN`,
        {
          Ref_fournisseur: this.article.Ref_fournisseur,
          Code_EAN: this.article.Code_EAN,
          Code_marque: this.article.Code_marque,
          plateform: this.user.proprietaire,
        }
      );
      this.mvtTabLength = res.data;
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  mounted() {
    this.getMvtArticle(1, 10);
    this.getMvtArticleLength();
    this.getStockArticle();
    this.pageSwitch();
  },
};
</script>

<style scoped></style>
