<template>
  <div class="flex">
    <div
      class="flex flex-col space-x-2 overflow-y-auto max-h-64"
      v-if="photo.length > 0"
    >
      <img
        v-for="(ph, indexPh) in photo"
        :key="indexPh"
        :src="ph.imageURL200"
        @click="selectPhoto(ph.imageURL400)"
        class="w-16 h-16 cursor-pointer"
      />
    </div>
    <img v-if="photoSelected" :src="photoSelected" class="mx-auto border" />
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      photo: [],
      photoSelected: "",
    };
  },
  props: ["Ref_fournisseur", "Code_marque"],
  methods: {
    async getPhotoArticle() {
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/photoArticle`, {
          Ref_fournisseur: this.Ref_fournisseur,
          Code_marque: this.Code_marque,
        })
        .then((res) => {
          this.photo = res.data;
          this.photoSelected = res.data[0].imageURL400;
        });
    },
    selectPhoto(url) {
      this.photoSelected = url;
    },
  },
  beforeMount() {
    this.getPhotoArticle();
  },
};
</script>

<style scoped>
</style>
