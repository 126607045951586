import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";
import { getFrDate } from "@/hooks/tools/date.ts";

export function createtransport(objet, duplicata) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();
  var edition =
    "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes;
  var datepdf = "Le " + getFrDate(objet.Date) + ",";

  let colDuplicata;
  if (duplicata) {
    colDuplicata = {
      columns: [
        {
          text: "Duplicata",
          style: "invoiceTitleDetail",
          margin: [0, 0, 0, 0],
          width: 390,
        },
      ],
    };
  }

  let imagePlateforme = {
    text: "",
    width: 100,
    height: 100,
  };
  let titrePlateforme = {
    text: store.state.plateform.Titre2 + "\n",
    style: "invoiceBillingTitle",
    width: 245,
    margin: [0, 5, 0, 0],
  };
  let titre2Plateforme = {
    text: "",
    style: "invoiceBillingSubTitle",
    width: 245,
    margin: [40, 0, 0, 0],
  };
  if (objet.Fournisseur !== "DA SILVA" && objet.Fournisseur !== "FRIGAIR") {
    imagePlateforme = {
      image: "imgg",
      width: 100,
      height: 100,
    };
    titrePlateforme = {
      text: store.state.plateform.Titre1 + "\n",
      style: "invoiceBillingTitle",
      width: 245,
      margin: [0, 5, 0, 0],
    };
    titre2Plateforme = {
      text: store.state.plateform.Titre2 + "\n",
      style: "invoiceBillingSubTitle",
      width: 245,
      margin: [40, 0, 0, 0],
    };
  }

  var poidstotal = 0;
  // if (objet.dataTransport) {
  //   poidstotal = objet.dataTransport.poids;
  // } else {
  objet.Articles.forEach((element) => {
    if (parseInt(element.Poids)) {
      poidstotal = poidstotal + parseInt(element.Poids);
    }
  });
  // }

  var produits = [];

  produits.push([
    {
      text: "Qté",
      style: ["itemsHeader", "center"],
      border: [true, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Produit",
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text:
        "Servi par : " +
        objet.Name +
        "            " +
        "réf. cl. : " +
        objet.Note,
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Poids",
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
  ]);
  objet.pdfData.forEach((element, index) => {
    produits.push([
      {
        text: element.quantite,
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text: element.ref,
        style: "itemTitle",
        border: [true, false, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text:
          element.commentaire != ""
            ? element.description + "\n \n" + "Note : " + element.commentaire
            : element.description,
        style: "itemTitle",
        border: [false, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text: objet.Articles[index].Poids + " g",
        style: "itemTitle",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
    ]);
  });

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let infoCompClient = "";
  if (objet.clientInfo[0].professionnel == true && objet.clientInfo[0].siret) {
    infoCompClient =
      objet.clientInfo[0].siret +
      " - " +
      objet.clientInfo[0].numerotva +
      " - " +
      objet.clientInfo[0].nafape;
  }
  let moyendepaiement = "";
  if (
    objet.clientInfo[0].professionnel == true &&
    objet.clientInfo[0].moyendepaiement
  ) {
    moyendepaiement =
      "Moyen de paiement : " +
      objet.clientInfo[0].moyendepaiement +
      "\n" +
      objet.clientInfo[0].NumeroClient
        ? "N° Client : " + objet.clientInfo[0].NumeroClient
        : "";
  }

  var dd = {
    header: [
      {
        columns: [
          {
            text: edition,
            style: "documentHeaderRight",
          },
        ],
      },
      // Header
      {
        margin: [40, 0, 0, 0],
        columns: [
          imagePlateforme,
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: "Bon de transport",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            {
              columns: [
                {
                  text: "n°" + "100000" + objet.Numero,
                  style: "invoiceTitleDetail",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            colDuplicata,
            {
              stack: [
                {
                  columns: [
                    {
                      text: "",
                      style: "invoiceSubValue",
                      width: 180,
                    },
                    {
                      text: datepdf,
                      style: "invoiceSubValue",
                      margin: [0, 40, 0, 0],
                      width: 190,
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        margin: [40, 0, 0, 0],
        columns: [
          titrePlateforme,
          {
            text: objet.Client + "\n",
            style: "invoiceBillingTitle1",
            width: 245,
            margin: [25, 0, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          titre2Plateforme,
          {
            text: objet.clientInfo[0].Adresse
              ? objet.clientInfo[0].Adresse
              : "",
            style: "invoiceBillingDetails",
            width: 210,
            margin: [24, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Adresse,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 10, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
          {
            text: objet.clientInfo[0].Complement
              ? objet.clientInfo[0].Complement
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [-1, -9, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.plateform.Code_postale +
              " " +
              store.state.plateform.Ville,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo[0].PostalCode && objet.clientInfo[0].City
                ? objet.clientInfo[0].PostalCode +
                  " " +
                  objet.clientInfo[0].City
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Pays,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].Country
              ? objet.clientInfo[0].Country
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Telephone,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 5, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].telephone
              ? objet.clientInfo[0].telephone
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Email,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].email ? objet.clientInfo[0].email : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.plateform.Siret +
              " - " +
              store.state.plateform.Tva +
              " - " +
              store.state.plateform.Naf,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: infoCompClient,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: moyendepaiement,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -8, 0, 0],
          },
        ],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `${store.state.plateform.Forme_sociale} ${store.state.plateform.Titre1} au capital de ${store.state.plateform.Capital} € dont le siège social est situé à ${store.state.plateform.Adresse} ${store.state.plateform.Code_postale} ${store.state.plateform.Ville}. \n En cas de retard de paiement, taux de penalité appliqué égal à 3 fois le taux d'intérêt en vigueur. Pas d'escompte en cas de paiement anticipé. Echange ou retour sous 15 jours.`,
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [
      // Items
      {
        table: {
          headerRows: 1,
          widths: [30, 140, "*", 50],
          text: objet.clientInfo[0].nomcommercial,
          body: produits,
        },
      },
      "\n\n",
      {
        table: {
          headerRows: 0,
          widths: [230, 80, "*", 80, "*"],

          body: [
            [
              {
                text: "",
                style: "itemsFooterTotalTitle",
              },
              {
                text: "Transporteur : ",
                style: "itemsFooterTotalTitle",
              },
              {
                text: objet.dataTransport
                  ? objet.dataTransport.transporteur
                  : "",

                style: "itemsFooterTotalValue",
              },
              {
                text: "Poids total : ",
                style: "itemsFooterTotalTitle",
              },
              {
                text: poidstotal + " g",
                style: "itemsFooterTotalValue",
              },
            ],
          ],
        },
        layout: "lightHorizontalLines",
      },
    ],
    images: {
      imgg: {
        url: `${store.state.plateform.lien_logo}`,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },

      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    pageMargins: [40, 260, 40, 40],
    defaultStyle: {
      columnGap: 20,
    },
  };
  pdfMake.createPdf(dd).open();
}
