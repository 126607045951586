<template>
  <!-- titre de la page -->
  <div class="flex text-gray-700 flex-row mx-auto">
    <div
      class="flex text-gray-700 text-xl md:text-3xl md:font-medium font-bold flex-row my-2 mx-auto sm: text-bluevk"
    >
      <div class="p-1">Articles</div>
    </div>
  </div>

  <!-- encarts -->

  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8 mx-2 lg:mx-20 md:p-16 lg:px-24 lg:pb-24"
  >
    <!-- Liste -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/articles">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Liste.png"
              alt="Pictogramme liste"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Liste
          </h5>
        </router-link>
      </div>
    </div>

    <!-- Liste articles divers -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/articlesDivers">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Liste.png"
              alt="Pictogramme liste"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Articles divers
          </h5>
        </router-link>
      </div>
    </div>

    <!-- catalogue de pneus -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/cataloguedepneus">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/KtaPneus.png"
              alt="Pictogramme catalogue de pneus"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Catalogue de pneus
          </h5>
        </router-link>
      </div>
    </div>

    <!-- Inventaire -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/hubInventaireStock">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Inventaire.png"
              alt="Pictogramme inventaire"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-4"
          >
            Inventaire
          </h5>
        </router-link>
      </div>
    </div>

    <!-- état des stocks -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/etatStock">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/EtatDesStocks.png"
              alt="Pictogramme état des stocks"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            État des stocks
          </h5>
        </router-link>
      </div>
    </div>

    <!-- Régul des stocks -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/regulStock">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Regul.png"
              alt="Pictogramme régularisation des stocks"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Régularisation des stocks
          </h5>
        </router-link>
      </div>
    </div>

    <!-- mouvement des stocks -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/mouvementstock">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/MouvementStocks.png"
              alt="Pictogramme mouvement de stocks"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Mouvement de stocks
          </h5>
        </router-link>
      </div>
    </div>

    <!-- Réception de stocks -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="entreesortiestock">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Reception.png"
              alt="Pictogramme réception de stocks"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Réception de stocks
          </h5>
        </router-link>
      </div>
    </div>

    <!-- Dépôt -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/transfertHub">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Depot.png"
              alt="Pictogramme dépôt de stock"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Transfert de stock
          </h5>
        </router-link>
      </div>
    </div>

    <!-- Cross multiple -->

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/ManyCrossBrand">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/CrossMultiple.png"
              alt="Pictogramme cross multiple"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Cross multiple
          </h5>
        </router-link>
      </div>
    </div>

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/promotions">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/Liste.png"
              alt="Pictogramme liste"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Promotions
          </h5>
        </router-link>
      </div>
    </div>

    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
    >
      <div class="flex flex-col items-center pb-5 lg:pb-0">
        <router-link to="/CrossArticle">
          <div
            class="flex flex-col text-center p-12 lg:pt-14 lg:pb-10 hover:scale-110 duration-300 hover:text-gray-600"
          >
            <img
              src="../assets/articleHub/CrossMultiple.png"
              alt="Pictogramme cross multiple"
              class="h-32 lg:h-24"
            />
          </div>
          <h5
            class="text-m font-medium text-gray-500 w-full text-center lg:pb-6"
          >
            Ref croisées
          </h5>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
/* .hover-bg-bluevk:hover {
  background-color: #2262b3;
  color: #fff;
}

.hover-bg-bluevk:hover * {
  color: #fff;
}
.hover-bg-bluevk:hover span:first-child {
  color: #ff914d;
}
.hover-bg-bluevk:hover span:last-child {
  color: #ff914d;
}

.hover-bg-orangevk:hover * {
  background-color: #ff914d;
  color: #fff;
} */
</style>
