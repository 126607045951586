<template>
  <div class="flex flex-row items-center">
    <span class="material-symbols-rounded text-red-500" v-if="rating == 1">
      sentiment_sad
    </span>
    <span class="material-symbols-rounded text-orange-500" v-if="rating == 2">
      sentiment_dissatisfied
    </span>
    <span class="material-symbols-rounded text-gray-500" v-if="rating == 3">
      sentiment_neutral
    </span>
    <span class="material-symbols-rounded text-yellow-500" v-if="rating == 4">
      sentiment_satisfied
    </span>
    <span class="material-symbols-rounded text-green-500" v-if="rating == 5">
      sentiment_very_satisfied
    </span>
  </div>
</template>
<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style></style>
