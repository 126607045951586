<template>
  <div class="animate-pulse flex flex-col space-y-2">
    <div class="text-xs ml-2 flex flex-row space-x-2 items-center">
      <input
        type="checkbox"
        class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
      />
      <span class="bg-gray-200 text-gray-200 rounded">----------</span>
    </div>
    <div class="text-xs ml-2 flex flex-row space-x-2 items-center">
      <input
        type="checkbox"
        class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
      />
      <span class="bg-gray-200 text-gray-200 rounded">----------</span>
    </div>
    <div class="text-xs ml-2 flex flex-row space-x-2 items-center">
      <input
        type="checkbox"
        class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
      />
      <span class="bg-gray-200 text-gray-200 rounded">----------</span>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
