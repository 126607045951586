<template>
  <div>Prix Brut : {{ prixBrut }} €</div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      prixBrut: "--",
      userGroupClient: "",
    };
  },
  props: ["article", "marque", "Prefixe_tarif", "qty"],
  computed: {
    ...mapGetters(["user", "comptoirSessionActive"]),
  },
  async mounted() {
    if (this.comptoirSessionActive.length > 0) {
      axios
        .post(`${process.env.VUE_APP_API}/catalogue/getPrixDispo`, {
          Ref_fournisseur: this.article,
          Code_marque: this.marque,
          Prefixe_tarif: this.Prefixe_tarif,
          plateform: this.user.proprietaire,
          client: this.comptoirSessionActive[0].Client[0].Name,
          groupClient: this.comptoirSessionActive[0].Client[0].Group.Name,
          Quantity: this.qty,
        })
        .then((response) => {
          response.data.prixBrut != "--"
            ? (this.prixBrut = parseFloat(response.data.prixBrut).toFixed(2))
            : (this.prixBrut = "--");
        });
    }
  },
};
</script>
<style></style>
