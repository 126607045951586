<template>
  <div id="Recherche" class="w-96">
    <label
      for="default-search"
      class="mb-2 text-sm font-medium text-gray-900 sr-only"
      >Search</label
    >
    <div class="relative">
      <div
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <svg
          aria-hidden="true"
          class="w-5 h-5 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      <input
        type="text"
        id="default-search"
        class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-sky-300 outline-2"
        :placeholder="placeholder"
        v-model="searchTerm"
        required
        @keyup.enter="rechercher()"
        @input="catchFalse()"
      />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <span v-if="catchError" title="Aucune réponse" class="material-icons-outlined text-orange-600 mr-1"> error </span>
        <PulseLoader v-if="searchStarted" />
        <button
          v-else
          class="material-icons-outlined text-white bg-bluevk h-fit m-auto mr-2 rounded-md shadow-lg w-12 h-9 text-4xl px-1.5"
          title="Rechercher"
          @click="rechercher()"
        >
          arrow_right_alt
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "searchInput",
  props: {
    placeholder: {
      type: String,
      default: "Tapez votre recherche",
    },
    searchStarted: {
      type: Boolean,
      default: false,
    },
    catchError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
      catchMsg: true
    };
  },
  components: {
    PulseLoader,
  },
  methods: {
    rechercher() {
      this.$emit("searchWord", this.searchTerm);
    },
    catchFalse() {
      this.$emit("catchFalse", this.catchMsg)
    }
  },
  mounted() {
    this.catchMsg = this.catchError
  }
};
</script>
