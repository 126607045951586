<template>
  <div class="flex flex-col space-y-2">
    <div class="flex justify-end">
      <div v-for="(depot, indexDepot) in tabDepot" :key="indexDepot">
        <button
          v-if="loadingCmd[indexDepot]"
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="execution = false"
        >
          <PulseLoader color="#ffffff" class="my-auto mr-2" />
          <span>Commande {{ depot.nom_depot }}</span>
        </button>
        <button
          v-else
          @click="sendCommandVH(indexDepot, depot)"
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          Commander {{ depot.nom_depot }}
        </button>
      </div>
    </div>

    <button
      v-if="loadingGlobal"
      class="ml-auto bg-orange-500 text-white hover:bg-orange-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit rounded mr-1 flex justify-center items-center"
      @click="execution = false"
    >
      <PulseLoader color="#ffffff" class="my-auto mr-2" />
      <span>Disponibilités</span>
    </button>
    <button
      v-else
      class="ml-auto bg-orange-500 text-white hover:bg-orange-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit rounded mr-1 flex justify-center items-center"
      @click="refreshAllDispo()"
    >
      <span class="material-icons-outlined text-xl mr-2"> find_replace </span>
      <span>Disponibilités</span>
    </button>

    <table class="min-w-full border-2">
      <div class="overflow-y-auto h-[40rem]">
        <thead class="tableHEADTR">
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Réf
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Description
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Prix
            </th>
            <th
              class="pl-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Qté
            </th>
            <th
              v-for="(depot, indexDepot) in tabDepot"
              :key="indexDepot"
              class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <div class="flex space-x-2 m-auto">
                <div class="my-auto uppercase font-medium text-orange-500">
                  {{ depot.nom_depot }}
                </div>
                <button
                  v-if="loadingRefreshAll[indexDepot]"
                  @click="executionByDepot = false"
                  class="material-icons-outlined text-md my-auto text-orange-500 shadow rounded-full bg-orange-100 animate-spin"
                >
                  sync
                </button>
                <button
                  v-else
                  @click="refreshDispoByDepot(indexDepot, depot)"
                  class="material-icons-outlined text-md my-auto text-blue-500 shadow rounded-full bg-blue-100"
                >
                  sync
                </button>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="tableHEADTR bg-white w-fit">
          <tr
            class="hover:shadow hover:bg-blue-50"
            v-for="(item, indexItem) in tab"
            :key="indexItem"
          >
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="flex flex-row space-x-2 items-center">
                <div>
                  <input
                    type="checkbox"
                    class="form-checkbox"
                    v-model="item.checked"
                  />
                </div>

                <div class="text-sm leading-5 text-gray-900">
                  {{ item.Ref_fournisseur }}
                </div>
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ item.Description }}
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="leading-5 text-gray-900 flex flex-col">
                <div class="text-sm">{{ item.Prix_euro }} €</div>
                <div
                  class="text-sm flex flex-row items-center space-x-1"
                  v-if="user.accountType == 'Administrateur'"
                >
                  <span>PA: </span>
                  <input
                    @change="
                      $emit('setPA', {
                        index: indexItem,
                        prix_net: item.PrixAchat,
                        fournisseur: Fournisseur,
                        typeFournisseur: typeFournisseur,
                      })
                    "
                    disabled
                    type="number"
                    v-model="item.PrixAchat"
                    class="p-1 w-16 border rounded-md shadow outline-2 focus:outline-sky-300"
                  />
                  <span>€</span>
                </div>
                <div
                  class="text-xs"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{ item.remiseFournisseur }} %
                </div>
              </div>
            </td>

            <!-- QTE STANDARD -->
            <td class="pl-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="flex space-x-2 text-sm leading-5 text-gray-900">
                <input
                  class="p-1 w-16 border rounded-md shadow outline-2 focus:outline-sky-300"
                  type="number"
                  v-model="item.quantity"
                  @change="modifyQuantity(item, item.quantity)"
                  @keyup.enter="
                    refreshOneDispo(
                      tabDepot[0],
                      indexItem,
                      item.Ref_fournisseur,
                      item.quantity
                    )
                  "
                />
                <div>
                  <span
                    class="material-icons-round bg-red-500 rounded-sm text-white text-xs cursor-pointer"
                    @click="
                      $emit('deleteOne', {
                        data: item,
                        index: indexItem,
                        fournisseur: Fournisseur,
                        typeFournisseur: typeFournisseur,
                      }),
                        tabChecked.splice(indexItem, 1)
                    "
                  >
                    clear
                  </span>
                </div>
              </div>
            </td>

            <!-- DISPO -->
            <td
              v-for="(depot, indexDepot) in tabDepot"
              :key="indexDepot"
              class="py-4 border-b border-gray-200 whitespace-nowrap"
            >
              <div class="space-y-1">
                <div
                  class=""
                  v-for="(alter, indexAlter) in depot.tabAlternatif"
                  :key="indexAlter"
                >
                  <div
                    v-if="indexDepot > 0 && indexAlter == indexItem"
                    class="text-xs"
                  >
                    Qté demandé : {{ alter.quantity }}
                  </div>
                </div>

                <div
                  class="flex flex-row items-center space-x-2"
                  v-for="(dispo, indexDispo) in depot.tabDispo"
                  :key="indexDispo"
                >
                  <div
                    v-if="indexDispo == indexItem && dispo.data"
                    class="space-y-1"
                  >
                    <!-- Nuit -->
                    <div v-if="parseInt(dispo.data.night) > 0" class="flex">
                      <div
                        :title="dispo.data.night_date"
                        class="flex border border-yellow-500 p-0.5 rounded shadow items-center"
                      >
                        <span
                          class="material-icons-outlined text-yellow-500"
                          style="font-size: 15px"
                          >light_mode</span
                        >

                        <span
                          class="text-xs text-black"
                          v-if="parseInt(dispo.data.night)"
                        >
                          {{ parseInt(dispo.data.night) }}
                        </span>
                      </div>
                    </div>

                    <!-- Jour -->
                    <div v-if="parseInt(dispo.data.day) > 0" class="flex">
                      <div
                        :title="dispo.data.day_date"
                        class="flex border border-blue-700 p-0.5 rounded shadow"
                      >
                        <span
                          class="material-icons-outlined text-blue-700"
                          style="font-size: 15px"
                          >nightlight</span
                        >

                        <span
                          class="text-xs text-black"
                          v-if="parseInt(dispo.data.day)"
                        >
                          {{ parseInt(dispo.data.day) }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-if="indexDispo == indexItem && dispo.data">
                    <span
                      class="material-icons-round text-sm text-blue-500 cursor-pointer"
                      @click="
                        refreshOneDispo(
                          depot,
                          indexItem,
                          item.Ref_fournisseur,
                          item.quantity
                        )
                      "
                      v-show="!depot.loading[indexItem]"
                    >
                      replay
                    </span>
                    <span
                      class="material-icons-round text-orange-500 animate-spin text-sm"
                      v-show="depot.loading[indexItem]"
                    >
                      replay
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </div>
    </table>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  refreshDispo,
  addPanierInst,
  prepareReception,
} from "@/hooks/commandeFournisseur/commandeFournisseur";
import { getAllBySupplier } from "@/hooks/fournisseur/depotFournisseur";
import { sendA05 } from "@/hooks/partner/vanheck.ts";
import { mapGetters } from "vuex";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  props: ["article", "Fournisseur", "typeFournisseur", "tab"],
  data() {
    return {
      fournisseurs: [],
      login: "",
      logCode: "",
      tabDepot: [],

      tabChecked: [],

      tabDispo: [],
      loadingRefreshAll: [],
      loadingRefreshDispo: [],

      // tabDispoSchiedam: [],
      // loadingRefreshAllSchiedam: false,
      // loadingRefreshDispoSchiedam: [],
      // tabAlternatif: [],

      loadingGlobal: false,
      execution: false,
      executionByDepot: false,

      loadingCmd: [],
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "preparationFournisseur"]),
  },
  methods: {
    async getFournisseursDepot() {
      this.tabDepot = await getAllBySupplier(this.user.proprietaire, "Vhi");
      console.log(this.tabDepot);
    },

    // Recherche dans chaque dépôt
    async refreshAllDispo() {
      this.loadingGlobal = true;
      this.execution = true;
      for (const [indexDepot, depot] of this.tabDepot.entries()) {
        if (!this.execution) {
          this.loadingRefreshAll[indexDepot] = false;
          break;
        } else {
          this.loadingRefreshAll[indexDepot] = true;
          if (!depot.tabDispo && !depot.loading) {
            depot.tabDispo = [];
            depot.loading = [];
          }
          for (const [index, re] of this.tab.entries()) {
            if (!this.execution) {
              depot.loading[index] = false;
              break;
            } else {
              depot.loading[index] = true;
              let refresh;
              if (indexDepot == 0) {
                refresh = await refreshDispo(
                  this.user.proprietaire,
                  this.Fournisseur,
                  re.Ref_fournisseur,
                  re.quantity,
                  depot.code_depot,
                  depot.login
                );
              } else if (
                indexDepot > 0 &&
                this.tabDepot[indexDepot].tabAlternatif &&
                this.tabDepot[indexDepot].tabAlternatif.length > 0 &&
                this.tabDepot[indexDepot].tabAlternatif[index].quantity != 0
              ) {
                refresh = await refreshDispo(
                  this.user.proprietaire,
                  this.Fournisseur,
                  re.Ref_fournisseur,
                  this.tabDepot[indexDepot].tabAlternatif[index].quantity,
                  depot.code_depot,
                  depot.login
                );
                console.log(refresh);
              } else {
                refresh = {};
              }
              depot.tabDispo[index] = refresh;

              if (refresh == "Erreur") {
                await this.error("Erreur !");
                depot.loading[index] = false;
                this.loadingRefreshAll[indexDepot] = false;
                break;
              }

              // DISPO ALTERNATIF
              if (indexDepot + 1 <= this.tabDepot.length - 1) {
                if (!this.tabDepot[indexDepot].tabAlternatif) {
                  this.tabDepot[indexDepot].tabAlternatif = [];
                }
                if (indexDepot == 0) {
                  this.tabDepot[indexDepot].tabAlternatif[index] = {
                    quantity: parseInt(re.quantity),
                    Ref_fournisseur: re.Ref_fournisseur,
                  };
                } else {
                  this.tabDepot[indexDepot].tabAlternatif[index] = {
                    quantity: parseInt(
                      this.tabDepot[indexDepot].tabAlternatif[index].quantity
                    ),
                    Ref_fournisseur: re.Ref_fournisseur,
                  };
                }

                if (!this.tabDepot[indexDepot + 1].tabAlternatif) {
                  this.tabDepot[indexDepot + 1].tabAlternatif = [];
                }

                if (
                  refresh.data &&
                  parseInt(refresh.data.day) + parseInt(refresh.data.night) <
                    this.tabDepot[indexDepot].tabAlternatif[index].quantity
                ) {
                  this.tabDepot[indexDepot + 1].tabAlternatif[index] = {
                    quantity:
                      this.tabDepot[indexDepot].tabAlternatif[index].quantity -
                      (parseInt(refresh.data.day) +
                        parseInt(refresh.data.night)),
                    Ref_fournisseur: re.Ref_fournisseur,
                  };
                } else {
                  this.tabDepot[indexDepot + 1].tabAlternatif[index] = {
                    quantity: 0,
                    Ref_fournisseur: "",
                  };
                }
              }

              depot.loading[index] = false;
            }
          }
          this.loadingRefreshAll[indexDepot] = false;
        }
      }
      this.execution = false;
      this.loadingGlobal = false;
    },

    // Recherche par dépôt
    async refreshDispoByDepot(indexDepot, depot) {
      this.executionByDepot = true;
      this.loadingRefreshAll[indexDepot] = true;
      for (let i = indexDepot; i < this.tabDepot.length; i++) {
        if (this.tabDepot[i].tabDispo) {
          this.tabDepot[i].tabDispo = [];
        }
        if (this.tabDepot[i].tabAlternatif) {
          this.tabDepot[i].tabAlternatif = [];
        }
      }

      if (!depot.tabDispo && !depot.loading) {
        depot.tabDispo = [];
        depot.loading = [];
      }
      for (const [index, re] of this.tab.entries()) {
        if (!this.executionByDepot) {
          depot.loading[index] = false;
          break;
        } else {
          depot.loading[index] = true;

          let refresh;

          if (
            indexDepot == 0 ||
            (indexDepot > 0 && !this.tabDepot[indexDepot].tabAlternatif)
          ) {
            refresh = await refreshDispo(
              this.user.proprietaire,
              this.Fournisseur,
              re.Ref_fournisseur,
              re.quantity,
              depot.code_depot,
              depot.login
            );
          } else if (
            indexDepot > 0 &&
            !this.tabDepot[indexDepot].tabAlternatif[index]
          ) {
            refresh = await refreshDispo(
              this.user.proprietaire,
              this.Fournisseur,
              re.Ref_fournisseur,
              re.quantity,
              depot.code_depot,
              depot.login
            );
          } else if (
            indexDepot > 0 &&
            this.tabDepot[indexDepot].tabAlternatif &&
            this.tabDepot[indexDepot].tabAlternatif.length > 0 &&
            this.tabDepot[indexDepot].tabAlternatif[index].quantity != 0
          ) {
            refresh = await refreshDispo(
              this.user.proprietaire,
              this.Fournisseur,
              re.Ref_fournisseur,
              this.tabDepot[indexDepot].tabAlternatif[index].quantity,
              depot.code_depot,
              depot.login
            );
          } else {
            refresh = {};
          }
          depot.tabDispo[index] = refresh;

          if (refresh == "Erreur") {
            await this.error("Erreur !");
            depot.loading[index] = false;
            this.loadingRefreshAll[indexDepot] = false;
            break;
          }

          // DISPO ALTERNATIF
          if (indexDepot + 1 <= this.tabDepot.length - 1) {
            if (!this.tabDepot[indexDepot].tabAlternatif) {
              this.tabDepot[indexDepot].tabAlternatif = [];
            }
            if (
              indexDepot == 0 ||
              (indexDepot > 0 && !this.tabDepot[indexDepot].tabAlternatif)
            ) {
              this.tabDepot[indexDepot].tabAlternatif[index] = {
                quantity: parseInt(re.quantity),
                Ref_fournisseur: re.Ref_fournisseur,
              };
            } else if (
              indexDepot > 0 &&
              !this.tabDepot[indexDepot].tabAlternatif[index]
            ) {
              this.tabDepot[indexDepot].tabAlternatif[index] = {
                quantity: parseInt(re.quantity),
                Ref_fournisseur: re.Ref_fournisseur,
              };
            } else if (
              indexDepot > 0 &&
              this.tabDepot[indexDepot].tabAlternatif &&
              this.tabDepot[indexDepot].tabAlternatif.length > 0 &&
              this.tabDepot[indexDepot].tabAlternatif[index].quantity != 0
            ) {
              this.tabDepot[indexDepot].tabAlternatif[index] = {
                quantity: parseInt(
                  this.tabDepot[indexDepot].tabAlternatif[index].quantity
                ),
                Ref_fournisseur: re.Ref_fournisseur,
              };
            } else {
              this.tabDepot[indexDepot].tabAlternatif[index] = {
                quantity: 0,
                Ref_fournisseur: "",
              };
            }

            if (!this.tabDepot[indexDepot + 1].tabAlternatif) {
              this.tabDepot[indexDepot + 1].tabAlternatif = [];
            }

            if (
              refresh.data &&
              parseInt(refresh.data.day) + parseInt(refresh.data.night) <
                this.tabDepot[indexDepot].tabAlternatif[index].quantity
            ) {
              this.tabDepot[indexDepot + 1].tabAlternatif[index] = {
                quantity:
                  this.tabDepot[indexDepot].tabAlternatif[index].quantity -
                  (parseInt(refresh.data.day) + parseInt(refresh.data.night)),
                Ref_fournisseur: re.Ref_fournisseur,
              };
            } else {
              this.tabDepot[indexDepot + 1].tabAlternatif[index] = {
                quantity: 0,
                Ref_fournisseur: "",
              };
            }
          }

          depot.loading[index] = false;
        }
      }
      this.loadingRefreshAll[indexDepot] = false;
    },

    // Recherche par ligne
    async refreshOneDispo(depot, index, ref, quantity) {
      depot.loading[index] = true;
      const refresh = await refreshDispo(
        this.user.proprietaire,
        this.Fournisseur,
        ref,
        quantity,
        depot.code_depot,
        depot.login
      );
      depot.tabDispo[index] = refresh;
      console.log(refresh);
      depot.loading[index] = false;
    },

    // Modifie la quantité et l'enregistre
    async modifyQuantity(data, qty) {
      console.log(data);
      await addPanierInst(this.user.proprietaire, data, qty);
    },

    // Commander
    async sendCommandVH(indexDepot, depot) {
      this.loadingCmd[indexDepot] = true;

      await this.refreshDispoByDepot(indexDepot, depot);

      let tabCmd = [];
      for (const [indexArt, article] of this.tab.entries()) {
        for (const [indexDispo, ligne] of depot.tabDispo.entries()) {
          if (article.checked == true && indexDispo == indexArt) {
            tabCmd.push({
              Ref_fournisseur: article.Ref_fournisseur,
              Code_EAN: article.Code_EAN,
              Code_marque: article.Code_marque,
              Prefixe_tarif: article.Prefixe_tarif,
              Description: article.Description,
              fournisseur: "VAN HECK",
              remise: article.remiseFournisseur,
              prix_brut: article.Prix_euro,
              prix_net: article.PrixAchat,
              quantity_asked: article.quantity,
              quantity_received: 0,
              group: article.Code_remise,
              quantity_available: {
                day: ligne.data.day,
                night: ligne.data.night,
              },
            });
          }
        }
      }

      const send = await sendA05(
        tabCmd,
        this.user.proprietaire,
        this.user.username,
        depot.login,
        0,
        "this.comCommandeVH",
        depot.code_depot
      );

      if (send == "Erreur") {
        await this.error("Erreur !");
      } else {
        await prepareReception(
          this.user.proprietaire,
          tabCmd,
          this.user.username,
          "VAN HECK",
          "Inst",
          0,
          "this.comCommandeVH",
          depot.code_depot,
          depot.login
        );
      }

      this.loadingCmd[indexDepot] = false;
      this.success("Commandé !");
      this.get;
    },

    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  beforeMount() {
    this.getFournisseursDepot();
    this.tab.forEach((item) => {
      item.checked = true;
    });
  },
};
</script>
<style></style>
