<template>
  <!-- modal commentaire global -->
  <div
    v-if="isModalOpenGlobal"
    :class="`modal ${
      !isModalOpenGlobal && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="isModalOpenGlobal = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <div class="flex space-x-2 text-2xl font-bold">
            <p>Rapport global</p>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="isModalOpenGlobal = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Placeholder -->
        <textarea
          placeholder="Saisissez votre commentaire..."
          class="w-full h-44 p-4 border rounded focus:outline-none focus:ring focus:border-blue-500"
          v-model="tabCP.identification[0].rapport"
        ></textarea>

        <!--Footer-->
        <div class="flex justify-end mt-4">
          <button
            @click="isModalOpenGlobal = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-600 hover:text-white hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="save(), isModalOpenGlobal = false"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal commentaire global -->

  <!-- modal par catégorie -->
  <div
    v-if="isModalOpen"
    :class="`modal ${
      !isModalOpen && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="isModalOpen = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <div class="flex space-x-2 text-2xl font-bold">
            <p>Commentaire pour</p>
            <p class="text-sky-600">{{ infoInModal.data }}</p>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="isModalOpen = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Placeholder -->
        <textarea
          placeholder="Saisissez votre commentaire..."
          class="w-full h-44 p-4 border rounded focus:outline-none focus:ring focus:border-blue-500"
          v-model="tabCP.checkTab[infoInModal.index].note"
        ></textarea>

        <!--Footer-->
        <div class="flex justify-end mt-4">
          <button
            @click="isModalOpen = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-600 hover:text-white hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="saveNote(tabCP.checkTab[infoInModal.index].note), isModalOpen = false"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal par catégorie -->

  <div
    class="text-center text-white text-m md:text-m md:font-light font-light mb px-4 rounded-t bg-bluevk"
  >
    Caractéristiques <span class="font-bold">à cocher</span>
  </div>

  <!-- NOTE -->
  <div class="flex justify-end">
    <button
      @click="openModalNoteGlobal()"
      class="flex items-center justify-center w-7 h-7 text-bluevk mr-1 hover:text-blue-900 focus:outline-none"
    >
      <!-- Icône stylo -->
      <svg
        class="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        />
      </svg>
    </button>
  </div>

  <div class="container mx-auto p-4 bg-white rounded-b-lg">
    <div
      v-if="tabCP.checkTab && tabCP.checkTab.length >= 0"
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4"
    >
      <div
        v-for="(family, indexF) in tabCP.checkTab"
        :key="indexF"
        class="bg-beigevk shadow rounded p-5"
      >
        <div class="text-m font-bold mb-2 text-bluevk flex justify-between">
          <input
            v-if="stateModif[indexF]"
            class="bg-transparent"
            type="text"
            v-model="family.famille"
            @change="save()"
          />
          <div v-else>
            <div
              class="text-m font-bold mb-2 text-bluevk cursor-pointer hover:text-gray-500"
              @click="directionToCatalog(family)"
            >
              {{ family.famille }}
            </div>
          </div>

          <div class="flex flex-row items-center space-x-2">
            <button @click="addSousFamille(indexF)">ajouter</button>
            <div v-if="stateModif[indexF]">
              <button
                class="material-icons-round text-red-500"
                @click="deleteFamille(indexF)"
              >
                delete_forever
              </button>
              <button
                class="material-icons-round text-blue-500"
                @click="stateModif[indexF] = !stateModif[indexF]"
              >
                edit
              </button>
            </div>

            <button
              class="material-icons-round text-blue-500"
              @click="stateModif[indexF] = !stateModif[indexF]"
              v-else
            >
              edit
            </button>
          </div>
        </div>

        <!-- En-tête -->
        <div class="flex justify-between text-xs mb-2 text-orange-500">
          <h1>Titre</h1>
          <div class="flex space-x-8">
            <h1 class="mr-0.5">Niveau</h1>
            <h1>Check</h1>
            <h1>HS</h1>
            <h1>Note</h1>
          </div>
        </div>

        <div
          v-for="(feature, indexSF) in family.data"
          :key="indexSF"
          class="flex flex-row justify-between items-center"
        >
          <!-- Titre -->
          <div class="flex-grow inline-flex">
            <div class="flex flex-row items-center space-x-2">
              <div class="flex flex-col inline-flex">
                <input
                  v-if="stateModif[indexF]"
                  class="bg-transparent text-xs font-medium text-gray-900 text-gray-600"
                  type="text"
                  v-model="feature.nom"
                  @change="save()"
                />
                <label
                  v-else
                  for="red-checkbox"
                  class="ms-2 text-xs font-medium text-gray-900 text-gray-600"
                  >{{ feature.nom }}</label
                >
              </div>

              <button
                @click="delSousFamille(indexF, indexSF)"
                v-show="stateModif[indexF]"
                class="material-icons-round text-red-500 text-sm"
              >
                backspace
              </button>
            </div>
          </div>

          <!-- partie droite -->
          <div class="flex space-x-4">
            <!-- SCORE -->
            <div class="flex space-x-1 flex-row items-center">
              <div
                v-if="feature.rate >= 1"
                @click="modifRate(0, indexF, indexSF)"
                class="w-2 h-2 bg-green-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-else
                @click="modifRate(1, indexF, indexSF)"
                class="w-2 h-2 bg-orange-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-if="feature.rate >= 2"
                @click="modifRate(1, indexF, indexSF)"
                class="w-2 h-2 bg-green-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-else
                @click="modifRate(2, indexF, indexSF)"
                class="w-2 h-2 bg-orange-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-if="feature.rate >= 3"
                @click="modifRate(2, indexF, indexSF)"
                class="w-2 h-2 bg-green-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-else
                @click="modifRate(3, indexF, indexSF)"
                class="w-2 h-2 bg-orange-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-if="feature.rate >= 4"
                @click="modifRate(3, indexF, indexSF)"
                class="w-2 h-2 bg-green-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-else
                @click="modifRate(4, indexF, indexSF)"
                class="w-2 h-2 bg-orange-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-if="feature.rate >= 5"
                @click="modifRate(4, indexF, indexSF)"
                class="w-2 h-2 bg-green-500 rounded-full inline-flex cursor-pointer"
              ></div>
              <div
                v-else
                @click="modifRate(5, indexF, indexSF)"
                class="w-2 h-2 bg-orange-500 rounded-full inline-flex cursor-pointer"
              ></div>
            </div>

            <!-- CHECK -->
            <div class="flex space-x-1 flex-row items-center">
              <label class="relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="feature.checked"
                  value=""
                  class="sr-only peer"
                />
                <div
                  class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
                <span
                  class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                ></span>
              </label>
            </div>

            <!-- CHECK HS -->
            <div class="flex space-x-1 flex-row items-center">
              <label class="relative items-center cursor-pointer">
                <input
                  v-if="feature.checkedHS == true"
                  type="checkbox"
                  v-model="feature.checkedHS"
                  @click="modifRate(1, indexF, indexSF)"
                  value=""
                  class="sr-only peer"
                />
                <input
                  v-else
                  type="checkbox"
                  v-model="feature.checkedHS"
                  @click="
                    modifRate(0, indexF, indexSF), directionToCatalog(family)
                  "
                  value=""
                  class="sr-only peer"
                />
                <div
                  class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
                <span
                  class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                ></span>
              </label>
            </div>

            <!-- NOTE -->
            <div>
              <!-- Bouton icône stylo -->
              <!-- :class="{ 'text-orangevk': tabCP.checkTab[indexSF].note !== '' }" -->
              <button
                @click="openModalNote(indexSF, feature.nom)"
                class="flex items-center justify-center w-7 h-7 text-bluevk mr-1 hover:text-blue-900 focus:outline-none"
              >
                <!-- Icône stylo -->
                <svg
                  class="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-beigevk shadow rounded p-5 cursor-pointer hover:bg-gray-100"
        @click="addFamille()"
      >
        <div class="w-full h-full flex align-center justify-center">
          <div class="my-auto flex flex-col justify-center text-bluevk">
            <span class="material-icons-round mx-auto font-lg"> add </span>
            <span>Ajouter</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {
  getCPbyId,
  saveChange,
} from "@/hooks/controlePreliminaire/controlePreliminaire.ts";

export default {
  name: "verificationVehicule",
  data() {
    return {
      isModalOpenGlobal: false,
      isModalOpen: false,
      stateModif: [],

      infoInModal: {},
      tabCP: {},
    };
  },
  computed: {
    ...mapGetters(["controlePreliminaireSelected"]),
  },
  methods: {
    ...mapActions(["setcatalogueagselected"]),
    save() {
      saveChange(this.tabCP._id, this.tabCP);
    },
    openModalNoteGlobal() {
      this.isModalOpenGlobal = true;
    },
    openModalNote(index, data) {
      console.log(index);
      this.infoInModal.index = index;
      this.infoInModal.data = data;
      this.isModalOpen = true;
    },
    saveNote(data) {
      if (this.tabCP.identification[0].rapport == "") {
        this.tabCP.identification[0].rapport =
          this.tabCP.identification[0].rapport + "- " + data;
      } else {
        this.tabCP.identification[0].rapport =
          this.tabCP.identification[0].rapport + "\n" + "- " + data;
      }
      this.save();
    },
    addFamille() {
      this.tabCP.checkTab.push({
        famille: "Nouvelle famille",
        data: [
          {
            nom: "Nouvelle caractéristique",
            rate: 4,
            note: "",
            stateNote: false,
            checked: true,
          },
        ],
      });
      this.save();
    },
    addSousFamille(indexF) {
      this.tabCP.checkTab[indexF].data.push({
        nom: "Nouvelle caractéristique",
        rate: 2,
        note: "",
        stateNote: false,
        checked: false,
      });
      this.save();
    },
    deleteFamille(indexF) {
      this.tabCP.checkTab.splice(indexF, 1);
      this.save();
    },
    delSousFamille(indexF, indexSF) {
      this.tabCP.checkTab[indexF].data.splice(indexSF, 1);
      this.save();
    },
    // async saveCheckTab() {
    //   const response = await axios.post(
    //     `${process.env.VUE_APP_API}/controlepreliminaire/saveCheckTab`,
    //     {
    //       id: this.cpTab._id,
    //       checkTab: this.tabCP.checkTab,
    //       NotesTravaux: this.tabCP.verification[0].rapport,
    //     }
    //   );
    // },
    async modifRate(rate, indexF, indexSF) {
      console.log(rate);
      this.tabCP.checkTab[indexF].data[indexSF].rate = rate;
      if (rate == 0) {
        this.tabCP.checkTab[indexF].data[indexSF].checkedHS = true;
      } else {
        this.tabCP.checkTab[indexF].data[indexSF].checkedHS = false;
      }
      await this.save();
    },
    async openFreinage() {
      this.setcatalogueagselected({
        assemblyGroupConfiguration: {
          assemblyGroupName: "Dispositif de freinage",
          assemblyGroupNodeId: 100006,
          hasChilds: true,
          named: true,
          newName: "Dispositif de Freinage",
          brand: [
            "MEYLE",
            "SKF",
            "LECOY",
            "CASCO",
            "DA SILVA",
            "PIERBURG",
            "HOFFER",
          ],
          brandSecondary: ["BREMBO", "BOSCH", "NIPPARTS", "MEAT & DORIA"],
          child: [
            {
              assemblyGroupName: "Frein à disque",
              assemblyGroupNodeId: 100626,
              blacklistBrand: [],
              named: true,
              newName: "Disque/Plaquette",
              hasChilds: true,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d64",
            },
            {
              assemblyGroupName: "Frein à tambour",
              assemblyGroupNodeId: 100627,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: true,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d65",
            },
            {
              assemblyGroupName: "Freins de stationnement",
              assemblyGroupNodeId: 100034,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d67",
            },
            {
              assemblyGroupName: "Levier / câble / tirette",
              assemblyGroupNodeId: 100635,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d6a",
            },
            {
              assemblyGroupName: "Flexibles de frein",
              assemblyGroupNodeId: 100035,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d63",
            },
            {
              assemblyGroupName: "Étrier de frein",
              assemblyGroupNodeId: 100027,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: true,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d61",
            },
            {
              assemblyGroupName: "Maître-cylindre de frein",
              assemblyGroupNodeId: 100026,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d6d",
            },
            {
              assemblyGroupName: "Pompe à vide",
              assemblyGroupNodeId: 100039,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d6f",
            },
            {
              assemblyGroupName: "Servofrein",
              assemblyGroupNodeId: 100025,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d72",
            },
            {
              assemblyGroupName: "Liquide de frein",
              assemblyGroupNodeId: 102208,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d6b",
            },
            {
              assemblyGroupName: "Frein de haute performance",
              assemblyGroupNodeId: 102224,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: true,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d66",
            },
            {
              assemblyGroupName: "Réglage du comportement dynamique",
              assemblyGroupNodeId: 100038,
              blacklistBrand: [],
              named: false,
              newName: "",
              hasChilds: false,
              parentNodeId: 100006,
              actif: true,
              _id: "64200f1ef40f4130b0671d70",
            },
          ],
          actif: true,
          _id: "64200f1ef40f4130b0671d5d",
        },
        indexChild: null,
        type: "parent",
        ID: 100006,
        carID: "18780",
      });
      this.$router.push("/catalogueTecDoc");
    },
    async openPneu() {
      this.$router.push("/cataloguedepneus");
    },
    directionToCatalog(family) {
      if (
        family.famille != "Systeme de freinage" &&
        family.famille != "Pneus"
      ) {
        this.$router.push("/catalogueHub");
      } else if (family.famille == "Pneus") {
        this.$router.push("/cataloguedepneus");
      } else {
        this.openFreinage();
      }
    },
  },
  async beforeMount() {
    this.tabCP = await getCPbyId(this.controlePreliminaireSelected);
  },
};
</script>
