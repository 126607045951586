<template>
  <div class="w-5/6 mx-auto">
    <!-- Boutons groupement -->
    <div class="flex justify-between mb-8">
      <div class="flex">
        <button
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 border border-bluevk hover:border-transparent rounded mr-1 w-32 flex justify-center items-center"
          @click="createGroup"
          v-if="
            !plateform.groupement.name &&
            !groupementCreated &&
            !plateform.groupement.name &&
            !groupementAccepted
          "
        >
          <span class="material-icons-outlined text-xl mr-2"> edit </span>
          <span>Créer</span>
        </button>
        <button
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 border border-bluevk hover:border-transparent rounded mr-1 w-32 flex justify-center items-center"
          @click="inviteGroup"
          v-if="plateform.groupement.typeFiliale == 'mere' || groupementCreated"
        >
          <span class="material-icons-outlined text-xl mr-2"> person_add </span>
          <span>Inviter</span>
        </button>
        <button
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 border border-bluevk hover:border-transparent rounded w-32 flex justify-center items-center"
          @click="joinGroup"
          v-if="
            plateform.groupement.typeFiliale == 'fille' ||
            (!groupementCreated && !plateform.groupement.name)
          "
        >
          <span class="material-icons-outlined text-xl mr-2"> how_to_reg </span>
          <span>Rejoindre</span>
        </button>
      </div>
    </div>

    <!-- Créer un groupement -->
    <div
      class="bg-white mb-8 p-8 w-full rounded-2xl shadow-lg"
      v-if="createButtonGroupement && !groupementCreated"
    >
      <div class="w-1/2 mx-auto">
        <label
          class="block tracking-wide text-gray-500 text-base font-semibold mb-4"
        >
          entrez un nom pour votre groupement
        </label>
        <div class="mb-4">
          <div class="flex w-full">
            <input
              class="text-sm text-gray-700 border rounded shadow-md px-4 py-2 leading-tight focus:outline-none w-full"
              type="text"
              placeholder="Corporation A"
              @keyup.enter="createGroupement"
              v-model="newName"
            />
            <button
              class="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded ml-4"
              @click="createGroupement"
              v-if="!groupTab.data"
            >
              valider
            </button>
          </div>
          <div v-if="!isNameValid" class="text-red-500 text-sm ml-4 mt-2">
            Veuillez entrer un nom valide.
          </div>
        </div>
      </div>
    </div>

    <!-- Inviter dans un groupement-->
    <div
      class="bg-white mb-8 p-8 w-full rounded-2xl shadow-lg"
      v-if="inviteButtonGroupement"
    >
      <div class="mb-8 w-1/2 mx-auto">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          @click="genGroupementCode"
        >
          générer un code
        </button>
        <div
          class="bg-gray-200 h-10 px-4 flex items-center shadow-md"
          v-if="getCode"
        >
          <!-- emplacement ou le code sera généré -->
          {{ getCode.code }}
        </div>
      </div>
      <div class="w-1/2 mx-auto">
        <label
          class="block tracking-wide text-gray-500 text-base font-semibold mb-4"
        >
          entrez un email pour l'ajouter à la liste
        </label>
        <div class="mb-12">
          <div class="flex w-full">
            <input
              class="text-sm text-gray-700 border rounded shadow-md px-4 py-2 leading-tight focus:outline-none w-full"
              type="email"
              @keyup.enter="addEmail"
              placeholder="johndoe@gmail.com"
              v-model="newEmail"
            />

            <button
              class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-4"
              @click="addEmail"
            >
              ajouter
            </button>
          </div>
          <div v-if="!isEmailValid" class="text-red-500 text-sm ml-4 my-2">
            Veuillez entrer une adresse email valide.
          </div>
        </div>
        <div class="bg-white shadow-md mb-4 min-h-fit w-full">
          <div class="p-4">
            <ul>
              <li
                v-for="(email, index) in emails"
                :key="index"
                class="flex justify-between items-center"
              >
                <span>{{ email }}</span>
                <span
                  class="material-icons-outlined text-red-500 cursor-pointer"
                  @click="clearEmail(index)"
                >
                  delete
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="mb-12">
          <button
            class="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded ml-4 float-right mb-4"
            @click="sendEmail"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>

    <!-- Rejoindre un groupement -->
    <div
      class="bg-white mb-8 p-8 w-full rounded-2xl shadow-lg"
      v-if="joinButtonGroupement && !groupementAccepted"
    >
      <div class="w-1/2 mx-auto">
        <label
          class="block tracking-wide text-gray-500 text-base font-semibold mb-4"
        >
          entrez le code que vous avez reçu par e-mail pour rejoindre ce
          groupement
        </label>
        <div class="mb-4">
          <div class="flex w-full">
            <input
              class="text-sm text-gray-700 border rounded shadow-md px-4 py-2 leading-tight focus:outline-none w-full"
              type="email"
              placeholder="48465714971981633819"
              v-model="newGroup"
            />
          </div>
          <div v-if="!isGroupValid" class="text-red-500 text-sm ml-4 my-2">
            Le code que vous avez rentré est erroné ou expiré
          </div>
        </div>
        <label
          class="block tracking-wide text-gray-500 text-base font-semibold mb-4"
        >
          entrez le nom du groupement que vous voulez rejoindre
        </label>
        <div class="mb-4">
          <div class="flex w-full">
            <input
              class="text-sm text-gray-700 border rounded shadow-md px-4 py-2 leading-tight focus:outline-none w-full"
              type="email"
              @keyup.enter="addGroup"
              placeholder="Corporation A"
              v-model="joinGroupementByCode"
            />

            <button
              class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-4"
              @click="addGroup"
            >
              valider
            </button>
          </div>
          <div v-if="!isGroupValid" class="text-red-500 text-sm ml-4 my-2">
            Le nom que vous avez rentré est erroné
          </div>
        </div>
      </div>
    </div>

    <!-- div si un groupement existe -->
    <div
      class="bg-white rounded-xl shadow-lg w-full p-4 text-center"
      v-if="groupTab && groupTab.data"
    >
      <div
        class="bg-gray-200 py-4 px-8 border border-gray-300 shadow-md w-fit rounded-xl"
        v-if="groupTab && groupTab.data"
      >
        <span class="text-gray-500">{{ groupTab.data[0].name }}</span>
      </div>
      <div class="flex-col space-y-24">
        <div
          class="flex flex-row items-center space-x-4 p-2 border border-gray-200 shadow-md rounded shadow-lg w-fit mx-auto"
        >
          <div class="h-16 w-28">
            <img :src="groupTab.data[0].mere.lien_logo" />
          </div>
          <div class="flex flex-col space-y-2">
            <div>{{ groupTab.data[0].mere.Name }}</div>
            <div class="flex flex-row items-center space-x-2">
              <div
                class="flex flex-col space-y-1 px-2 py-1 border border-gray-200 rounded"
              >
                <div class="flex flex-row items-center justify-between">
                  <div>dispo</div>
                </div>
                <div
                  class="flex flex-row items-center space-x-1"
                  v-for="(mereDispo, indexMD) in groupTab.data[0].mere
                    .dispoStyle"
                  :key="indexMD"
                >
                  <span
                    title="Sur commande"
                    :class="
                      groupTab.data[0].mere.dispoStyle[indexMD].styleValue
                    "
                    :style="{
                      backgroundColor:
                        groupTab.data[0].mere.dispoStyle[indexMD].bgColor,
                      color:
                        groupTab.data[0].mere.dispoStyle[indexMD].textColor,
                    }"
                  >
                    {{
                      groupTab.data[0].mere.dispoStyle[indexMD].logoName
                    }}</span
                  >
                  <div>
                    <input
                      class="w-6"
                      type="color"
                      v-model="
                        groupTab.data[0].mere.dispoStyle[indexMD].bgColor
                      "
                      @change="savaGroupement()"
                    />
                  </div>
                  <div>
                    <input
                      class="w-6"
                      type="color"
                      v-model="
                        groupTab.data[0].mere.dispoStyle[indexMD].textColor
                      "
                      @change="savaGroupement()"
                    />
                  </div>
                  <div>{{ mereDispo.name }}</div>
                </div>
              </div>
              <div
                class="flex flex-col space-y-1 px-2 py-1 border border-gray-200 rounded"
              >
                <div>coef</div>
                <div
                  v-for="(mereCoef, indexMC) in groupTab.data[0].mere.coef"
                  :key="indexMC"
                  class="flex flex-row items-center space-x-2"
                >
                  <div>
                    <input
                      type="Number"
                      v-model="groupTab.data[0].mere.coef[indexMC].value"
                      class="w-12"
                      @change="savaGroupement()"
                    />
                  </div>
                  <div>{{ mereCoef.name }}</div>
                </div>
              </div>
              <div
                class="flex flex-col space-y-1 px-2 py-1 border border-gray-200 rounded"
              >
                <div class="flex flex-row items-center justify-between">
                  <div>liens</div>
                </div>
                <div v-if="groupTab.data && groupTab.data.length > 0">
                  <div
                    class="flex flex-row items-center space-x-1"
                    v-for="(mereLinkage, indexMD) in groupTab.data[0].mere
                      .linkage"
                    :key="indexMD"
                  >
                    <div>
                      <span
                        v-if="mereLinkage.linked"
                        class="material-icons-round text-green-500"
                      >
                        local_shipping
                      </span>
                      <span v-else class="material-icons-round text-gray-500">
                        receipt_long
                      </span>
                    </div>

                    <div>{{ mereLinkage.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid gap-4 grid-cols-2">
          <div
            class="flex flex-row items-center space-x-4 p-2 border border-gray-200 shadow-md rounded shadow-lg w-fit mx-auto"
            v-for="(fille, indexFille) in groupTab.data[0].fille"
            :key="indexFille"
          >
            <div class="h-16 w-28">
              <img :src="fille.lien_logo" />
            </div>
            <div class="flex flex-col space-y-2">
              <div>{{ fille.Name }}</div>
              <div class="flex flex-row items-center space-x-2">
                <div
                  class="flex flex-col space-y-1 px-2 py-1 border border-gray-200 rounded"
                >
                  <div class="flex flex-row items-center justify-between">
                    <div>dispo</div>
                  </div>
                  <div
                    class="flex flex-row items-center space-x-1"
                    v-for="(style, indexStyle) in fille.dispoStyle"
                    :key="indexStyle"
                  >
                    <span
                      title="Sur commande"
                      :class="style.styleValue"
                      :style="{
                        backgroundColor: style.bgColor,
                        color: style.textColor,
                      }"
                    >
                      {{ style.logoName }}</span
                    >
                    <div>
                      <input
                        class="w-6"
                        type="color"
                        v-model="
                          groupTab.data[0].fille[indexFille].dispoStyle[
                            indexStyle
                          ].bgColor
                        "
                        @change="savaGroupement()"
                      />
                    </div>
                    <div>
                      <input
                        class="w-6"
                        type="color"
                        v-model="
                          groupTab.data[0].fille[indexFille].dispoStyle[
                            indexStyle
                          ].textColor
                        "
                        @change="savaGroupement()"
                      />
                    </div>
                    <div>{{ style.name }}</div>
                  </div>
                </div>
                <div
                  class="flex flex-col space-y-1 px-2 py-1 border border-gray-200 rounded"
                >
                  <div>coef</div>
                  <div
                    v-for="(coef, indexCoef) in fille.coef"
                    :key="indexCoef"
                    class="flex flex-row items-center space-x-2"
                  >
                    <div>
                      <input
                        type="Number"
                        v-model="
                          groupTab.data[0].fille[indexFille].coef[indexCoef]
                            .value
                        "
                        class="w-12"
                        @change="savaGroupement()"
                      />
                    </div>
                    <div>{{ coef.name }}</div>
                  </div>
                </div>
                <div
                  class="flex flex-col space-y-1 px-2 py-1 border border-gray-200 rounded"
                >
                  <div class="flex flex-row items-center justify-between">
                    <div>liens</div>
                  </div>
                  <div v-if="groupTab.data && groupTab.data.length > 0">
                    <div
                      class="flex flex-row items-center space-x-1"
                      v-for="(linkage, indexLinkage) in fille.linkage"
                      :key="indexLinkage"
                    >
                      <div>
                        <span
                          v-if="linkage.linked"
                          class="material-icons-round text-green-500"
                        >
                          local_shipping
                        </span>
                        <span v-else class="material-icons-round text-gray-500">
                          receipt_long
                        </span>
                      </div>

                      <div>{{ linkage.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="bg-red-600 text-white hover:bg-red-800 border border-red-800 shadow-md font-semibold hover:text-white py-1 border border-bluevk hover:border-transparent rounded mt-8 w-32 flex justify-center items-center float-right"
        @click="deleteGroupement"
        v-if="plateform.groupement.typeFiliale == 'mere' || groupementCreated"
      >
        <span class="material-icons-outlined text-xl mr-2"> remove </span>
        <span>Supprimer</span>
      </button>
    </div>

    <!-- div si aucun groupement n'existe-->
    <div class="bg-white rounded-2xl shadow-lg w-full py-32 text-center" v-else>
      <span
        >Vous n'avez pour l'instant aucun groupement ou aucune filiale...
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  name: "OrganisationsPage",
  data() {
    return {
      groupement: false,
      createButtonGroupement: false,
      inviteButtonGroupement: false,
      joinButtonGroupement: false,
      newEmail: "",
      newName: "",
      nameTab: {},
      emails: [],
      newGroup: "",
      emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      isEmailValid: true,
      isGroupValid: true,
      isNameValid: true,
      groupTab: [],
      getCode: [],
      joinGroupementByCode: "",
      groupementCreated: false,
      groupementAccepted: false,
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    createGroup() {
      this.createButtonGroupement = true;
      this.joinButtonGroupement = false;
      this.inviteButtonGroupement = false;
    },
    inviteGroup() {
      this.inviteButtonGroupement = true;
      this.joinButtonGroupement = false;
      this.createButtonGroupement = false;
    },
    joinGroup() {
      this.joinButtonGroupement = true;
      this.createButtonGroupement = false;
      this.inviteButtonGroupement = false;
    },
    // logPlateform() {
    //   const plateform = this.plateform;
    //   console.log("Plateforme : ", plateform);
    // },
    createGroupement() {
      if (this.newName.trim().length > 0) {
        this.groupement = true;
        axios
          .post(`${process.env.VUE_APP_API}/plateform/createGroupement`, {
            plateform: this.user.proprietaire,
            name: this.newName,
          })
          .then(() => {
            this.groupementCreated = true;
            this.getGroupement();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.isNameValid = false;
      }
    },
    getGroupement() {
      axios
        .get(
          `${process.env.VUE_APP_API}/plateform/getGroupement/${this.user.proprietaire}`
        )
        .then((res) => {
          if (!res.data.error) {
            this.groupTab = res.data;
          } else {
            console.log("aucun groupement trouvé");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addGroup() {
      axios
        .post(`${process.env.VUE_APP_API}/plateform/verifGroupementCode`, {
          plateform: this.user.proprietaire,
          name: this.joinGroupementByCode,
          code: this.newGroup,
        })
        .then(() => {
          (this.groupementAccepted = true), this.getGroupement();
          this.toast.success("groupement rejoint !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.groupement = true;
          this.isGroupValid = true;
        });
    },
    genGroupementCode() {
      axios
        .post(`${process.env.VUE_APP_API}/plateform/genGroupementCode`, {
          plateform: this.user.proprietaire,
          name: this.groupTab.data[0].name,
        })
        .then((res) => {
          this.getGroupementCode();
        });
    },
    getGroupementCode() {
      axios
        .post(`${process.env.VUE_APP_API}/plateform/getGroupementCode`, {
          plateform: this.user.proprietaire,
          name: this.groupTab.data[0].name,
        })
        .then((res) => {
          console.log("code");
          console.log(res.data);
          this.getCode = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendEmail() {
      axios
        .post(`${process.env.VUE_APP_API}/plateform/sendGroupementCode`, {
          plateform: this.user.proprietaire,
          name: this.groupTab.data[0].name,
          email: this.emails,
          from: ["wanis.mahjor@vekteur.fr"],
        })
        .then((res) => {
          console.log(res.data);
          if (this.getCode && this.emails.length > 0) {
            this.toast.success("Email(s) envoyé(s) !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.getCode = "";
            this.emails = [];
          } else {
            alert("veuillez renseigner les champs manquants");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteGroupement() {
      axios
        .delete(
          `${process.env.VUE_APP_API}/plateform/deleteGroupement/${this.groupTab.data[0]._id}`
        )
        .then((res) => {
          console.log(res.data);
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addEmail() {
      if (this.emailPattern.test(this.newEmail)) {
        this.emails.push(this.newEmail);
        this.newEmail = "";
        this.isEmailValid = true;
      } else {
        this.isEmailValid = false;
      }
    },
    clearEmail(index) {
      this.emails.splice(index, 1);
    },
    savaGroupement() {
      axios.post(`${process.env.VUE_APP_API}/plateform/updateGroupement`, {
        id: this.groupTab.data[0]._id,
        data: this.groupTab.data[0],
      });
    },
  },
  mounted() {
    this.getGroupement();
    // this.logPlateform();
  },
};
</script>

<style scoped></style>
