import axios from "axios";

export async function validRecep(
  plateform: string,
  id: string,
  receptionneur: string,
  Articles: Array<object>,
) {
  const validR = await axios.post(
    `${process.env.VUE_APP_API}/commandeFournisseur/validRecep`,
    {
      plateform: plateform,
      id: id,
      receptionneur: receptionneur,
      Articles: Articles,
    }
  );

  return validR.data;
}
