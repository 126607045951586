<template>
  <div
    v-if="openInfoVehicule"
    :class="`modal ${
      !openInfoVehicule && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openInfoVehicule = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      v-if="vehiculeSet"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 50rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-3">
          <div class="flex">
            <div class="flex flex-col">
              <p class="text-2xl font-bold">Informations pour:</p>
              <p class="text-2xl font-bold uppercase text-left ml-4">
                {{ vehicule[0].data.AWN_immat }}
              </p>
            </div>
            <div>
              <p class="text-2xl ml-44">
                {{ vehicule[0].data.AWN_marque_carrosserie }}
              </p>
              <div class="text-right">
                <p class="text-2xl font-bold uppercase ml-4">
                  {{ vehicule[0].data.AWN_modele_etude }}
                </p>
                <p class="text-2xl font-bold uppercase ml-4">
                  {{ vehicule[0].data.AWN_version }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openInfoVehicule = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div v-if="vehiculeSet" class="mt-4">
          <infosVehicule :vehicule="vehicule" />
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="openInfoVehicule = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div @change="save()">
    <div class="flex space-x-4 p-4 bg-bluevk">
      <!-- gauche -->
      <div class="flex flex-col w-full space-y-3">
        <div class="border bg-white shadow-lg rounded-md h-fit p-2 w-full">
          <div class="flex space-x-4">
            <h4 class="w-44 text-bluevk">Clients</h4>
            <img src="@/assets/ControlePreliminaire/VEKTEUR.png" class="w-32" />
            <div
              class="flex flex-col my-auto"
              v-if="tabCP.Client && tabCP.Client.length >= 0"
            >
              <h4 class="font-bold uppercase">
                {{ tabCP.Client[0].Name }}
              </h4>
              <h4>{{ tabCP.Client[0].Adresse }}</h4>
              <h4>{{ tabCP.Client[0].Complement }}</h4>
              <h4>
                {{ tabCP.Client[0].PostalCode }}
                {{ tabCP.Client[0].City }},
                {{ tabCP.Client[0].Country }}
              </h4>
            </div>
          </div>
          <div class="relative w-56 ml-auto">
            <input
              type="text"
              id="parking"
              v-model="tabCP.parking"
              class="block px-2.5 pb-1 pt-2 w-full text-sm text-sky-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
            />
            <label
              for="parking"
              class="absolute text-sm text-sky-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Parking</label
            >
          </div>
        </div>

        <!-- Travaux -->
        <div
          v-if="tabCP.identification && tabCP.identification.length >= 0"
          class="border bg-white shadow-lg rounded-md flex space-x-4 h-36 p-2 w-full"
        >
          <h4 class="w-44 text-bluevk">Travaux à effectuer</h4>
          <textarea
            class="border shadow-lg rounded-md outline-2 focus:outline-sky-300 p-1 text-sm w-full"
            v-model="tabCP.identification[0].travaux"
          ></textarea>
        </div>

        <!-- Notes -->
        <div
          v-if="tabCP.identification && tabCP.identification.length >= 0"
          class="border bg-white shadow-lg rounded-md flex space-x-4 h-36 p-2 w-full"
        >
          <h4 class="w-44 text-bluevk">Notes du chef d'atelier</h4>
          <textarea
            class="border shadow-lg rounded-md outline-2 focus:outline-sky-300 p-1 text-sm w-full"
            v-model="tabCP.identification[0].notes"
          ></textarea>
        </div>

        <!-- Rapport -->
        <div
          v-if="tabCP.identification && tabCP.identification.length >= 0"
          class="border bg-white shadow-lg rounded-md flex space-x-4 h-36 p-2 w-full"
        >
          <h4 class="w-44 text-bluevk">Rapport du technicien</h4>
          <textarea
            class="border shadow-lg rounded-md outline-2 focus:outline-sky-300 p-1 text-sm w-full"
            v-model="tabCP.identification[0].rapport"
          ></textarea>
        </div>
      </div>

      <!-- droite -->
      <div class="w-full">
        <div
          class="border bg-white shadow-lg rounded-md flex space-x-4 p-2 w-full h-full"
        >
          <div class="flex flex-col w-full">
            <h4 class="w-44 text-bluevk">Détails de la voiture</h4>
            <button
              class="text-right material-icons-round text-bluevk hover:opacity-70"
              style="font-size: 30px"
              @click="openInfoVehicule = true"
            >
              info
            </button>
            <div
              v-if="tabCP.vehiculeData && tabCP.vehiculeData.length >= 0"
              class="flex bg-white p-4 rounded shadow-md"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0 mr-4">
                  <img
                    src="@/assets/ControlePreliminaire/car.jpeg"
                    alt="Car Image"
                    class="h-20 object-cover rounded-full"
                  />
                </div>
                <div v-if="tabCP.vehiculeData[0].data" class="ml-12">
                  <h3 class="text-lg font-semibold uppercase">
                    {{ tabCP.vehiculeData[0].data.AWN_immat }}
                  </h3>
                  <p class="text-gray-500" title="VIN">
                    {{ tabCP.vehiculeData[0].data.AWN_VIN }}
                  </p>
                  <p class="text-gray-400" title="Type mine">
                    {{ tabCP.vehiculeData[0].data.AWN_type_mine }}
                  </p>
                  <h4>{{ tabCP.vehiculeData[0].data.AWN_marque }}</h4>
                  <h4>
                    {{ tabCP.vehiculeData[0].data.AWN_modele }}
                    {{ tabCP.vehiculeData[0].data.AWN_version }}
                  </h4>
                  <h4 title="Mise en circulation">
                    {{
                      tabCP.vehiculeData[0].data.AWN_date_mise_en_circulation
                    }}
                  </h4>
                </div>
                <div class="ml-12">
                  <h4>{{ tabCP.vehiculeData[0].data.AWN_energie }}</h4>
                  <h4>{{ tabCP.vehiculeData[0].data.AWN_nbr_vitesses }} Cv</h4>
                  <h4>{{ tabCP.vehiculeData[0].data.AWN_puissance_KW }} kW</h4>
                  <h4>
                    {{ tabCP.vehiculeData[0].data.AWN_nbr_portes }} portes
                  </h4>
                  <h4>{{ tabCP.vehiculeData[0].data.AWN_couleur }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import infosVehicule from "@/components/vehicule/infosVehicule.vue";
import {
  getCPbyId,
  saveChange,
} from "@/hooks/controlePreliminaire/controlePreliminaire.ts";

export default {
  name: "identificationVehicule",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openInfoVehicule: false,
      tabCP: {},
    };
  },
  components: {
    infosVehicule,
  },
  computed: {
    ...mapGetters([
      "controlePreliminaireSelected",
      "vehicule",
      "vehiculeSet",
      "comptoirSessionActive",
    ]),
  },
  methods: {
    // getInfoCP() {
    //   axios
    //     .post(
    //       `${process.env.VUE_APP_API}/controlepreliminaire/${this.cpTab._id}`
    //     )
    //     .then((response) => {
    //       this.tabCP = response.data;
    //     });
    // },
    save() {
      saveChange(this.tabCP._id, this.tabCP);
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  async beforeMount() {
    this.tabCP = await getCPbyId(this.controlePreliminaireSelected);
  },
};
</script>
