<template>
  <div class="bg-white w-full lg:p-4 xl:p-4 md:p-4 sm:p-4 rounded-sm shadow">
    <!-- Add margin to the outer div for sm and md screens -->
    <div class="p-8 w-full">
      <!-- En-tête -->
      <div class="w-full">
        <div
          class="flex flex-col items-center w-full md:flex-row sm:flex-row lg:pb-10 xl:pb-10 md:pb-10 sm:pb-5"
        >
          <div class="w-1/2 sm:w-1/4">
            <img
              :src="plateform.lien_logo"
              alt="Logo de l'entreprise"
              class="w-1/2 h-auto mx-auto"
            />
          </div>
        </div>

        <!-- "Bon de livraison" sous l'image en md et sm -->
        <div class="mt-4 md:mt-0 sm:mt-0 text-center">
          <div
            class="text-gray-700 uppercase font-medium text-xl sm:text-s max-w-3/4 mx-auto"
          >
            Devis
            <span class="text-bluevk">n° {{ devisSelected.Numero }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-end text-gray-700 text-xs font-medium pt-8">
        Le {{ getFRDate(devisSelected.Date) }}
      </div>

      <!-- Adresses -->
      <div class="sm:flex sm:justify-between sm:pt-4 sm:pb-8">
        <div class="sm:w-2/4 md:mr-3 bg-white rounded-sm shadow-md">
          <div
            class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
          >
            Expéditeur
          </div>
          <div class="md:flex justify-between">
            <!-- Première partie -->
            <div class="flex-1 md:w-1/2 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ plateform.Titre2 }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ plateform.Adresse }} <br />
                {{ plateform.Code_postale }}
                <span class="uppercase">{{ plateform.Ville }}</span
                ><br />
                {{ plateform.Pays }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs font-bold text-gray-700">
                {{ plateform.Titre1 }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ plateform.Email }}<br />
                {{ plateform.Telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ plateform.Siret }} - {{ plateform.Tva }} -
                {{ plateform.Naf }}
              </div>
            </div>
          </div>
        </div>
        <!-- encart destinataire -->
        <div class="sm:w-2/4 md:ml-3 bg-white rounded-sm shadow-md">
          <div
            class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
          >
            Destinataire
          </div>
          <div
            class="md:flex justify-between"
            v-if="devisSelected.clientInfo[0]"
          >
            <!-- Première partie -->
            <div class="flex-1 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ devisSelected.Client }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ devisSelected.clientInfo[0].Adresse }}<br />
                {{ devisSelected.clientInfo[0].PostalCode }}
                <span class="uppercase">{{
                  devisSelected.clientInfo[0].City
                }}</span
                ><br />
                {{ devisSelected.clientInfo[0].Country }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs text-gray-700 font-bold">
                {{ devisSelected.Name }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ devisSelected.clientInfo[0].email }}<br />
                {{ devisSelected.clientInfo[0].telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ devisSelected.clientInfo[0].siret }} -
                {{ devisSelected.clientInfo[0].numerotva }} -
                {{ devisSelected.clientInfo[0].nafape }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="md:mt-8">
          <div class="text-xs font-bold text-gray-700">
            Servi par : {{ devisSelected.Name }}
          </div>
          <div class="text-xs italic font-medium text-bluevk ml-auto">
            Commentaire : {{ devisSelected.Note }}
          </div>
        </div>
        <!-- tableau responsive -->
        <div class="overflow-x-auto">
          <table
            class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
          >
            <!-- Table headers -->
            <thead>
              <tr class="bg-bluevk text-white text-left px-3 rounded-t-sm">
                <th class="px-2 py-1 text-xs font-medium">Qté</th>
                <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                <th
                  class="px-2 py-1 text-xs font-medium md:block hidden"
                  v-if="user.accountType == 'Administrateur'"
                >
                  Marge
                </th>
                <th class="px-2 py-1 text-xs font-medium">Prix brut HT</th>
                <th class="px-2 py-1 text-xs font-medium">Remise</th>
                <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
              </tr>
            </thead>

            <!-- Table body -->
            <tbody class="text-xs font-medium">
              <!-- Rows -->
              <tr
                v-for="(article, index) in devisSelected.pdfData"
                :key="index"
              >
                <td class="border border-gray-300 p-2">
                  {{ article.quantite }}
                </td>
                <td class="border border-gray-300 p-2">{{ article.ref }}</td>
                <td class="border border-gray-300 p-2">
                  {{ article.description }}
                </td>
                <td
                  class="border border-gray-300 p-2 md:block hidden"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{}}
                </td>
                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(devisSelected.Articles[index].Prix_euro).toFixed(
                      2
                    )
                  }}€
                </td>
                <td class="border border-gray-300 p-2">
                  {{ article.remise }}%
                </td>
                <td class="border border-gray-300 p-2">
                  <div
                    v-if="
                      devisSelected.Articles[index].PrixFournisseurType !=
                      'prixnet'
                    "
                  >
                    {{
                      parseFloat(
                        devisSelected.Articles[index].Prix_euro -
                          devisSelected.Articles[index].Prix_euro *
                            (article.remise / 100)
                      ).toFixed(2)
                    }}
                  </div>
                  <div v-else>
                    {{
                      parseFloat(
                        devisSelected.Articles[index].Prix_vente
                      ).toFixed(2)
                    }}
                  </div>
                </td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(article.total).toFixed(2) }}€
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-end items-center pb-8 text-gray-700">
        <div class="w-1/3 flex justify-end">
          <table
            class="mt-4 mb-8 w-full rounded border-collapse border border-gray-300"
          >
            <thead></thead>
            <tbody class="text-xs font-medium">
              <!-- Première ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Transport</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(devisSelected.Transport).toFixed(2) }}€
                </td>
              </tr>

              <!-- Deuxième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total HT</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(devisSelected.TotalPrice).toFixed(2) }}€
                </td>
              </tr>

              <!-- Troisième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">TVA</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(devisSelected.TotalPrice * 0.2).toFixed(2) }}€
                </td>
              </tr>

              <!-- Quatrième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total TTC</td>
                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(
                      devisSelected.TotalPrice + devisSelected.TotalPrice * 0.2
                    ).toFixed(2)
                  }}€
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex justify-center">
        <button
          @click="imprimerBonDeLivraison"
          class="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded"
        >
          Imprimer
        </button>
      </div>
    </div>
    <div v-if="bonDeLivraisonImprime" class="mt-4">
      <h2 class="text-xl font-semibold mb-2">Bon de Livraison</h2>
      <p><strong>Numéro de commande :</strong> {{ numeroCommande }}</p>
      <p><strong>Nom du client :</strong> {{ nomClient }}</p>
      <p><strong>Articles :</strong></p>
      <pre>{{ articles }}</pre>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  computed: {
    ...mapGetters(["user", "plateform", "devisSelected"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
  },
};
</script>
