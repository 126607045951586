<template>
  <!-- modal ajout client -->
  <div
    v-if="showClientPar"
    :class="`modal ${
      !showClientPar && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showClientPar = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 52rem; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Ajouter un client particulier</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showClientPar = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <transition>
          <div
            class="bloc-couleur couleur-remplissage"
            id="informationsgenerales"
          >
            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Professionnel ?</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      disabled
                      type="radio"
                      v-model="professionnelParticulier"
                      value="true"
                      data-val="true"
                      data-val-required="Ce champ est obligatoire."
                      id="professionnelParticulier"
                      name="professionnelParticulier"
                      data-enable="True"
                      data-for-field="professionnelParticulier"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      disabled
                      type="radio"
                      v-model="professionnelParticulier"
                      checked
                      value="false"
                      id="professionnelParticulier"
                      name="professionnelParticulier"
                      data-enable="false"
                      data-for-field="professionnelParticulier"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Groupe de client</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <select
                    v-model="groupeClient"
                    class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                  >
                    <option
                      :value="cond.Name"
                      v-for="(cond, indexC) in configurationConditionList"
                      :key="indexC"
                    >
                      {{ cond.Name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Devis </label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="devisRadio"
                      id="devisRadio"
                      name="devisRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="devisRadio"
                      id="devisRadio"
                      name="devisRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Bon de commande</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="commandeRadio"
                      id="commandeRadio"
                      name="commandeRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="commandeRadio"
                      id="commandeRadio"
                      name="commandeRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Bon de livraison</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonRadio"
                      id="livraisonRadio"
                      name="livraisonRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonRadio"
                      id="livraisonRadio"
                      name="livraisonRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Bon de livraison avoir</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonAvoirRadio"
                      id="livraisonAvoirRadio"
                      name="livraisonAvoirRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonAvoirRadio"
                      id="livraisonAvoirRadio"
                      name="livraisonAvoirRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Facture</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="factureRadio"
                      id="factureRadio"
                      name="factureRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="factureRadio"
                      id="factureRadio"
                      name="factureRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Retour</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="retourRadio"
                      id="retourRadio"
                      name="retourRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="retourRadio"
                      id="retourRadio"
                      name="retourRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-4">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Marge minimum (en %)</label>
                <input
                  v-model.trim="min_marge"
                  type="Number"
                  ref="min_marge"
                  @keydown.enter="this.$refs.min_coef.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Coef minimum (en Decimal)</label>
                <input
                  v-model.trim="min_coef"
                  type="Float"
                  ref="min_coef"
                  @keydown.enter="this.$refs.nomParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b"></div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b"></div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Nom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  v-model="nomParticulier"
                  type="text"
                  ref="nomParticulier"
                  @keydown.enter="this.$refs.adresseParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="adresseParticulier"
                  ref="adresseParticulier"
                  @keydown.enter="this.$refs.complementParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Complément d'adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="complementParticulier"
                  ref="complementParticulier"
                  @keydown.enter="this.$refs.codepostalParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Code Postal</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="codepostalParticulier"
                    ref="codepostalParticulier"
                    @keydown.enter="this.$refs.villeParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Ville</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="villeParticulier"
                    ref="villeParticulier"
                    @keydown.enter="this.$refs.paysParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label for="paysParticulier" class="text-xs">Pays</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <select
                    name="paysParticulier"
                    v-model="paysParticulier"
                    ref="paysParticulier"
                    @keydown.enter="this.$refs.telephoneParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                  >
                    <option value="ALLEMAGNE">ALLEMAGNE</option>
                    <option value="AUTRICHE">AUTRICHE</option>
                    <option value="BELGIQUE">BELGIQUE</option>
                    <option value="ESPAGNE">ESPAGNE</option>
                    <option selected value="FRANCE">FRANCE</option>
                    <option value="ITALIE">ITALIE</option>
                    <option value="LUXEMBOURG">LUXEMBOURG</option>
                    <option value="MAROC">MAROC</option>
                    <option value="PAYS-BAS">PAYS-BAS</option>
                    <option value="SUISSE">SUISSE</option>
                    <option value="TURQUIE">TURQUIE</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Téléphone</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="tel"
                    v-model="telephoneParticulier"
                    ref="telephoneParticulier"
                    @keydown.enter="this.$refs.emailParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Email</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="emailParticulier"
                    v-model="emailParticulier"
                    ref="emailParticulier"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!--Footer-->
        <div class="flex justify-end mt-4">
          <button
            @click="showClientPar = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-blue-600 hover:text-white hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="createClient()"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Ajouter et Sélectionner
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal ajout client -->

  <div
    v-if="openModal"
    :class="`modal ${
      !openModal && 'opacity-0 pointer-events-none'
    } z-40 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="$emit('closeModalSelectClientSession', 'close')"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 50rem; height: 26rem"
      class="z-40 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="$emit('closeModalSelectClientSession', 'close')"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Sélectionner un client</p>
          <div
            class="z-40 cursor-pointer modal-close"
            @click="$emit('closeModalSelectClientSession', 'close')"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="relative" style="height: 17rem">
          <div class="flex flex-col">
            <div v-if="clientChoosed" class="flex space-x-4 border shadow">
              <p class="my-auto">Client sélectionné :</p>
              <div class="flex justify-between">
                <div>
                  <p class="font-bold">{{ clientChoosed.Name }}</p>
                  <p class="text-xs">
                    {{
                      clientChoosed.NumeroClient
                        ? clientChoosed.NumeroClient + " - "
                        : ""
                    }}
                    {{ clientChoosed.Adresse ? clientChoosed.Adresse : "" }}
                    {{
                      clientChoosed.Complement
                        ? ", " + clientChoosed.Complement
                        : ""
                    }}
                    {{
                      clientChoosed.PostalCode
                        ? ", " + clientChoosed.PostalCode
                        : ""
                    }}
                    {{ clientChoosed.City ? ", " + clientChoosed.City : "" }}
                  </p>
                </div>
                <button
                  title="Supprimer"
                  style="font-size: 18px"
                  @click="clientChoosed = null"
                  class="material-icons-outlined my-auto rounded-full bg-white hover:bg-gray-300 text-red-700 border hover:from-transparent"
                >
                  cancel
                </button>
              </div>
            </div>
            <input
              @input="searchClientFav(inputClient, false)"
              @focus="inputClient = ''"
              @keydown.enter="searchClient()"
              v-model="inputClient"
              placeholder="Modifier la session"
              class="mt-1 border-2 px-1 py-0.5 border-gray-500 w-full text-black rounded-md focus:placeholder-gray-600 focus:text-gray-700 focus:outline-2 outline-sky-300"
            />
          </div>
          <div
            v-if="Clients.length > 0"
            class="mt-2 bg-gray-50 text-gray-800 rounded-sm p-1 w-full flex flex-col h-48 max-h-48 border rounded-md shadow overflow-y-auto"
          >
            <div
              class="cursor-pointer rounded-sm hover:bg-gray-200"
              @click="(clientChoosed = cl), (Clients = [])"
              v-for="(cl, indexCL) in Clients"
              :key="indexCL"
            >
              <p class="font-bold">{{ cl.Name }}</p>
              <p class="text-xs">
                {{ cl.NumeroClient ? cl.NumeroClient + " - " : "" }}
                {{ cl.Adresse ? cl.Adresse : "" }}
                {{ cl.Complement ? ", " + cl.Complement : "" }}
                {{ cl.PostalCode ? ", " + cl.PostalCode : "" }}
                {{ cl.City ? ", " + cl.City : "" }}
              </p>
            </div>
          </div>

          <div
            v-else
            class="bg-blue-100 z-40 bg-white text-gray-800 rounded-sm p-1 w-full flex flex-col h-48 overflow-y-auto"
          ></div>
        </div>

        <!--Footer-->
        <div class="bottom-0 flex justify-end mt-4">
          <button
            @click="$emit('closeModalSelectClientSession', 'close')"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-blue-600 hover:text-white hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="updateSessionClient(clientChoosed)"
            :disabled="loadingSelection"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            <PulseLoader
              v-if="loadingSelection"
              color="#ffffff"
              class="my-auto"
            />
            <p v-else>Sélectionner</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openModal: true,
      inputClient: "",
      Clients: [],
      clientChoosed: null,

      showClientPar: false,
      devisRadio: true,
      commandeRadio: true,
      livraisonRadio: false,
      livraisonAvoirRadio: false,
      factureRadio: true,
      retourRadio: false,
      telephoneParticulier: "",
      emailParticulier: "",
      paysParticulier: "FRANCE",
      villeParticulier: "",
      codepostalParticulier: "",
      complementParticulier: "",
      adresseParticulier: "",
      groupeClient: "",
      nomParticulier: "",
      professionnelParticulier: false,

      configurationConditionList: [],

      loadingSelection: false,
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters([
      "clientFocus",
      "comptoirSession",
      "actionArticle",
      "user",
      "comptoirSessionActive",
      "refClient",
      "dataConsigne",
      "dataNonConsigne",
      "dsvCheck",
      "plateform",
      "Ref_fournisseur_search",
    ]),
  },
  methods: {
    ...mapActions([
      "updateactionArticle",
      "getallcomptoirsession",
      "createcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
      "savecomptoirsession",
      "setrefclient",
      "getclientchoosedtab",
      "updaterefclient",
      "setdsvcheck",
      "updateactionarticlesession",
      "sendToRestoreTransportCart",
      "searchRefFournisseur",
    ]),
    getFavori(cdtnCl) {
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: cdtnCl,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    searchClientFav(searchTerm, pro) {
      axios
        .post(`${process.env.VUE_APP_API}/client/searchClientFav`, {
          plateform: this.user.proprietaire,
          searchTerm: searchTerm,
          pro: pro,
          archived: false,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    async updateSessionClient(clientChoosed) {
      if (clientChoosed) {
        this.loadingSelection = true;
        let response = await axios.post(
          `${process.env.VUE_APP_API}/client/getByID`,
          {
            _id: clientChoosed._id,
          }
        );

        if (response.data) {
          await this.savecomptoirsession({
            clientFocus: response.data,
            refClient: this.comptoirSessionActive[0].refClient,
            consigneData: this.comptoirSessionActive[0].consigneData,
            consigneNonData: this.comptoirSessionActive[0].consigneNonData,
            dsvCheck: this.comptoirSessionActive[0].dsvCheck,
          });
          await this.updateactionarticlesession({
            Name: response.data.Name,
            Group: response.data.Group.Name,
            _id: response.data._id,
            TransportCart: true,
          });

          if (
            this.comptoirSessionActive[0]?.documentType == "Bon de commande" ||
            this.comptoirSessionActive[0]?.documentType == "Facturation"
          ) {
            this.$emit("closeModalSelectClientSession", 'encaissement');
          } else {
            this.$emit("closeModalSelectClientSession", 'close');
          }
        }
        this.loadingSelection = false;
      } else {
        this.toast.warning("Aucun client sélectionné !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async searchClient() {
      if (this.Clients.length > 0) {
        this.inputClient = this.Clients[0].Name;
      } else {
        this.nomParticulier = this.inputClient;
        await this.getConfigurationCondition();
        this.showClientPar = true;
        setTimeout(() => {
          this.$refs.nomParticulier.focus();
        }, 0);
        this.inputClient = "";
      }
    },
    async createClient() {
      await axios
        .post(`${process.env.VUE_APP_API}/client`, {
          professionnel: false,
          plateform: this.user.proprietaire,
          Name: this.nomParticulier,
          Group: {
            Name: this.groupeClient,
          },
          Adresse: this.adresseParticulier,
          Complement: this.complementParticulier,
          PostalCode: this.codepostalParticulier,
          City: this.villeParticulier,
          Country: this.paysParticulier,
          email: this.emailParticulier,
          telephone: this.telephoneParticulier,
          archived: false,
          counter: {
            devis: this.devisRadio,
            bondecommande: this.commandeRadio,
            bondelivraison: this.livraisonRadio,
            bondelivraisonavoir: this.livraisonAvoirRadio,
            facture: this.factureRadio,
            retour: this.retourRadio,
          },
        })
        .then(async (res) => {
          console.log("response: ", res);

          this.clientChoosed = res.data;
          this.showClientPar = false;

          this.toast.success("Client ajouté !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          await this.updateSessionClient(this.clientChoosed);

          this.telephoneParticulier = "";
          this.emailParticulier = "";
          this.paysParticulier = "FRANCE";
          this.villeParticulier = "";
          this.codepostalParticulier = "";
          this.complementParticulier = "";
          this.adresseParticulier = "";
          this.groupeClient = "";
          this.nomParticulier = "";
          this.professionnelParticulier = false;
          this.devisRadio = true;
          this.commandeRadio = true;
          this.livraisonRadio = false;
          this.livraisonAvoirRadio = false;
          this.factureRadio = true;
          this.retourRadio = false;
        });
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;

      if (this.comptoirSessionActive[0]?.Client[0]?.Group.Name) {
        this.groupeClient = this.comptoirSessionActive[0].Client[0].Group.Name;
      } else {
        const defaultOption = this.configurationConditionList.find(
          (cond) => cond.default
        );
        if (defaultOption) {
          this.groupeClient = defaultOption.Name;
        }
      }
    },
  },
  mounted() {
    this.getFavori(false);
  },
};
</script>
