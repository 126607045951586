import axios from "axios";

export async function getClientPro(plateform: string) {
  const response = await axios.get(
    `${process.env.VUE_APP_API}/client/${plateform}`
  );

  return response.data;
}

export async function getClientPart(plateform: string) {
  const response = await axios.get(
    `${process.env.VUE_APP_API}/client/clientParticulier/${plateform}`
  );

  return response.data;
}

export async function getAllByPlateform(plateform: string, pro: boolean, archived: boolean) {
  const getAllByPlateform = await axios.post(
    `${process.env.VUE_APP_API}/client/getAllByPlateform`,
    {
      plateform: plateform,
      pro: pro,
      archived: archived,
    }
  );

  return getAllByPlateform.data;
}

export async function changeAgence(client_id: string, agence: string) {
  const response = await axios.post(
    `${process.env.VUE_APP_API}/client/changeAgence`,
    {
      client_id: client_id,
      agence: agence,
    }
  );

  return response.data;
}