<template>
  <div
    class="flex flex-row space-x-2 xs:hidden sm:block items-center w-fit h-fit px-2 py-1 rounded-[7px] bg-bluevk hover:bg-blue-500 focus:outline-none text-white font-medium rounded px-2 shadow cursor-pointer"
    @click="getToRouter()"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
</template>

<script>
export default {
  name: "backButton",
  data() {
    return {
      routerLink: "",
    };
  },
  props: ["route"],
  methods: {
    getToRouter() {
      if (this.route) {
        this.$router.push(this.route);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style></style>
