<template>
  <div class="bg-white rounded-sm shadow p-2 w-11/12" v-if="familleTab">
    <div class="text-lg text-bluevk font-bold" v-if="familleTab">Familles</div>
    <div class="flex flex-col space-y-1 ml-2 mt-2">
      <div
        v-for="(fam, indexFam) in familleInfos.sousFamille"
        :key="indexFam"
        v-show="fam.show"
      >
        <div
          class="px-1 hover:text-bluevk cursor-pointer"
          @click="$emit('selectFamille', fam)"
        >
          <div
            v-if="selectedFamille.includes(fam.name)"
            class="flex flex-row space-x-2 items-center text-blue-500 bg-green-100 rounded-sm p-1"
          >
            <span>{{ fam.name }}</span>
          </div>
          <div v-else class="flex flex-row space-x-2 items-center">
            <span>{{ fam.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sousFamilleList",
  props: {
    familleTab: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selectedFamille: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    familleInfos: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  mounted() {
    if (this.familleTab.data) {
      for (const fam of this.familleTab.data) {
        for (const sousFam of this.familleInfos.sousFamille) {
          if (sousFam.genericArticleId.includes(fam.genericArticleId)) {
            sousFam.show = true;
          }
        }
      }
    }
  },
};
</script>
<style></style>
