import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";

export function commandeFournisseur(tab1) {
  var tabBody = [];
  tabBody.push([
    { text: "Ref", style: "tableHeader" },
    { text: "Desc", style: "tableHeader" },
    { text: "Quantité", style: "tableHeader" },
  ]);
  for (const art of tab1) {
    tabBody.push(art);
  }
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  var dd = {
    content: [
      // Header
      {
        columns: [
          {
            image: "imgg",
            width: 150,
          },
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: "",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 340,
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        columns: [
          {
            text: store.state.plateform.Titre1 + "\n",
            style: "invoiceBillingTitle",
            width: 245,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Titre2 + "\n",
            style: "invoiceBillingSubTitle",
            width: 245,
            margin: [40, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Adresse,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 10, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text:
              store.state.plateform.Code_postale +
              " " +
              store.state.plateform.Ville,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Pays,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Telephone,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 5, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: store.state.plateform.Email,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text:
              store.state.plateform.Siret +
              " - " +
              store.state.plateform.Tva +
              " - " +
              store.state.plateform.Naf,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        style: "tableExample",
        table: {
          headerrows: 1,
          widths: [150, "*", 100],
          body: tabBody,
        },
      },
    ],

    images: {
      // imgg: 'http://vekteurbucket.s3.amazonaws.com/Logo/Gap.png',
      imgg: {
        url: `${store.state.plateform.lien_logo}`,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };
  pdfMake.createPdf(dd).open();
}
