<template>
  <div
    v-if="showAddAppointment"
    :class="`modal ${
      !showAddAppointment && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeAddAppointment()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 52rem; height: 53rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="h-[45rem] px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <h1 class="text-2xl font-bold">Création d'un rendez-vous</h1>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="closeAddAppointment()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Body -->
        <div class="h-full">
          <div class="flex flex-col space-y-4 mt-6 ml-6">
            <div class="grid grid-cols-2 gap-x-2">
              <!-- Client -->
              <div class="flex flex-col space-y-2">
                <div class="text-xl font-bold">Client</div>
                <data-list-client-vue
                  :user="user"
                  @vmodel="creation.client = $event"
                />
              </div>

              <!-- Commercial -->
              <div class="flex flex-col space-y-2">
                <div class="text-xl font-bold">Commercial</div>
                <select
                  name="commercial"
                  id="commercial"
                  v-model="creation.commercial"
                  class="px-4 py-2 text-black border shadow rounded-md focus:outline-none outline-sky-300"
                >
                  <option disabled value="" selected>Commercial</option>
                  <option
                    v-for="commercial in tabCommercial"
                    :key="commercial._id"
                    :value="commercial"
                  >
                    {{ commercial.username }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex flex-col space-y-2">
              <div class="text-xl font-bold">Contact</div>
              <div class="grid grid-cols-2 gap-2">
                <input
                  placeholder="Nom"
                  v-model="creation.contact.name"
                  class="w-full shadow focus:outline-2 outline-sky-300 px-4 py-2 border rounded-md"
                />
                <input
                  placeholder="Position"
                  v-model="creation.contact.position"
                  class="w-full shadow focus:outline-2 outline-sky-300 px-4 py-2 border rounded-md"
                />
                <input
                  placeholder="Téléphone"
                  v-model="creation.contact.phone"
                  class="w-full shadow focus:outline-2 outline-sky-300 px-4 py-2 border rounded-md"
                />
                <input
                  placeholder="E-mail"
                  v-model="creation.contact.email"
                  class="w-full shadow focus:outline-2 outline-sky-300 px-4 py-2 border rounded-md"
                />
              </div>
            </div>

            <div class="flex flex-col space-y-2">
              <div class="text-xl font-bold">Date</div>
              <div class="flex flex-row space-x-4">
                <dropdown defaultValue="Récurrence" />
                <datepicker
                  @selectDate="
                    (creation.startDate = $event.startDate),
                      (creation.endDate = $event.endDate)
                  "
                />
              </div>
            </div>

            <div class="flex flex-col space-y-1">
              <div class="text-xl font-bold">Sujet(s) à aborder :</div>
              <div
                class="flex justify-start space-x-2 max-w-full overflow-y-auto"
              >
                <div
                  v-for="(topic, indexTopic) in creation.topics"
                  :key="indexTopic"
                >
                  <button
                    class="px-8 py-1 text-white shadow-lg border rounded-lg bg-bluevk hover:bg-blue-500"
                  >
                    {{ topic.topic }}
                  </button>
                </div>
                <div>
                  <input
                    v-model="creationTopic"
                    placeholder="Ecrire..."
                    @keyup.enter="
                      creation.topics.push({
                        done: false,
                        topic: creationTopic,
                      }),
                        (creationTopic = '')
                    "
                    class="px-8 py-1 text-white shadow-lg border rounded-lg bg-gray-700 hover:bg-gray-500"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col space-y-1">
              <div class="text-xl font-bold">Description :</div>
              <textarea
                v-model="creation.notes"
                placeholder="Notes"
                class="w-full border border-gray-300 rounded-lg p-2 h-36 focus:outline-none focus:border-blue-500"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- End Body -->

        <div class="flex justify-end space-x-2">
          <button
            @click="addAppointment()"
            class="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Ajouter
          </button>
          <button
            @click="closeAddAppointment()"
            class="px-4 py-2 bg-gray-300 rounded"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showContact"
    :class="`modal ${
      !showContact && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeContact()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 60vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div class="z-50 cursor-pointer modal-close" @click="closeContact()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Body -->
        <div class="flex flex-col space-y-4 mt-6 ml-6">
          <ContactCard :contact="contactSelected" />
        </div>
        <!-- End Body -->
      </div>
    </div>
  </div>

  <div class="flex flex-col space-y-4">
    <div class="flex justify-between">
      <backButton :route="'/clients'" />
      <div>Rapports de rendez-vous client</div>
    </div>
    <div
      class="flex flex-col space-y-2 bg-white rounded shadow w-full h-80 p-4"
    >
      <div class="flex justify-between items-center">
        <div class="flex flex-row space-x-2 items-center">
          <searchInput />
          <datepicker
            placeholder="Date de début et de fin"
            @selectDate="
              (startDate = $event.startDate),
                (endDate = $event.endDate),
                filterByDateRange()
            "
          />
          <dropdown
            defaultValue="Client"
            @vmodel="(client = $event), filterByDateRange()"
          />
          <!-- <dropdown
            defaultValue="Commercial"
            @vmodel="(commercial = $event), filterByDateRange()"
            :options="tabCommercial"
          /> -->
          <select
            name="commercial"
            id="commercial"
            v-model="commercial"
            @change="filterByDateRange()"
            class="px-4 py-2 text-black border shadow rounded-md focus:outline-none outline-sky-300"
          >
            <option disabled value="" selected>Commercial</option>
            <option
              v-for="commercial in tabCommercial"
              :key="commercial.id"
              :value="commercial"
            >
              {{ commercial.username }}
            </option>
          </select>
          <dropdown
            defaultValue="Contact"
            @vmodel="(contact = $event), filterByDateRange()"
          />
          <dropdown
            defaultValue="Statut"
            @vmodel="(status = $event), filterByDateRange()"
            :options="[
              'En attente',
              'Annulé',
              'Confirmé',
              'Terminé',
              'Planifié',
            ]"
          />
        </div>
        <div>
          <add @click="openAddAppointment()" />
        </div>
      </div>
      <div
        class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Adresse
                </th>
                <th
                  class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client
                </th>
                <th
                  class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Commercial
                </th>
                <th
                  class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Contact
                </th>
                <th
                  class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Note
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                class="hover:bg-gray-100 cursor-pointer"
                v-for="(ap, indexAP) in filteredAppointments"
                :key="indexAP"
                @click="goToDetailReportsPage(ap)"
              >
                <td
                  class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ getFRDate(ap.appointmentDate) }}
                </td>

                <td
                  class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ ap.address }}
                </td>

                <td
                  class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ ap.clientId }}
                </td>
                <td
                  class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ ap.commercial.name }}
                </td>

                <td
                  class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div
                    class="hover:border-b hover:border-gray-500 w-fit"
                    @click="openContact(ap.contact)"
                  >
                    {{ ap.contact.name }}
                  </div>
                </td>
                <td
                  class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <EmojiRating :rating="ap.rating" />
                </td>
              </tr>
            </tbody>
          </table>
          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="pagination?.totalRecords"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(pagination?.totalRecords / 10)"
            :clickFunction="getAppointment"
            :currentPage="currentPage"
            :typeList="'Rapports'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'Rapports'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import backButton from "@/components/Button/back.vue";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import datepicker from "@/components/Input/datepicker.vue";
import dropdown from "@/components/Input/dropdown.vue";
import add from "@/components/Button/add.vue";
import { filter } from "@/hooks/clients/appointment.ts";
import EmojiRating from "@/components/Label/EmojiRating.vue";
import ContactCard from "@/components/Card/ContactCard.vue";
import DataListClientVue from "@/components/Input/datalistclient.vue";
import { getFrDate } from "@/hooks/tools/date.ts";
import { getCommerciaux } from "@/hooks/commercial/commercial.ts";

export default {
  name: "ReportsPage",
  data() {
    return {
      appointments: [],
      searchActive: false,
      searchTerm: "",
      pageStart: 0,
      pageNext: 10,
      pageTab: 0,
      currentPage: 1,
      promoslength: 0,
      searchLength: 0,
      showAddAppointment: false,
      showContact: false,
      contactSelected: {},
      clientList: [],
      endDate: null,
      filteredAppointments: [],
      startDate: null,
      tabCommercial: [],
      pagination: {},

      clientId: "",
      commercial: "",
      contacts: "",
      status: "",

      creation: {
        topics: [],
        demands: [],
        actions: [],
        contact: { name: "", email: "", phone: "", position: "" },
        commercial: { name: "", email: "", phone: "", position: "" },
      },
      creationTopic: "",
    };
  },
  components: {
    backButton,
    searchInput,
    datepicker,
    dropdown,
    add,
    pageNumber,
    EmojiRating,
    ContactCard,
    DataListClientVue,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setIdAppointment"]),
    openAddAppointment() {
      this.showAddAppointment = true;
    },
    closeAddAppointment() {
      this.showAddAppointment = false;
    },
    openContact(contactInfo) {
      this.contactSelected = contactInfo;
      this.showContact = true;
    },
    closeContact() {
      this.showContact = false;
    },
    async addAppointment() {
      await axios.post(`${process.env.VUE_APP_API}/appointment`, {
        appointmentDate: this.creation.startDate,
        nextAppointmentDate: this.creation.endDate,
        address: this.creation.address,
        postalCode: this.creation.postalCode,
        city: this.creation.city,
        status: this.creation.endDate,
        rating: 0,
        commercial: {
          id: this.creation.commercial._id,
          name: this.creation.commercial.username,
          email: this.creation.commercial.email,
          phone: this.creation.commercial.telephone,
          position: "Commercial",
        },
        contact: {
          name: this.creation.contact.name,
          email: this.creation.contact.email,
          phone: this.creation.contact.phone,
          position: this.creation.contact.position,
        },
        attachments: [],
        notes: this.creation.notes,
        topics: this.creation.topics,
        demands: this.creation.demands,
        actions: [],
        clientId: this.creation.client._id,
        plateform: this.user.proprietaire,
        createdBy: this.user.username,
        updatedBy: this.user.username,
        validation: {
          date: new Date(),
          location: {
            latitude: "",
            longitude: "",
          },
        },
      });
      await this.getAppointment();
      this.closeAddAppointment();
    },
    async getAppointment(skipValue, limitValue) {
      this.currentPage = skipValue;
      let filterInfo = await filter(
        this.user.proprietaire,
        (skipValue - 1) * 10,
        limitValue,
        "appointmentDate",
        "asc"
      );
      this.appointments = filterInfo.data;
      this.pagination = filterInfo.pagination;
      this.filteredAppointments = filterInfo.data;
    },
    goToDetailReportsPage(appointment) {
      this.$router.push("/detailReportsPage");
      this.setIdAppointment(appointment._id);
    },
    filterByDateRange() {
      console.log("filterred");

      axios
        .post(`${process.env.VUE_APP_API}/appointment/filter`, {
          startDate: this.startDate,
          endDate: this.endDate,
          status: this.status,
          commercials: [this.commercial],
          contacts: [this.contacts],
          clientId: this.clientId,
          page: 1,
          limit: 10,
          sortBy: "appointmentdate",
          sortOrder: "desc",
        })
        .then((response) => {
          console.log("filteredAppointments : ", response.data.data);
          this.filteredAppointments = response.data.data;
        });
    },
    getFRDate(date) {
      return getFrDate(date);
    },
    async getCommercial() {
      try {
        const commercial = await getCommerciaux(this.user.proprietaire);
        this.tabCommercial = commercial;
        console.log("Commercial Data:", commercial);
      } catch (error) {
        console.error("erreur:", error);
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  async mounted() {
    await this.getAppointment(1, 10);
    await this.getCommercial();
    this.pageSwitch();
  },
};
</script>
<style></style>
