<template>
  <div
    id="retour"
    @click="back()"
    class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400 cursor-pointer"
  >
    <img src="../assets/RetourIcon.png" class="w-6" />
    <span style="color: #5976e8">Retour</span>
  </div>

  <div class="mb-10">
    <menuDetailFournisseur />
  </div>
  <div class="flex justify-end mb-5">
    <span
      title="télécharger le PDF"
      @click="createPDF()"
      class="z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
      style="color: blue; background-color: aliceblue"
    >
      download
    </span>
  </div>
  <div class="drop-shadow-md border">
    <div class="bg-white overflow-y-scroll" style="height: 70vh">
      <table class="w-full">
        <thead class="sticky top-0">
          <tr class="drop-shadow-md">
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              REF FOURNISSEUR
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              ARTICLES
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PRIX
            </th>
          </tr>
        </thead>
        <tbody
          class=""
          v-for="(f, Fournisseur) in allBdlByFournisseur"
          :key="Fournisseur"
        >
          <tr>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ f.Ref_fournisseur }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ f.Description }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ parseFloat(f.Prix_euro).toFixed(2) }} €
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="px-6 w-full py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase border-t border-gray-200 bg-gray-50 drop-shadow-md"
    >
      Total: <strong class="text-xl">{{ setTotal }} € </strong>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import menuDetailFournisseur from "../components/Fournisseur/menuDetailFournisseur.vue";
import { createfournisseur } from "./../hooks/pdf/chiffresFournisseur.js";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  data() {
    return {
      allBdlByFournisseur: [],
      fournisseurTab: {},
    };
  },
  computed: {
    ...mapGetters(["user", "fournisseurID"]),
    setTotal() {
      let totalPrice = 0;
      this.allBdlByFournisseur.forEach((el) => {
        totalPrice += parseFloat(el.Prix_euro);
      });
      return totalPrice.toFixed(2);
    },
  },
  components: {
    menuDetailFournisseur,
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    back() {
      this.$router.back();
    },
    getBdlByFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getBdlByFournisseur`, {
          fournisseur: this.fournisseurTab.Prefixe_tarif,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.allBdlByFournisseur = response.data;
        });
    },
    createPDF() {
      var ref = [];
      for (const refA of this.allBdlByFournisseur) {
        ref.push([
          refA.Ref_fournisseur,
          refA.Description,
          parseFloat(refA.Prix_euro).toFixed(2).toString() + " €",
        ]);
      }
      createfournisseur(ref, this.setTotal);
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getFournisseur();
    this.getBdlByFournisseur();
  },
};
</script>

<style scoped></style>
