export async function getLastDayMonth(month: number, year: number) {
  const day = new Date(year, month + 1, 0).getDate();
  const monthString = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`;
  const yearString = month < 12 ? `${year}` : `${year + 1}`;
  if (month === 12) {
    return `31/01/${year + 1}`;
  } else {
    return `${day}/${monthString}/${year}`;
  }
}

export async function getPaiementLimit(date: Date, delay: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + delay);
  return newDate;
}
