<template>
  <creerPromo
    v-if="showCreerPromo"
    :valueOpenCreer="showCreerPromo"
    @close="getPromos(currentPage, 10)"
  />

  <div id="body" class="bg-white p-2 rounded-md">
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/articleHub'" />
      </div>
      <button
        class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="showCreerPromo = true"
      >
        <span class="material-icons-outlined text-xl mr-2"> add_circle </span>
        <span>Ajouter</span>
      </button>
    </div>

    <div class="flex justify-between mt-4">
      <searchInput
        placeholder="Rechercher dans promotions"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
    </div>

    <div
      class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Date
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Référence
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Titre
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Désignation
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Remise
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Prix net HT
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              ></th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr v-for="(promo, index) in promoTab" :key="index">
              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ getFRDate(promo.date) }}
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex flex-col">
                  <p class="font-medium">
                    {{ promo.Ref_fournisseur }}
                  </p>
                  <p>
                    {{ promo.Code_marque }}
                  </p>
                </div>
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                <p class="overflow-y-auto" style="max-width: 20rem">
                  {{ promo.titre }}
                </p>
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ promo.Quantite }}
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                <p class="overflow-y-auto" style="max-width: 20rem">
                  {{ promo.Designation }}
                </p>
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ promo.Remise }}
              </td>

              <td
                class="font-medium px-4 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(promo.PrixNet).toFixed(2) }} €
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                <button>
                  <span
                    class="material-icons-round"
                    style="color: red"
                    @click="deleteItem(promo._id)"
                  >
                    delete</span
                  >
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <pageNumber
          v-if="!searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="promoslength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(promoslength / 10)"
          :clickFunction="getPromos"
          :currentPage="currentPage"
          :typeList="'promotions'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
          :currentPage="currentPage"
          :typeList="'promotions'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import creerPromo from "@/components/Promotions/creerPromo";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import searchInput from "@/components/searchInput.vue";
import pageNumber from "@/components/pageNumber.vue";
import {
  searchArticles,
  prepareArticleimport,
} from "@/hooks/searchEngine/articles";
import { getFrDate } from "@/hooks/tools/date";
import backButton from "@/components/Button/back.vue";

export default {
  name: "MyPromotions",
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    creerPromo,
    pageNumber,
    searchInput,
    // CardQuantity,
    // PulseLoader,
    backButton,
  },
  data() {
    return {
      showCreerPromo: false,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      promoslength: 0,

      promoTab: [],
      prixQtifTab: [],
      foundPromo: [],
      articleSearch: "",
      titre: "",
      typeRemise: "quantitatif",
      remise: 0,
      prixnet: 0,
      quantite: 0,
      designation: "",
      remiseQtif: 0,
      prixnetQtif: 0,
      fileImage: null,
      imagePromo: null,
      imageState: true,
      articlePromo: [],
      isLoading: false,
      showSearch: false,
      autoCompleteTab: [],
      showAutoComplete: false,
      formIsValid: false,
      searchTab: [],

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
    };
  },
  computed: {
    ...mapGetters(["user", "comptoirSessionActive", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getPromos(skipValue, limitValue) {
      this.showCreerPromo = false;
      axios
        .post(`${process.env.VUE_APP_API}/promo/getAll`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((res) => {
          this.promoTab = res.data.foundPromo;
          this.promoslength = res.data.countPromo;
        });
    },
    handlerArticle(arti) {
      this.articlePromo.push(arti);
      this.showSearch = false;
    },
    deleteItem(id) {
      axios
        .post(`${process.env.VUE_APP_API}/promo/deletePromo`, {
          id: id,
        })
        .then(() => {
          this.getPromos(this.currentPage, 10);
          this.success("Supprimée !");
        });
    },
    async getOneArticle() {
      this.showAutoComplete = false;
      if (this.articleSearch != "") {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await searchArticles(
          this.articleSearch,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name
        );
        this.showSearch = true;
      } else {
        this.error("Completez le champs");
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.fileImage = file;
      this.imagePromo = URL.createObjectURL(file);
      console.log(this.imagePromo);
      this.imageState = !this.imageState;
    },
    onImageDelete() {
      this.fileImage = null;
      this.imagePromo = null;
      this.imageState = !this.imageState;
      this.$refs.file.value = "";
    },
    onRefDelete() {
      this.articlePromo = [];
      this.articleSearch = "";
    },
    findOne(reference, marque) {
      axios
        .post(`${process.env.VUE_APP_API}/promo/getone`, {
          promoinfo: {
            plateformID: this.user.proprietaire,
            Ref_fournisseur: reference,
            Code_marque: marque,
          },
        })
        .then((res) => {
          this.foundPromo = res.data;
          this.promoTab = res.data;
        });
    },
    changeType(type) {
      this.typeRemise = type;
    },
    addPromo() {
      // var today = new Date();
      // var dd = String(today.getDate()).padStart(2, "0");
      // var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      // var yyyy = today.getFullYear();
      // var hour = today.getHours();
      // var seconds = today.getSeconds();
      // var minutes = today.getMinutes();
      // today =
      //   dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      var fd = new FormData();

      fd.append("image", this.fileImage);
      fd.append("date", new Date());
      fd.append("titre", this.titre);
      fd.append("plateform", this.user.proprietaire);
      fd.append("type", this.typeRemise);
      fd.append("Ref_fournisseur", this.articlePromo[0].Ref_fournisseur);
      fd.append("Code_marque", this.articlePromo[0].Code_marque);
      fd.append("Prefixe_tarif", this.articlePromo[0].Prefixe_tarif);
      fd.append("Remise", this.remise);
      fd.append("PrixNet", this.prixnet);
      fd.append("Quantite", this.quantite);
      fd.append("Designation", this.designation);
      fd.append("designation", this.designationQtif);
      fd.append("PrixQuantitatif", JSON.stringify(this.prixQtifTab));
      fd.append("withImage", true);

      console.log(this.imagePromo);

      if (
        this.remise >= 0 &&
        this.designation != "" &&
        this.quantite > 0 &&
        this.prixnet != "" &&
        this.prixnet >= 0 &&
        this.titre != "" &&
        this.articlePromo[0].Ref_fournisseur &&
        this.imagePromo
      ) {
        (this.isLoading = true),
          axios
            .post(`${process.env.VUE_APP_API}/promo/image`, fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.isLoading = false;
              this.getPromos(this.currentPage, 10);
              this.success("Promotion ajoutée avec une image!");
            });
      } else if (
        this.remise >= 0 &&
        this.designation != "" &&
        this.quantite > 0 &&
        this.prixnet != "" &&
        this.prixnet >= 0 &&
        this.titre != "" &&
        this.articlePromo[0].Ref_fournisseur &&
        !this.imagePromo
      ) {
        (this.isLoading = true),
          axios
            .post(`${process.env.VUE_APP_API}/promo`, {
              infos: {
                date: new Date(),
                titre: this.titre,
                plateform: this.user.proprietaire,
                type: this.typeRemise,
                Ref_fournisseur: this.articlePromo[0].Ref_fournisseur,
                Code_marque: this.articlePromo[0].Code_marque,
                Prefixe_tarif: this.articlePromo[0].Prefixe_tarif,
                Remise: this.remise,
                PrixNet: this.prixnet,
                Quantite: this.quantite,
                Designation: this.designation,
                PrixQuantitatif: this.prixQtifTab,
                withImage: false,
                designation: this.designationQtif,
              },
            })
            .then(() => {
              this.isLoading = false;
              this.getPromos(this.currentPage, 10);
              this.success("Promotion ajoutée sans image!");
            });
      } else {
        this.error("Veuillez entrer tous les champs manquants");
      }
    },
    setPromoExist(promo) {
      this.articleSearch = promo.Ref_fournisseur;
      this.prixQtifTab = promo.PrixQuantitatif;
      this.titre = promo.titre;
      this.typeRemise = promo.type;
      this.remise = promo.Remise;
      this.remise1 = promo.remiseQtf;
      this.prixnet = promo.PrixNet;
      this.Quantite = promo.quantite;
      this.Designation = promo.designation;
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    autoComplete(ref) {
      if (this.articleSearch != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.articleSearch = ref;
      this.showAutoComplete = false;
      this.getOneArticle();
    },
    validateForm() {
      if (
        !this.titre ||
        !this.articlePromo ||
        !this.quantite ||
        !this.designation ||
        this.remise < 0 ||
        !this.prixnet
      ) {
        this.formIsValid = true;
      } else if (
        this.titre &&
        this.articlePromo &&
        this.quantite &&
        this.designation &&
        this.prixnet
      ) {
        this.articlePromo = [];
        this.titre = "";
        this.articleSearch = "";
        this.quantite = 0;
        this.designation = "";
        this.remise = 0;
        this.prixnet = 0;
      } else {
        this.formIsValid = false;
      }
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getPromos(skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/promo/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.promoTab = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  mounted() {
    this.getPromos(this.currentPage, 10);
    this.pageSwitch();
  },
};
</script>

<style scoped>
.error {
  border: 1px solid red;
}

.bg-vk {
  background-color: #2262b3;
}

.bg-vk2 {
  background-color: #4080d3;
}
.bg-vk1:hover {
  background-color: #2262b3;
  color: white;
}

.text-vk-blue {
  color: #2262b3;
}

.text-vk {
  color: #ff914d;
}
</style>
