<template>
  <div>
    <!-- modal info article -->
    <div
      v-if="openModalArticle"
      :class="`modal ${
        !openModalArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModalArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 68vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModalArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <article-info :key="articleInfo_componentKey" />
        </div>
      </div>
    </div>
    <!-- fin modal info article -->

    <!-- modal commandé chez le fournisseur -->
    <div
      v-if="showCmdFournisseur"
      :class="`modal ${
        !showCmdFournisseur && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showCmdFournisseur = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 50rem; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">
              Changer le statut par : Commandé chez le fournisseur
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showCmdFournisseur = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <p class="text-sm mt-2">Veuillez laisser un commentaire :</p>
              <h2>
                <textarea
                  rows="2"
                  cols="40"
                  :disabled="
                    cmdPreparation.status == 'Commande et BL disponibles'
                  "
                  class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                  placeholder="Ex. : Pièces commandées chez le fournisseur."
                  v-model="cmdPreparation.commandeFournisseur.message"
                ></textarea>
              </h2>
              <p class="text-xs" style="font-style: italic; color: #888">
                * Ce message sera visible par le client.
              </p>
              <div v-if="cmdPreparation.commandeFournisseur.user != ''">
                Pièce(s) manquante(s) commandée(s) par :
                {{ cmdPreparation.commandeFournisseur.user }}
              </div>
            </div>
            <div class="flex space-x-32">
              <!-- Date livraison estimée chez nous -->
              <div class="flex flex-col">
                <p class="text-sm mt-2">
                  Date livraison estimée par le fournisseur
                </p>
                <h2>
                  <input
                    type="date"
                    class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                    :disabled="
                      cmdPreparation.status == 'Commande et BL disponibles'
                    "
                    v-model="
                      cmdPreparation.commandeFournisseur.deliveryDateToUs
                    "
                  />
                </h2>
                <p class="text-xs" style="font-style: italic; color: #888">
                  * Ce message sera visible par le client.
                </p>
              </div>
              <!-- Date livraison estimée chez client -->
              <div class="flex flex-col">
                <p class="text-sm mt-2">
                  Date livraison estimée chez le client
                </p>
                <h2>
                  <input
                    type="date"
                    class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                    :disabled="
                      cmdPreparation.status == 'Commande et BL disponibles'
                    "
                    v-model="
                      cmdPreparation.commandeFournisseur.deliveryDateToCustomer
                    "
                  />
                </h2>
                <p class="text-xs" style="font-style: italic; color: #888">
                  * Ce message sera visible par le client.
                </p>
              </div>
            </div>
          </div>

          <div class="flex justify-end my-2">
            <button
              @click="showCmdFournisseur = false"
              class="px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              v-if="
                cmdPreparation.status != 'Commande et BL disponibles' &&
                !loadingAction
              "
              class="px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
              @click="
                saveMsgCmdFourn(cmdPreparation.commandeFournisseur.message)
              "
            >
              Valider
            </button>
            <button
              v-else-if="loadingAction"
              class="px-6 py-3 mr-2 bg-gray-300 text-white border border-blue-900 shadow-md font-semibold border border-bluevk rounded animate-pulse"
              disabled
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal commandé chez le fournisseur -->

    <div
      v-if="showDirectTransfertFillialeModal"
      :class="`modal ${
        !showDirectTransfertFillialeModal && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDirectTransfertFillialeModal = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 30vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Commande Filliale</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDirectTransfertFillialeModal = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div>
            <div class="w-full bg-white flex flex-col">
              <div v-show="!loadingCommandeFilliale">
                Souhaitez-vous commander
                <input
                  type="Number"
                  v-model="articleDirectTransfert.quantity"
                  :max="maxDirectTransfert"
                  @change="checkDirectTransfertQty()"
                  class="w-10"
                />
                pièces chez
                <select v-model="plateformDirectTransfert">
                  <option
                    v-for="(pl, indexPL) in plateformList"
                    :key="indexPL"
                    :value="pl"
                  >
                    {{ pl }}
                  </option>
                </select>
                ?
              </div>
              <div v-show="loadingCommandeFilliale" class="animate-pulse">
                Commande en cours d'envoi...
              </div>
              <div class="flex justify-end mb-2">
                <button
                  v-if="
                    plateformDirectTransfert != '' && !loadingCommandeFilliale
                  "
                  class="p-2 bg-blue-500 text-white rounded shadow"
                  @click="
                    setStateGroupement(
                      {
                        plateform: plateformDirectTransfert,
                        stockToTransfert: articleDirectTransfert.quantity,
                        force: true,
                      },
                      indexDirectTransfert
                    )
                  "
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modalPanierLKQ v-if="openRecapVH" @closeModalLKQ="openRecapVH = $event" />

    <remisesFournisseurs
      v-if="openRefSimil"
      :Ref_fournisseur="refSimil"
      @closeModalRemFourn="openRefSimil = $event"
    />

    <referencesFournisseurs
      v-if="openRefArticle"
      :Ref_fournisseur="refSimil"
      @closeModalRefFourn="openRefArticle = $event"
    />

    <encaissement
      v-if="openEncaissement"
      :showModal="openEncaissement"
      :setTotalTTC="cmdPreparation.TotalPriceTTC"
      :reglement="cmdPreparation.reglement"
      @closeModalEncaissement="openEncaissement = $event"
      @emitEncaissement="finirCommandePart($event)"
    />

    <!-- <modalTransport
      v-if="openModalTransport"
      @closeModalTransport="openModalTransport = $event"
      @dataTransport="
        (this.dataTransport = $event),
          saveFraisDePort(this.dataTransport.fraisdeport),
          envoyerCommande()
      "
      :comptoir="false"
      :client="cmdPreparation.clientInfo"
      :fraisdeport="cmdPreparation.Transport"
    /> -->

    <div class="">
      <div class="flex justify-between">
        <!-- Back Button -->
        <div class="flex justify-between">
          <div id="retour" class="flex justify-start">
            <backButton :route="'/commandePro'" />
          </div>
        </div>
        <!-- Fin Back Button -->

        <div class="hidden md:flex md:flex-row space-x-8">
          <p>Commandé le : {{ getFRDate(cmdPreparation.Date) }}</p>
          <div class="flex flex-col px-2">
            <span>
              Par : {{ cmdPreparation.Owner }} / {{ cmdPreparation.Name }}</span
            >
            <div class="flex space-x-2 items-center">
              <p>Montant :</p>
              <h4 class="font-medium text-xl">
                {{ parseFloat(setTotal).toFixed(2) }} € HT
              </h4>
              <p>soit</p>
              <h4>
                {{ parseFloat(setTotalTTC).toFixed(2) }}
                € TTC
              </h4>
            </div>
            <div class="flex items-center space-x-1">
              <button
                class="relative material-icons-outlined p-0.5 bg-bluevk hover:bg-white hover:text-bluevk text-white rounded-full shadow-lg"
                @click="showEdit = true"
                v-if="!showEdit"
                title="Editer le commentaire"
              >
                drive_file_rename_outline
              </button>
              <span v-if="!showEdit"
                >Commentaire : {{ cmdPreparation.Commentaire }}</span
              >
              <div v-if="showEdit">
                <textarea
                  type="text"
                  v-model="cmdPreparation.Commentaire"
                  @blur="showEdit = false"
                  @change="saveCommande"
                  class=""
                  style="width: 15vw"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="text-right">
            <span v-if="cmdPreparation.clientInfo">
              {{ cmdPreparation.clientInfo[0].Adresse }},
              {{ cmdPreparation.clientInfo[0].PostalCode }}
              <p v-if="cmdPreparation.clientInfo[0].Complement != ''">
                {{ cmdPreparation.clientInfo[0].Complement }},
              </p>
              {{ cmdPreparation.clientInfo[0].City }},
              {{ cmdPreparation.clientInfo[0].Country }} <br />
              {{ cmdPreparation.clientInfo[0].telephone }} <br />
              <p v-if="cmdPreparation.clientInfo[0].transporteur != ''">
                Transporteur : {{ cmdPreparation.clientInfo[0].transporteur }}
              </p>
              <br />
            </span>
            <div class="flex items-center space-x-1 justify-end">
              <button
                v-if="cmdPreparation.cmdstate[1] && !showEditTransport"
                class="relative material-icons-outlined p-0.5 bg-bluevk hover:bg-white hover:text-bluevk text-white rounded-full shadow-lg"
                @click="showEditTransport = true"
                title="Editer le frais de port"
              >
                drive_file_rename_outline
              </button>
              <div class="flex space-x-2">
                <p class="my-auto">Transport :</p>
                <p v-if="!showEditTransport">
                  {{ cmdPreparation.Transport }} €
                </p>
                <div v-if="showEditTransport">
                  <div class="relative w-24 my-auto">
                    <div class="flex space-x-2">
                      <input
                        type="number"
                        :min="0"
                        v-model="setTransport"
                        @keyup.enter="saveFraisDePort(setTransport)"
                        class="shadow-lg rounded-md outline-2 outline-sky-300 px-2 w-24 h-9 pr-11"
                      />
                      <p class="my-auto">€</p>
                    </div>

                    <div
                      class="absolute inset-y-0 right-0 flex items-center"
                      @click="saveFraisDePort(setTransport)"
                    >
                      <button
                        class="material-icons-outlined text-white bg-bluevk w-10 h-8 mr-1 rounded-md shadow-lg"
                        title="Valider"
                      >
                        arrow_right_alt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="cmdPreparation.relicatState" class="ml-4">
              <span v-if="cmdPreparation.relicatNumber != 0"
                >Relicat : {{ cmdPreparation.relicatNumber }}</span
              >
              <span v-if="cmdPreparation.relicatFromNumber != 0"
                >Relicat de : {{ cmdPreparation.relicatFromNumber }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="md:hidden flex flex-col mt-2">
        <div class="mx-auto">
          {{ cmdPreparation.Owner }}
        </div>
        <div class="mx-auto">
          {{ cmdPreparation.Name }}
        </div>
        <div v-if="cmdPreparation.clientInfo" class="text-sm mx-auto">
          {{ cmdPreparation.clientInfo[0].Adresse }},
          {{ cmdPreparation.clientInfo[0].PostalCode }}
          <span v-if="cmdPreparation.clientInfo[0].Complement != ''">
            {{ cmdPreparation.clientInfo[0].Complement }},
          </span>
          {{ cmdPreparation.clientInfo[0].City }},
          {{ cmdPreparation.clientInfo[0].Country }}
        </div>
        <div v-if="cmdPreparation.clientInfo" class="mx-auto">
          {{ cmdPreparation.clientInfo[0].telephone }}
        </div>

        <div
          v-if="cmdPreparation.clientInfo[0].transporteur != ''"
          class="mx-auto"
        >
          {{ cmdPreparation.clientInfo[0].transporteur }}
        </div>
        <div
          v-if="cmdPreparation.relicatState"
          class="flex flex-row justify-between"
        >
          <div
            v-if="cmdPreparation.clientInfo"
            class="flex flex-row w-fit mx-auto space-x-2"
          >
            {{ parseFloat(setTotal).toFixed(2) }} € HT
            {{ parseFloat(setTotalTTC).toFixed(2) }}
            € TTC
          </div>
          <div>
            <span v-if="cmdPreparation.relicatNumber != 0"
              >Relicat : {{ cmdPreparation.relicatNumber }}</span
            >
            <span v-if="cmdPreparation.relicatFromNumber != 0"
              >Relicat de : {{ cmdPreparation.relicatFromNumber }}</span
            >
          </div>
        </div>
      </div>

      <div class="flex flex-col md:justify-between">
        <breadCrumb :status="cmdPreparation.status" />

        <!-- Boutons d'action -->
        <div class="mt-6 flex flex-col">
          <div class="flex flex-row justify-end space-x-2">
            <div
              v-if="
                cmdPreparation.status == 'Commande et BL disponibles' &&
                tabState != 'archived'
              "
              class="flex items-center"
            >
              <button
                class="flex w-fit px-2 py-1 h-fit text-blue-600 rounded-md shadow bg-gray-50 hover:bg-gray-100 focus:outline-none items-center"
                @click="archiverCommande()"
              >
                <span class="material-icons-outlined m-auto">archive</span>
                <span class="m-auto">Archiver</span>
              </button>
            </div>
            <!-- <div>
            <button
              class="p-2 text-red-400 border-2 border-red-400 hover:bg-red-400 hover:text-white rounded-full shadow"
              @click="cancelOneCommande()"
            >
              <span>Annuler</span>
            </button>
          </div> -->
            <div>
              <button
                class="p-2 mr-8 bg-red-400 text-white rounded-full shadow text-xs md:text-base"
                v-if="!loadingAction && !cmdPreparation.cmdstate[4]"
                @click="attenteCommande"
              >
                Mettre en attente
              </button>
              <button
                class="p-2 mr-8 bg-gray-400 text-white rounded-full shadow text-xs md:text-base animate-pulse"
                v-else-if="loadingAction && !cmdPreparation.cmdstate[4]"
                disabled
              >
                Mettre en attente
              </button>
            </div>

            <div>
              <button
                class="p-2 ml-2 px-4 bg-btnAction text-white rounded-full shadow w-48"
                v-if="!loadingAction && cmdPreparation.cmdstate[0]"
                @click="validerCommande"
              >
                <span>Démarrer</span>
              </button>
              <button
                class="p-2 ml-2 px-4 bg-gray-400 text-white rounded-full shadow w-48 animate-pulse"
                v-else-if="loadingAction && cmdPreparation.cmdstate[0]"
                disabled
              >
                <span>Démarrer</span>
              </button>
            </div>

            <button
              class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
              @click="openBC"
              v-if="cmdPreparation.cmdstate[0]"
            >
              <span>Bon de commande</span>
              <button class="material-icons-round text-orange-500 my-auto">
                download
              </button>
            </button>
            <!-- <span
          class="p-2 ml-2 bg-blue-500 text-white rounded-full shadow"
          @click="preparerCommande"
          v-if="cmdPreparation.cmdstate[1]"
          >Préparer la commande</span
        > -->
            <div>
              <button
                class="p-2 ml-2 px-4 bg-btnAction text-white rounded-full shadow w-48"
                v-if="!loadingAction && cmdPreparation.cmdstate[1]"
                @click="finirCommande"
              >
                <span>Préparation terminée</span>
              </button>
              <button
                class="p-2 ml-2 px-4 bg-gray-400 text-white rounded-full shadow w-48 animate-pulse"
                v-else-if="loadingAction && cmdPreparation.cmdstate[1]"
                disabled
              >
                <span>Préparation terminée</span>
              </button>
            </div>

            <button
              class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
              @click="openBP"
              v-if="cmdPreparation.cmdstate[1]"
            >
              <span>Bon de préparation</span>
              <button class="material-icons-round text-orange-500 my-auto">
                download
              </button>
            </button>

            <button
              class="flex justify-center items-center p-2 ml-2 px-4 bg-btnAction text-white rounded-full shadow w-48"
              @click="validerBL"
              v-if="
                !cmdPreparation.BLNumber &&
                !loadingValidBL &&
                cmdPreparation.cmdstate[3]
              "
            >
              Valider et BL
              <button class="material-icons-round text-orange-500 my-auto">
                download
              </button>
            </button>
            <button
              class="flex justify-center items-center p-2 ml-2 px-4 bg-btnAction text-white rounded-full shadow w-48"
              v-if="
                !cmdPreparation.BLNumber &&
                loadingValidBL &&
                cmdPreparation.cmdstate[3]
              "
            >
              <PulseLoader color="#ffff" />
              <span>En cours</span>
            </button>
            <button
              class="flex justify-center items-center p-2 ml-2 px-4 bg-btnAction text-white rounded-full shadow w-48"
              @click="validerBT"
              v-if="
                !cmdPreparation.BLNumber &&
                !loadingValidBT &&
                cmdPreparation.cmdstate[3]
              "
            >
              Valider et BT
              <button class="material-icons-round text-orange-500 my-auto">
                download
              </button>
            </button>
            <button
              class="flex justify-center items-center p-2 ml-2 px-4 bg-btnAction text-white rounded-full shadow w-48"
              v-if="
                !cmdPreparation.BLNumber &&
                loadingValidBT &&
                cmdPreparation.cmdstate[3]
              "
            >
              <PulseLoader color="#ffff" />
              <span>En cours</span>
            </button>

            <button
              class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
              @click="openBL(cmdPreparation)"
              v-if="cmdPreparation.BLNumber && cmdPreparation.cmdstate[4]"
            >
              <span>Bon de livraison</span>
              <button class="material-icons-round text-orange-500 my-auto">
                download
              </button>
            </button>
            <button
              class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
              @click="openBT(cmdPreparation)"
              v-if="cmdPreparation.BLNumber && cmdPreparation.cmdstate[4]"
            >
              <span>Bon de transport</span>
              <button class="material-icons-round text-orange-500 my-auto">
                download
              </button>
            </button>
          </div>
        </div>
      </div>

      <div class="flex my-2">
        <input
          v-if="cmdPreparation.cmdstate[1]"
          v-model="scanEAN"
          type="text"
          placeholder="Scanner"
          class="p-2 sm:w-1/4 w-full focus:outline-2 outline-sky-300 rounded shadow border border-blue-600 placeholder:text-blue-400"
          v-on:keyup.enter="addByScan"
        />
        <div v-if="cmdPreparation.cmdstate[1]" class="relative my-auto ml-12">
          <span
            class="p-2 bg-orange-400 text-white rounded-md shadow cursor-pointer"
            @click="showCmdFournisseur = true"
            >Commandé chez le fournisseur ?</span
          >
        </div>
        <div v-if="cmdPreparation.cmdstate[3]" class="relative my-auto">
          <span
            class="p-2 bg-green-700 text-white rounded-md shadow cursor-pointer"
            @click="showCmdFournisseur = true"
            >Commandé chez le fournisseur ?</span
          >
        </div>
        <div
          v-if="
            cmdPreparation.status == 'Commande et BL disponibles' &&
            cmdPreparation.commandeFournisseur.user
          "
          class="relative my-auto"
        >
          <span
            class="p-2 bg-green-700 text-white rounded-md shadow cursor-pointer"
            @click="showCmdFournisseur = true"
            >Commandé chez le fournisseur ?</span
          >
        </div>
      </div>
      <div class="flex flex-col">
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Réf. / EAN
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    Marque / Fournisseur
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    <div
                      v-if="cmdPreparation.cmdstate[1]"
                      class="flex space-x-8"
                    >
                      <div class="flex space-x-1">
                        <div class="m-auto">Qté Prép.</div>
                        <span class="m-auto">/</span>
                        <div class="flex m-auto">
                          <span class="m-auto">Qté Cmdé.</span>
                          <button
                            title="Modifier la quantité commandée"
                            class="material-icons-round hover:text-yellow-500 text-yellow-600 my-auto text-md"
                            @click="enabledInputCmdQty()"
                          >
                            edit
                          </button>
                        </div>
                      </div>
                      <button
                        class="material-icons-round rounded hover:bg-blue-500 hover:text-white"
                        @click="fillQtyOk()"
                      >
                        task_alt
                      </button>
                    </div>
                    <span v-else>Quantité</span>
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    Stock
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    PPC
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    Prix
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    Total
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <span>Transfert</span>
                      <span
                        @click="removeAllStateGroupement()"
                        class="material-icons-round text-sm text-red-500 cursor-pointer"
                      >
                        cancel
                      </span>
                    </div>
                  </th>
                  <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr
                  v-for="(u, index) in cmdPreparation.Articles"
                  :key="index"
                  class="hover:bg-gray-100 h-fit"
                >
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap w-14 max-w-14 overflow-x-auto"
                  >
                    <div class="flex flex-row">
                      <div class="flex flex-col">
                        <div class="text-sm leading-5 text-gray-900">
                          <div class="flex flex-row space-x-2 items-center">
                            <span
                              v-if="u.Prefixe_tarif == 'Vhi'"
                              class="hidden md:flex material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                              style="font-size: 11px"
                              @click="getRefSimilaire(u.Ref_fournisseur)"
                            >
                              sync
                            </span>
                            <span
                              class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                              >{{ u.Ref_fournisseur }}</span
                            >
                            <span
                              class="hidden md:flex material-icons-outlined m-auto cursor-pointer text-blue-500 hover:bg-gray-100 rounded hover:shadow"
                              style="font-size: 12px"
                              @click="plusInfo(u)"
                            >
                              info
                            </span>
                            <span
                              v-if="u.Prefixe_tarif == 'Vhi' && u.PrixLotVH"
                              class="hidden md:flex material-icons-outlined animate-pulse text-orange-500 cursor-pointer"
                              style="font-size: 15px"
                              @click="openModalRefSimil(u.Ref_fournisseur)"
                            >
                              auto_graph
                            </span>
                          </div>
                        </div>
                        <div
                          class="text-sm leading-5 text-gray-600"
                          v-if="u.Ref_complementaire"
                        >
                          <span
                            class="inline-flex px-2 text-xs leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                            >Comp : {{ u.Ref_complementaire }}</span
                          >
                        </div>
                        <div
                          class="hidden md:block text-sm leading-5 text-gray-900"
                        >
                          <span
                            class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                            >{{ u.Code_EAN }}</span
                          >
                        </div>
                        <div class="md:hidden text-sm leading-5 text-gray-900">
                          <span
                            class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                            >{{ u.Code_marque }}</span
                          >
                        </div>
                        <div class="md:hidden text-sm leading-5 text-gray-900">
                          <p
                            class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                            v-if="u.Description"
                          >
                            {{ u.Description.slice(0, 20) }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="hidden md:flex"
                        v-if="
                          cmdPreparation.status == 'Commande validée' ||
                          cmdPreparation.status ==
                            'Commandé chez le fournisseur'
                        "
                      >
                        <span
                          class="material-icons-round cursor-pointer text-orange-500"
                          @click="findSimilar(u, index)"
                        >
                          find_replace
                        </span>
                        <div
                          class="flex flex-col absolute z-20 w-1/3"
                          v-if="showRefSimilar[index]"
                        >
                          <div
                            class="flex flex-col bg-white rounded shadow max-h-96 overflow-y-auto"
                          >
                            <div
                              class="p-4 animate-pulse flex items-center"
                              v-if="loadingRef"
                            >
                              <svg
                                aria-hidden="true"
                                class="w-5 h-5 text-gray-500 dark:text-gray-400 mr-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                ></path>
                              </svg>
                              Recherche d'équivalence...
                            </div>

                            <div class="relative" v-if="!loadingRef">
                              <div
                                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                  ></path>
                                </svg>
                              </div>

                              <input
                                type="text"
                                v-model="searchInput"
                                id="default-search"
                                class="block w-full p-4 pl-10 text-sm text-gray-800 outline-blue-500 rounded shadow border rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="P85, LS243,..."
                                @keyup.enter="searchInputDirect()"
                              />
                              <button
                                type="submit"
                                class="text-white absolute right-10 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                @click="searchInputDirect()"
                              >
                                Search
                              </button>
                              <span
                                class="material-icons-round text-red-500 absolute right-2 bottom-4 cursor-pointer hover:scale-125 hover:border-gray-700 transition duration-150"
                                @click="closeTableau"
                              >
                                close
                              </span>
                            </div>
                            <div v-if="!loadingRef && similarRef.length < 1">
                              Nous n'avons pas trouvé d'équivalence référencée
                            </div>

                            <table v-if="foundArticle.length > 0">
                              <tbody>
                                <tr
                                  v-for="(arti, indexArt) in foundArticle"
                                  :key="indexArt"
                                  class="bg-vk2 cursor-pointer"
                                  @click="
                                    replaceArticle(arti, u.quantity, index)
                                  "
                                >
                                  <td
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ arti.article.Prefixe_tarif }} -
                                    {{ arti.article.Ref_fournisseur }}
                                  </td>
                                  <td
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ arti.article.Code_marque }}
                                  </td>
                                  <td
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ arti.article.Prix_euro }} €
                                  </td>
                                  <td
                                    v-if="
                                      arti.stockData.length > 0 &&
                                      arti.stockData[0].stock > 0
                                    "
                                    class="text-green-500 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ arti.stockData[0].stock }}
                                  </td>
                                  <td
                                    v-else-if="
                                      arti.stockData.length > 0 &&
                                      arti.stockData[0].stock < 0
                                    "
                                    class="text-red-500 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ arti.stockData[0].stock }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <table v-if="similarRef.length > 0">
                              <tbody class="bg-white">
                                <tr
                                  v-for="(artSim, indexArtSim) in similarRef"
                                  :key="indexArtSim"
                                  class="bg-vk1 cursor-pointer"
                                  @click="
                                    replaceArticle(artSim, u.quantity, index)
                                  "
                                >
                                  <td
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ artSim.article.Ref_fournisseur }}
                                  </td>
                                  <td
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    ({{ artSim.article.Code_marque }})
                                  </td>
                                  <td
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ artSim.article.Prix_euro }} €
                                  </td>

                                  <td
                                    v-if="
                                      artSim.stock.length > 0 &&
                                      artSim.stock[0].stock > 0
                                    "
                                    class="text-green-500 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ artSim.stock[0].stock }}
                                  </td>

                                  <td
                                    v-else-if="
                                      artSim.stock.length > 0 &&
                                      artSim.stock[0].stock < 0
                                    "
                                    class="text-red-500 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    {{ artSim.stock[0].stock }}
                                  </td>

                                  <!--
                                    <td
                                      v-if="artSim.stock.length> 0 && artSim.stock > 0"
                                      class="text-green-500 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                    >
                                      {{  artSim.stock[0].stock }}
                                    </td>
                                    <td
                                      v-if="artSim.stock.length> 0 && artSim.stock < 0"
                                      class="text-red-500 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                    >
                                      {{  artSim.stock[0].stock }}
                                    </td>
                                    -->
                                  <td
                                    v-else
                                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                                  >
                                    0
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    <div class="flex flex-col">
                      <p>{{ u.Code_marque }}</p>
                      <p class="bg-gray-300 rounded-md w-fit p-0.5 text-sm">
                        {{ u.FournisseurSelected }} - {{ u.Prefixe_tarif }}
                      </p>
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    <div class="text-sm leading-5 text-gray-900">
                      {{ u.Description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="hidden md:flex md:flex-row">
                      <div
                        class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800"
                        v-if="cmdPreparation.cmdstate[1]"
                      >
                        <span class="my-auto mr-4"> {{ u.quantityPrep }} </span
                        >/
                      </div>
                      <div
                        class="flex"
                        v-if="cmdPreparation.cmdstate[1] && showInputCmdQty"
                      >
                        <button
                          class="inline-flex px-2 font-semibold leading-5 text-gray-800"
                        >
                          <input
                            type="number"
                            v-model="tabQtyCmd[index]"
                            class="w-16 p-1 focus:outline-2 outline-sky-300 rounded shadow border border-blue-600"
                            @input="filterInputCmdQty"
                            @keyup.enter="saveBC(index, tabQtyCmd[index])"
                          />
                        </button>
                        <button
                          class="material-icons-round rounded-md bg-gray-100 text-green-500 hover:text-green-600"
                          @click="saveBC(index, tabQtyCmd[index])"
                        >
                          done
                        </button>
                      </div>
                      <span
                        class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800"
                        v-else
                        >{{ u.quantity }}</span
                      >
                      <div
                        class="flex my-auto"
                        v-if="cmdPreparation.cmdstate[1]"
                      >
                        <span
                          class="ml-9 my-auto inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-green-100 rounded-full"
                          v-if="u.quantity == u.quantityPrep"
                        >
                          Complet
                        </span>
                        <span
                          class="ml-9 my-auto inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-red-100 rounded-full"
                          v-else
                        >
                          Incomplet
                        </span>
                        <button
                          class="inline-flex material-icons-round rounded-full hover:bg-red-400 hover:text-white"
                          @click="removeOne(index)"
                        >
                          remove
                        </button>
                        <button
                          class="inline-flex material-icons-round rounded-full hover:bg-green-500 hover:text-white"
                          @click="addOne(index)"
                        >
                          add
                        </button>
                        <button
                          class="inline-flex material-icons-round rounded hover:text-blue-500 hover:text-white"
                          @click="fillQty(index)"
                        >
                          task_alt
                        </button>
                        <div class="inline-flex ml-4">
                          <div class="flex flex-row items-center relative">
                            <button
                              class="material-icons-round hover:text-blue-500"
                              v-if="
                                u.quantity != u.quantityPrep &&
                                u.quantityOrdered >= 0
                              "
                              @click="
                                openCommandeFournisseur(
                                  index,
                                  u.quantity,
                                  u.quantityPrep
                                )
                              "
                              @dblclick="openRecapVH = !openRecapVH"
                            >
                              shopping_cart_checkout
                            </button>
                            <span
                              v-if="
                                u.quantity != u.quantityPrep &&
                                u.quantityOrdered >= 0
                              "
                              >{{ u.quantityOrdered }}</span
                            >
                          </div>
                          <div
                            class="absolute z-20 p-1 mt-6 bg-white rounded shadow flex flex-row items-center space-x-2"
                            v-if="showCommandeFournisseur[index]"
                          >
                            <input
                              type="number"
                              v-model="quantityCommandeFournisseur"
                              class="w-16 p-1"
                            />
                            <button
                              v-if="!loadingCommandeFournisseur[index]"
                              class="material-icons-round text-sm"
                              @click="addToCommandeFournisseur(u, index)"
                            >
                              done
                            </button>
                            <span
                              class="material-icons-round text-sm animate-spin"
                              v-if="loadingCommandeFournisseur[index]"
                            >
                              sync
                            </span>
                            <button
                              class="material-icons-round text-sm"
                              v-if="!loadingCommandeFournisseur[index]"
                              @click="showCommandeFournisseur[index] = false"
                            >
                              clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col md:hidden">
                      <div class="flex flex-row">
                        <div
                          :class="
                            u.quantity == u.quantityPrep
                              ? 'bg-green-200 p-1 rounded-lg'
                              : 'bg-red-200 p-1 rounded-lg'
                          "
                        >
                          <div
                            class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800"
                            v-if="cmdPreparation.cmdstate[1]"
                          >
                            <span class="my-auto mr-4">
                              {{ u.quantityPrep }} </span
                            >/
                          </div>
                          <div
                            class="flex"
                            v-if="cmdPreparation.cmdstate[1] && showInputCmdQty"
                          >
                            <button
                              class="inline-flex px-2 font-semibold leading-5 text-gray-800"
                            >
                              <input
                                type="number"
                                v-model="tabQtyCmd[index]"
                                class="w-16 p-1 focus:outline-2 outline-sky-300 rounded shadow border border-blue-600"
                                @input="filterInputCmdQty"
                                @keyup.enter="saveBC(index, tabQtyCmd[index])"
                              />
                            </button>
                            <button
                              class="material-icons-round rounded-md bg-gray-100 text-green-500 hover:text-green-600"
                              @click="saveBC(index, tabQtyCmd[index])"
                            >
                              done
                            </button>
                          </div>
                          <span
                            class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800"
                            v-else
                            >{{ u.quantity }}</span
                          >
                        </div>
                        <div v-if="cmdPreparation.cmdstate[1]">
                          <button
                            class="inline-flex material-icons-round rounded-full hover:bg-red-400 hover:text-white"
                            @click="removeOne(index)"
                          >
                            remove
                          </button>
                          <button
                            class="inline-flex material-icons-round rounded-full hover:bg-green-500 hover:text-white"
                            @click="addOne(index)"
                          >
                            add
                          </button>
                          <button
                            class="inline-flex material-icons-round rounded hover:text-blue-500 hover:text-white"
                            @click="fillQty(index)"
                          >
                            task_alt
                          </button>
                        </div>
                      </div>
                      <div>
                        <stockvue
                          :quantity="u.quantity"
                          :Article="u"
                          :key="u.Code_EAN + reloadKeyStock"
                          @setStateGroupement="
                            setStateGroupement($event, index)
                          "
                        />
                      </div>
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap w-52 hidden md:table-cell"
                  >
                    <stockvue
                      :quantity="u.quantity"
                      :Article="u"
                      :key="u.Code_EAN + reloadKeyStock"
                      @setStateGroupement="setStateGroupement($event, index)"
                    />
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    {{ parseFloat(u.Prix_euro).toFixed(2) }} €
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    <div v-if="u.PrixFournisseurType == 'prixnet'">prixnet</div>
                    <div v-else>{{ u.remise }} %</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    <span v-if="u.PrixFournisseurType == 'prixnet'">
                      {{ parseFloat(u.Prix_vente).toFixed(2) }}
                      €
                    </span>
                    <span v-else>
                      {{
                        parseFloat(
                          u.Prix_euro - (u.Prix_euro * u.remise) / 100
                        ).toFixed(2)
                      }}
                      €
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    <span v-if="u.PrixFournisseurType == 'prixnet'">
                      {{ parseFloat(u.Prix_vente * u.quantity).toFixed(2) }}
                      €
                    </span>
                    <span v-else>
                      {{
                        parseFloat(
                          (u.Prix_euro - (u.Prix_euro * u.remise) / 100) *
                            u.quantity
                        ).toFixed(2)
                      }}
                      €
                    </span>
                  </td>
                  <td
                    class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                  >
                    <div
                      v-if="u.toCommandGroupement"
                      class="w-fit flex flex-row items-center space-x-1 bg-blue-100 text-blue-800 text-xs font-medium px-1 py-0.5 rounded border border-blue-400"
                    >
                      <span>{{ u.toCommandGroupementPlateform }}</span>
                      <span>:</span>
                      <input
                        type="Number"
                        v-model="u.stockToTransfert"
                        class="bg-transparent w-10"
                        @change="saveCommande()"
                      />
                      <span
                        class="material-icons-round text-sm text-red-500 cursor-pointer"
                        @click="removeStateGroupement(index)"
                      >
                        close
                      </span>
                    </div>
                    <div v-else class="flex flex-row space-x-2 items-center">
                      <div>-</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <button
                          @click="startDirectTransfertFilliale(u, index)"
                          class="material-symbols-rounded bg-green-500 rounded-full shadow text-white font-lg p-2"
                        >
                          quick_reorder
                        </button>
                        <div v-if="u.directTransfered">
                          <span class="font-bold">{{
                            u.directTransferedQty
                          }}</span>
                        </div>
                        <div v-else>
                          <span class="font-bold text-green-500">0</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { checkGroupement } from "@/hooks/stock/transfertStock.ts";
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createcommande } from "./../hooks/pdf/commande.js";
import { createpreparation } from "./../hooks/pdf/preparation.js";
import { createlivraison } from "./../hooks/pdf/livraison.js";
import { createtransport } from "./../hooks/pdf/transport.js";
import { useToast } from "vue-toastification";
import BreadCrumb from "@/components/Commandes/breadcrumb.vue";
// import ProgressBar from "@/components/Commandes/progressBar.vue";
import stockvue from "@/components/Article/stock.vue";
import {
  decrementReservedStock,
  unreserveStock,
  updateReservation,
  backorderStock,
  reserveStock,
  cancelOrder,
  addStock,
} from "@/hooks/stock/stock.ts";
import articleInfo from "../components/articleInfo/articleInfo.vue";
import { addPanierInst } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import backButton from "@/components/Button/back.vue";
import modalPanierLKQ from "@/components/Comptoir/modalPanierLKQ.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import remisesFournisseurs from "../components/Comptoir/remisesFournisseurs.vue";
import referencesFournisseurs from "../components/Comptoir/referencesFournisseurs.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  createReception,
  validateReception,
} from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import {
  updateLineBC,
  addRelicatPanier,
  updateByBCTotal,
} from "@/hooks/transport/panierTransport.ts";
import { getPaiementLimit } from "@/hooks/tools/getLastDayMonth.ts";
import { createfacture } from "@/hooks/pdf/facture";
import encaissement from "@/components/Comptoir/encaissement.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyCommandes",
  data: () => ({
    showDirectTransfertFillialeModal: false,
    plateformList: [],
    articleDirectTransfert: {},
    plateformDirectTransfert: "",
    indexDirectTransfert: 0,
    maxDirectTransfert: 0,

    infosCommandes: [],
    tempInfosCommandes: [],

    openRefSimil: false,
    openRefArticle: false,
    refSimil: "",

    tabState: "",
    scanEAN: "",
    openRecapVH: false,
    valideCommandestate: true,
    prepareCommandestate: false,
    endCommandestate: false,
    envoiCommandestate: false,
    tabCommandestate: [true, false, false, false, false],
    tempNBArticles: 0,
    validateTempArt: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    validateTempEAN: [],
    showErrorPrepaArticle: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    articlePrepState: [],
    quantitePrep: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0,
    ],
    numDoc: 0,
    numDocConsigne: 0,
    index: 0,
    tabDasilva: [],
    dsvcheck: false,
    cdtnDsv: false,
    pdfinfo: [],
    showMoreFilter: false,
    loadingFile: false,
    showRefSimilar: [],
    similarRef: [],
    loadingRef: false,
    autoCompleteTab: [],
    showAutoComplete: false,
    openModalSearch: false,
    openModalSearchVanHeck: false,
    foundArticle: [],
    searchInput: "",
    showEdit: false,
    showEditTransport: false,
    openCancelCmd: false,
    dataToCanceled: null,
    showInputCmdQty: false,
    tabQtyCmd: [],
    dispoStock: [],
    showCmdFournisseur: false,
    msgCmdFournisseur: "Pièces manquantes commandées.",
    articleInfo_componentKey: 0,
    openModalArticle: false,
    showCommandeFournisseur: [],
    loadingCommandeFournisseur: [],
    quantityCommandeFournisseur: 0,
    setTransport: 0,
    stockFilliale: [],
    loadingValidBL: false,
    loadingValidBT: false,
    dataTransport: {},
    openModalTransport: false,
    loadingAction: false,
    loadingCommandeFilliale: false,
    reloadKeyStock: 0,
    openEncaissement: false,
  }),
  components: {
    modalPanierLKQ,
    stockvue,
    // PulseLoader,
    BreadCrumb,
    // ProgressBar,
    articleInfo,
    backButton,
    remisesFournisseurs,
    referencesFournisseurs,
    PulseLoader,
    // modalTransport,
    encaissement,
  },
  computed: {
    ...mapGetters(["user", "plateform", "cmdPreparation"]),
    setTodayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      return today;
    },
    setNewTotalPrice() {
      var total = 0.0;
      var totalPrice = 0.0;
      this.cmdPreparation.Articles.forEach((element) => {
        if (element.PrixFournisseurType == "prixnet") {
          totalPrice += parseFloat(element.Prix_vente) * element.quantity;
        } else {
          totalPrice +=
            (parseFloat(element.Prix_euro) -
              (parseFloat(element.Prix_euro) * element.remise) / 100) *
            element.quantity;
        }
        if (parseFloat(this.cmdPreparation.clientInfo[0].francodeport) >= 0) {
          if (
            totalPrice >=
            parseFloat(this.cmdPreparation.clientInfo[0].francodeport)
          ) {
            this.cmdPreparation.clientInfo[0].fraisportsave = "0";
            total = totalPrice;
          } else {
            this.cmdPreparation.clientInfo[0].fraisportsave =
              this.cmdPreparation.clientInfo[0].fraisdeport;
            total =
              totalPrice +
              parseFloat(this.cmdPreparation.clientInfo[0].fraisportsave);
          }
        }
      });
      return total;
    },
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      // var qty = 0;
      this.cmdPreparation.Articles.forEach((element) => {
        // qty = element.quantity;
        if (element.PrixFournisseurType == "prixnet") {
          totalRemise = parseFloat(
            parseFloat(element.Prix_vente * element.quantity).toFixed(2)
          );
        } else {
          totalRemise = parseFloat(
            parseFloat(
              (element.Prix_euro - element.Prix_euro * (element.remise / 100)) *
                element.quantity
            ).toFixed(2)
          );
        }
        total = total + totalRemise;
        this.cmdPreparation.TotalPrice = total + this.cmdPreparation.Transport;
      });
      // this.cmdPreparation.pdfData.forEach((element) => {
      //   element.quantite = qty;
      // });
      return this.cmdPreparation.TotalPrice;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  methods: {
    ...mapActions([
      "suppStockArticle",
      "addStockArticle",
      // "setMvtStock",
      "searchDirect",
      "searchTranslatedDirect",
      "getCmdPreparation",
      "setArticleInfo",
      "createcomptoirsession",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    openModalRefSimil(ref) {
      this.refSimil = ref;
      this.openRefSimil = true;
    },
    getRefSimilaire(ref) {
      this.refSimil = ref;
      this.openRefArticle = true;
    },
    closeTableau() {
      this.showRefSimilar = [];
    },
    searchInputDirect() {
      axios
        .post(`${process.env.VUE_APP_API}/searchEngine/searchArticle`, {
          Ref_fournisseur: this.searchInput,
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.foundArticle = res.data;
        });
    },
    // archiver cmdPreparation
    archiverCommande() {
      axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            archived: true,
          }
        )
        .then(() => {
          this.toast.success("Commande archivée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    cancelOneCommande() {
      axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            archived: true,
            status: "archived",
          }
        )
        .then(() => {
          this.toast.success("Commande annulée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    async getOneCommande() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/commandesClient/getonecmd/${this.cmdPreparation._id}`
        )
        .then((response) => {
          this.setTransport = this.cmdPreparation.Transport;
          this.getCmdPreparation(response.data[0]);
        });
    },
    async validerCommande() {
      this.loadingAction = true;
      this.tabCommandestate = [false, true, false, false, false];
      await axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            status: "Commande validée",
            PourcentageStatus: "25",
            cmdstate: [false, true, false, false, false],
          }
        )
        .then(async () => {
          this.getOneCommande();
        });
      this.loadingAction = false;
    },
    async attenteCommande() {
      this.loadingAction = true;
      await axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          { status: "Commande en attente" }
        )
        .then(() => {
          this.getOneCommande();
        });
      this.loadingAction = false;
    },
    preparerCommande() {
      this.tabCommandestate = [false, false, true, false, false];
      axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            status: "Commande en cours de préparation",
            PourcentageStatus: "50",
            cmdstate: [false, false, true, false, false],
          }
        )
        .then(() => {
          this.getOneCommande();
        });
    },
    async finirCommande() {
      this.loadingAction = true;
      // commandes professionnelles
      if (this.cmdPreparation.clientInfo[0].professionnel == true) {
        await this.finirCommandePro();
      }
      // commandes particuliers
      else {
        this.openEncaissement = true;
        // const tabBC = await axios.post(
        //   `${process.env.VUE_APP_API}/commandes/getone`,
        //   {
        //     uid: {
        //       BCNumber: this.cmdPreparation.BCNumber,
        //       plateform: this.user.proprietaire,
        //     },
        //   }
        // );
        // tabBC.data[0].reglement.forEach((element) => {
        //   element.disabled = true;
        // });
        // tabBC.data[0].reglement = tabBC.data[0].reglement.filter(
        //   (ligne) => ligne.placeholder !== "Reste dû"
        // );
        // this.createcomptoirsession({
        //   clientFocus: this.cmdPreparation.clientInfo,
        //   Articles: this.cmdPreparation.Articles,
        //   refClient: this.cmdPreparation.Note,
        //   consigneData: [],
        //   consigneNonData: [],
        //   dsvCheck: false,
        //   remisegenerale: 0,
        //   documentType: "Facturation",
        //   reglementTab: tabBC.data[0].reglement,
        // });
      }
      this.loadingAction = false;
    },
    async verifPreparation() {
      let preparedState = false;
      for (const art of this.cmdPreparation.Articles) {
        if (art.quantityPrep != 0) {
          preparedState = true;
        }
      }
      return preparedState;
    },
    async finirCommandePro() {
      const prep = await this.verifPreparation();
      if (prep) {
        this.tabCommandestate = [false, false, false, true, false];
        let relicatCmd = this.cmdPreparation;
        let doReliquat = false;
        let numDoc = 0;
        let ArticlesRelicat = [];
        let ArticlesOK = [];
        for (const [index, element] of relicatCmd.Articles.entries()) {
          let elementcopy = { ...element };
          if (element.quantityPrep < element.quantity) {
            doReliquat = true;
            let art = { ...element };
            art.quantity = element.quantity - element.quantityPrep;
            art.quantityPrep = 0;
            ArticlesRelicat.push(art);
            elementcopy.quantity = element.quantityPrep;
            if (elementcopy.quantityPrep != 0) {
              ArticlesOK.push(elementcopy);
            }
          } else if (element.quantityPrep == element.quantity) {
            ArticlesOK.push(elementcopy);
          }
        }

        if (doReliquat) {
          var pdfinfo = [];
          var prixRemise = 0.0;
          var total = 0.0;
          var total2 = 0.0;
          ArticlesRelicat.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemise = parseFloat(element.Prix_vente);
            } else {
              prixRemise =
                parseFloat(element.Prix_euro) -
                parseFloat(element.Prix_euro) *
                  (ArticlesRelicat[index].remise / 100);
            }
            pdfinfo[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: element.quantity,
              remise: element.remise,
              remisegenerale: 0,
              commentaire: "",
              prixU: prixRemise,
              total: prixRemise * element.quantity,
              totalSansRemise: parseFloat(element.Prix_euro) * element.quantity,
            };

            // total
            if (element.PrixFournisseurType == "prixnet") {
              total2 = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              total2 = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            total = total + total2 + parseInt(this.cmdPreparation.Transport);
          });
          await axios
            .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              numDoc = response.data + 1;
            });
          await axios.post(`${process.env.VUE_APP_API}/commandes`, {
            Owner: this.cmdPreparation.plateform,
            Name: this.user.username,
            Date: new Date(),
            Numero: numDoc,
            TotalPrice: total,
            TotalPriceTTC: total + total * 0.2,
            Client: this.cmdPreparation.Client,
            IsProcessed: false,
            Note: this.cmdPreparation.Note,
            Articles: ArticlesRelicat,
            reglement: [],
            pdfData: pdfinfo,
            clientInfo: this.cmdPreparation.clientInfo,
            Transport: 0,
            relicatState: true,
            relicatFromNumber: this.cmdPreparation.BCNumber,
          });
          await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
            Type: "Commande",
            cmdPro: this.cmdPreparation.clientInfo[0].professionnel,
            Owner: this.cmdPreparation.Owner,
            Name: this.user.username,
            plateform: this.cmdPreparation.plateform,
            Date: new Date(),
            Numero: 123,
            TotalPrice: total,
            TotalPriceTTC: total + total * 0.2,
            Client: this.cmdPreparation.Client,
            IsProcessed: false,
            Note: this.cmdPreparation.Note,
            Commentaire: this.cmdPreparation.Commentaire,
            clientInfo: this.cmdPreparation.clientInfo,
            BCNumber: numDoc,
            Articles: ArticlesRelicat,
            status: "Commande Passée",
            preparedBy: "personne",
            refCommande: "",
            PourcentageStatus: "10",
            cmdstate: [true, false, false, false, false],
            Transport: 0,
            relicatState: true,
            relicatFromNumber: this.cmdPreparation.BCNumber,
          });

          await axios
            .put(
              `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
              {
                status: "Commande prête",
                PourcentageStatus: "75",
                cmdstate: [false, false, false, true, false],
                ArticleState: this.articlePrepState,
                Articles: ArticlesOK,
                relicatState: true,
                relicatNumber: numDoc,
              }
            )
            // await axios
            // .put(
            //   `${process.env.VUE_APP_API}/commandes/${this.cmdPreparation._id}`,
            //   {
            //     status: "Commande prête",
            //     PourcentageStatus: "75",
            //     cmdstate: [false, false, false, true, true],
            //     ArticleState: this.articlePrepState,
            //     Articles: ArticlesOK,
            //     relicatState: true,
            //     relicatNumber: numDoc,
            //   }
            // )
            .then(() => {
              this.getOneCommande();
            });

          await addRelicatPanier(
            this.user.proprietaire,
            this.cmdPreparation.clientInfo[0]._id,
            this.user.username,
            {
              type: "Bon de commande",
              created_here: "préparation de commande",
              BCnumber: numDoc,
              BCdate: new Date(),
              BCuser: this.user.username,
              BCtotalPrice: total,
              BLnumber: "",
              BLdate: "",
              BLuser: "",
              BLtotalPrice: 0,
            },
            this.cmdPreparation.BCNumber
          );
        } else {
          await axios
            .put(
              `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
              {
                status: "Commande prête",
                PourcentageStatus: "75",
                cmdstate: [false, false, false, true, false],
                ArticleState: this.articlePrepState,
              }
            )
            .then(() => {
              this.getOneCommande();
            });
        }
      } else {
        this.toast.error("Commande non préparé !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async finirCommandePart(reglementTab) {
      var pdfinfo = [];
      for (const [index, element] of this.cmdPreparation.Articles.entries()) {
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          remisegenerale: 0,
          commentaire: "",
          prixU: element.Prix_euro - element.Prix_euro * (element.remise / 100),
          total: element.Prix_vente * element.quantity,
          totalSansRemise: element.Prix_euro * element.quantity,
        };

        if (element.Prefixe_tarif) {
          unreserveStock(
            this.user._id,
            this.user.proprietaire,
            element._id,
            element.Ref_fournisseur,
            element.Code_marque,
            element.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "",
            element.Prefixe_tarif,
            this.cmdPreparation.Client,
            this.cmdPreparation.BCNumber,
            "Facture",
            "préparation",
            element.quantity
          );
        }
      }

      await axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            status: "Commande et BL disponibles",
            PourcentageStatus: "100",
            cmdstate: [false, false, false, false, true],
            ArticleState: this.articlePrepState,
            archived: true,
          }
        )
        .then(async () => {
          let numDoc = 0;
          await axios
            .post(`${process.env.VUE_APP_API}/facture/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              numDoc = response.data + 1;
            });

          await axios
            .post(`${process.env.VUE_APP_API}/facture`, {
              Name: this.user.username,
              Owner: this.user.proprietaire,
              Date: new Date(),
              Numero: numDoc,
              TotalPrice: this.cmdPreparation.TotalPrice,
              TotalPriceTTC:
                this.cmdPreparation.TotalPrice +
                this.cmdPreparation.TotalPrice * 0.2,
              paymentDeadline: await getPaiementLimit(
                this.cmdPreparation.clientInfo[0].delaidepaiement
              ),
              Client: this.cmdPreparation.Owner,
              Note: this.cmdPreparation.Note,
              Articles: this.cmdPreparation.Articles,
              reglement: reglementTab,
              pdfData: pdfinfo,
              clientInfo: this.cmdPreparation.clientInfo,
              Transport: 0,
            })
            .then((res) => {
              if (this.cmdPreparation.TotalPrice >= 0) {
                createfacture(res.data, false, false);
              } else {
                createfacture(res.data, false, true);
              }
            });
          this.$router.push("/commandePro");
        });
    },
    async validerBL() {
      // if (this.cmdPreparation.clientInfo[0].livrepartransporteur == true) {
      //   this.openModalTransport = true;
      // } else {
      this.loadingCommandeFilliale = true;
      this.loadingValidBL = true;
      await this.reserveStockFilliale();
      await this.envoyerCommande();
      if (this.cmdPreparation.transfert_id) {
        await validateReception(
          this.user.proprietaire,
          this.cmdPreparation.transfert_id,
          this.cmdPreparation.Articles,
          this.user.username
        );
      }
      this.loadingCommandeFilliale = false;
      this.loadingValidBL = false;

      const transportData = {
        type: "Bon de livraison",
        created_here: "préparation de commande",
        BCnumber: this.cmdPreparation.BCNumber.toString(),
        BCdate: "",
        BCuser: "",
        BCtotalPrice: 0,
        BLnumber: this.numDoc.toString(),
        BLdate: new Date(),
        BLuser: this.user.username,
        BLtotalPrice: this.setTotal,
      };
      await updateLineBC(
        this.user.proprietaire,
        this.cmdPreparation.clientInfo[0]._id,
        transportData,
        this.cmdPreparation.BCNumber.toString()
      );
    },
    async validerBT() {
      // if (this.cmdPreparation.clientInfo[0].livrepartransporteur == true) {
      //   this.openModalTransport = true;
      // } else {
      this.loadingCommandeFilliale = true;
      this.loadingValidBL = true;
      await this.reserveStockFilliale();
      await this.envoyerCommandeBT();
      if (this.cmdPreparation.transfert_id) {
        await validateReception(
          this.user.proprietaire,
          this.cmdPreparation.transfert_id,
          this.cmdPreparation.Articles,
          this.user.username
        );
      }
      this.loadingCommandeFilliale = false;
      this.loadingValidBL = false;

      const transportData = {
        type: "Bon de livraison",
        created_here: "préparation de commande",
        BCnumber: this.cmdPreparation.BCNumber.toString(),
        BCdate: "",
        BCuser: "",
        BCtotalPrice: 0,
        BLnumber: this.numDoc.toString(),
        BLdate: new Date(),
        BLuser: this.user.username,
        BLtotalPrice: this.setTotal,
      };
      await updateLineBC(
        this.user.proprietaire,
        this.cmdPreparation.clientInfo[0]._id,
        transportData,
        this.cmdPreparation.BCNumber.toString()
      );
    },
    async envoyerCommande() {
      this.openModalTransport = false;

      this.tabCommandestate = [false, false, false, false, true];

      await axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            status: "Commande et BL disponibles",
            PourcentageStatus: "100",
            cmdstate: [false, false, false, false, true],
          }
        )
        .then(async () => {
          axios.put(
            `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
            {
              archived: true,
            }
          );
          this.cdtnDsv = false;
          await this.creerBL(this.cmdPreparation, false);
        });
    },
    async envoyerCommandeBT() {
      this.openModalTransport = false;
      this.loadingValidBT = true;
      this.tabCommandestate = [false, false, false, false, true];
      await axios
        .put(
          `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
          {
            status: "Commande et BL disponibles",
            PourcentageStatus: "100",
            cmdstate: [false, false, false, false, true],
          }
        )
        .then(async () => {
          axios.put(
            `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
            {
              archived: true,
            }
          );
          this.cdtnDsv = false;
          await this.creerBL(this.cmdPreparation, true);
          this.loadingValidBT = false;
        });
    },
    setRetour() {
      this.$router.push("/commandePro");
      this.dsvcheck = false;
    },
    updateTempArticle(index, code_inscrit, code_art) {
      if (code_inscrit == code_art) {
        this.validateTempArt[index] = true;
        this.showErrorPrepaArticle[index] = false;
        this.articlePrepState.push(true);
      } else {
        this.showErrorPrepaArticle[index] = true;
        this.validateTempArt[index] = false;
        this.articlePrepState.push(false);
      }
    },
    removeOne(index) {
      if (this.cmdPreparation.Articles[index].quantityPrep > 0) {
        this.cmdPreparation.Articles[index].quantityPrep =
          this.cmdPreparation.Articles[index].quantityPrep - 1;
      }
      this.saveCommande();
    },
    addOne(index) {
      if (
        this.cmdPreparation.Articles[index].quantityPrep <
        this.cmdPreparation.Articles[index].quantity
      ) {
        this.cmdPreparation.Articles[index].quantityPrep =
          this.cmdPreparation.Articles[index].quantityPrep + 1;
      }
      this.saveCommande();
    },
    fillQty(index) {
      this.cmdPreparation.Articles[index].quantityPrep = parseInt(
        this.cmdPreparation.Articles[index].quantity
      );
      this.saveCommande();
    },
    fillQtyOk() {
      this.cmdPreparation.Articles.forEach((element, index) => {
        this.cmdPreparation.Articles[index].quantityPrep = parseInt(
          element.quantity
        );
      });
      this.saveCommande();
    },
    addByScan() {
      var ean = this.cmdPreparation.Articles.find(
        (element) => element.Code_EAN == this.scanEAN
      );
      if (ean != []) {
        var index = this.cmdPreparation.Articles.indexOf(ean);
        this.fillQty(index);
        this.articlePrepState[index] = true;
      }
    },
    async creerBL(tab, dataPDF) {
      var pdfinfo = [];
      var pdfinfoCons = [];
      var tempDsv = [];

      for (const [index, element] of tab.Articles.entries()) {
        if (element.Code_marque == "DA SILVA") {
          tempDsv.push(element);
          pdfinfoCons.push({
            description: element.Description,
            ref: element.Ref_fournisseur,
            quantite: element.quantity,
            remise: element.remise,
            remisegenerale: 0,
            commentaire: "",
            // remiseSupp: this.remiseTabSCD[index],
            prixU:
              element.Prix_euro - element.Prix_euro * (element.remise / 100),
            // total: prixRemiseSupp * this.qtyTab[index],
            total: element.Prix_euro * element.quantity,
          });
        }
      }

      for (const [index, element] of tab.Articles.entries()) {
        //TODO ajouter la verif des marges minimum

        // prixRemise =
        //   element.Prix_euro - element.Prix_euro * (this.remiseTab[index] / 100);
        // prixRemiseSupp =
        //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          remisegenerale: 0,
          commentaire: "",
          // remiseSupp: this.remiseTabSCD[index],
          prixU: element.Prix_euro - element.Prix_euro * (element.remise / 100),
          // total: prixRemiseSupp * this.qtyTab[index],
          total: element.Prix_vente * element.quantity,
          totalSansRemise: element.Prix_euro * element.quantity,
        };

        if (element.Prefixe_tarif) {
          unreserveStock(
            this.user._id,
            this.user.proprietaire,
            element._id,
            element.Ref_fournisseur,
            element.Code_marque,
            element.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "",
            element.Prefixe_tarif,
            this.cmdPreparation.Client,
            this.cmdPreparation.BCNumber,
            "BL",
            "préparation",
            element.quantity
          );

          // decrementReservedStock(
          //   element.Code_EAN,
          //   0,
          //   element.quantity,
          //   this.user.proprietaire,
          //   element.Ref_fournisseur,
          //   element.Prefixe_tarif,
          //   element.Code_marque,
          //   this.user.username,
          //   "Preparation de commande : Bon de commande n°" +
          //     this.cmdPreparation.BCNumber +
          //     " édité par : " +
          //     this.user.username,
          //   this.cmdPreparation.Client,
          //   this.cmdPreparation.BCNumber
          // );
        }
      }
      this.pdfinfo = pdfinfo;

      if (!tab.Fournisseur || tab.Fournisseur == "") {
        if (this.plateform.structure_depot == true && tempDsv.length > 0) {
          await this.creerDsvBL(tab, tempDsv, pdfinfoCons, false, dataPDF);

          await this.creerArtBL(tab, pdfinfo, dataPDF);
        } else {
          await this.creerArtBL(tab, pdfinfo, dataPDF);
        }
      }
      if (tab.Fournisseur && tab.Fournisseur != "") {
        if (this.plateform.structure_depot == true && tempDsv.length > 0) {
          await this.creerDsvBL(tab, tempDsv, pdfinfoCons, true, dataPDF);
        } else {
          await this.creerArtBL(tab, pdfinfo, dataPDF);
        }
      } else if (tab.Fournisseur && tab.Fournisseur == this.user.proprietaire) {
        if (this.plateform.structure_depot == true && tempDsv.length > 0) {
          await this.creerDsvBL(tab, tempDsv, pdfinfoCons, false, dataPDF);

          await this.creerArtBL(tab, pdfinfo, dataPDF);
        } else {
          await this.creerArtBL(tab, pdfinfo, dataPDF);
        }
      }
    },
    async creerArtBL(tab, pdfinfo, dataPDF) {
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.numDoc = response.data + 1;
        });
      let dataBL = {
        Name: this.user.username,
        Numero: this.numDoc,
        TotalPrice: tab.TotalPrice,
        TotalPriceTTC: tab.TotalPrice + tab.TotalPrice * 0.2,
        Note: tab.Note,
        Client: tab.Owner,
        Owner: this.user.proprietaire,
        IsProcessed: false,
        Articles: tab.Articles,
        pdfData: pdfinfo,
        clientInfo: tab.clientInfo,
        Transport: 0,
        Fournisseur: tab.Name == "FRIGAIR" ? "FRIGAIR" : "",
        dataTransport: this.dataTransport,
      };
      await axios
        .post(`${process.env.VUE_APP_API}/bdl`, dataBL)
        .then(() => {
          axios.put(`${process.env.VUE_APP_API}/commandesClient/${tab._id}`, {
            BLNumber: this.numDoc,
          });
        })
        .then(async () => {
          await this.getOneCommande();
          this.openBLValide(this.cmdPreparation, this.numDoc, dataPDF);
          // IF BILLAFTERBL
          if (tab.clientInfo[0]?.billAfterBL) {
            let numDocBill = 0;
            await axios
              .post(`${process.env.VUE_APP_API}/facture/getlast`, {
                uid: this.user.proprietaire,
              })
              .then((response) => {
                numDocBill = response.data + 1;
              });
            await axios
              .post(`${process.env.VUE_APP_API}/facture`, {
                Name: this.user.username,
                Owner: this.user.proprietaire,
                Date: new Date(),
                Numero: numDocBill,
                TotalPrice: tab.TotalPrice,
                TotalPriceTTC: tab.TotalPrice + tab.TotalPrice * 0.2,
                paymentDeadline: await getPaiementLimit(
                  this.cmdPreparation.clientInfo[0].delaidepaiement
                ),
                Client: tab.Owner,
                IsProcessed: false,
                Note: tab.Note,
                Articles: tab.Articles,
                reglement: tab.reglementTab,
                pdfData: pdfinfo,
                clientInfo: tab.clientInfo,
                Transport: 0,
                // Bdls: [dataBL],
              })
              .then((res) => {
                if (tab.TotalPrice >= 0) {
                  createfacture(res.data, false, false);
                } else {
                  createfacture(res.data, false, true);
                }
              });
            await axios.post(`${process.env.VUE_APP_API}/bdl/updateByNumber`, {
              Numero: this.numDoc.toString(),
              plateform: this.user.proprietaire,
              archived: true,
            });
          }
          this.$router.push("/commandePro");
        });
    },
    async creerDsvBL(tab, tabArt, tabPdf, check, dataPDF) {
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.numDocConsigne = response.data + 1 + "D";
        });

      var clientCode = "";
      var blType = "";
      // Prix total ht des articles dasilva
      var total = 0.0;
      var totalRemise = 0.0;
      tabArt.forEach((element, index) => {
        totalRemise =
          parseFloat(element.Prix_euro) -
          parseFloat(element.Prix_euro) * (tabArt[index].remise / 100);
        total = total + totalRemise * tabArt[index].quantity;
      });

      if (total >= 0) {
        blType = "F";
      } else {
        blType = "A";
      }
      if (check == true) {
        clientCode = tab.clientInfo[0].numcomptedasilva;
      } else {
        clientCode = this.plateform.dasilva.codeClient;
      }
      let orderdsv = false;
      var numCmd = "";

      if (tab.Name == "DA SILVA") {
        orderdsv = true;
        numCmd = tab.BCNumber;
      } else {
        numCmd = this.numDocConsigne;
        orderdsv = false;
      }

      axios
        .post(`${process.env.VUE_APP_API}/bdl`, {
          Name: this.user.username,
          Numero: this.numDocConsigne,
          TotalPrice: total,
          TotalPriceTTC: total + total * 0.2,
          Note: tab.Note,
          Client: tab.Owner,
          Owner: this.user.proprietaire,
          IsProcessed: false,
          Articles: tabArt,
          pdfData: tabPdf,
          clientInfo: tab.clientInfo,
          Transport: 0,
          Fournisseur: "DA SILVA",
          orderByDsv: orderdsv,
          textFile: {
            consignataire: this.plateform.dasilva.consignataire,
            typeBL: blType,
            codeClient: clientCode,
            numeroCmd: numCmd,
            commentaire: "",
          },
          dataTransport: this.dataTransport,
        })
        .then(() => {
          axios.put(`${process.env.VUE_APP_API}/commandesClient/${tab._id}`, {
            BLNumber: this.numDocConsigne,
          });
        })
        .then(async () => {
          this.openBLValide(tab, this.numDocConsigne, dataPDF);
          this.$router.push("/commandePro");
          // this.reloadPage();
        });
    },
    filterInputCmdQty(event) {
      event.preventDefault();
      const inputValue = event.target.value;
      const filteredValue = inputValue.replace(/[,/.]/g, "");
      event.target.value = parseInt(filteredValue);
      if (inputValue == "") {
        event.target.value = parseInt(0);
      }
    },
    openBL(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDF(response.data[0]);
        });
    },
    openBT(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDFtransport(response.data[0]);
        });
    },
    async openBLValide(tab, numDoc, dataPDF) {
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: { BLNumber: numDoc, plateform: this.user.proprietaire },
        })
        .then((response) => {
          if (dataPDF == true) {
            createtransport(response.data[0], false);
          } else {
            this.createPDF(response.data[0]);
          }
          this.toast.success("Commande archivée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    async openBC() {
      console.log(this.cmdPreparation.BCNumber);
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getone`, {
          uid: {
            BCNumber: this.cmdPreparation.BCNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          console.log("response BC : ", response);
          this.createPDFcommande(response.data[0]);
        });
    },
    openBP() {
      console.log("Numero: ", this.cmdPreparation.BCNumber);
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getone`, {
          uid: {
            BCNumber: this.cmdPreparation.BCNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDFpreparation(response.data[0]);
        });
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(objet, false, false);
      } else {
        createlivraison(objet, true, false);
      }
    },
    createPDFpreparation(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createpreparation(objet, false);
      } else {
        createpreparation(objet, true);
      }
    },
    createPDFcommande(objet) {
      var today = new Date();

      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createcommande(objet, false);
      } else {
        createcommande(objet, true);
      }
    },
    createPDFtransport(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createtransport(objet, false);
      } else {
        createtransport(objet, false);
      }
    },
    findSimilar(article, index) {
      this.similarRef = [];
      if (this.showRefSimilar[index] == true) {
        this.showRefSimilar[index] = !this.showRefSimilar[index];
        this.loadingRef = false;
      } else {
        this.showRefSimilar[index] = !this.showRefSimilar[index];
        this.loadingRef = true;
        var Ref_fournisseur;
        if (article.Code_marque == "MEYLE") {
          Ref_fournisseur = article.Ref_complementaire;
        } else {
          Ref_fournisseur = article.Ref_fournisseur;
        }

        axios
          .post(`${process.env.VUE_APP_API}/searchEngine/getRefSimilaire`, {
            Ref_fournisseur: Ref_fournisseur,
            plateform: this.user.proprietaire,
          })
          .then((res) => {
            this.loadingRef = false;
            this.similarRef = res.data;
          });
      }
    },
    closeSimilar(index) {
      this.showRefSimilar[index] = !this.showRefSimilar[index];
      this.loadingRef = false;
      this.similarRef = [];
    },
    async replaceArticle(article, quantity, index) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/prepareArticleDirect`,
        {
          articleData: article,
          plateform: this.user.proprietaire,
          client: this.cmdPreparation.clientInfo[0].Name,
          groupeClient: this.cmdPreparation.clientInfo[0].Group.Name,
          quantity: quantity,
        }
      );
      if (res.data.error.state) {
        this.error("une erreur est survenue");
      } else {
        var articleData = res.data.data;
        articleData.lastArticle = article;

        await reserveStock(
          this.user._id,
          this.user.proprietaire,
          articleData._id,
          articleData.Ref_fournisseur,
          articleData.Code_marque,
          articleData.Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "Commande n°" +
            this.cmdPreparation.BCNumber +
            ": " +
            this.cmdPreparation.Articles[index].Ref_fournisseur +
            " remplacé par " +
            articleData.Ref_fournisseur +
            " et édité par " +
            this.user.username,
          articleData.Prefixe_tarif,
          this.cmdPreparation.Client,
          this.cmdPreparation.BCNumber,
          "BC",
          "préparation de commande",
          quantity
        );

        // await incrementStockDecrementReserved(
        //   this.cmdPreparation.Articles[index].Code_EAN,
        //   quantity,
        //   quantity,
        //   this.user.proprietaire,
        //   this.cmdPreparation.Articles[index].Ref_fournisseur,
        //   this.cmdPreparation.Articles[index].Prefixe_tarif,
        //   this.cmdPreparation.Articles[index].Code_marque,
        //   this.user.username,
        //   "Remplacement d'article " +
        //     this.cmdPreparation.Articles[index].Ref_fournisseur +
        //     " <-> " +
        //     articleData.Ref_fournisseur +
        //     " : Bon de commande n°" +
        //     this.cmdPreparation.BCNumber +
        //     " édité par : " +
        //     this.user.username +
        //     ". " +
        //     quantity.toString() +
        //     " remis en stock.",
        //   this.user.proprietaire,
        //   this.cmdPreparation.BCNumber
        // );

        await cancelOrder(
          this.user._id,
          this.user.proprietaire,
          this.cmdPreparation.Articles[index]._id,
          this.cmdPreparation.Articles[index].Ref_fournisseur,
          this.cmdPreparation.Articles[index].Code_marque,
          this.cmdPreparation.Articles[index].Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "Remise en stock après remplacement par " +
            articleData.Ref_fournisseur +
            " Commande " +
            this.cmdPreparation.BCNumber,
          this.cmdPreparation.Articles[index].Prefixe_tarif,
          this.cmdPreparation.Client,
          this.cmdPreparation.BCNumber,
          "BL",
          "préparation de commande",
          quantity
        );

        // await decrementStockIncrementReserved(
        //   articleData.Code_EAN,
        //   quantity,
        //   quantity,
        //   this.user.proprietaire,
        //   articleData.Ref_fournisseur,
        //   articleData.Prefixe_tarif,
        //   articleData.Code_marque,
        //   this.user.username,
        //   "Remplacement d'article " +
        //     this.cmdPreparation.Articles[index].Ref_fournisseur +
        //     " <-> " +
        //     articleData.Ref_fournisseur +
        //     " : Bon de commande n°" +
        //     this.cmdPreparation.BCNumber +
        //     " édité par : " +
        //     this.user.username +
        //     ". " +
        //     quantity.toString() +
        //     " réservé.",
        //   this.user.proprietaire,
        //   this.cmdPreparation.BCNumber
        // );

        this.cmdPreparation.Articles[index] = articleData;
        this.cmdPreparation.Articles[index].quantity = quantity;
        // const indexToReplace = this.cmdPreparation.Articles.findIndex((a) => a.id === article.id)
        // this.cmdPreparation.Articles.splice(indexToReplace, 1, articleData);
        this.closeSimilar(index);
        this.cmdPreparation.TotalPrice = this.setNewTotalPrice;
        this.cmdPreparation.TotalPriceTTC = parseFloat(
          this.setNewTotalPrice + this.setNewTotalPrice * 0.2
        );

        let response = await axios.post(
          `${process.env.VUE_APP_API}/commandes/getone`,
          {
            uid: {
              BCNumber: this.cmdPreparation.BCNumber,
              plateform: this.user.proprietaire,
            },
          }
        );
        var tabBC = response.data[0];
        tabBC.pdfData = this.cmdPreparation.pdfData;
        tabBC.Articles = this.cmdPreparation.Articles;
        tabBC.TotalPrice = this.cmdPreparation.TotalPrice;
        tabBC.TotalPriceTTC = this.cmdPreparation.TotalPriceTTC;
        tabBC.Transport = 0;
        await axios.put(
          `${process.env.VUE_APP_API}/commandes/${response.data[0]._id}`,
          tabBC
        );

        await updateByBCTotal(
          this.user.proprietaire,
          tabBC.clientInfo[0]._id,
          this.cmdPreparation.BCNumber.toString(),
          tabBC.TotalPrice
        );

        await this.saveCommande();
        this.reloadKeyStock++;
      }
    },
    async saveCommande() {
      await axios.put(
        `${process.env.VUE_APP_API}/commandesClient/${this.cmdPreparation._id}`,
        this.cmdPreparation
      );
      await this.getOneCommande();
    },
    async saveBC(index, qtyCmd) {
      let response = await axios.post(
        `${process.env.VUE_APP_API}/commandes/getone`,
        {
          uid: {
            BCNumber: this.cmdPreparation.BCNumber,
            plateform: this.user.proprietaire,
          },
        }
      );
      if (
        parseInt(qtyCmd) &&
        response.data[0].Articles[index].quantity != parseInt(qtyCmd)
      ) {
        await updateReservation(
          this.user._id,
          this.user.proprietaire,
          response.data[0].Articles[index]._id,
          response.data[0].Articles[index].Ref_fournisseur,
          response.data[0].Articles[index].Code_marque,
          response.data[0].Articles[index].Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          response.data[0].Articles[index].Description,
          response.data[0].Articles[index].Prefixe_tarif,
          this.user.proprietaire,
          response.data[0].Numero,
          "BC",
          "préparation: modif quantité commandée",
          parseInt(qtyCmd)
        );
      }
      this.cmdPreparation.Articles[index].quantity = parseInt(qtyCmd);
      this.cmdPreparation.TotalPrice = this.setNewTotalPrice;
      this.cmdPreparation.TotalPriceTTC = parseFloat(
        this.setNewTotalPrice + this.setNewTotalPrice * 0.2
      );

      parseInt(qtyCmd);
      var tabBC = response.data[0];
      tabBC.pdfData.forEach(() => {
        tabBC.pdfData[index].quantite = parseInt(qtyCmd);
      });
      tabBC.Articles.forEach(() => {
        tabBC.Articles[index].quantity = parseInt(qtyCmd);
      });
      tabBC.TotalPrice = this.setNewTotalPrice;
      tabBC.Transport = 0;

      await axios.put(
        `${process.env.VUE_APP_API}/commandes/${response.data[0]._id}`,
        tabBC
      );

      await updateByBCTotal(
        this.user.proprietaire,
        tabBC.clientInfo[0]._id,
        this.cmdPreparation.BCNumber.toString(),
        tabBC.TotalPrice
      );

      this.tabQtyCmd = [];
      this.cmdPreparation.Articles[index].quantityPrep = 0;
      this.showInputCmdQty = false;
      this.saveCommande();
      this.getCmdPreparation(this.cmdPreparation);
      this.reloadKeyStock++;
      this.toast.success("Quantité commandée enregistrée !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    enabledInputCmdQty() {
      this.showInputCmdQty = !this.showInputCmdQty;
    },
    async saveMsgCmdFourn(msg) {
      if (msg != "") {
        this.loadingAction = true;
        this.cmdPreparation.commandeFournisseur.user = this.user.username;
        this.cmdPreparation.status = "Commandé chez le fournisseur";
        await this.saveCommande();
        this.showCmdFournisseur = false;
        this.toast.success("Message enregistrée !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.loadingAction = false;
      } else {
        this.toast.warning("Laissez un commentaire !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async saveFraisDePort(setTransport) {
      this.cmdPreparation.Transport = setTransport;
      await this.saveCommande();
      this.toast.success("Transport enregistré !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.showEditTransport = false;
    },
    // async getBdlsOfTheDayByClient() {
    //   await axios
    //     .post(`${process.env.VUE_APP_API}/bdl/getBdlsOfTheDay`, {
    //       plateform: this.user.plateform,
    //       clientId: this.cmdPreparation.clientInfo[0]._id,
    //       returnData: true,
    //       user: this.user.username,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //     });
    // },
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    openCommandeFournisseur(index, quantity, quantityPrep) {
      if (this.showCommandeFournisseur[index]) {
        this.showCommandeFournisseur[index] = false;
      } else {
        this.showCommandeFournisseur[index] = true;
        this.quantityCommandeFournisseur = quantity - quantityPrep;
      }
    },
    async addToCommandeFournisseur(article, index) {
      this.loadingCommandeFournisseur[index] = true;
      await addPanierInst(
        this.user.proprietaire,
        article,
        this.quantityCommandeFournisseur
      );
      article.quantityOrdered = this.quantityCommandeFournisseur;
      this.saveCommande();
      this.showCommandeFournisseur[index] = false;
      this.quantityCommandeFournisseur = 0;
      this.loadingCommandeFournisseur[index] = false;
    },
    async prepareTabGroupement() {
      let commandeGroupement = [];
      for (const [index, element] of this.cmdPreparation.Articles.entries()) {
        if (element.toCommandGroupement) {
          let plateformExist = false;
          for (const [index2, element2] of commandeGroupement.entries()) {
            if (element2.plateform == element.toCommandGroupementPlateform) {
              commandeGroupement[index2].Articles.push(element);
              plateformExist = true;
            }
          }
          if (!plateformExist) {
            commandeGroupement.push({
              plateform: element.toCommandGroupementPlateform,
              Articles: [element],
              stockToTransfert: element.stockToTransfert,
            });
          }
        }
      }
      return commandeGroupement;
    },
    async startDirectTransfertFilliale(article, index) {
      let platList = await checkGroupement(
        this.user.proprietaire,
        this.user._id
      );
      this.plateformList = platList.data;
      this.articleDirectTransfert = { ...article };
      this.indexDirectTransfert = index;
      this.maxDirectTransfert = article.quantity;
      this.showDirectTransfertFillialeModal = true;
    },
    checkDirectTransfertQty() {
      if (this.maxDirectTransfert < this.articleDirectTransfert.quantity) {
        this.articleDirectTransfert.quantity = this.maxDirectTransfert;
      }
    },
    async transfertDirectStockFilliale() {
      this.loadingCommandeFilliale = true;
      let ArticlesTab = [];
      let pdfinfo = [];
      let Prix_Total = 0.0;
      const filialeInformation = await axios.get(
        `${process.env.VUE_APP_API}/plateform/getone/${this.plateformDirectTransfert}`
      );
      const getCoef = await axios.post(
        `${process.env.VUE_APP_API}/plateform/getCoef`,
        {
          plateform: this.plateformDirectTransfert,
          plateform_receiver: this.user.proprietaire,
        }
      );
      let art_data = { ...this.articleDirectTransfert };
      art_data.PrixFournisseurType = "prixnet";
      art_data.Prix_vente = art_data.Prix_achat * getCoef.data;
      art_data.prix_net = art_data.Prix_achat * getCoef.data;
      art_data.quantity_received = 0;
      art_data.quantity_asked = parseInt(art_data.quantity);
      art_data.toCommandGroupement = false;

      ArticlesTab.push(art_data);

      Prix_Total += art_data.Prix_vente * art_data.quantity;

      pdfinfo.push({
        description: art_data.Description,
        ref: art_data.Ref_fournisseur,
        quantite: art_data.quantity,
        remise: 0,
        commentaire: art_data.commentaire ? art_data.commentaire : "",
        remisegenerale: 0,
        prixU: art_data.Prix_vente,
        total: art_data.Prix_vente * art_data.quantity,
        totalSansRemise: art_data.Prix_vente * art_data.quantity,
      });

      let numDoc = 0;
      await axios
        .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
          uid: this.plateformDirectTransfert,
        })
        .then((response) => {
          numDoc = response.data + 1;
        });

      var dataCommande = {
        Name: this.user.username,
        Owner: this.plateformDirectTransfert,
        // Date: today,
        Numero: numDoc,
        TotalPrice: Prix_Total,
        TotalPriceTTC: Prix_Total + Prix_Total * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: "Commande inter depot",
        Articles: ArticlesTab,
        reglement: [],
        pdfData: pdfinfo,
        clientInfo: this.plateform,
        transfert_id: this.cmdPreparation._id,
        Transport: 0,
      };
      await axios.post(`${process.env.VUE_APP_API}/commandes`, dataCommande);
      await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
        Type: "Commande",
        cmdPro: true,
        Owner: this.user.proprietaire,
        Name: this.user.username,
        plateform: this.plateformDirectTransfert,
        // Date: today,
        Numero: 123,
        TotalPrice: Prix_Total,
        TotalPriceTTC: Prix_Total + Prix_Total * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: "Commande inter depot : " + this.cmdPreparation.Note,
        Commentaire:
          "Commande Automatique inter depot pour client " +
          this.user.proprietaire +
          " : " +
          this.cmdPreparation.Client +
          " Ref : " +
          this.cmdPreparation.Note,
        clientInfo: this.plateform,
        BCNumber: numDoc,
        Articles: ArticlesTab,
        status: "Commande Passée",
        preparedBy: "personne",
        refCommande: "kjgkjyg",
        PourcentageStatus: "10",
        cmdstate: [true, false, false, false, false],
        Transport: 0,
        transfert_id: this.cmdPreparation._id,
      });
      for (const art of ArticlesTab) {
        if (art.Prefixe_tarif) {
          reserveStock(
            this.user._id,
            this.plateformDirectTransfert,
            art._id,
            art.Ref_fournisseur,
            art.Code_marque,
            art.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "BC n°" +
              numDoc +
              " : résèrve stock filiale par " +
              this.user.username,
            art.Prefixe_tarif,
            this.cmdPreparation.Client,
            numDoc,
            "BC",
            "préparation de commande",
            art.quantity
          );

          //transfert stock direct
          await addStock(
            this.user._id,
            this.user.proprietaire,
            art._id,
            art.Ref_fournisseur,
            art.Code_marque,
            art.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "BC transfert n°" +
              numDoc +
              " : transfert stock filiale par " +
              this.user.username,
            art.Prefixe_tarif,
            this.user.proprietaire,
            "",
            "NA",
            "transfert automatique",
            parseInt(art.quantity)
          );
        }
      }

      this.cmdPreparation.Articles[this.indexDirectTransfert][
        "directTransfered"
      ] = true;
      this.cmdPreparation.Articles[this.indexDirectTransfert][
        "directTransferedQty"
      ]
        ? (this.cmdPreparation.Articles[this.indexDirectTransfert][
            "directTransferedQty"
          ] += parseInt(art_data.quantity))
        : (this.cmdPreparation.Articles[this.indexDirectTransfert][
            "directTransferedQty"
          ] = parseInt(art_data.quantity));

      this.saveCommande();

      this.openFillialeCommande = false;
      this.loadingCommandeFilliale = false;
      this.showDirectTransfertFillialeModal = false;
    },
    async reserveStockFilliale() {
      let groupementTab = await this.prepareTabGroupement();
      for (const transfertData of groupementTab) {
        let ArticlesTab = [];
        let pdfinfo = [];
        let Prix_Total = 0.0;
        const filialeInformation = await axios.get(
          `${process.env.VUE_APP_API}/plateform/getone/${transfertData.plateform}`
        );
        const getCoef = await axios.post(
          `${process.env.VUE_APP_API}/plateform/getCoef`,
          {
            plateform: transfertData.plateform,
            plateform_receiver: this.user.proprietaire,
          }
        );
        for (const groupArt of transfertData.Articles) {
          let art_data = { ...groupArt };
          art_data.quantity = parseInt(groupArt.stockToTransfert);
          art_data.PrixFournisseurType = "prixnet";
          art_data.Prix_vente = art_data.Prix_achat * getCoef.data;
          art_data.prix_net = art_data.Prix_achat * getCoef.data;
          art_data.quantity_received = 0;
          art_data.quantity_asked = parseInt(groupArt.stockToTransfert);
          art_data.toCommandGroupement = false;

          ArticlesTab.push(art_data);

          Prix_Total += art_data.Prix_vente * art_data.quantity;

          pdfinfo.push({
            description: art_data.Description,
            ref: art_data.Ref_fournisseur,
            quantite: art_data.quantity,
            remise: 0,
            commentaire: art_data.commentaire ? art_data.commentaire : "",
            remisegenerale: 0,
            prixU: art_data.Prix_vente,
            total: art_data.Prix_vente * art_data.quantity,
            totalSansRemise: art_data.Prix_vente * art_data.quantity,
          });
        }

        let numDoc = 0;
        await axios
          .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
            uid: transfertData.plateform,
          })
          .then((response) => {
            numDoc = response.data + 1;
          });

        var dataCommande = {
          Name: this.user.username,
          Owner: transfertData.plateform,
          // Date: today,
          Numero: numDoc,
          TotalPrice: Prix_Total,
          TotalPriceTTC: Prix_Total + Prix_Total * 0.2,
          Client: this.user.proprietaire,
          IsProcessed: false,
          Note: "Commande inter depot",
          Articles: ArticlesTab,
          reglement: [],
          pdfData: pdfinfo,
          clientInfo: this.plateform,
          transfert_id: this.cmdPreparation._id,
          Transport: 0,
        };
        await axios.post(`${process.env.VUE_APP_API}/commandes`, dataCommande);
        await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
          Type: "Commande",
          cmdPro: true,
          Owner: this.user.proprietaire,
          Name: this.user.username,
          plateform: transfertData.plateform,
          // Date: today,
          Numero: 123,
          TotalPrice: Prix_Total,
          TotalPriceTTC: Prix_Total + Prix_Total * 0.2,
          Client: this.user.proprietaire,
          IsProcessed: false,
          Note: "Commande inter depot : " + this.cmdPreparation.Note,
          Commentaire:
            "Commande Automatique inter depot pour client " +
            this.user.proprietaire +
            " : " +
            this.cmdPreparation.Client +
            " Ref : " +
            this.cmdPreparation.Note,
          clientInfo: this.plateform,
          BCNumber: numDoc,
          Articles: ArticlesTab,
          status: "Commande Passée",
          preparedBy: "personne",
          refCommande: "kjgkjyg",
          PourcentageStatus: "10",
          cmdstate: [true, false, false, false, false],
          Transport: 0,
          transfert_id: this.cmdPreparation._id,
        });
        for (const art of ArticlesTab) {
          if (art.Prefixe_tarif) {
            reserveStock(
              this.user._id,
              transfertData.plateform,
              art._id,
              art.Ref_fournisseur,
              art.Code_marque,
              art.Code_EAN,
              0,
              0,
              10,
              "",
              this.user.username,
              "BC n°" +
                numDoc +
                " : résèrve stock filiale par " +
                this.user.username,
              art.Prefixe_tarif,
              this.cmdPreparation.Client,
              numDoc,
              "BC",
              "préparation de commande",
              art.quantity
            );

            //transfert stock direct
            await addStock(
              this.user._id,
              this.user.proprietaire,
              art._id,
              art.Ref_fournisseur,
              art.Code_marque,
              art.Code_EAN,
              0,
              0,
              10,
              "",
              this.user.username,
              "BC transfert n°" +
                numDoc +
                " : transfert stock filiale par " +
                this.user.username,
              art.Prefixe_tarif,
              this.user.proprietaire,
              "",
              "NA",
              "transfert automatique",
              art.quantity
            );
          }
        }
      }
      this.openFillialeCommande = false;
    },
    setStateGroupement(data, index) {
      if (
        !this.cmdPreparation.Articles[index]["toCommandGroupementchecked"] ||
        data.force
      ) {
        this.cmdPreparation.Articles[index][
          "toCommandGroupementchecked"
        ] = true;
        this.cmdPreparation.Articles[index]["toCommandGroupement"] = true;
        this.cmdPreparation.Articles[index]["stockToTransfert"] =
          data.stockToTransfert;
        this.cmdPreparation.Articles[index]["toCommandGroupementPlateform"] =
          data.plateform;
        this.showDirectTransfertFillialeModal = false;
        this.saveCommande();
      }
    },
    removeStateGroupement(index) {
      this.cmdPreparation.Articles[index]["toCommandGroupement"] = false;
      this.cmdPreparation.Articles[index]["stockToTransfert"] = 0;
      this.cmdPreparation.Articles[index]["toCommandGroupementPlateform"] = "";
      this.saveCommande();
    },
    removeAllStateGroupement() {
      for (const [index, element] of this.cmdPreparation.Articles.entries()) {
        this.cmdPreparation.Articles[index]["toCommandGroupement"] = false;
        this.cmdPreparation.Articles[index]["stockToTransfert"] = 0;
        this.cmdPreparation.Articles[index]["toCommandGroupementPlateform"] =
          "";
      }
      this.saveCommande();
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getOneCommande();
  },
};
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}
.bg-vk1:hover {
  background-color: #ff914d;
}
.bg-vk2:hover {
  background-color: #2262b3;
}
.bg-btnDownload {
  background-color: #1e7bf5;
}
.bg-btnDownload:hover {
  background-color: #4d9aff;
}
.bg-btnAction {
  background-color: #22c55e;
}
.bg-btnAction:hover {
  background-color: #58d686e8;
}
</style>
