<template>
  <!-- début modal promo -->
  <div
    v-if="openPromo"
    :class="`modal ${
      !openPromo && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openPromo = false"
      class="absolute w-full h-full bg-gray-500 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 60rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between mb-3">
          <p class="text-2xl font-bold">{{ tabDetailPromo.titre }}</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openPromo = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div
          style="max-width: 65rem; max-height: 50rem"
          class="overflow-y-auto"
        >
          <img :src="tabDetailPromo.url" class="h-full w-full" />
        </div>

        <!--Footer-->
        <div class="flex justify-end mt-2">
          <button
            @click="openPromo = false"
            class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            class="px-6 py-3 font-medium tracking-wide material-icons-round text-bluevk rounded-md focus:outline-none"
            @click="ajoutPromoPanier(tabDetailPromo)"
          >
            add_shopping_cart
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal promo -->

  <!-- bandeau -->
  <div class="flex flex-row mt-0 bg-red-600 justify-center md:mb-8">
    <div
      class="flex text-white md:text-2xl font-medium flex-row items-baseline p-1 cursor-pointer"
    >
      Nos promotions en cours
    </div>
  </div>

  <!-- Recherche -->
  <div class="flex justify-center flex-row mx-auto">
    <div
      class="flex items-center px-2 py-1 shadow rounded border w-1/3 hidden sm:block text-xs sm:text-xs lg:text-base"
    >
      <div class="flex flex-row items-center">
        <input
          type="text"
          v-model="searchInput"
          placeholder="Rechercher une promotion"
          class="px-1 py-1 w-full text-center focus:outline-2 outline-sky-300"
          @keyup.enter="searchRefFournisseur()"
          @input="msgSearch = ''"
        />
        <span
          class="material-icons-outlined cursor-pointer pl-2 text-xs lg:text-base xl:text-xl"
          @click="searchRefFournisseur()"
        >
          search
        </span>
      </div>
    </div>
    <h1 class="my-auto ml-8 text-bluevk">{{ msgSearch }}</h1>
  </div>

  <!-- encarts -->

  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8 mx-2 mt-10 lg:mx-10"
  >
    <div v-for="(promo, index) in promoTab" :key="index">
      <div
        class="w-full h-36 w-76 border bg-white border-gray-200 rounded-lg shadow-lg flex flex-row items-center justify-center"
      >
        <div
          class="hover:scale-110 duration-300 hover:text-gray-600"
          @click="pushDataToModal(promo)"
        >
          <img :src="promo.url" class="h-36 w-76" :alt="`Image ${index + 1}`" />
        </div>
      </div>

      <div class="mt-2 font-bold text-center">
        <h1>{{ promo.titre.toUpperCase() }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  searchArticles,
  prepareArticle,
} from "@/hooks/searchEngine/articles.ts";

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      searchInput: "",
      msgSearch: "",
      promoTab: [],
      openPromo: false,
      articleSelected: "",
      tabDetailPromo: {},

      formattedDateHist: [],
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "plateform",
      "comptoirSessionActive",
      "actionArticle",
    ]),
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
    ]),
    async getPromos() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      const promoData = await axios.get(
        `${process.env.VUE_APP_API}/promo/image/${plateform_mere}`
      );
      this.promoTab = promoData.data;
    },
    async searchRefFournisseur() {
      if (this.searchInput == "") {
        await this.getPromos();
      } else {
        const promoByID = await axios.post(
          `${process.env.VUE_APP_API}/promo/getPromoById`,
          {
            plateform: this.user.proprietaire,
            searchTerm: this.searchInput,
          }
        );
        this.promoTab = promoByID.data.data;
        this.msgSearch = promoByID.data.message;
      }
    },
    pushDataToModal(data) {
      this.tabDetailPromo = data;
      this.articleSelected = data.Ref_fournisseur;
      this.openPromo = true;
    },
    async ajoutPromoPanier(data) {
      var article;
      var searchTab = await searchArticles(
        this.articleSelected,
        this.user.proprietaire,
        this.comptoirSessionActive[0].Client[0].Name,
        this.comptoirSessionActive[0].Client[0].Group.Name
      );

      for (const art of searchTab) {
        if (art.article.Prefixe_tarif == data.Prefixe_tarif) {
          article = art.article;
          break;
        }
      }

      var existArticle = false;
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + parseInt(1);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        const articleData = { article: article, stockData: [] };
        var dataTab = await prepareArticle(
          articleData,
          this.user.proprietaire,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name,
          1
        );

        await this.addactionArticle(dataTab);
        await this.addConsigne(dataTab);
      }
      this.open = false;
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.user.proprietaire.dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id + "Consigne",
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
      } else {
        this.adddatanonconsigne(dataConsigne);
      }
    },
  },
  beforeMount() {
    this.getPromos();
  },
};
</script>
