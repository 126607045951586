<template>
  <div class="flex-wrap flex justify-end my-6">
    <nav class="space-x-2">
      <router-link
        to="/suiviDepot"
        v-if="fournisseurTab.Prefixe_tarif == 'Dsv'|| fournisseurTab.Prefixe_tarif == 'Fri'"
      >
        <a
          href="#"
          name="suiviDepot"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Suivi dépôt
        </a>
      </router-link>
      <router-link
        to="/liaisonsDasilva"
        v-if="
          plateform.structure_depot == true &&
          fournisseurTab.CompanyName == 'DA SILVA'
        "
      >
        <a
          href="#"
          name="Liaisonsdasilva"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Liaisons DA SILVA
        </a>
      </router-link>
      <router-link to="/ImportArticleFournisseur">
        <a
          href="#"
          name="Intégrationtarifs"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Ajout Articles
        </a>
      </router-link>

      <router-link to="/ImportPneuFournisseur">
        <a
          href="#"
          name="Intégrationtarifs"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Ajout Pneu
        </a>
      </router-link>
      <router-link to="/specificConditionFournisseur">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Conditions
        </a>
      </router-link>
      <router-link to="/prixNetFournisseur">
        <a
          href="#"
          name="Prixnet"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Prix net
        </a>
      </router-link>
      <router-link to="/ChiffresFournisseur">
        <a
          href="#"
          name="Chiffres"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Chiffres
        </a>
      </router-link>
      <router-link to="/stockToOrder">
        <a
          href="#"
          name="Articles à commander"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Articles à commander
        </a>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  data() {
    return {
      fournisseurTab: {},
    };
  },
  computed: {
    ...mapGetters(["fournisseurID", "user", "plateform"]),
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
  },
  async mounted() {
    await this.getFournisseur();
  },
};
</script>

<style></style>
