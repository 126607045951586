<template>
  <!-- Modal Detail Bdl -->
  <div
    v-if="showDetailBdl"
    :class="`modal ${
      !showDetailBdl && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showDetailBdl = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showDetailBdl = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailBdl.Note }}
          </div>

          <!--  -->
          <div class="flex flex-col h-8 justify-end items-center ml-auto mx-10">
            <span
              class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
              style="color: blue"
              @click="createPDFCmd(detailBdl)"
            >
              download
            </span>
            <div>BT n°{{ detailBdl.Numero }}</div>
          </div>
        </div>

        <!--Body-->
        <table v-if="detailBdl.groupedTab" class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Poids
                </th>
              </tr>
            </thead>

            <tbody
              v-if="detailBdl.groupedTab.length > 0"
              class="bg-white w-fit"
            >
              <div
                v-for="(bdl, ind) in detailBdl.groupedTab"
                :key="ind"
                class="tableHEADTR"
              >
                <br />
                <div class="bg-gray-100">BL n°{{ bdl.Numero }}</div>

                <tr
                  v-for="(r, index) in detailBdl.groupedTab[ind].pdfData"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.ref }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantite }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      parseFloat(
                        detailBdl.groupedTab[ind].Articles[index].Prix_euro
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailBdl.groupedTab[ind].Articles[index].Prix_euro !=
                        'prixnet'
                      "
                    >
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailBdl.groupedTab[ind].Articles[index]
                          .PrixFournisseurType != 'prixnet'
                      "
                    >
                      {{
                        parseFloat(
                          detailBdl.groupedTab[ind].Articles[index].Prix_euro -
                            detailBdl.groupedTab[ind].Articles[index]
                              .Prix_euro *
                              (r.remise / 100)
                        ).toFixed(2)
                      }}
                      €
                    </div>

                    <div v-else>
                      {{
                        parseFloat(
                          detailBdl.groupedTab[ind].Articles[index].Prix_vente
                        ).toFixed(2)
                      }}
                      €
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.total).toFixed(2) }}€
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ detailBdl.groupedTab[ind].Articles[index].Poids }}
                    {{ detailBdl.groupedTab[ind].Articles[index].Udm_poids }}
                  </td>
                </tr>
              </div>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT : {{ parseFloat(detailTotal).toFixed(2) }} €
                </div>
                <div class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{ parseFloat(detailTotal + detailTotal * 0.2).toFixed(2) }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>

        <!--Footer-->
        <!-- <div class="flex justify-end pt-2">
        <button
          @click="showDetailBdl = false"
          class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
        >
          Fermer
        </button>
      </div> -->
      </div>
    </div>
  </div>
  <!-- Fin modal Detail Bdl -->

  <div v-if="!showRegroupage" class="">
    <div class="flex justify-center border">
      <div class="flex space-x-12 bg-gray-100 rounded-md shadow my-2 p-6 w-fit">
        <div class="flex flex-col">
          <label for="client" class="text-sm">Client</label>
          <div class="relative">
            <button
              class="absolute material-icons-round inset-y-0 left-0 px-2 py-1 rounded-l-md text-orange-500 hover:text-orange-600"
              @click="client = null"
              title="Supprimer le client"
            >
              cancel
            </button>
            <select
              v-model="client"
              class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md pl-8"
            >
              <option
                v-for="(cl, index) in clients"
                :key="index"
                :value="cl.Client"
              >
                {{ cl.Client }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-col">
          <label for="transporteur" class="text-sm">Transporteur</label>
          <div class="relative">
            <button
              class="absolute material-icons-round inset-y-0 left-0 px-2 py-1 rounded-l-md text-orange-500 hover:text-orange-600"
              @click="transporteur = null"
              title="Supprimer le transporteur"
            >
              cancel
            </button>
            <select
              v-model="transporteur"
              disabled
              class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md pl-8"
            >
              <option
                v-for="(tr, index) in transporteurs"
                :key="index"
                :value="tr._id"
              >
                {{ tr.numero }} - {{ tr.transporteur }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-col">
          <label for="cutoff" class="text-sm">Cut-off</label>
          <select
            v-model="cutoff"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          >
            <option v-for="(tab, index) in tabCutOff" :key="index">
              {{ tab }}
            </option>
          </select>
        </div>

        <PulseLoader color="#2d74da" class="my-auto" v-if="loading" />
        <button
          v-else
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="getCmd(cutoff, transporteur)"
        >
          <span class="material-icons-outlined text-xl mr-2"> forward </span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>

  <!-- Regroupement -->
  <div v-else class="">
    <div v-if="!showRegroupedDoc" class="flex justfiy-between">
      <button
        class="bg-bluevk hover:bg-bluevkdark text-white font-bold px-4 rounded"
        title="Cliquer pour revenir en arrière"
        @click="(showRegroupage = false), $emit('regroupement', false)"
      >
        <p class="my-auto">&lt; Retour</p>
      </button>

      <button
        v-if="loadingRegrouper"
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <PulseLoader color="#ffffff" class="my-auto" />
      </button>
      <button
        v-else
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="regrouper()"
      >
        <span class="material-icons-outlined text-xl mr-2"> done_all </span>
        <p>Regrouper</p>
      </button>
    </div>

    <div v-else class="flex justfiy-between">
      <button
        class="bg-bluevk hover:bg-bluevkdark text-white font-bold px-4 rounded"
        title="Cliquer pour revenir en arrière"
        @click="(showRegroupage = false), $emit('regroupement', false)"
      >
        <p class="my-auto">&lt; Retour</p>
      </button>
      <button
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="print()"
      >
        <span class="material-icons-outlined text-xl mr-2"> print </span>
        <p>Imprimer</p>
      </button>
    </div>

    <div v-if="!showRegroupedDoc" class="flex justify-center text-xl mt-4">
      <div class="grid gap-x-8 gap-y-4 grid-cols-2">
        <table
          class="min-w-full"
          v-for="(cl, ind) in transport.clients"
          :key="ind"
        >
          <div
            class="overflow-auto max-w-[50rem] max-h-[10rem] border border-gray-800 rounded-md shadow"
          >
            <caption class="text-lg font-semibold mb-2">
              {{
                cl.Client
              }}
            </caption>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  N° cmd
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Commandé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total Prix
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BL
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BT
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in transport.commandes"
                :key="index"
                class="hover:bg-gray-100"
              >
                <td
                  v-if="u.Client == cl.Client"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm font-medium leading-5 text-gray-900">
                    {{ u.BCNumber }}
                  </div>
                </td>

                <td
                  v-if="u.Client == cl.Client"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ getFRDate(u.Date) }}
                  </div>
                </td>

                <td
                  v-if="u.Client == cl.Client"
                  class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    >{{ u.Name }}</span
                  >
                  <div class="text-sm leading-5 text-gray-900">
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                      >{{ u.Client }}</span
                    >
                  </div>
                </td>

                <td
                  v-if="u.Client == cl.Client"
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>
                <!--  -->
                <td
                  v-if="u.Client == cl.Client"
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col w-fit">
                    <span
                      class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                      v-if="u.BLNumber"
                      style="color: blue"
                      @click="openBL(u)"
                    >
                      download
                    </span>

                    <span class="m-auto">{{ u.BLNumber }}</span>
                  </div>
                </td>
                <!--  -->
                <td
                  v-if="u.Client == cl.Client"
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col w-fit">
                    <span
                      class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                      v-if="u.BLNumber"
                      style="color: blue"
                      @click="openBT(u)"
                    >
                      download
                    </span>
                    <span class="m-auto">{{ u.BLNumber }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </div>
        </table>
      </div>
    </div>

    <div v-else>
      <table class="min-w-full">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Référence client</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Date</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Numéro</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Client</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Créé par</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Total Prix</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PDF
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="(u, index) in tabGrouped"
            :key="index"
            class="cursor-pointer hover:bg-gray-100"
          >
            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              {{ u.Note }}
            </td>
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="flex items-center">
                <div class="ml-4">
                  <div class="text-sm font-medium leading-5 text-gray-900">
                    {{ getFRDate(u.Date) }}
                  </div>
                </div>
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ u.Numero }}
                <span
                  @click="getDetailBdl(u)"
                  title="Plus d'informations"
                  class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                >
                  info
                </span>
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ u.Client }}
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              {{ u.Name }}
            </td>

            <td
              class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ parseFloat(u.TotalPrice).toFixed(2) }}
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              <div class="flex space-x-4">
                <span
                  class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                  @click="createPDFCmd(u)"
                >
                  download
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import { getTransporteurs, getAllCutOff } from "@/hooks/transport/transporteur";
import {
  getClientsCmd,
  getCmdRegroupe,
  groupByCmdPro,
} from "@/hooks/regroupeDoc/commandePro";
import { createlivraison } from "@/hooks/pdf/livraison.js";
import { createtransport } from "@/hooks/pdf/transport.js";
import { printGroupedBT, createGroupedBT } from "@/hooks/pdf/groupedCmd.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data: () => ({
    showRegroupage: false,
    loading: false,

    client: null,
    transporteur: null,
    cutoff: null,

    clients: [],
    transporteurs: [],
    tabCutOff: [],

    transport: {},

    loadingRegrouper: false,
    showRegroupedDoc: false,
    tabGrouped: {},

    showDetailBdl: false,
    detailBdl: [],
    detailTotal: null,
  }),
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    setTodayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      return today;
    },
    getCurrentTime() {
      var now = new Date();
      var hours = now.getHours().toString().padStart(2, "0");
      var minutes = now.getMinutes().toString().padStart(2, "0");
      return hours + ":" + minutes;
    },

    // Fonction pour trouver l'heure la plus proche de l'heure actuelle dans le tableau
    findClosestTime() {
      var currentTime = this.getCurrentTime;
      var closestTime = null;
      var timeDiff = null;

      this.tabCutOff.forEach((time) => {
        var diff = Math.abs(
          new Date("2000-01-01 " + time) - new Date("2000-01-01 " + currentTime)
        );

        if (closestTime === null || diff < timeDiff) {
          timeDiff = diff;
          closestTime = time;
        }
      });

      return closestTime;
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async getClients() {
      this.clients = await getClientsCmd(this.user.proprietaire);
      console.log(this.clients);
    },
    async getTransporteur() {
      this.transporteurs = await getTransporteurs(this.user.proprietaire);
      console.log(this.transporteurs);
    },
    async getTabCutOff() {
      this.tabCutOff = await getAllCutOff(this.user.proprietaire);
      if (this.tabCutOff.length == 0) {
        this.tabCutOff.push("23:59");
      }
      console.log(this.tabCutOff);
      this.cutoff = this.findClosestTime;
    },

    // Regrouper les commandes validées
    async getCmd() {
      if (this.cutoff != null) {
        this.loading = true;
        this.transport = await getCmdRegroupe(
          this.user.proprietaire,
          this.client,
          this.transporteur,
          this.cutoff
        );
        this.loading = false;
        if (this.transport.clients.length > 0) {
          this.$emit("regroupement", true);
          this.showRegroupage = true;
        } else {
          this.error("Aucune commande trouvée !");
        }
        console.log(this.transport);
      } else {
        this.warning("Cut-off obligatoire !");
      }
    },
    openBL(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDF(response.data[0]);
        });
    },
    openBT(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDFtransport(response.data[0]);
        });
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(objet, false, false);
      } else {
        createlivraison(objet, true, false);
      }
    },
    createPDFtransport(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createtransport(objet, false);
      } else {
        createtransport(objet, true);
      }
    },

    // Créer les BL définitivement
    async regrouper() {
      this.loadingRegrouper = true;
      this.tabGrouped = await groupByCmdPro(
        this.user.proprietaire,
        this.user.username,
        this.transport.commandes
      );
      console.log(this.tabGrouped);
      this.loadingRegrouper = false;
      this.showRegroupedDoc = true;
      this.success("Créé !");
    },
    getDetailBdl(u) {
      this.detailBdl = u;
      this.detailTotal = u.TotalPrice;
      this.showDetailBdl = true;
    },
    print() {
      printGroupedBT(this.tabGrouped);
    },
    createPDFCmd(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      console.log(dateedition);
      console.log(datepdf);
      if (dateedition == datepdf) {
        createGroupedBT(objet, false);
      } else {
        createGroupedBT(objet, true);
      }
    },

    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(msg) {
      this.toast.warning(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getClients();
    this.getTransporteur();
    this.getTabCutOff();
  },
};
</script>
