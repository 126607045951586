<template>
  <div id="retour" class="flex justify-start">
    <backButton :route="'/configurationCatalogueHub'" />
  </div>
  <div class="flex justify-center"><menuConfiguration /></div>
  <div class="mt-4 space-y-4">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center space-x-4">
        <input
          type="text"
          v-model="searchMarque"
          placeholder="Search"
          class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
        />
        <button
          class="bg-bluevk text-white p-2 rounded shadow"
          @click="$router.push('/ConfigurationGenArt')"
        >
          Afficher les GenArt
        </button>
      </div>
      <div>
        <button
          class="flex flex-row items-center space-x-2 p-2 text-blue-500"
          @click="$router.push('/PropalBrandPriority')"
        >
          <span class="material-icons-round"> auto_fix_high </span>
          <span>Propositions</span>
        </button>
      </div>
    </div>

    <div class="grid grid-cols-7 gap-5" v-if="Marque.length">
      <div
        v-for="(MA, index) in filteredMarque"
        :key="index"
        class="bg-white rounded flex flex-row items-center justify-between p-1"
      >
        <span class="ml-1 cursor-pointer" @click="setBrandSelected(MA)">
          {{ MA.mfrName }}
        </span>
        <span
          class="material-icons-round p-1 bg-red-500 text-white rounded-full cursor-pointer"
          v-if="
            configurationCatalogue.brandSearchBlacklist.includes(MA.mfrName)
          "
          @click="removeFromSearchBlackList(MA.mfrName)"
        >
          search
        </span>
        <span
          class="material-icons-round p-1 bg-green-500 text-white rounded-full cursor-pointer"
          @click="addToSearchBlackList(MA.mfrName)"
          v-else
        >
          search
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      AssemblyGroup: [],
      childAssemblyGroup: [],
      Marque: [],
      childMarque: [],
      childName: [],
      assemblyGroupTitle: "",
      searchMarque: "",
      searchFournisseur: "",
      configurationCatalogue: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    filteredFamille() {
      if (this.searchFournisseur) {
        return this.AssemblyGroup.filter((item, element) => {
          element = item.assemblyGroupName.toLowerCase();
          return element.startsWith(this.searchFournisseur);
        });
      } else {
        return this.AssemblyGroup;
      }
    },
    filteredMarque() {
      if (this.searchMarque) {
        return this.Marque.filter((item) => {
          return item.mfrName.startsWith(this.searchMarque.toUpperCase());
        });
      } else {
        return this.Marque;
      }
    },
  },
  methods: {
    ...mapActions(["setbrandselected"]),
    hideChildAssemblyGroup(parentID, index) {
      this.filtresTab = [];
      this.childAssemblyGroup[index] = parentID;
    },
    async showArticles(agname, assemblygroup) {
      this.isLoading = true;
      this.articles = [];
      this.articlesFilter = [];
      this.articlesBrand = [];
      this.filterDone = false;
      this.brandDone = false;
      this.filtresTab = [];
      this.BrandTab = [];
      this.tempAG = agname;
      this.tempAGN = assemblygroup;
      this.assemblyGroupState = false;
      this.prixTabTD = [];
      this.tabRecherchePS = [];
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/gettecdocarticle`, {
          AssemblyGroup: assemblygroup,
          LinkingTarget: this.carID,
        })
        .then((response) => {
          this.articles = response.data;
          this.articles_Save = response.data;
          this.isLoading = false;
        })
        .then(() => {
          this.setPrixTD();
        })
        .then(() => {
          this.getPrixTD();
        });
    },
    getAssemblyGroup() {
      axios
        .get(`${process.env.VUE_APP_API}/articlecross/getallassemblygroup`)
        .then((res) => {
          this.AssemblyGroup = res.data;
        });
    },
    getChildAssemblyGroup(parentID, assemblyGroupTitle) {
      this.filtresTab = [];
      this.childMarque = [];
      this.childName = [];
      this.assemblyGroupTitle = assemblyGroupTitle;
      axios
        .post(
          `${process.env.VUE_APP_API}/articlecross/getallchildassemblygroup`,
          {
            Ref_fournisseur: { parent: parentID },
          }
        )
        .then((response) => {
          this.childAssemblyGroup = response.data;
          this.childAssemblyGroup.forEach((element, index) => {
            axios
              .post(`${process.env.VUE_APP_API}/configuration/getone`, {
                uid: {
                  plateform: this.user.proprietaire,
                  assemblyGroupNodeId: element.assemblyGroupNodeId,
                },
              })
              .then((res) => {
                if (res.data.length > 0) {
                  if (res.data[0].marque) {
                    this.childMarque[index] = res.data[0].marque;
                    this.childName[index] = element;
                  } else {
                    this.childMarque[index] = [];
                    this.childName[index] = element;
                  }
                } else {
                  this.childMarque[index] = [];
                  this.childName[index] = element;
                }
              });
          });
        });
    },
    getBrand() {
      axios
        .get(`${process.env.VUE_APP_API}/articlecross/getambrand`)
        .then((res) => {
          this.Marque = res.data;
        });
    },
    deleteBrand(index, index_marque) {
      this.childMarque[index].splice(index_marque, 1);
    },
    setTab() {
      var configTab = [];
      this.childName.forEach((element, index) => {
        configTab.push({ Node: element, Marques: this.childMarque[index] });
      });

      axios
        .post(`${process.env.VUE_APP_API}/configuration/updateChildNode`, {
          // assemblyGroupNode: this.childName[0],
          plateform: this.user.proprietaire,
          // Marque: this.childMarque[0],
          // actif: true,
          configTab,
        })
        .then(() => {
          this.toast.success("Sauvegardé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    setBrandSelected(brand) {
      this.setbrandselected(brand);
      this.$router.push("/configurationBrandFournisseur");
    },
    getConfigurationCatalogue() {
      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/getone`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.configurationCatalogue = res.data[0];
        });
    },
    addToSearchBlackList(brand) {
      this.configurationCatalogue.brandSearchBlacklist.push(brand);
      this.saveBrandSearchBlackList();
    },
    removeFromSearchBlackList(brand) {
      this.configurationCatalogue.brandSearchBlacklist.splice(
        this.configurationCatalogue.brandSearchBlacklist.indexOf(brand),
        1
      );
      this.saveBrandSearchBlackList();
    },
    saveBrandSearchBlackList() {
      axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogue/saveBrandSearchBlackList`,
        {
          plateform: this.user.proprietaire,
          data: {
            brandSearchBlacklist:
              this.configurationCatalogue.brandSearchBlacklist,
          },
        }
      );
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getConfigurationCatalogue();
    this.getAssemblyGroup();
    this.getBrand();
  },
  components: {
    backButton,
    menuConfiguration,
  },
};
</script>
<style></style>
