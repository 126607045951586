import { String } from "core-js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";
import axios from "axios";
import { getFrDate } from "@/hooks/tools/date.ts";

export function createlivraisonDsv(
  objet,
  tabTxt,
  dataEmail,
  user,
  fournisseur,
  fournisseurID
) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();
  var datepdf = "Le " + getFrDate(objet[0].Date.substr(0, 10)) + ",";

  var edition = [
    {
      text:
        "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes,
      style: "documentHeaderRight",
    },
  ];

  var co = [];
  objet.forEach((objetElement, index) => {
    var produits = [];
    var footerBody = {};

    produits.push([
      {
        text: "Qté",
        style: ["itemsHeader", "center"],
        border: [true, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Produit",
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text:
          "Servi par : " +
          objetElement.Name +
          "            " +
          "réf. cl. : " +
          objetElement.Note,
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Px brut HT",
        style: ["itemsHeader", "center"],
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Remise",
        style: ["itemsHeader", "center"],
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Px net HT",
        style: ["itemsHeader", "center"],
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Mt HT",
        style: ["itemsHeader", "center"],
        border: [false, true, true, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
    ]);
    objetElement.pdfData.forEach((element, index) => {
      var prixTTC = objetElement.Articles[index].Prix_euro;
      var prixRemise;
      var remise;
      if (objetElement.Articles[index].PrixFournisseurType == "prixnet") {
        prixRemise = objetElement.Articles[index].Prix_vente;
        objetElement.Articles[index].remise = "prix net";
        objetElement.pdfData[index].remise = "prix net";
        remise = "prix net";
      } else {
        prixRemise = parseFloat(
          prixTTC - prixTTC * (element.remise / 100)
        ).toFixed(2);
        remise = element.remise + "%";
      }
      produits.push([
        {
          text: element.quantite,
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text: element.ref,
          style: "itemTitle",
          border: [true, false, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text:
            element.commentaire != ""
              ? element.description + "\n \n" + "Note : " + element.commentaire
              : element.description,
          style: "itemTitle",
          border: [false, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text: parseFloat(prixTTC).toFixed(2),
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text: remise + "%",
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text: parseFloat(prixRemise).toFixed(2),
          style: "itemNumber",
          border: [true, false, false, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text: parseFloat(prixRemise * element.quantite).toFixed(2),
          style: "itemNumber",
          border: [false, false, true, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#F7F7F7",
        },
      ]);
    });
    if (
      objetElement.pdfData[0].remisegenerale &&
      objetElement.pdfData[0].remisegenerale != 0
    ) {
      footerBody = {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 0,
        widths: [180, 80, "*", 80, "*"],

        body: [
          // Total
          [
            {
              text: "",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Transport : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: parseFloat(objetElement.Transport).toFixed(2) + " €",
              style: "itemsFooterTotalValue",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Total HT : ",
              style: "itemsFooterTotalTitle",
            },
            {
              text:
                parseFloat(objetElement.pdfData[0].totalSansRemise).toFixed(2) +
                " €",
              style: "itemsFooterTotalValue1",
            },
          ],
          [
            {
              text: "",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Remise : ",
              style: "itemsFooterTotalTitle",
            },
            {
              text: parseFloat(objetElement.pdfData[0].remisegenerale) + " %",
              style: "itemsFooterTotalValue",
            },
            {
              text: "Total HT remisé : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: parseFloat(objetElement.TotalPrice).toFixed(2) + " €",
              style: "itemsFooterTotalValue1",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
          ],
          [
            {
              text: "",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "TVA : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: parseFloat(objetElement.TotalPrice * 0.2).toFixed(2) + " €",
              style: "itemsFooterTotalValue",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Total TTC : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text:
                parseFloat(
                  objetElement.TotalPrice + objetElement.TotalPrice * 0.2
                ).toFixed(2) + " €",
              style: "itemsFooterTotalValue1",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
          ],
        ],
      };
    } else {
      footerBody = {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 0,
        widths: [180, 80, "*", 80, "*"],

        body: [
          // Total
          [
            {
              text: "",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Transport : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: parseFloat(objetElement.Transport).toFixed(2) + " €",
              style: "itemsFooterTotalValue",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Total HT : ",
              style: "itemsFooterTotalTitle",
            },
            {
              text: parseFloat(objetElement.TotalPrice).toFixed(2) + " €",
              style: "itemsFooterTotalValue1",
            },
          ],
          [
            {
              text: "",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "TVA : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: parseFloat(objetElement.TotalPrice * 0.2).toFixed(2) + " €",
              style: "itemsFooterTotalValue",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text: "Total TTC : ",
              style: "itemsFooterTotalTitle",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
            {
              text:
                parseFloat(
                  objetElement.TotalPrice + objetElement.TotalPrice * 0.2
                ).toFixed(2) + " €",
              style: "itemsFooterTotalValue1",
              borderColor: ["#949494", "#949494", "#949494", "#949494"],
            },
          ],
        ],
      };
    }

    var nextPage1 = {
      table: footerBody, // table
      layout: "lightHorizontalLines",
    };

    var nextPage2 = { text: "" };

    if (index + 1 != objet.length) {
      nextPage2 = {
        text: "",
        pageBreak: "after",
      };
    }

    let infoCompClient = "";
    if (
      objetElement.clientInfo[0].professionnel == true &&
      objetElement.clientInfo[0].siret
    ) {
      infoCompClient =
        objetElement.clientInfo[0].siret +
        " - " +
        objetElement.clientInfo[0].numerotva +
        " - " +
        objetElement.clientInfo[0].nafape;
    }

    //PRODUIT PREPARE ICI DONC ON CREER LE CONTENT
    if (dataEmail) {
      co.push([
        // Header
        {
          columns: [
            {
              image: "imgg",
              width: 100,
              height: 100,
            },
            // margin: [left, top, right, bottom]
            [
              {
                columns: [
                  {
                    text: "Bon de livraison",
                    style: "invoiceTitle",
                    margin: [0, 0, 0, 0],
                    width: 390,
                  },
                ],
              },
              {
                columns: [
                  {
                    text: "n°" + "100000" + objetElement.Numero,
                    style: "invoiceTitleDetail",
                    margin: [0, 0, 0, 0],
                    width: 390,
                  },
                ],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: "",
                        style: "invoiceSubValue",
                        width: 180,
                      },
                      {
                        text: datepdf,
                        style: "invoiceSubValue",
                        margin: [0, 40, 0, 0],
                        width: 190,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        // Billing Headers
        {
          columns: [
            {
              text: "DA SILVA" + "\n",
              style: "invoiceBillingTitle",
              width: 245,
              margin: [0, 5, 0, 0],
            },
            {
              text: objetElement.Client + "\n",
              style: "invoiceBillingTitle1",
              width: 245,
              margin: [25, 0, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "\n",
              style: "invoiceBillingSubTitle",
              width: 245,
              margin: [40, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].Adresse
                ? objetElement.clientInfo[0].Adresse
                : "",
              style: "invoiceBillingDetails",
              width: 210,
              margin: [24, -5, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "280 Rue Léon Joulin",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, 10, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetails",
              width: 0,
              margin: [0, 10, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].Complement
                ? objetElement.clientInfo[0].Complement
                : "",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [-1, -9, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "31100 Toulouse",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, 0, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetails",
              width: 0,
              margin: [0, 0, 0, 0],
            },
            {
              text:
                objetElement.clientInfo[0].PostalCode &&
                objetElement.clientInfo[0].City
                  ? objetElement.clientInfo[0].PostalCode +
                    " " +
                    objetElement.clientInfo[0].City
                  : "",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, -20, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "France",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, 0, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetails",
              width: 0,
              margin: [0, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].Country
                ? objetElement.clientInfo[0].Country
                : "",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, -20, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "05 62 20 66 70",
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, 5, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingContactCompany",
              width: 40,
              margin: [0, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].telephone
                ? objetElement.clientInfo[0].telephone
                : "",
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, -5, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "commandes@groupe-dasilva.com",
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, 0, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetailsCompany",
              width: 40,
              margin: [0, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].email
                ? objetElement.clientInfo[0].email
                : "",
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, -10, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: "34741845100022 - FR65347418451 -  4531Z",
              style: "invoiceBillingDetailsCompany",
              width: 210,
              margin: [0, 2, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetailsCompany",
              width: 40,
              margin: [0, 0, 0, 0],
            },
            {
              text: infoCompClient,
              style: "invoiceBillingDetailsCompany",
              width: 210,
              margin: [0, -8, 0, 0],
            },
          ],
        },
        // Line breaks
        "\n",
        // Items
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [30, 50, 210, 40, 40, 40, 40],
            text: objetElement.clientInfo[0].nomcommercial,
            body: produits,
          }, // table
          //  layout: 'lightHorizontalLines'
        },
        "\n\n",
        nextPage1,
        nextPage2,
      ]);
    } else {
      co.push([
        // Header
        {
          columns: [
            {
              image: "imgg",
              width: 100,
              height: 100,
            },
            // margin: [left, top, right, bottom]
            [
              {
                columns: [
                  {
                    text: "Bon de livraison",
                    style: "invoiceTitle",
                    margin: [0, 0, 0, 0],
                    width: 390,
                  },
                ],
              },
              {
                columns: [
                  {
                    text: "n°" + "100000" + objetElement.Numero,
                    style: "invoiceTitleDetail",
                    margin: [0, 0, 0, 0],
                    width: 390,
                  },
                ],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: "",
                        style: "invoiceSubValue",
                        width: 180,
                      },
                      {
                        text: datepdf,
                        style: "invoiceSubValue",
                        margin: [0, 40, 0, 0],
                        width: 140,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        // Billing Headers
        {
          columns: [
            {
              text: store.state.plateform.Titre1 + "\n",
              style: "invoiceBillingTitle",
              width: 245,
              margin: [0, 5, 0, 0],
            },
            {
              text: objetElement.Client + "\n",
              style: "invoiceBillingTitle1",
              width: 245,
              margin: [25, 0, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: store.state.plateform.Titre2 + "\n",
              style: "invoiceBillingSubTitle",
              width: 245,
              margin: [40, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].Adresse,
              style: "invoiceBillingDetails",
              width: 210,
              margin: [24, -5, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: store.state.plateform.Adresse,
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, 10, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetails",
              width: 0,
              margin: [0, 10, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].Complement
                ? objetElement.clientInfo[0].Complement
                : "",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [-1, -9, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text:
                store.state.plateform.Code_postale +
                " " +
                store.state.plateform.Ville,
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, 0, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetails",
              width: 0,
              margin: [0, 0, 0, 0],
            },
            {
              text:
                objetElement.clientInfo[0].PostalCode +
                " " +
                objetElement.clientInfo[0].City,
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, -20, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: store.state.plateform.Pays,
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, 0, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetails",
              width: 0,
              margin: [0, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].Country
                ? objetElement.clientInfo[0].Country
                : "",
              style: "invoiceBillingDetails",
              width: 250,
              margin: [0, -20, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: store.state.plateform.Telephone,
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, 5, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingContactCompany",
              width: 40,
              margin: [0, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].telephone,
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, -5, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text: store.state.plateform.Email,
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, 0, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetailsCompany",
              width: 40,
              margin: [0, 0, 0, 0],
            },
            {
              text: objetElement.clientInfo[0].email,
              style: "invoiceBillingContactCompany",
              width: 210,
              margin: [0, -10, 0, 0],
            },
          ],
        },
        {
          columns: [
            {
              text:
                store.state.plateform.Siret +
                " - " +
                store.state.plateform.Tva +
                " - " +
                store.state.plateform.Naf,
              style: "invoiceBillingDetailsCompany",
              width: 210,
              margin: [0, 2, 0, 0],
            },
            {
              text: "",
              style: "invoiceBillingDetailsCompany",
              width: 40,
              margin: [0, 0, 0, 0],
            },
            {
              text: infoCompClient,
              style: "invoiceBillingDetailsCompany",
              width: 210,
              margin: [0, -8, 0, 0],
            },
          ],
        },
        // Line breaks
        "\n",
        // Items
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [30, 50, 210, 40, 40, 40, 40],
            text: objetElement.clientInfo[0].nomcommercial,
            body: produits,
          }, // table
          //  layout: 'lightHorizontalLines'
        },
        "\n\n",
        nextPage1,
        nextPage2,
      ]);
    }
  });

  var urlImg;
  if (dataEmail) {
    urlImg = "https://vekteurbucket.s3.amazonaws.com/Logo/Logo-DaSilva.png";
  } else {
    urlImg = `${store.state.plateform.lien_logo}`;
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  var dd = {
    header: {
      columns: edition,
    },
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `${store.state.plateform.Forme_sociale} ${store.state.plateform.Titre1} au capital de ${store.state.plateform.Capital} € dont le siège social est situé à ${store.state.plateform.Adresse} ${store.state.plateform.Code_postale} ${store.state.plateform.Ville}. \n En cas de retard de paiement, taux de penalité appliqué égal à 3 fois le taux d'intérêt en vigueur. Pas d'escompte en cas de paiement anticipé. Echange ou retour sous 15 jours.`,
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [co],
    images: {
      // imgg: 'http://vekteurbucket.s3.amazonaws.com/Logo/Gap.png',
      imgg: {
        url: urlImg,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },

      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue1: {
        margin: [-10, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    defaultStyle: {
      columnGap: 20,
    },
  };

  pdfMake.createPdf(dd).getBase64((b64BDL) => {
    axios.post(`${process.env.VUE_APP_API}/emailDaSilva/sendEmail`, {
      plateform: user.proprietaire,
      data: {
        b64BDL: b64BDL,
        codeConsignataire: objet[0].textFile.consignataire,
        codeClient: objet[0].textFile.codeClient,
        NBLDS: "123456",
        type:
          "Récapitulatif des ventes " +
          store.state.plateform.dasilva.consignataire,
        txtFile: tabTxt,
      },
      dataEmail: dataEmail,
      user: user.username,
      fournisseur: fournisseur,
      fournisseurID: fournisseurID,
    });
  });
}

export function createVideDsv(user, tabTxt, fournisseur, fournisseurID) {
  axios.post(`${process.env.VUE_APP_API}/emailDaSilva/sendEmailVide`, {
    plateform: user.proprietaire,
    data: {
      type:
        "Récapitulatif des ventes " +
        store.state.plateform.dasilva.consignataire,
      txtFile: tabTxt,
    },
    user: user.username,
    fournisseur: fournisseur,
    fournisseurID: fournisseurID,
  });
}
