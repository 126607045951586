<template>
  <div
    v-if="openSave"
    :class="`modal ${
      !openSave && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openSave = false), $emit('closeModalEncaissement', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span
          @click="(openSave = false), $emit('closeModalEncaissement', false)"
          class="text-sm"
          >(Esc)</span
        >
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Encaissement</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openSave = false), $emit('closeModalEncaissement', false)"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
          <label class="text-xs">Commentaire interne</label>

          <div class="relative mt-2 rounded-md shadow-sm">
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >
              <span class="material-icons-round"> subtitles </span>
            </span>

            <input
              type="text"
              v-model="commentaireEncaissement"
              class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none"
            />
          </div>
        </div>

        <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
          <label class="text-xs">Mode de règlement</label>
          <div class="relative">
            <label class="text-xs"
              >Montant à payer : {{ financial(setTotalTTC) }} €</label
            >
          </div>

          <div class="relative flex justify-center p-1">
            <div class="ml-6" v-for="(moyen, ind) in moyenPaiement" :key="ind">
              <div class="flex flex-col">
                <img
                  class="w-16 border border-black rounded-md m-auto cursor-pointer hover:shadow focus:outline-2 outline-sky-300"
                  :src="moyen.image"
                  @click="setReglement(moyen.abbrev)"
                />
                <span style="font-size: 8px" class="m-auto">{{
                  moyen.type
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="relative mt-2 rounded-md shadow-sm"
            v-for="(reglement, index) in reglementTab"
            :key="index"
          >
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >
              <img
                class="w-9 h-9 rounded-md m-auto rounded-md"
                :src="reglement.img"
              />
            </span>

            <input
              type="number"
              :placeholder="reglement.placeholder"
              :disabled="reglementTab[index].disabled"
              v-model="reglementTab[index].model"
              class="w-2/5 focus:outline-2 outline-sky-300 pl-16 pr-2 py-2 border-transparent rounded-md appearance-none"
            />
            <input
              @change="errorEncaissement = false"
              type="date"
              :disabled="reglementTab[index].disabled"
              v-model="reglementTab[index].date"
              :min="todayDate"
              class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border-transparent rounded-md appearance-none"
            />
            <span
              v-if="!reglementTab[index].disabled"
              class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none"
            >
              <span
                class="mt-2 material-icons-outlined text-red-600 cursor-pointer"
                @click="removeReglement(index)"
              >
                clear
              </span>
            </span>
          </div>

          <div class="relative mt-3" v-show="errorPaymentMethod">
            <label class="text-xs text-red-500"
              >Cette méthode de paiement n'existe pas</label
            >
          </div>
          <div class="relative mt-3" v-show="errorEncaissement">
            <label class="text-xs text-red-500"
              >Veuillez encaisser la facture</label
            >
          </div>
          <div class="relative mt-3">
            <label class="text-l"
              >Reste dû : {{ financial(setRestant) }} €</label
            >
            <label class="text-xs"> - </label>
            <label class="text-l">A rendre : {{ financial(setRendu) }} €</label>
          </div>
        </div>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="(openSave = false), $emit('closeModalEncaissement', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="emitEncaissement()"
            class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            :disabled="loading"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MyEncaissement",
  props: ["showModal", "setTotalTTC", "reglement"],
  data() {
    return {
      openSave: false,
      commentaireEncaissement: "",
      reglementTab: [],
      moyenPaiement: [
        {
          type: "LCR",
          abbrev: "lc",
          image: require("@/assets/icons_payments/LCR.png"),
        },
        {
          type: "Espèce",
          abbrev: "es",
          image: require("@/assets/icons_payments/ESP.png"),
        },
        {
          type: "Virement",
          abbrev: "vi",
          image: require("@/assets/icons_payments/Virement.png"),
        },
        {
          type: "Carte Bancaire",
          abbrev: "cb",
          image: require("@/assets/icons_payments/CB.png"),
        },
        {
          type: "Chèque Comptant",
          abbrev: "cc",
          image: require("@/assets/icons_payments/CHQ.png"),
        },
      ],
      errorPaymentMethod: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    setRestant() {
      var restant = 0;
      var paye = 0;
      for (const element of this.reglementTab) {
        paye = paye + parseFloat(element.model);
      }
      restant = parseFloat(this.setTotalTTC) - parseFloat(paye);
      if (restant < 0) {
        restant = 0;
      }
      return parseFloat(restant).toFixed(2);
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      for (const element of this.reglementTab) {
        paye = paye + parseFloat(element.model);
      }
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
  },
  methods: {
    setReglement(method) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      switch (method) {
        case "es":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
    },
    removeReglement(index) {
      this.reglementTab.splice(index, 1);
    },
    emitEncaissement() {
      this.loading = true;
      this.$emit("emitEncaissement", this.reglementTab);
      this.$emit("closeModalEncaissement", false);
      this.loading = false;
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  beforeMount() {
    this.openSave = this.showModal;
    this.reglementTab = this.reglement
      .filter((item) => item.placeholder !== "Reste dû")
      .map((item) => {
        // Reformater la date
        const dateObj = new Date(item.date);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Mois commence à 0
        const day = dateObj.getDate().toString().padStart(2, "0");

        return { ...item, date: `${year}-${month}-${day}`, disabled: true }; // Ajouter disabled: true à chaque élément
      });
  },
};
</script>
