<template>
  <router-link to="/Fournisseurs">
    <div id="retour" class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400">
      <img src="../assets/RetourIcon.png" class="w-6"/>
      <span style="color: #5976E8">Retour</span>
    </div>
  </router-link>
  <div class="flex-wrap space-y-6 mt-6">
    <div class="flex justify-center grid grid-cols-3 m-auto space-">
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/LKQ.jpg"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> LKQ </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez LKQ
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/Doyen.png"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> DOYEN </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez DOYEN
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/idlp.jpeg"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> IDLP </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez IDLP
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="flex justify-center grid grid-cols-3 m-auto">
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/AutoPartner.gif"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> AUTOPARTNER </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez AUTOPARTNER
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/DaSilva.jpeg"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> DASILVA </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez DASILVA
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/Meyle.jpg"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> MEYLE </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez MEYLE
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="flex justify-center grid grid-cols-3 m-auto">
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/Procodis.png"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> PROCODIS </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez PROCODIS
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/VanWezel.jpg"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> VANWEZEL </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez VANWEZEL
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/SKF.jpg"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> SKF </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez SKF
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="flex justify-center grid grid-cols-3 m-auto">
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/kraftwerk.png"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> KRAFTWERK </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez KRAFTWERK
              </span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <img
            src="../assets/Fournisseurs/Jbm.png"
            class="material-icons-outlined rounded-full text-blue-600 mx-2 my-4 p-2 w-24 h-24 bg-white"
          />
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> JBM </span>
              <span class="text-gray-600 pt-2">
                Consulter les commandes passées chez JBM
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
