import axios from "axios";

// Créée un commercial
export async function createCommercial(
    data: null,

) {
    const commercial = await axios.post(
        `${process.env.VUE_APP_API}/commercial`,
        {
            data: data,

        }
    );
    return commercial.data;
}

// Récupère les commerciaux
export async function getCommerciaux(
    plateform: string,
) {
    const commercial = await axios.get(
        `${process.env.VUE_APP_API}/user/commercial/${plateform}`
    );
    return commercial.data;
}