<template>
  <div>
    <div class="flex flex-row items-center space-x-2">
      Enregistrement des articles en cours ...
      <PulseLoader color="#2d74da" />
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  components: {
    PulseLoader,
  },
};
</script>
<style></style>
