<template>
  <!-- modal ajout client -->
  <div
    v-if="showClientPar"
    :class="`modal ${
      !showClientPar && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showClientPar = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 52rem; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Ajouter un client particulier</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showClientPar = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <transition>
          <div
            class="bloc-couleur couleur-remplissage"
            id="informationsgenerales"
          >
            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Professionnel ?</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      disabled
                      type="radio"
                      v-model="professionnelParticulier"
                      value="true"
                      data-val="true"
                      data-val-required="Ce champ est obligatoire."
                      id="professionnelParticulier"
                      name="professionnelParticulier"
                      data-enable="True"
                      data-for-field="professionnelParticulier"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      disabled
                      type="radio"
                      v-model="professionnelParticulier"
                      checked
                      value="false"
                      id="professionnelParticulier"
                      name="professionnelParticulier"
                      data-enable="false"
                      data-for-field="professionnelParticulier"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Groupe de client</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <select
                    v-model="groupeClient"
                    class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                  >
                    <option
                      :value="cond.Name"
                      v-for="(cond, indexC) in configurationConditionList"
                      :key="indexC"
                    >
                      {{ cond.Name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Devis </label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="devisRadio"
                      id="devisRadio"
                      name="devisRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="devisRadio"
                      id="devisRadio"
                      name="devisRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Bon de commande</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="commandeRadio"
                      id="commandeRadio"
                      name="commandeRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="commandeRadio"
                      id="commandeRadio"
                      name="commandeRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Bon de livraison</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonRadio"
                      id="livraisonRadio"
                      name="livraisonRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonRadio"
                      id="livraisonRadio"
                      name="livraisonRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Bon de livraison avoir</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonAvoirRadio"
                      id="livraisonAvoirRadio"
                      name="livraisonAvoirRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="livraisonAvoirRadio"
                      id="livraisonAvoirRadio"
                      name="livraisonAvoirRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Facture</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="factureRadio"
                      id="factureRadio"
                      name="factureRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="factureRadio"
                      id="factureRadio"
                      name="factureRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Retour</label>

                <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                  <div
                    class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="retourRadio"
                      id="retourRadio"
                      name="retourRadio"
                      value="true"
                    />
                    <label class="pl-2 checkbox">Oui</label>
                  </div>

                  <div
                    class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  >
                    <input
                      type="radio"
                      v-model.trim="retourRadio"
                      id="retourRadio"
                      name="retourRadio"
                      value="false"
                    />
                    <label class="pl-2 checkbox">Non</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-4">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Marge minimum (en %)</label>
                <input
                  v-model.trim="min_marge"
                  type="Number"
                  ref="min_marge"
                  @keydown.enter="this.$refs.min_coef.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Coef minimum (en Decimal)</label>
                <input
                  v-model.trim="min_coef"
                  type="Float"
                  ref="min_coef"
                  @keydown.enter="this.$refs.nomParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b"></div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b"></div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Nom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  v-model="nomParticulier"
                  type="text"
                  ref="nomParticulier"
                  @keydown.enter="this.$refs.adresseParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="adresseParticulier"
                  ref="adresseParticulier"
                  @keydown.enter="this.$refs.complementParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Complément d'adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="complementParticulier"
                  ref="complementParticulier"
                  @keydown.enter="this.$refs.codepostalParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Code Postal</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="codepostalParticulier"
                    ref="codepostalParticulier"
                    @keydown.enter="this.$refs.villeParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Ville</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="villeParticulier"
                    ref="villeParticulier"
                    @keydown.enter="this.$refs.paysParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label for="paysParticulier" class="text-xs">Pays</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <select
                    name="paysParticulier"
                    v-model="paysParticulier"
                    ref="paysParticulier"
                    @keydown.enter="this.$refs.telephoneParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                  >
                    <option value="ALLEMAGNE">ALLEMAGNE</option>
                    <option value="AUTRICHE">AUTRICHE</option>
                    <option value="BELGIQUE">BELGIQUE</option>
                    <option value="ESPAGNE">ESPAGNE</option>
                    <option selected value="FRANCE">FRANCE</option>
                    <option value="ITALIE">ITALIE</option>
                    <option value="LUXEMBOURG">LUXEMBOURG</option>
                    <option value="MAROC">MAROC</option>
                    <option value="PAYS-BAS">PAYS-BAS</option>
                    <option value="SUISSE">SUISSE</option>
                    <option value="TURQUIE">TURQUIE</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Téléphone</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="tel"
                    v-model="telephoneParticulier"
                    ref="telephoneParticulier"
                    @keydown.enter="this.$refs.emailParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Email</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="emailParticulier"
                    v-model="emailParticulier"
                    ref="emailParticulier"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!--Footer-->
        <div class="flex justify-end mt-4">
          <button
            @click="showClientPar = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-blue-600 hover:text-white hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="(showClientPar = false), postPartClients()"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal ajout client -->

  <!-- modal historique  -->
  <div
    v-if="historiqueShow"
    :class="`modal ${
      !historiqueShow && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="historiqueShow = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 78rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Historique</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="historiqueShow = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex flex-row mt-3">
          <label>
            <input
              type="checkbox"
              class="w-5 h-5 text-blue-600 rounded-md focus:outline-2 outline-sky-300"
              name="radio"
              v-model="checkboxDevis"
              @change="filterHistoriqueFile('Devis', checkboxDevis)"
            /><span class="ml-2 text-gray-700">Devis</span>
          </label>
          <label>
            <input
              type="checkbox"
              class="w-5 ml-5 h-5 text-blue-600 rounded-md focus:outline-2 outline-sky-300"
              name="radio"
              v-model="checkboxFacturation"
              @change="filterHistoriqueFile('Facturation', checkboxFacturation)"
            /><span class="ml-2 text-gray-700">Facturation</span>
          </label>
        </div>
        <div
          class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          style="height: 30vh"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr
                  v-for="(historiqueFile, index) in historique"
                  :key="index"
                  @click="
                    setHistoriqueFile(historiqueFile.Articles),
                      (historiqueShow = false)
                  "
                  style="cursor: pointer"
                  class="hover:bg-blue-50"
                >
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    {{ historiqueFile.Type }} N° {{ historiqueFile.Numero }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Date : {{ getFRDate(historiqueFile.Date) }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Client : {{ historiqueFile.Client }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Crée par : {{ historiqueFile.Name }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Prix Total : {{ financial(historiqueFile.TotalPrice) }} €
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Desc : {{ historiqueFile.Note }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Validation :
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      v-if="historiqueFile.IsProcessed === true"
                    >
                      <span class="material-icons-outlined"> done </span></span
                    >
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                      v-else
                    >
                      <span class="material-icons-outlined">close</span>
                    </span>
                  </th>
                  <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                </tr>
              </thead>
            </table>
          </div>
        </div>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="historiqueShow = false"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal historique -->

  <!--  DETAIL MODAL -->
  <div
    class="z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center"
    v-if="showDetail"
  >
    <div
      class="absolute w-screen h-screen top-0 inset-0 bg-black opacity-50"
      @click="CloseShowDetail()"
    ></div>
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-4xl relative z-10">
      <div class="flex items-center justify-between pb-3">
        <p class="text-lg font-medium">Panier</p>
        <div class="cursor-pointer" @click="CloseShowDetail()">
          <svg
            class="text-black fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
        </div>
      </div>
      <div class="overflow-y-auto max-h-[25rem]">
        <table class="min-w-full mt-3">
          <thead class="bg-gray-50 tableHEADTR">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Réf.
              </th>
              <th
                class="px-6 py-3 w-[24rem] max-w-24 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                P.V.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Remise
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Px final
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Total HT
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="(art, ind) in detailTab.actionArticle"
              :key="ind"
              class="tableHEADTR"
            >
              <!-- ref -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p>{{ art.Ref_fournisseur }}</p>
              </td>

              <!-- desc -->
              <td
                class="px-6 max-w-24 w-[24rem] border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p
                  class="overflow-y-auto text-sm leading-5 whitespace-normal w-[24rem] max-w-24"
                >
                  {{ art.Description }}
                </p>
              </td>

              <!-- qté -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p>{{ art.quantity }}</p>
              </td>

              <!-- p.v -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p>{{ financial(art.Prix_euro) }} €</p>
              </td>

              <!-- remise -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p v-if="art.PrixFournisseurType != 'prixnet'">
                  {{ art.remise }} %
                </p>
                <p v-else>prix net</p>
              </td>

              <!-- px final -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <span v-if="art.PrixFournisseurType != 'prixnet'"
                  >{{
                    financial(
                      art.Prix_euro - art.Prix_euro * (art.remise / 100)
                    )
                  }}
                  €
                </span>
                <span v-else>
                  <span> {{ art.Prix_vente }} €</span>
                </span>
              </td>

              <!-- total ht -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <span v-if="art.PrixFournisseurType != 'prixnet'">
                  {{
                    financial(
                      (art.Prix_euro - art.Prix_euro * (art.remise / 100)) *
                        art.quantity *
                        0.2 +
                        (art.Prix_euro - art.Prix_euro * (art.remise / 100)) *
                          art.quantity
                    )
                  }}
                  €
                </span>
                <span v-else>
                  {{
                    financial(
                      parseFloat(art.Prix_vente) * art.quantity * 0.2 +
                        parseFloat(art.Prix_vente) * art.quantity
                    )
                  }}
                  €
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pt-4">
        <div class="flex justify-end space-x-6">
          <div class="text-sm">
            <div class="flex space-x-2">
              <p>Total HT :</p>
              <p>{{ financial(parseFloat(setTotal)) }}</p>
            </div>
            <div class="flex space-x-2">
              <p>Total TTC :</p>
              <p>{{ financial(parseFloat(setTotalTTC)) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN DETAIL MODAL -->

  <!-- SESSION NON ACTIVE -->
  <div
    v-show="showOutilsSession"
    @click="showOutilsSession = !showOutilsSession"
    class="absolute w-screen h-screen z-20 top-0 left-0"
  ></div>

  <!-- BOUTONS NOUVELLES SESSIONS PRO ET PART -->
  <div class="flex flex-col justify-start mr-2">
    <button
      class="w-full px-4 text-white text-xs shadow py-1"
      :class="
        comptoirSessionActive &&
        comptoirSessionActive.length > 0 &&
        comptoirSessionActive[0].Client &&
        comptoirSessionActive[0].Client.length > 0 &&
        comptoirSessionActive[0].Client[0].professionnel
          ? 'bg-cyan-600 rounded-t'
          : 'bg-gray-300 border border-gray-300 rounded-t'
      "
      @click="(showNewInput = true), (cdtnClient = true), getFavori(true)"
    >
      Pro
    </button>
    <button
      class="w-full px-4 text-white text-xs shadow py-1"
      :class="
        comptoirSessionActive &&
        comptoirSessionActive.length > 0 &&
        comptoirSessionActive[0].Client &&
        comptoirSessionActive[0].Client.length > 0 &&
        !comptoirSessionActive[0].Client[0].professionnel
          ? 'bg-cyan-600 rounded-b'
          : 'bg-gray-300 border border-gray-300 rounded-b'
      "
      @click="(showNewInput = true), (cdtnClient = false), getFavori(false)"
    >
      Par
    </button>
  </div>

  <!-- BOUTONS OUTILS SESSIONS -->
  <div
    v-if="
      comptoirSessionActive &&
      comptoirSessionActive.length > 0 &&
      comptoirSessionActive[0].Client &&
      comptoirSessionActive[0].Client.length > 0 &&
      !comptoirSessionActive[0].Client[0]._id.startsWith('inconnu')
    "
    class="flex mr-2 mt-2"
  >
    <div v-if="comptoirSession.length >= 1" class="flex flex-col">
      <button
        @click="chooseClient()"
        title="Info Client"
        class="material-icons-outlined text-cyan-600 text-md"
        style="font-size: 18px"
      >
        info
      </button>
      <button
        @click="getHistorique(), (historiqueShow = true)"
        title="Historique Client"
        class="material-icons-outlined text-cyan-600 text-md"
        style="font-size: 18px"
      >
        person_search
      </button>
    </div>
    <div
      v-if="
        comptoirSessionActive.length > 0 &&
        comptoirSessionActive[0].Client.length > 0 &&
        !comptoirSessionActive[0].Client[0].professionnel
      "
    >
      <button
        @click="
          resetClientFields();
          showClientPar = true;
        "
        title="Ajouter un client"
        class="material-icons-outlined text-cyan-600 ml-2 text-md"
        style="font-size: 18px"
      >
        person_add
      </button>
    </div>
  </div>

  <div
    v-if="showSessionTab"
    @click="closeInputClient()"
    class="absolute w-screen h-screen z-20 top-0 left-0"
  ></div>
  <div
    v-show="showNewInput"
    @click="showNewInput = false"
    class="absolute w-screen h-screen z-20 top-0 left-0"
  ></div>

  <div class="mr-5">
    <div class="flex relative bg-opacity-50 rounded-md py-1 px-0.5 h-fit">
      <!-- SESSION ACTIVE -->
      <div
        v-show="
          comptoirSessionActive &&
          comptoirSessionActive.length > 0 &&
          comptoirSessionActive[0].Client &&
          comptoirSessionActive[0].Client.length > 0
        "
        v-for="(session, index) in comptoirSessionActive"
        :key="index"
      >
        <div class="flex">
          <div class="flex flex-col" v-if="session.actif">
            <div class="flex space-x-2">
              <!-- NOM CLIENT DE LA SESSION -->
              <button
                :title="session.Client[0].Name"
                v-if="session.Client[0].Name"
                @click="clientPush()"
                style="height: 28px; width: auto"
                class="my-auto bg-cyan-600 relative px-1 hover:bg-cyan-700 text-white shadow rounded-md cursor-pointer z-10 border border-2 border-cyan-600 text-sm 2xl:text-base"
              >
                <div v-if="session.Client.length > 0">
                  <div class="xs:block lg:hidden">
                    {{ session.Client[0].Name.substr(0, 6) }}..
                  </div>
                  <div class="hidden lg:block">
                    {{ session.Client[0].Name.substr(0, 25) }}
                  </div>
                </div>
                <div
                  v-if="comptoirSessionActive.length > 0"
                  @click="removeSession()"
                  class="absolute top-0 right-0 -my-4 -mr-3 text-gray-700 z-30"
                >
                  <span
                    title="Supprimer"
                    style="font-size: 16px"
                    class="material-icons-outlined cursor-pointer rounded-full bg-white hover:bg-gray-300 text-red-700 border hover:from-transparent"
                    >cancel</span
                  >
                </div>
              </button>

              <!-- GROUPE DE REMISE POUR DIVERS -->
              <div
                v-if="comptoirSessionActive[0].Client[0]?._id.startsWith('inconnu')"
                class="my-auto border focus:outline-2 outline-sky-300 rounded-md shadow"
              >
                <select
                  v-model="comptoirSessionActive[0].Client[0].Group.Name"
                  @change="saveGroupClient(comptoirSessionActive[0])"
                  class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                >
                  <option
                    :value="cond.Name"
                    v-for="(cond, indexC) in configurationConditionList"
                    :key="indexC"
                  >
                    {{ cond.Name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- MODIFIER LA SESSION -->
            <div class="mt-1" v-if="showSessionTab && session.Client[0].Name">
              <div
                class="absolute z-20 bg-gray-400 flex shadow-lg rounded-md p-1 border-2 border-gray-500"
              >
                <div class="flex flex-col">
                  <button
                    @click="
                      (inputClient = ''), (modifPro = true), getFavori(true)
                    "
                    :class="modifPro ? 'bg-cyan-600' : 'bg-gray-300'"
                    class="px-1 text-center text-white shadow rounded-md cursor-pointer z-20"
                    style="height: 30px; max-height: 30px"
                  >
                    Pro
                  </button>
                  <button
                    @click="
                      (inputClient = ''), (modifPro = false), getFavori(false)
                    "
                    :class="modifPro ? 'bg-gray-300' : 'bg-cyan-600'"
                    class="px-1 text-center text-white shadow rounded-md cursor-pointer z-20"
                    style="height: 30px; max-height: 30px"
                  >
                    Par
                  </button>
                </div>
                <div
                  class="flex flex-col my-auto px-1 text-center text-white shadow rounded-md cursor-pointer z-20"
                >
                  <input
                    list="brow1"
                    @input="searchClientFav(inputClient, modifPro)"
                    @focus="inputClient = ''"
                    v-model="inputClient"
                    @keydown.enter="selectFirstClient('update')"
                    placeholder="Modifier la session"
                    style="width: auto"
                    class="m-auto border-2 pl-1 border-gray-500 w-full text-black rounded-md focus:placeholder-gray-600 focus:text-gray-700 focus:outline-2 outline-sky-300"
                  />
                  <div
                    class="bg-white text-gray-800 rounded-sm p-1 w-full flex flex-col max-h-64 overflow-y-auto"
                  >
                    <div
                      class="cursor-pointer rounded-sm hover:bg-gray-200"
                      @click="
                        (inputClient = cl.Name),
                          updateSessionClient(inputClient)
                      "
                      v-for="(cl, indexCL) in Clients"
                      :key="indexCL"
                    >
                      {{ cl.Name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Bouton pour modifier la session -->
          <button
            class="ml-2"
            title="Changer de client"
            v-if="session.actif"
            @click="
              (showSessionTab = true),
                (modifPro = comptoirSessionActive[0].Client[0].professionnel),
                getFavori(comptoirSessionActive[0].Client[0].professionnel)
            "
          >
            <span class="material-icons-outlined text-cyan-600 text-md">
              edit
            </span>
          </button>
        </div>
      </div>

      <!-- CREER LA SESSION -->
      <div v-if="showNewInput" class="absolute z-20 flex mt-8">
        <div
          class="absolute z-20 bg-gray-400 flex flex-col shadow-lg rounded-md p-1 border-2 border-gray-500"
        >
          <!-- @change="createSession()" -->
          <input
            list="brow2"
            @input="searchClientFav(newInputClient, cdtnClient)"
            @focus="newInputClient = ''"
            v-model="newInputClient"
            @keydown.enter="selectFirstClient('create')"
            placeholder="Nouveau client"
            style="width: auto"
            class="my-1 m-auto w-full pl-1 text-black border-2 border-gray-500 rounded-md focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 hover:bg-white focus:outline-2 outline-sky-300"
          />
          <!-- <datalist id="brow2">
            <option
              v-for="(c, index) in Clients"
              :key="index"
              :value="c.Name"
            ></option>
          </datalist> -->
          <div
            class="bg-white rounded-sm p-1 w-full flex flex-col max-h-64 overflow-y-auto"
          >
            <button
              v-if="stateToAdd == false"
              class="rounded-md shadow-lg bg-blue-100 border-2 border-blue-200 hover:bg-blue-400 hover:border-blue-500"
              @click="(newInputClient = 'Particulier'), createSession(true)"
            >
              Particulier
            </button>
            <div
              class="cursor-pointer rounded-sm hover:bg-gray-200"
              @click="(newInputClient = cl.Name), createSession(false)"
              v-for="(cl, indexCL) in Clients"
              :key="indexCL"
            >
              {{ cl.Name }}
            </div>
          </div>
        </div>
      </div>

      <!-- LISTE SESSIONS -->
      <div
        v-if="
          comptoirSessionActive &&
          comptoirSessionActive.length > 0 &&
          comptoirSessionActive[0].Client &&
          comptoirSessionActive[0].Client.length > 0
        "
        class="relative flex flex-col items-center"
      >
        <!-- Button -->
        <button
          @click="openSessionList()"
          class="relative flex flex-col m-auto h-fit ml-2 cursor-pointer bg-cyan-600 hover:bg-cyan-600 rounded-md text-xs"
          style="
            height: 28px;
            max-height: 28px;
            width: 3.5rem;
            max-width: 3.5rem;
          "
        >
          <span
            class="m-auto align-center items-center material-icons-outlined text-white"
          >
            more_horiz
          </span>
        </button>

        <div
          v-show="showOutilsSession"
          class="absolute w-[32rem] h-[15rem] m-auto py-1 mt-10 ml-36 bg-white text-black shadow-lg rounded-md border-2 border-sky-300 overflow-auto z-20"
        >
          <!-- Arrow -->
          <div class="absolute top-[-8px] left-1/2 transform -translate-x-1/2">
            <!-- <div
              class="w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-white"
            ></div> -->
          </div>

          <table
            class="table-auto w-full border-collapse border border-gray-100"
          >
            <thead>
              <tr class="bg-gray-100 text-black">
                <!-- <th class="border border-gray-100 p-2 text-left">Nom</th>
                <th class="border border-gray-100 p-2 text-left" colspan="2">
                  Articles en cours
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(session, index) in comptoirSession"
                :key="index"
                class="hover:bg-gray-200 cursor-pointer"
                :class="
                  session._id == comptoirSessionActive[0]._id
                    ? 'bg-blue-100 border-2 border-blue-200'
                    : ''
                "
                @click="selectSession(session)"
              >
                <td class="border text-[14px] border-gray-100 p-2">
                  {{ session.Client[0].Name }}
                </td>

                <td class="w-20 border text-[14px] border-gray-100 p-2">
                  {{ session.Client[0].Group.Name }}
                </td>

                <td class="border border-gray-100 p-2" colspan="2">
                  <div class="flex items-center justify-between my-auto">
                    <span class="text-blue-600 font-medium">{{
                      session.actionArticle.length
                    }}</span>
                    <span class="ml-4 text-[15px]">Articles en cours</span>
                    <span
                      class="material-icons-outlined text-blue-600 cursor-pointer hover:text-blue-400 ml-4 text-[20px]"
                      @click.stop="OpenShowDetail(session)"
                      title="Détail des articles"
                    >
                      info
                    </span>
                    <span
                      class="material-icons-round text-red-500 cursor-pointer hover:text-red-400 mr-2 text-[20px]"
                      @click.stop="removecomptoirsession(session)"
                      title="Supprimer la session"
                    >
                      cancel
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Aucune session active
      <div class="pr-3 m-auto" v-if="comptoirSession.length == 0">
        <router-link to="/comptoirSession">
          <button
            style="height: 28px; width: auto"
            class="bg-red-300 px-1 hover:bg-red-700 text-white shadow rounded-md cursor-pointer z-10 border border-red-300"
          >
            Aucune session active
          </button>
        </router-link>
      </div> -->

      <!-- Montant commande de la journée -->
      <div
        class="pl-2 relative hidden 2xl:block"
        v-if="comptoirSessionActive.length > 0"
      >
        <button @click="dropdownOpen = !dropdownOpen">
          <span
            class="material-icons-outlined text-cyan-600 text-md shadow rounded-md cursor-pointer z-10"
          >
            inventory
          </span>
        </button>
        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 h-full w-full z-10"
        ></div>
        <div
          v-show="dropdownOpen"
          class="absolute right-50 z-20 w-48 py-5 mt-2 bg-white rounded-lg shadow-xl text-center"
        >
          Commande d'aujourd'hui :
          <span class="font-semibold" v-if="comptoirSessionActive[0]"
            >{{
              parseFloat(comptoirSessionActive[0].totalPanier).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
// import { getPanierTransport } from "@/hooks/transport/panierTransport.ts";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      telephoneParticulier: "",
      emailParticulier: "",
      paysParticulier: "FRANCE",
      villeParticulier: "",
      codepostalParticulier: "",
      complementParticulier: "",
      adresseParticulier: "",
      groupeClient: "",
      nomParticulier: "",
      professionnelParticulier: false,
      showClientPar: false,

      historique: [],
      historiqueShow: false,
      showOutilsClient: false,
      showNameHistClient: false,
      tabFilterHistorique: [],
      checkboxDevis: false,
      checkboxFacturation: false,

      showOutilsSession: false,
      showNewInput: false,
      newInputClient: "",
      inputClient: "",
      showSessionTab: false,
      clientChosed: "",
      qtyTab: [],
      Clients: [],
      isColumn: false,
      fournisseurList: [],
      noteDocument: "",
      ClientsPar: [],
      cdtnClient: false,
      dropdownOpen: false,

      panierTransport: {},
      modifPro: true,
      devisRadio: true,
      commandeRadio: true,
      livraisonRadio: false,
      livraisonAvoirRadio: false,
      factureRadio: true,
      retourRadio: false,
      showDetail: false,
      detailTab: {},

      stateToAdd: false,

      configurationConditionList: [],
    };
  },
  computed: {
    ...mapGetters([
      "clientFocus",
      "comptoirSession",
      "actionArticle",
      "user",
      "comptoirSessionActive",
      "refClient",
      "dataConsigne",
      "dataNonConsigne",
      "dsvCheck",
      "plateform",
      "Ref_fournisseur_search",
    ]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totalRemiseGen = 0.0;
      var totaldebut = 0.0;

      if (this.detailTab.actionArticle.length > 0) {
        this.detailTab.actionArticle.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            totalRemise = parseFloat(element.Prix_vente);
          } else {
            totalRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) *
                (this.detailTab.actionArticle[index].remise / 100);
          }
          totaldebut =
            totaldebut +
            totalRemise * this.detailTab.actionArticle[index].quantity;
          this.totalDebutFrais = totaldebut;
        });

        if (
          this.detailTab.Client[0].professionnel == true &&
          (this.detailTab.documentType === "Devis" ||
            this.detailTab.documentType === "Facture")
        ) {
          if (parseFloat(this.detailTab.francodeport) >= 0) {
            if (parseFloat(this.detailTab.totalPanier) > 0.0) {
              if (
                parseFloat(this.detailTab.totalPanier) + totaldebut <
                parseFloat(this.detailTab.francodeport)
              ) {
                this.setfraisdeport({
                  value: this.detailTab.fraisdeportsave,
                });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              }
            } else {
              if (
                parseFloat(totaldebut) < parseFloat(this.detailTab.francodeport)
              ) {
                this.setfraisdeport({
                  value: this.detailTab.fraisdeportsave,
                });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total += totaldebut;
              }
            }
          } else {
            total = totaldebut;
          }
        } else {
          total = totaldebut;
        }
      } else {
        total += totaldebut;
      }

      if (
        this.detailTab.documentType === "Devis" ||
        this.detailTab.documentType === "Facture"
      ) {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) * (this.detailTab.remisegenerale / 100) +
          parseFloat(this.detailTab.fraisdeportsave);
      } else {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) * (this.detailTab.remisegenerale / 100);
      }

      if (totaldebut >= 0) {
        if (
          this.detailTab.remisegenerale &&
          this.detailTab.remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          return total;
        }
      } else {
        if (
          this.detailTab.remisegenerale &&
          this.detailTab.remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          this.setfraisdeport({ value: "0" });
          return totaldebut;
        }
      }
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  props: ["clientsInput"],
  methods: {
    ...mapActions([
      "updateactionArticle",
      "getallcomptoirsession",
      "createcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
      "savecomptoirsession",
      "setrefclient",
      "getclientchoosedtab",
      "updaterefclient",
      "setdsvcheck",
      "updateactionarticlesession",
      "sendToRestoreTransportCart",
      "searchRefFournisseur",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    searchClientFav(searchTerm, pro) {
      axios
        .post(`${process.env.VUE_APP_API}/client/searchClientFav`, {
          plateform: this.user.proprietaire,
          searchTerm: searchTerm,
          pro: pro,
          archived: false,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    async selectFirstClient(param) {
      if (this.newInputClient !== "" || this.inputClient !== "") {
        if (this.Clients.length > 0) {
          if (param == "create") {
            this.newInputClient = this.Clients[0].Name;
            await this.createSession(false);
          } else {
            this.inputClient = this.Clients[0].Name;
            await this.updateSessionClient(this.inputClient);
          }
        } else {
          if (param == "create") {
            this.nomParticulier = this.newInputClient;
          } else {
            this.nomParticulier = this.inputClient;
          }
          this.showClientPar = true;
        }
      }
      // }
    },
    resetClientFields() {
      this.nomParticulier = "";
      this.adresseParticulier = "";
      this.telephoneParticulier = "";
      this.emailParticulier = "";
    },

    closeModal(clearFields = false) {
      this.showClientPar = false;
      if (clearFields) {
        this.resetClientFields();
      }
    },
    filterClients(cdtn) {
      if (this.newInputClient != "") {
        if (cdtn) {
          this.getClientsPro();
        } else {
          this.getClientsPar();
        }
        const searchTerm = this.newInputClient.trim().toLowerCase();
        this.Clients = this.Clients.filter((client) => {
          return client.Name.toLowerCase().includes(searchTerm);
        });
      } else {
        this.getFavori(cdtn);
      }
    },
    filterModifClients(cdtn) {
      if (this.inputClient != "") {
        if (cdtn) {
          this.getClientsPro();
        } else {
          this.getClientsPar();
        }
        const searchTerm = this.inputClient.trim().toLowerCase();
        this.Clients = this.Clients.filter((client) => {
          return client.Name.toLowerCase().includes(searchTerm);
        });
      } else {
        this.getFavori(cdtn);
      }
    },
    filterSessionClients() {
      if (this.inputClient == "") {
        this.getFavori(this.comptoirSessionActive[0].Client[0].professionnel);
      } else {
        this.getClient(this.comptoirSessionActive[0].Client[0].professionnel);
        const searchTerm = this.inputClient.trim().toLowerCase();
        this.Clients = this.Clients.filter((client) => {
          return client.Name.toLowerCase().includes(searchTerm);
        });
      }
    },
    getHistorique() {
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getone`, {
          uid: this.comptoirSessionActive[0].Client[0].Name,
        })
        .then((response) => {
          this.historique = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterHistoriqueFile(value, state) {
      if (state == true) {
        this.tabFilterHistorique.push(value);
        this.fetchHistoriqueFiltred();
      } else {
        var indexD = this.tabFilterHistorique.indexOf(value);

        if (indexD > -1) {
          this.tabFilterHistorique.splice(indexD, 1);
        }
        this.fetchHistoriqueFiltred();
      }
    },
    fetchHistoriqueFiltred() {
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getType`, {
          uid: this.comptoirSessionActive[0].Client[0].Name,
          Type: this.tabFilterHistorique,
        })
        .then((response) => {
          this.historique = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setHistoriqueFile(historiquedata) {
      this.updateactionArticle(historiquedata);
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    async postPartClients() {
      await axios.post(`${process.env.VUE_APP_API}/client`, {
        professionnel: false,
        plateform: this.user.proprietaire,
        Name: this.nomParticulier,
        Group: {
          Name: this.groupeClient,
        },
        Adresse: this.adresseParticulier,
        Complement: this.complementParticulier,
        PostalCode: this.codepostalParticulier,
        City: this.villeParticulier,
        Country: this.paysParticulier,
        email: this.emailParticulier,
        telephone: this.telephoneParticulier,
        archived: false,
        counter: {
          devis: this.devisRadio,
          bondecommande: this.commandeRadio,
          bondelivraison: this.livraisonRadio,
          bondelivraisonavoir: this.livraisonAvoirRadio,
          facture: this.factureRadio,
          retour: this.retourRadio,
        },
      });
      this.getClientsPar();
      let response = await axios.post(
        `${process.env.VUE_APP_API}/client/getOne/${this.nomParticulier}`,
        {
          plateform: this.user.proprietaire,
        }
      );
      await this.createcomptoirsession({
        clientFocus: response.data,
        refClient: "",
        consigneData: [],
        consigneNonData: [],
        dsvCheck: false,
        remisegenerale: 0,
      });

      this.closeNewInputClient();
      this.closeInputClient();

      this.toast.success("Client ajouté !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    chooseClient() {
      this.getclientchoosedtab(this.comptoirSessionActive[0].Client[0]);
      this.$router.push("/detailClient");
    },
    async getClient(pro) {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      if (pro) {
        await axios
          .get(`${process.env.VUE_APP_API}/client/${plateform_mere}`)
          .then((response) => {
            this.Clients = response.data;
          });
      } else {
        axios
          .get(
            `${process.env.VUE_APP_API}/client/clientParticulier/${plateform_mere}`
          )
          .then((response) => {
            this.Clients = response.data;
          });
      }
    },
    async getClientsPro() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .get(`${process.env.VUE_APP_API}/client/${plateform_mere}`)
        .then((response) => {
          this.Clients = response.data;
          this.cdtnClient = true;
        });
    },
    async getClientsPar() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      axios
        .get(
          `${process.env.VUE_APP_API}/client/clientParticulier/${plateform_mere}`
        )
        .then((response) => {
          this.Clients = response.data;
          this.cdtnClient = false;
        });
    },
    getFavori(cdtnCl) {
      this.stateToAdd = cdtnCl;
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: cdtnCl,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    async createSession(createParticulier) {
      if (this.newInputClient != "") {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }

        let response = {};
        if (createParticulier) {
          await this.getConfigurationCondition();
          const defaultOption = this.configurationConditionList.find(
            (cond) => cond.default
          );

          response.data = [];
          response.data = [
            {
              _id: "inconnu" + Math.floor(Math.random() * 1000).toString(),
              plateform: this.user.proprietaire,
              Name: "Particulier",
              Group: {
                Name: defaultOption ? defaultOption.Name : "T3",
              },
              professionnel: false,
              editionRemise: true,
              counter: {
                devis: true,
                bondecommande: true,
                facture: true,
                bondelivraison: false,
                bondelivraisonavoir: false,
                retour: false,
              },
            },
          ];
        } else {
          response = await axios.post(
            `${process.env.VUE_APP_API}/client/getone/${this.newInputClient}`,
            {
              plateform: plateform_mere,
            }
          );
        }
        console.log(response.data);

        if (response.data.length > 0) {
          var docType;
          if (this.cdtnClient) {
            docType = "Bon de livraison";
          } else {
            docType = "Bon de commande";
          }
          await this.createcomptoirsession({
            clientFocus: response.data,
            refClient: "",
            consigneData: [],
            consigneNonData: [],
            dsvCheck: false,
            remisegenerale: 0,
            documentType: docType,
          });

          if (!createParticulier) {
            await this.sendToRestoreTransportCart({
              _id: response.data[0]._id,
              TransportCart: true,
            });
          }

          if (
            this.$route.path === "/searchArticleExact" ||
            this.$route.path === "/searchArticleExactBis"
          ) {
            this.searchRefFournisseur({
              Ref_fournisseur: this.Ref_fournisseur_search,
            });
          }
          this.newInputClient = "";
          this.showNewInput = false;
        }
      } else {
        this.getFavori(this.cdtnClient);
        this.newInputClient = "";
        this.showNewInput = false;
      }
    },

    async updateSessionClient(client) {
      if (this.inputClient != "") {
        let response = await axios.post(
          `${process.env.VUE_APP_API}/client/getone/${client}`,
          {
            plateform: this.user.proprietaire,
          }
        );

        if (response.data.length > 0) {
          var docType;
          if (this.modifPro) {
            docType = "Bon de livraison";
          } else {
            docType = "Bon de commande";
          }
          await this.savecomptoirsession({
            clientFocus: response.data,
            refClient: this.comptoirSessionActive[0].refClient,
            consigneData: this.comptoirSessionActive[0].consigneData,
            consigneNonData: this.comptoirSessionActive[0].consigneNonData,
            dsvCheck: this.comptoirSessionActive[0].dsvCheck,
            documentType: docType,
          });
          await this.updateactionarticlesession({
            Name: this.comptoirSessionActive[0].Client[0].Name,
            Group: this.comptoirSessionActive[0].Client[0].Group.Name,
            _id: this.comptoirSessionActive[0].Client[0]._id,
            TransportCart: true,
          });
          if (this.$route.path === "/searchArticleExact") {
            this.searchRefFournisseur({
              Ref_fournisseur: this.Ref_fournisseur_search,
            });
          }
          this.inputClient = "";
          this.showSessionTab = false;
        }
      } else {
        this.getFavori(this.cdtnClient);
        this.inputClient = "";
        this.showSessionTab = false;
      }
    },

    openSessionList() {
      this.showOutilsSession = !this.showOutilsSession;
    },
    selectSession(session) {
      this.openComptoirSession(session);
      this.showOutilsSession = false;
    },
    showSession(session) {
      this.inputClient = session.Client[0].Name;
    },
    clientPush() {
      this.$router.push("/actionClient");
    },
    async chooseSession(session) {
      this.setcomptoirsession(session);
      // await this.getPanierTransportClient();
      this.savecomptoirsession({
        clientFocus: session.Client,
      });
      this.sendToRestoreTransportCart({
        _id: session.Client[0]._id,
        TransportCart: true,
      });
    },
    closeNewInputClient() {
      this.showNewInput = false;
      this.newInputClient = "";
    },
    closeInputClient() {
      this.showSessionTab = false;
      this.inputClient = "";
    },
    async removeSession() {
      await this.removecomptoirsession(this.comptoirSessionActive[0]);
      this.showSessionTab = false;
    },
    openComptoirSession(session) {
      this.chooseSession(session);
    },
    OpenShowDetail(session) {
      this.detailTab = session;
      this.showDetail = true;
    },
    CloseShowDetail() {
      this.showDetail = false;
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
    },
    async saveGroupClient(tab) {
      await this.savecomptoirsession({
        clientFocus: tab.Client,
        refClient: tab.refClient,
        consigneData: tab.consigneData,
        consigneNonData: tab.consigneNonData,
        dsvCheck: tab.dsvCheck,
      });
      await this.updateactionarticlesession({
        Name: tab.Client[0].Name,
        Group: tab.Client[0].Group.Name,
        _id: tab.Client[0]._id,
        TransportCart: true,
      });
    },
  },
  async beforeMount() {
    this.getallcomptoirsession();
  },
  async mounted() {
    this.getFournisseur();
    if (this.comptoirSessionActive.length > 0) {
      // await this.getPanierTransportClient();
      // await this.savecomptoirsession({
      //   clientFocus: this.comptoirSessionActive[0].Client,
      // });
      if (this.comptoirSessionActive[0].Client[0]._id.startsWith('inconnu')) {
        await this.getConfigurationCondition();
      } else {
        await this.sendToRestoreTransportCart({
          _id: this.comptoirSessionActive[0].Client[0]._id,
          TransportCart: true,
        });
      }
    }
  },
};
</script>
<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.overflow-container {
  overflow-x: hidden;
  cursor: pointer;
}

.overflow-container::-webkit-scrollbar {
  width: 8px;
}

.overflow-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
