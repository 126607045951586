import axios from "axios";

export async function sendCommandHoffer(
  plateform: string,
  articles: Array<object>,
  user: string,
  numeroCMD: number
) {
  const prepareCommande = await axios.post(
    `${process.env.VUE_APP_API}/hoffer/sendCommand`,
    {
      plateform: plateform,
      articles: articles,
      user: user,
      numeroCMD: numeroCMD,
    }
  );
  return prepareCommande.data;
}
