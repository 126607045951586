<template>
  <div class="flex flex-col space-y-2" style="height: 85vh">
    <div
      v-if="openModalArticle"
      :class="`modal ${
        !openModalArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModalArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 68vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModalArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <article-info :key="articleInfo_componentKey" />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center">
      <div>
        <button class="w-54 h-8" @click="back()">
          <div
            class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
          >
            <img src="../../assets/RetourIcon.png" class="w-6" />
            <span style="color: #5976e8">Retour</span>
          </div>
        </button>
      </div>
      <div class="flex w-2/6">
        <span
          v-if="catalogueAGSelected.type == 'parent'"
          class="mx-auto text-3xl font-mono"
          >{{
            catalogueAGSelected.assemblyGroupConfiguration.assemblyGroupName
          }}</span
        >
        <span v-else class="mx-auto text-3xl font-mono">{{
          catalogueAGSelected.assemblyGroupConfiguration.child[
            catalogueAGSelected.indexChild
          ].assemblyGroupName
        }}</span>
      </div>
    </div>
    <!-- premier bloc -->
    <div class="flex flex-row">
      <div class="flex w-1/6 bg-white p-1 rounded-sm shadow">
        <div class="flex flex-row space-x-4 mx-auto">
          <div
            class="px-2 bg-blue-500 rounded text-white drop-shadow-sm cursor-pointer"
            v-if="typeSearch == 'En vente'"
          >
            <span>En vente</span>
          </div>
          <div
            class="px-2 bg-gray-400 rounded text-white drop-shadow-sm cursor-pointer"
            @click="getLinkedArticles()"
            v-else
          >
            <span>En vente</span>
          </div>
          <div
            class="px-2 bg-blue-500 rounded text-white drop-shadow-sm cursor-pointer"
            v-if="typeSearch == 'Tous'"
          >
            <span>Tous</span>
          </div>
          <div
            class="px-2 bg-gray-400 rounded text-white drop-shadow-sm cursor-pointer"
            @click="getAllLinkedArticles()"
            v-else
          >
            <span>Tous</span>
          </div>
        </div>
      </div>
      <div class="w-5/6"></div>
    </div>
    <!-- deuxième bloc -->
    <div class="flex flex-row h-5/6">
      <!-- bloc 1.1 -->
      <div class="w-1/6 p-2 flex flex-col space-y-4">
        <div
          class="h-fit bg-white shadow rounded-sm p-2 space-y-3 overflow-y-auto"
        >
          <div>
            <span class="font-semibold text-lg text-blue-800">Marques</span>

            <div class="flex flex-col ml-2 mt-1">
              <side-loading v-show="isLoading" />
              <div
                class="flex flex-row space-x-2 items-center"
                v-for="(bd, indexBrand) in brandList"
                :key="indexBrand"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                  v-model="brandSelected"
                  :value="bd"
                  @change="sortByBrand()"
                />
                <span class="text-sm">{{ bd }}</span>
              </div>
            </div>
          </div>

          <div
            v-if="
              brandList.length > 0 &&
              catalogueAGSelected.assemblyGroupConfiguration.brandSecondary
                .length > 0
            "
          >
            <span class="text-md text-blue-800">Plus de marques</span>

            <div class="flex flex-col ml-2 mt-1">
              <side-loading v-show="isLoading" />
              <div
                class="flex flex-row space-x-2 items-center"
                v-for="(bdSecondary, indexBrandSecondary) in catalogueAGSelected
                  .assemblyGroupConfiguration.brandSecondary"
                :key="indexBrandSecondary"
              >
                <span
                  class="cursor-pointer"
                  v-if="!brandList.includes(bdSecondary)"
                  @click="addNewBrand(bdSecondary, indexBrandSecondary)"
                  >{{ bdSecondary }}</span
                >
                <span
                  v-if="loadingAddNewBrand[indexBrandSecondary]"
                  class="material-icons-outlined text-sm animate-spin"
                >
                  sync
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col max-h-96 bg-white shadow overflow-y-auto rounded-sm px-2 py-1"
        >
          <div
            class="flex flex-row space-x-2 items-center"
            v-for="(gp, indexGroup) in groupList"
            :key="indexGroup"
          >
            <input
              type="checkbox"
              class="w-4 h-8 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
              v-model="groupSelected"
              :value="gp"
              @change="sortByGroup()"
            />
            <span class="">{{ gp }}</span>
          </div>
          <!-- <div>
            <span
              class="text-blue-800 hover:text-gray-500 cursor-pointer"
              @click="
                showArticles(
                  catalogueAGSelected.assemblyGroupConfiguration,
                  null,
                  'parent',
                  index,
                  catalogueAGSelected.assemblyGroupConfiguration
                    .assemblyGroupNodeId
                )
              "
              >{{
                catalogueAGSelected.assemblyGroupConfiguration.assemblyGroupName.toUpperCase()
              }}</span
            >
          </div>
          <div
            class="ml-2"
            v-for="(childConf, indexC) in catalogueAGSelected
              .assemblyGroupConfiguration.child"
            :key="indexC"
          >
            <span
              v-if="
                catalogueAGSelected.type == 'child' &&
                indexC == catalogueAGSelected.indexChild
              "
              class="text-blue-500"
              >{{ childConf.assemblyGroupName }}</span
            >
            <span
              v-else
              class="hover:text-gray-400 cursor-pointer"
              @click="
                showArticles(
                  catalogueAGSelected.assemblyGroupConfiguration,
                  indexC,
                  'child',
                  childConf.assemblyGroupNodeId
                )
              "
              >{{ childConf.assemblyGroupName }}</span
            >
          </div> -->
        </div>
        <div class="h-fit bg-white shadow rounded-sm p-2 overflow-y-auto">
          <div
            class="flex flex-col ml-2 mt-1"
            v-if="!showFiltreList"
            @click="showFiltreList = true"
          >
            <div class="flex items-center justify-between text-lg">
              <span
                class="material-icons-outlined bg-blue-600 text-white rounded-full absolute -mt-2 -ml-2"
              >
                info
              </span>
              <button class="ml-4">Plus de filtres</button>
              <span
                class="material-icons-outlined cursor-pointer"
                style="color: orange"
                >keyboard_double_arrow_down</span
              >
            </div>
          </div>
          <div class="flex flex-col ml-2 mt-1" v-else>
            <side-loading v-show="isLoading" />
            <div class="flex justify-between">
              <span class="text-blue-800 font-semibold text-lg">Filtres</span>

              <span
                @click="showFiltreList = false"
                class="material-icons-outlined cursor-pointer"
                style="color: orange"
                >keyboard_double_arrow_up</span
              >
            </div>
            <div
              class="ml-1"
              v-for="(fil, indexFiltre) in filtreList"
              :key="indexFiltre"
            >
              <div
                class="flex items-center cursor-pointer space-y-1"
                @click="tabFiltreVal[indexFiltre] = !tabFiltreVal[indexFiltre]"
              >
                <span>{{ fil.name.toUpperCase() }}</span>
                <span
                  v-if="tabFiltreVal[indexFiltre]"
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  >keyboard_arrow_up</span
                >
                <span
                  v-if="!tabFiltreVal[indexFiltre]"
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  >keyboard_arrow_down</span
                >
              </div>
              <div
                v-show="tabFiltreVal[indexFiltre]"
                class="text-sm space-y-1 ml-2 flex flex-row space-x-2 items-center"
                v-for="(val, indexVal) in fil.value"
                :key="indexVal"
              >
                <input
                  type="checkbox"
                  v-model="filtreSelected"
                  :value="val"
                  @change="sortByFilter()"
                  class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                />
                <span>{{ val }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- bloc 1.2 -->
      <div class="flex flex-col w-4/6 p-2 space-y-2 overflow-y-auto">
        <article-loading v-show="isLoading" />
        <div
          class="flex flex-col bg-white rounded-sm drop-shadow-sm px-2 py-2 space-y-2"
          v-for="(art, indexArt) in articles"
          :key="indexArt"
        >
          <div class="flex flex-row">
            <div class="w-1/5 font-semibold">
              {{ art.tecdocArticle.mfrName }}
            </div>
            <div class="flex flex-row space-x-2 w-3/5">
              <span class="text-blue-600 font-semibold">{{
                art.tecdocArticle.articleNumber
              }}</span>
              <ref-courte
                v-if="art.tecdocArticle.mfrName == 'MEYLE'"
                :article="art.tecdocArticle.articleNumber"
                :key="art.tecdocArticle.articleDbData._id"
              />
              <div
                class="flex flex-row items-center text-sm text-blue-500 cursor-pointer w-fit"
                @click="plusInfo(art.tecdocArticle.articleDbData)"
              >
                <span
                  title="Plus d'informations"
                  class="material-icons-outlined ml-1"
                >
                  info
                </span>
              </div>
            </div>
            <div class="w-1/5"></div>
          </div>
          <div class="flex flex-row">
            <div class="w-1/5">
              <div
                class="drop-shadow-sm w-24 h-24"
                @click="plusInfo(art.tecdocArticle.articleDbData)"
              >
                <img
                  class="h-full mx-auto"
                  v-if="art.tecdocArticle.images[0]"
                  :src="art.tecdocArticle.images[0].imageURL200"
                />
                <img
                  class="h-full mx-auto"
                  v-else
                  src="../../assets/not-found.png"
                />
              </div>
            </div>
            <div class="flex flex-row w-3/5">
              <div class="flex flex-col w-3/4">
                <div
                  class="font-semibold"
                  v-if="art.tecdocArticle.genericArticles"
                >
                  {{
                    art.tecdocArticle.genericArticles[0]
                      .genericArticleDescription
                  }}
                </div>
                <div
                  class="text-sm text-gray-400 max-h-10 overflow-hidden"
                  v-if="
                    art.tecdocArticle.articleCriteria.length > 0 &&
                    !showCriteria[indexArt]
                  "
                >
                  {{ art.tecdocArticle.articleCriteria[0].criteriaDescription }}
                  : {{ art.tecdocArticle.articleCriteria[0].formattedValue }}
                </div>
                <div
                  class="text-sm text-gray-400 max-h-10 overflow-hidden"
                  v-else-if="
                    art.tecdocArticle.articleCriteria.length > 0 &&
                    showCriteria[indexArt]
                  "
                >
                  <div
                    v-for="(critere, indexCrit) in art.tecdocArticle
                      .articleCriteria"
                    :key="indexCrit"
                  >
                    {{ critere.criteriaDescription }} :
                    {{ critere.formattedValue }}
                  </div>
                </div>
                <!-- <div
                  class="flex flex-row items-center text-sm text-blue-500 cursor-pointer w-fit"
                  @click="
                    plusInfo(
                      art.tecdocArticle.articleNumber,
                      art.tecdocArticle.mfrName
                    )
                  "
                >
                  <span title="Plus d'informations" class="material-icons-outlined ml-1 pt-2">
                    info
                  </span>
                </div> -->
              </div>
              <div class="w-1/4">
                <div
                  v-if="comptoirSession.length >= 1"
                  class="flex flex-row items-center space-x-2 ml-2"
                >
                  <input
                    type="Number"
                    @change="component_reload_key = component_reload_key + 1"
                    v-model="qtyToPanier[indexArt]"
                    class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                  />
                  <span
                    class="material-icons-round text-blue-500 cursor-pointer mx-auto"
                    @click="
                      handlerArticle(
                        {
                          article: art.tecdocArticle.articleDbData,
                          stockData: art.tecdocArticle.articleDbStock,
                        },
                        qtyToPanier[indexArt]
                      )
                    "
                  >
                    add_shopping_cart
                  </span>
                </div>
                <div v-else class="flex flex-col items-center space-x-2 ml-2">
                  <span class="material-icons text-red-500">
                    remove_shopping_cart
                  </span>
                  <p class="text-xs text-red-500">Client non sélectionné</p>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-center w-1/3">
              <div class="w-full flex flex-col text-sm">
                <fullDispoEANArticleVue
                  :Code_EAN="art.tecdocArticle.articleDbData.Code_EAN"
                  :qty="qtyToPanier[indexArt]"
                  :Prefixe_tarif="art.tecdocArticle.articleDbData.Prefixe_tarif"
                  :article="art.tecdocArticle.articleNumber"
                  :marque="art.tecdocArticle.mfrName"
                  :key="component_reload_key"
                />
              </div>
              <div class="w-full flex flex-col text-sm">
                <prixNetArticleVue
                  :article="art.tecdocArticle.articleNumber"
                  :marque="art.tecdocArticle.mfrName"
                  :Prefixe_tarif="art.tecdocArticle.articleDbData.Prefixe_tarif"
                  :qty="qtyToPanier[indexArt]"
                  :key="component_reload_key"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- bloc 1.3 -->
      <div class="w-1/6 flex flex-col space-y-2 p-2">
        <div class="w-full h-full bg-white"></div>
      </div>
    </div>
    <!-- troisième bloc -->
    <div class="flex flex-row">
      <div class="w-1/6"></div>
      <div class="w-4/6">
        <div class="flex flex-col mx-auto w-fit">
          <div class="text-xs">
            {{ activePage }} page sur {{ pageMax }} de
            {{ articleAmount }} articles
          </div>
          <div class="flex flex-row mx-auto text-sm">
            <span
              class="px-2 py-1 bg-gray-100 rounded-l cursor-pointer hover:bg-gray-200 shadow"
              v-if="pageMax > 1 && activePage != 1"
              @click="prevPage()"
              >Précédent</span
            >
            <span
              class="px-2 py-1 bg-gray-100 rounded-r cursor-pointer hover:bg-gray-200 shadow"
              v-if="pageMax > 1 && activePage != pageMax"
              @click="nextPage()"
              >Suivant</span
            >
          </div>
        </div>
      </div>
      <div class="w-1/6"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import articleLoading from "../../components/Catalogue/articleLoading.vue";
import sideLoading from "../../components/Catalogue/sideLoading.vue";
// import prixArticle from "../../components/Catalogue/prixArticle.vue";
import fullDispoEANArticleVue from "@/components/articleInfo/fullDispoEANArticle.vue";
import { useToast } from "vue-toastification";
import articleInfo from "../../components/articleInfo/articleInfo.vue";
import refCourte from "../../components/Catalogue/refCourte.vue";
import prixNetArticleVue from "@/components/Catalogue/prixNetArticle.vue";
import { searchArticles, prepareArticle } from "@/hooks/searchEngine/articles";
export default {
  data() {
    return {
      activePage: 1,
      articleAmount: 0,
      pageMax: 0,
      typeSearch: "En vente",
      groupList: [],
      groupSelected: [],
      brandList: [],
      brandSelected: [],
      filtreList: [],
      filtreSelected: [],
      articles: [],
      articlesTemp: [],
      articlesSave: [],
      qtyToPanier: [],
      tabFiltreVal: [],
      isLoading: false,
      showCriteria: [],
      brandNameToAdd: "",
      errorBrandAdd: false,
      component_reload_key: 0,
      articleInfo_componentKey: 0,
      userGroupClient: "",
      openModalArticle: false,
      showFiltreList: false,
      loadingAddNewBrand: [],
    };
  },
  components: {
    articleLoading,
    sideLoading,
    // prixArticle,
    articleInfo,
    refCourte,
    fullDispoEANArticleVue,
    prixNetArticleVue,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters([
      "catalogueAGSelected",
      "user",
      "comptoirSession",
      "comptoirSessionActive",
      "actionArticle",
      "vehicule",
      "plateform",
    ]),
  },
  methods: {
    ...mapActions([
      "setcatalogueagselected",
      "setArticleInfo",
      "setcomptoirsession",
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
      "savecomptoirsession",
      "updaterefclient",
    ]),
    back() {
      this.$router.push("/catalogueHubNew");
    },
    async showArticles(assemblyConfiguration, indexChild, type, selectedAGID) {
      this.activePage = 1;
      await this.setcatalogueagselected({
        assemblyGroupConfiguration: assemblyConfiguration,
        indexChild: indexChild,
        type: type,
        ID: selectedAGID,
        carID: this.catalogueAGSelected.carID,
      });
      this.$router.push("/catalogueTecDoc");
      this.getLinkedArticles();
    },
    initTabs() {
      this.articles = [];
      this.filtreList = [];
      this.filtreSelected = [];
      this.brandSelected = [];
      this.groupSelected = [];
      this.brandList = [];
      this.groupList = [];
    },
    async getLinkedArticles() {
      this.initTabs();
      this.isLoading = true;
      await axios
        .post(`${process.env.VUE_APP_API}/catalogue/gettecdocarticle`, {
          AssemblyGroup: this.catalogueAGSelected.ID,
          LinkingTarget: this.catalogueAGSelected.carID,
          assemblyGroupConfiguration:
            this.catalogueAGSelected.assemblyGroupConfiguration,
          indexChild: this.catalogueAGSelected.indexChild,
          type: this.catalogueAGSelected.type,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.articleAmount = response.data.pagesData[0].totalMatchingArticles;
          this.pageMax = response.data.pagesData[0].maxAllowedPage;
          this.typeSearch = response.data.typeSearch;
          this.setArticles(response.data.articles);
          this.isLoading = false;
        });
      if (this.articles.length < 1) {
        this.getAllLinkedArticles();
        this.error("Aucun article dispo!");
      }
      this.articlesSave = this.articles;
    },
    async getAllLinkedArticles() {
      this.initTabs();
      this.isLoading = true;
      await axios
        .post(`${process.env.VUE_APP_API}/catalogue/getalltecdocarticle`, {
          AssemblyGroup: this.catalogueAGSelected.ID,
          LinkingTarget: this.catalogueAGSelected.carID,
          plateform: this.user.proprietaire,
          page: this.activePage,
        })
        .then((response) => {
          this.articleAmount = response.data.pagesData[0].totalMatchingArticles;
          this.pageMax = response.data.pagesData[0].maxAllowedPage;
          this.typeSearch = response.data.typeSearch;
          this.setArticles(response.data.articles);
          this.isLoading = false;
        });
      this.articlesSave = this.articles;
    },
    setDispo(resArt) {
      for (const art of resArt) {
        art.dispo.forEach((element) => {
          this.articles.push(element);
        });
      }
    },
    async setArticles(tdArticles) {
      var tmpArtStock = [];
      var tmpArtVente = [];
      var enStock = [];
      var enVente = [];
      await tdArticles.forEach((element) => {
        if (
          element.articleDbStock.length > 0 &&
          parseInt(element.articleDbStock[0].stock) > 0
        ) {
          enStock.push(element);
        } else {
          enVente.push(element);
        }

        this.qtyToPanier.push(1);
        this.setFiltre({ tecdocArticle: element });
        this.setGroup({ tecdocArticle: element });
      });

      for (const artStock of enStock) {
        tmpArtStock.push({
          tecdocArticle: artStock,
          articlesInfo: { Prix_euro: "00" },
          stock: "0",
        });
      }

      for (const artVente of enVente) {
        tmpArtVente.push({
          tecdocArticle: artVente,
          articlesInfo: { Prix_euro: "00" },
          stock: "0",
        });
      }

      this.setBrandSort(tmpArtStock);

      this.setBrandSort(tmpArtVente);

      this.setBrandSecondary();
    },
    setBrandSort(artTab) {
      if (this.typeSearch != "Tous") {
        if (this.catalogueAGSelected.indexChild != null) {
          for (const bd of this.catalogueAGSelected.assemblyGroupConfiguration
            .brand) {
            if (
              !this.catalogueAGSelected.assemblyGroupConfiguration.child[
                this.catalogueAGSelected.indexChild
              ].blacklistBrand.includes(bd)
            ) {
              for (const art of artTab) {
                if (bd == art.tecdocArticle.mfrName) {
                  if (!this.brandList.includes(bd)) {
                    this.brandList.push(bd);
                  }
                  this.articles.push(art);
                }
              }
            }
          }
        } else {
          for (const bd of this.catalogueAGSelected.assemblyGroupConfiguration
            .brand) {
            for (const art of artTab) {
              if (bd == art.tecdocArticle.mfrName) {
                if (!this.brandList.includes(bd)) {
                  this.brandList.push(bd);
                }
                this.articles.push(art);
              }
            }
          }
        }
      } else {
        for (const art of artTab) {
          if (!this.brandList.includes(art.tecdocArticle.mfrName)) {
            this.brandList.push(art.tecdocArticle.mfrName);
          }
          this.articles.push(art);
        }
      }
    },
    setBrandSecondary() {
      if (this.typeSearch != "Tous") {
        if (this.catalogueAGSelected.indexChild != null) {
          for (const [
            indexBD,
            bd,
          ] of this.catalogueAGSelected.assemblyGroupConfiguration.brandSecondary.entries()) {
            if (
              this.catalogueAGSelected.assemblyGroupConfiguration.child[
                this.catalogueAGSelected.indexChild
              ].blacklistBrand.includes(bd)
            ) {
              this.catalogueAGSelected.assemblyGroupConfiguration.brandSecondary.splice(
                this.catalogueAGSelected.assemblyGroupConfiguration.brandSecondary.indexOf(
                  bd
                ),
                1
              );
            }
          }
        }
      }
    },
    setFiltre(fArt) {
      for (const cri of fArt.tecdocArticle.articleCriteria) {
        if (
          !this.filtreList.some((item) => item.name === cri.criteriaDescription)
        ) {
          this.filtreList.push({
            name: cri.criteriaDescription,
            value: [cri.formattedValue],
          });
        } else {
          var indexCrit = this.filtreList.findIndex(function (item) {
            return item.name === cri.criteriaDescription;
          });
          if (
            !this.filtreList[indexCrit].value.some(
              (item) => item === cri.formattedValue
            )
          ) {
            this.filtreList[indexCrit].value.push(cri.formattedValue);
          }
        }
      }
    },
    setGroup(fArt) {
      if (
        !this.groupList.includes(
          fArt.tecdocArticle.genericArticles[0].genericArticleDescription
        )
      ) {
        this.groupList.push(
          fArt.tecdocArticle.genericArticles[0].genericArticleDescription
        );
      }
    },
    sortByFilter() {
      var tmpArtAdd = [];
      if (this.filtreSelected.length < 1) {
        this.articles = this.articlesSave;
      } else {
        for (const valFil of this.filtreSelected) {
          for (const Art of this.articles) {
            if (Art.tecdocArticle.articleCriteria.length > 0) {
              for (const crit of Art.tecdocArticle.articleCriteria) {
                if (valFil == crit.formattedValue) {
                  tmpArtAdd.push(Art);
                }
              }
            }
          }
        }
        this.articles = tmpArtAdd;
      }
      this.component_reload_key++;
    },
    sortByBrand() {
      var tmpArtAdd = [];
      if (this.brandSelected.length < 1) {
        this.articles = this.articlesSave;
      } else {
        if (this.groupSelected.length > 0) {
          for (const valBrand of this.brandSelected) {
            for (const Art of this.articles) {
              if (valBrand == Art.tecdocArticle.mfrName) {
                tmpArtAdd.push(Art);
              }
            }
          }
        } else {
          for (const valBrand of this.brandSelected) {
            for (const Art of this.articlesSave) {
              if (valBrand == Art.tecdocArticle.mfrName) {
                tmpArtAdd.push(Art);
              }
            }
          }
        }
        this.articles = tmpArtAdd;
      }
      this.component_reload_key++;
    },
    sortByGroup() {
      var tmpArtAdd = [];
      if (this.groupSelected.length < 1) {
        this.articles = this.articlesSave;
      } else {
        if (this.brandSelected.length > 0) {
          for (const valGroup of this.groupSelected) {
            for (const Art of this.articles) {
              if (
                valGroup ==
                Art.tecdocArticle.genericArticles[0].genericArticleDescription
              ) {
                tmpArtAdd.push(Art);
              }
            }
          }
        } else {
          for (const valGroup of this.groupSelected) {
            for (const Art of this.articlesSave) {
              if (
                valGroup ==
                Art.tecdocArticle.genericArticles[0].genericArticleDescription
              ) {
                tmpArtAdd.push(Art);
              }
            }
          }
        }
        this.articles = tmpArtAdd;
      }
      this.component_reload_key++;
    },
    nextPage() {
      this.activePage = this.activePage + 1;
      this.getAllLinkedArticles();
    },
    prevPage() {
      this.activePage = this.activePage - 1;
      this.getAllLinkedArticles();
    },
    error(message) {
      this.toast.warning(message, {
        position: "top-center",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    addNewBrand(brandSecondary, indexLoading) {
      this.errorBrandAdd = false;
      this.loadingAddNewBrand[indexLoading] = true;
      axios
        .post(`${process.env.VUE_APP_API}/catalogue/addOneBrand`, {
          AssemblyGroup: this.catalogueAGSelected.ID,
          LinkingTarget: this.catalogueAGSelected.carID,
          brand: brandSecondary,
        })
        .then((res) => {
          this.loadingAddNewBrand[indexLoading] = false;
          if (res.data.articles.length > 0) {
            this.brandList.push(brandSecondary);
            res.data.articles.forEach((element) => {
              this.articles.push({
                tecdocArticle: element,
                articlesInfo: { Prix_euro: "00" },
                stock: "0",
              });
              // }
              this.qtyToPanier.push(1);
              this.setFiltre({ tecdocArticle: element });
              this.setGroup({ tecdocArticle: element });
            });
          } else {
            this.error("Aucun article trouvé pour cette marque");
          }
        });
    },
    async handlerArticle(structure, quantity) {
      var existArticle = false;
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + parseInt(quantity);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticle(
          structure,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name,
          quantity
        );

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);

        this.updaterefclient(
          this.vehicule[0].data.AWN_immat +
            " " +
            this.vehicule[0].data.AWN_modele
        );

        this.refClient = this.noteDocument;

        this.savecomptoirsession({
          id: 6,
          clientFocus: this.comptoirSessionActive[0].Client,
          refClient: this.comptoirSessionActive[0].refClient,
          consigneData: this.comptoirSessionActive[0].consigneData,
          consigneNonData: this.comptoirSessionActive[0].consigneNonData,
          dsvCheck: this.comptoirSessionActive[0].dsvCheck,
        });
      }
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.comptoirSessionActive[0].dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id + "Consigne",
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        // if (this.comptoirSessionActive[0].Client[0].professionnel == true) {
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
      } else {
        if (
          this.comptoirSessionActive[0].Client[0].numcomptedasilva &&
          this.comptoirSessionActive[0].Client[0].numcomptedasilva != ""
        ) {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
  },
  beforeMount() {
    this.getLinkedArticles();
  },
};
</script>
<style></style>
