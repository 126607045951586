<template>
  <!-- Modal Détails dispo -->
  <div
    v-if="openModal"
    :class="`modal ${
      !openModal && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openModal = false), $emit('closeModalRemFourn', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 50rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="(openModal = false), $emit('closeModalRemFourn', false)"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Remises fournisseurs</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openModal = false), $emit('closeModalRemFourn', false)"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <table class="min-w-full mt-4">
          <thead class="tableHEADTR">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté limite inférieure
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté limite supérieure
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Prix
              </th>
            </tr>
          </thead>

          <tbody class="bg-white w-fit">
            <tr v-for="(r, index) in PrixLot" :key="index" class="tableHEADTR">
              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-center">{{ r.$.QtyLowerLimit }} pcs</div>
              </td>

              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-center">{{ r.$.QtyUpperLimit }} pcs</div>
              </td>

              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-center">{{ financial(r.$.Price) }} €</div>
              </td>
            </tr>
          </tbody>
        </table>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="(openModal = false), $emit('closeModalRemFourn', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin Modal Détails dispo -->
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      PrixLot: [],
      openModal: true,
    };
  },
  props: ["Ref_fournisseur"],
  methods: {
    getPrixLot() {
      axios
        .get(
          `${process.env.VUE_APP_API}/vanheck/getPrixLot/${this.Ref_fournisseur}`
        )
        .then((response) => {
          this.PrixLot = response.data;
        })
        .catch(() => {
          this.PrixLot = [];
        });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.getPrixLot();
  },
};
</script>
