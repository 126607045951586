<template>
    <div
    v-if="showArticleIn"
    :class="`modal ${
    !showArticleIn && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
        <div
        @click="showArticleIn=false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>
    <div class="z-50 w-3/4 mx-auto bg-white rounded shadow-lg modal-container md:max-w-lg">
        <div class="flex items-center justify-end pb-3 text-center">
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showArticleIn = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
    <div class="z-50 px-6 text-left modal-content">

        <h1 class="pb-3 text-center text-2xl"> AJOUTER UN ARTICLE </h1>

        <!--Body-->
        <div class="py-9 grid grid-cols-2 text-black rounded-t-md bg-gray-200">
            <div class="text-center">
            <label> Fournisseur </label>
            <div class="">
                <select
                v-model="fournisseur.CompanyName"
                type="text"
                ref="fournisseur.CompanyName"
                class="focus:outline-2 outline-sky-300 w-3/4 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >    
                    <option></option>
                    <option></option>
                    <option></option>
                    <option></option>
                </select>
            </div>
        </div>
            <div class="text-center">
                <label class=""> Marque </label>
                <div class="">
                    <input
                    v-model="code_marque"
                    type="text"
                    placeholder="ARN"
                    ref="code_marque"
                    class="focus:outline-2 outline-sky-300 w-3/4 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
        </div>
            
        <div class="py-2 grid grid-cols-2 text-black bg-gray-200 border-b">
            <div class="text-center">
                <label class=""> Référence </label>
                <div> 
                    <input
                    v-model="ref_fournisseur"
                    type="text"
                    placeholder="Ex: MR-3441"
                    ref="ref_fournisseur"
                    class="focus:outline-2 outline-sky-300 w-3/4 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
            <div class="text-center">
                <label class=""> Référence courte </label>
                <div> 
                    <input
                    v-model="ref_fournisseur_courte"
                    type="text"
                    ref="ref_fournisseur_courte"
                    class="focus:outline-2 outline-sky-300 w-3/4 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
        </div>
        <div class="px-5 py-8 text-gray-700 bg-gray-200 border-b grid grid-cols-2">
            <div class="text-center">
                <label class="">Description</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <span
                    class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                    >
                    <span class="material-icons-round">description</span>
                    </span>

                    <input
                    v-model="description"
                    type="text"
                    class="w-full focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
            <div class="text-center pl-9">
                <label> Code EAN </label>
                <div class="mt-2"> 
                    <input
                    v-model="code_ean"
                    placeholder="815710020084"
                    type="text"
                    ref="code_ean"
                    class="focus:outline-2 outline-sky-300 w-3/4  p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
        </div>
        <div class="px-5 py-2 text-gray-700 bg-gray-200 grid grid-cols-2">
            <div class="text-center"> 
                <label> Prix en euros </label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="prix_euro"
                    type="number"
                    class="w-1/2 focus:outline-2 outline-sky-300 px-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"/>
                </div>
            </div>
            <div class="text-center">
                <label>Prix d'achat HT</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="prix_achat"
                    type="number"
                    placeholder="Prix en euros (€)"
                    class="w-1/2 focus:outline-2 outline-sky-300 px-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
        </div>
            
        <div class="px-5 py-2 text-gray-700 bg-gray-200 rounded-md grid grid-cols-3">
            <div class="text-center pr-1">
                <label>PNT1</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="prix_pnt1"
                    type="number"
                    class="w-full focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                    <span
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                        >
                        <span class="material-icons-round">euro</span>
                    </span>
                </div>
            </div>
            <div class="text-center pl-1 pr-1">
                <label>PNT2</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="prix_pnt2"
                    type="number"
                    class="w-full focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                    <span
                    class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                    >
                        <span class="material-icons-round">euro</span>
                    </span>
                </div>
            </div>
            <div class="text-center pl-1">
                <label>PNT3</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="prix_pnt3"
                    type="number"
                    class="w-full focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                    <span
                    class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                    >
                        <span class="material-icons-round">euro</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="px-5 py-2 text-gray-700 bg-gray-200 grid grid-cols-2">
            <div class="text-center"> 
                <label> Quantité mini </label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="quantite_min"
                    type="number"
                    class="w-1/2 focus:outline-2 outline-sky-300 px-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"/>
                </div>
            </div>
            <div class="text-center">
                <label>Quantité maxi</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="quantite_max"
                    type="number"
                    placeholder="Prix en euros (€)"
                    class="w-1/2 focus:outline-2 outline-sky-300 px-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
        </div>

        <div class="w-full py-8 text-gray-700 bg-gray-200 rounded-b-md grid grid-cols-2">
            <div class="text-center">
                <label class="">Code famille</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="code_famille"
                    type="text"
                    placeholder="Code famille"
                    class="w-3/4 focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>

            <div class="text-center">
                <label class="">Code sous famille</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                    v-model="code_sous_famille"
                    type="text"
                    placeholder="Code sous famille"
                    class="w-3/4 focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                </div>
            </div>
        </div>
    </div>


        <!--Footer-->
        <div class="flex justify-end pt-2">
            <button
            @click="showArticleIn = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
                Fermer
            </button>
            <button
            type="submit"
            @click="postArticles()"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
                Ajouter
            </button>
        </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
    setup() {
    const toast = useToast();
    return { toast };
    },
    name: 'ModaleArticle',
    data () {
        return {
            selectedArticle: [],
            ref_fournisseur: "",
            ref_fournisseur_courte: "",
            code_ean: "",
            description: "",
            fournisseur: "",
            fournisseurList: [],
            code_marque: "",
            quantite_min: "",
            quantite_max: "",
            prix_achat: "",
            prix_pnt1: "",
            prix_pnt2: "",
            prix_pnt3: "",
            prix_euro:"",
            code_famille: "",
            code_sous_famille: "",
            showArticleIn: false,
            Articles: [],
            articleNumber: 0,
        }
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        getArticles(skipValue, limitValue) {
      axios
        .post(`${process.env.VUE_APP_API}/article/getall`, {
          skip: skipValue,
          limit: limitValue,
        })
        .then((response) => {
          this.Articles = response.data;
        });
      axios.get(`${process.env.VUE_APP_API}/article/count`).then((response) => {
        this.articleNumber = response.data;
      });
    },
        postArticles() {
      axios
        .post(`${process.env.VUE_APP_API}/article`, {
          Ref_fournisseur: this.ref_fournisseur,
          Ref_fournisseur_trim: this.ref_fournisseur,
          Ref_fournisseur_courte: this.ref_fournisseur_courte,
          Code_EAN: this.code_ean,
          Description: this.description,
          fournisseur: this.fournisseur.CompanyName,
          Code_marque: this.code_marque,
          Qte_mini: this.quantite_min,
          Qte_maxi: this.quantite_max,
          Prix_euro: parseFloat(this.prix_euro),
          PNF: this.prix_achat,
          PNT1: this.prix_pnt1,
          PNT2: this.prix_pnt2,
          PNT3: this.prix_pnt3,
          Code_famille_NU: this.code_famille,
          Code_sousfamille_NU: this.code_sous_famille,
          StockInfo: {
            Depot: {
              Prefix: "",
            },
            Product: {
              Stock: this.quantite_stock,
            },
          },
          plateform: this.user.proprietaire,
        })
        .then(() => {
          this.getArticles(10, 10);
          this.toast.success("Article ajouté !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.showArticleIn=false
        })
        .catch(error => {
            console.error(error)
        })
    },
    },
    props: ["showArticle", "toggleModale"],
    
    
    mounted () {
        this.showArticleIn=this.showArticle;
    },
}
</script>

<style>

</style>