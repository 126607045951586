<template>
  <div class="flex justify-between mt-4">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/ReportsPage'" />
    </div>
    <div class="flex justify-end space-x-2">
      <div>
        <button
          class="px-6 py-3 font-medium text-white bg-bluevk rounded-md hover:bg-blue-500 focus:outline-none"
        >
          Valider
        </button>
      </div>
      <div>
        <button
          class="px-6 py-3 text-bluevk bg-transparent rounded-md hover:bg-gray-200 hover:text-blue-400 focus:outline-none"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>

  <div v-if="tabDetailAppointment" class="mt-4 p-2 h-full w-full">
    <div class="flex justify-between">
      <div class="flex space-x-52">
        <!-- Date -->
        <div class="flex flex-col space-y-1">
          <h1 class="underline mb-1">Date du RDV :</h1>
          <p>{{ getFRDate(tabDetailAppointment.appointmentDate) }}</p>
        </div>

        <!-- Adresse -->
        <div class="flex flex-col space-y-1">
          <h1 class="underline mb-1">Lieu du RDV :</h1>
          <p>{{ tabDetailAppointment.address }}</p>
          <p>{{ tabDetailAppointment.postalCode }}</p>
          <p>{{ tabDetailAppointment.city }}</p>
        </div>

        <!-- Contact -->
        <div
          v-if="tabDetailAppointment.contact"
          class="flex flex-col space-y-1"
        >
          <h1 class="underline mb-1">Contact :</h1>
          <p>{{ tabDetailAppointment.contact.name }}</p>
          <p>{{ tabDetailAppointment.contact.position }}</p>
          <p>{{ tabDetailAppointment.contact.email }}</p>
          <p>{{ tabDetailAppointment.contact.phone }}</p>
        </div>
      </div>

      <!-- Score -->
      <div class="flex flex-col space-y-1">
        <EmojiRating :rating="tabDetailAppointment.rating" class="" />
      </div>
    </div>

    <div class="mt-12 flex flex-col space-y-1">
      <div class="text-xl font-bold">Sujet(s) à aborder :</div>
      <div class="flex justify-start space-x-2 max-w-full overflow-y-auto">
        <div
          v-for="(topic, indexTopic) in tabDetailAppointment.topics"
          :key="indexTopic"
        >
          <button
            class="px-8 py-1 text-white shadow-lg border rounded-lg bg-bluevk hover:bg-blue-500"
          >
            {{ topic.topic }}
          </button>
        </div>
        <div>
          <input
            v-model="creationTopic"
            placeholder="Ecrire..."
            @keyup.enter="
              tabDetailAppointment.topics.push({
                done: false,
                topic: creationTopic,
              }),
                (creationTopic = '')
            "
            class="px-8 py-1 text-white shadow-lg border rounded-lg bg-gray-700 hover:bg-gray-500"
          />
        </div>
      </div>
    </div>

    <div class="flex space-x-12 mt-8">
      <div class="w-4/6 flex space-x-12">
        <button @click="toggleSpeechRecognition" class="absolute p-2">
          <span v-if="isListening" class="material-icons-round text-red-800"
            >radio_button_checked</span
          >
          <span v-else class="material-icons-round text-red-800">mic</span>
        </button>

        <!-- Description -->
        <textarea
          v-model="tabDetailAppointment.notes"
          placeholder="Notes"
          class="w-full border border-gray-300 rounded-lg p-2 h-36 focus:outline-none focus:border-blue-500"
        ></textarea>

        <!-- Audios -->
        <!-- <div class="flex flex-col max-h-36 overflow-y-auto">
          <div
            v-for="(
              attachment, indexAttachment
            ) in tabDetailAppointment.attachments"
            :key="indexAttachment"
          >
            <audio :src="attachment" controls></audio>
          </div>
        </div> -->
      </div>

      <!-- A demander -->
      <div class="w-1/6 flex flex-col space-y-1">
        <h1 class="underline mb-3">A demander :</h1>
        <div
          v-for="(demand, indexDemand) in tabDetailAppointment.demands"
          :key="indexDemand"
        >
          <div
            class="py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          >
            <input
              type="radio"
              id="demandRadio"
              name="demandRadio"
              value="true"
              v-model="demand.data"
            />
            <label class="pl-2 checkbox">{{ demand.data }}</label>
          </div>
        </div>
      </div>

      <!-- Actions -->
      <div class="w-1/6 flex flex-col space-y-1">
        <h1 class="underline mb-3">Actions à faire :</h1>
        <div
          v-for="(action, indexAction) in tabDetailAppointment.actions"
          :key="indexAction"
        >
          <div
            class="py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          >
            <input
              type="radio"
              id="actionRadio"
              name="actionRadio"
              value="true"
              v-model="action.data"
            />
            <label class="pl-2 checkbox">{{ action.data }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Statistiques -->
    <div class="mt-12">
      <p>Total BDLS : {{ totalBDL }}</p>
      <p>Total Factures : {{ totalFacture }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
// import audioRecorder from "@/components/Input/audioRecorder.vue";
import EmojiRating from "@/components/Label/EmojiRating.vue";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  data() {
    return {
      // audio: null,
      // isPlaying: false,
      // bars: 30,
      // currentTime: 0,
      // initialHeights: [],
      isPlaying: false,
      audio: null,
      currentAttachment: null,
      formattedTime: "0:00",

      creationTopic: "",
      totalBDL: null,
      totalFacture: null,

      tabDetailAppointment: {},
      rating: null,
    };
  },
  components: {
    backButton,
    EmojiRating,
    // audioRecorder,
  },
  computed: {
    ...mapGetters(["user", "idAppointment"]),
    // formattedTime() {
    //   const minutes = Math.floor(this.currentTime / 60);
    //   const seconds = Math.floor(this.currentTime % 60)
    //     .toString()
    //     .padStart(2, "0");
    //   return `${minutes}:${seconds}`;
    // },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getReportsPageDetail() {
      axios
        .post(`${process.env.VUE_APP_API}/appointment/getByID`, {
          _id: this.idAppointment,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          console.log("response : ", response);
          this.tabDetailAppointment = response.data.data.tabAppointment;
        });
    },
    togglePlay(attachment) {
      if (this.currentAttachment !== attachment) {
        this.loadAudio(attachment);
      }

      if (this.isPlaying) {
        this.audio.pause();
      } else {
        this.audio.play();
      }

      this.isPlaying = !this.isPlaying;
    },
    loadAudio(attachment) {
      if (this.audio) {
        this.audio.pause(); // Arrête l’audio en cours si nécessaire
      }
      this.audio = new Audio(attachment.url); // Utilisez l'URL du fichier dans S3
      this.currentAttachment = attachment;

      this.audio.addEventListener("timeupdate", this.updateTime);
      this.audio.addEventListener("ended", this.resetAudio);
    },
    updateTime() {
      const minutes = Math.floor(this.audio.currentTime / 60);
      const seconds = Math.floor(this.audio.currentTime % 60)
        .toString()
        .padStart(2, "0");
      this.formattedTime = `${minutes}:${seconds}`;
    },
    resetAudio() {
      this.isPlaying = false;
      this.formattedTime = "0:00";
    },
    toggleSpeechRecognition() {
      if (this.isListening) {
        // Dinleme devam ediyorsa durdur
        this.recognition.stop();
        this.isListening = false;
      } else {
        // Dinleme kapalıysa başlat
        this.recognition.start();
        this.isListening = true;
      }
    },

    // Statistiques
    async getTotalBdls() {
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/countByClientID`, {
          plateform: this.user.proprietaire,
          clientID: this.clientId,
        })
        .then((response) => {
          console.log(response);
          this.totalBDL = response.data;
        });
    },
    async getTotalFactures() {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/countByClientID`, {
          plateform: this.user.proprietaire,
          clientID: this.clientId,
        })
        .then((response) => {
          console.log(response);
          this.totalFacture = response.data;
        });
    },
  },
  async mounted() {
    this.getReportsPageDetail();
    await this.getTotalBdls();
    await this.getTotalFactures();

    // Web Speech API desteğini kontrol edip ses tanıma nesnesini oluşturuyoruz
    if ("webkitSpeechRecognition" in window) {
      this.recognition = new window.webkitSpeechRecognition();
      this.recognition.lang = "fr-FR"; // Türkçe dil ayarı
      this.recognition.continuous = true; // Devamlı dinleme için
      this.recognition.interimResults = false; // Geçici sonuçları gösterme

      // Ses tanıma başarılı olduğunda
      this.recognition.onresult = (event) => {
        const transcript =
          event.results[event.results.length - 1][0].transcript;
        this.tabDetailAppointment.notes += transcript; // Tanınan metni metin alanına ekler
      };

      // Hata oluşursa
      this.recognition.onerror = (event) => {
        console.error("Ses tanıma hatası:", event.error);
        this.isListening = false;
      };

      // Dinleme sona erdiğinde dinlemeyi kapatır
      this.recognition.onend = () => {
        this.isListening = false;
      };
    } else {
      alert("Tarayıcınız ses tanımayı desteklemiyor.");
    }
    // this.audio.addEventListener("timeupdate", this.updateTime);
    // this.audio.addEventListener("ended", () => {
    //   this.isPlaying = false;
    // });

    this.initialHeights = Array.from({ length: this.bars }, () =>
      this.getRandomHeight()
    );
  },
  beforeUnmount() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener("timeupdate", this.updateTime);
      this.audio.removeEventListener("ended", this.resetAudio);
    }
  },
};
</script>

<style scoped>
@keyframes pulse {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}
.animate-pulse {
  animation: pulse 0.5s infinite;
}
</style>
