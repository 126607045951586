<template>
  <div class="flex-wrap">
    <nav class="space-x-2">
      <router-link to="/fournisseurs">
        <a
          href="#"
          name="Liste"
          class="px-3 py-2 text-sky-900 underline underline-offset-2 decoration-2 decoration-orange-200"
        >
          Liste
        </a>
      </router-link>
      <router-link to="/conditionFournisseur">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Conditions
        </a>
      </router-link>
      <router-link to="/commandeInstFournisseur">
        <a
          href="#"
          name="Commander"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Commander
        </a>
      </router-link>
      <router-link to="/hubReceptionFournisseur">
        <a
          href="#"
          name="Réceptionner"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Réceptionner
        </a>
      </router-link>
      <router-link to="/PriorityFournisseur">
        <a
          href="#"
          name="Réceptionner"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Priorités
        </a>
      </router-link>
      <router-link
        to="/ImportConditionFournisseur"
        v-if="plateform.structure_type == 'distrib'"
      >
        <a
          href="#"
          name="Réceptionner"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Importation Fournisseur
        </a>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["plateform"]),
  },
};
</script>

<style></style>
