<template>
  <div>
    <catalogueU
      :configurationCatalogue="catalogueConfiguration"
      :carId="parseInt(carID)"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import catalogueU from "@/components/Catalogue/catalogueU.vue";
export default {
  data() {
    return {
      catalogueConfiguration: [],
      carID: 12345,
      carData: [],
      listChildState: [],
      agLinkedTab: [],
    };
  },
  components: {
    catalogueU,
  },
  computed: {
    ...mapGetters([
      "plateform",
      "vehicule",
      "vehiculeSet",
      "vehiculeVin",
      "user",
      "plateform",
      "selectionCarId",
    ]),
  },
  methods: {
    ...mapActions(["setcatalogueagselected"]),
    getCarID() {
      if (this.selectionCarId != null && !this.vehiculeSet) {
        this.carID = this.selectionCarId;
        this.getCatalogueConfiguration();
      } else {
        if (this.vehiculeSet) {
          if (
            this.vehicule[0].data.AWN_k_type ||
            this.vehicule[0].data.AWN_type_mine
          ) {
            axios
              .post(`${process.env.VUE_APP_API}/catalogue/getCarIdByKType`, {
                ktype: this.vehicule[0].data.AWN_k_type,
                typeMine: this.vehicule[0].data.AWN_type_mine,
              })
              .then((response) => {
                this.carData = response.data.data.array[0];
                if (
                  this.vehicule[0].data.AWN_k_type.length > 0 &&
                  this.vehicule[0].data.AWN_k_type != "Not requested"
                ) {
                  this.carID = this.vehicule[0].data.AWN_k_type;
                } else {
                  this.carID = response.data.data.array[0].carId;
                }
                this.getCatalogueConfiguration();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          this.$router.push("/tecdocsearch");
        }
      }
    },
    async getCatalogueConfiguration() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const catconf = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getLinkedGenArtUniversel`,
        {
          plateform: plateform_mere,
          carId: this.carID,
        }
      );
      this.catalogueConfiguration = catconf.data;
    },
    async setPersoChildCatalogue(indexAG, parentID) {
      if (!this.listChildState[indexAG]) {
        var childFound = [];
        for (const element of this.catalogueConfiguration[0].configuration[
          indexAG
        ].child) {
          for (const ag of this.agLinkedTab) {
            if (element.assemblyGroupNodeId === ag.assemblyGroupNodeId) {
              childFound.push(element);
            }
          }
        }
        this.catalogueConfiguration[0].configuration[indexAG].child =
          childFound;
      }
      this.listChildState[indexAG] = !this.listChildState[indexAG];
    },
    async showArticles(
      assemblyConfiguration,
      indexChild,
      type,
      indexAG,
      selectedAGID
    ) {
      if (type == "parent") {
        this.setPersoChildCatalogue(indexAG);
      }
      await this.setcatalogueagselected({
        assemblyGroupConfiguration: assemblyConfiguration,
        indexChild: indexChild,
        type: type,
        ID: selectedAGID,
        carID: this.carID,
      });
      this.$router.push("/catalogueTecDoc");
    },
  },
  mounted() {
    // location.reload();
    // this.$router.go();

    this.getCarID();
  },
};
</script>
<style></style>
