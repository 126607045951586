<template>
  <div>
    <router-link to="/commandeInstFournisseur">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8">Retour</span>
      </div>
    </router-link>
    <div class="">
      <!-- <div class="flex justify-end space-x-2">
        <buton class="p-2 bg-orange-400 text-white cursor-pointer hover:bg-orange-300 rounded shadow-lg">
          Compte n°1
        </buton>
        <buton class="p-2 bg-orange-400 text-white cursor-pointer hover:bg-orange-300 rounded shadow-lg">
          Compte n°2
        </buton>
        <buton class="p-2 bg-orange-400 text-white cursor-pointer hover:bg-orange-300 rounded shadow-lg">
          Compte n°3
        </buton>
      </div> -->

      <div class="flex justify-start mt-4 space-x-2">
        <!-- <div>
          <span
            v-if="typeCommande == 'instant'"
            class="p-2 bg-sky-500 text-white rounded shadow-lg"
            >Commande Instantanée</span
          >
          <span
            v-else
            class="p-2 bg-amber-400 text-white bg-opacity-60 hover:bg-opacity-25 rounded cursor-pointer hover:shadow"
            @click="typeCommande = 'instant'"
            >Commande Instantanée</span
          >
        </div> -->
        <div>
          <span
            v-if="typeCommande == 'vrac'"
            class="p-2 bg-sky-500 text-white rounded shadow-lg"
            >Commande en vrac</span
          >
          <span
            v-else
            class="p-2 bg-amber-400 text-white bg-opacity-60 hover:bg-opacity-25 rounded cursor-pointer hover:shadow"
            @click="typeCommande = 'vrac'"
            >Commande en vrac</span
          >
        </div>
      </div>
    </div>
    <div
      class="border rounded-tr-md rounded-b-md bg-gray-100 border-sky-400 mt-4 py-2 px-1"
    >
      <commandeInstantanee v-if="typeCommande == 'instant'" />
      <commandeVrac v-else-if="typeCommande == 'vrac'" />
    </div>
  </div>
</template>
<script>
import commandeInstantanee from "../components/Commander/commandeInstantanee.vue";
import commandeVrac from "../components/Commander/commandeVrac.vue";
export default {
  data() {
    return {
      typeCommande: "vrac",
    };
  },
  components: {
    commandeInstantanee,
    commandeVrac,
  },
};
</script>
<style></style>
