<template>
  <!-- modal infos vehicule -->
  <div
    v-if="openInfoVehicule"
    :class="`modal ${
      !openInfoVehicule && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openInfoVehicule = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      v-if="vehiculeSet"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 50rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-3">
          <div class="flex">
            <div class="flex flex-col">
              <p class="text-2xl font-bold">Informations pour:</p>
              <p class="text-2xl font-bold uppercase text-left ml-4">
                {{ vehicule[0].data.AWN_immat }}
              </p>
            </div>
            <div>
              <p class="text-2xl ml-44">
                {{ vehicule[0].data.AWN_marque_carrosserie }}
              </p>
              <div class="text-right">
                <p class="text-2xl font-bold uppercase ml-4">
                  {{ vehicule[0].data.AWN_modele_etude }}
                </p>
                <p class="text-2xl font-bold uppercase ml-4">
                  {{ vehicule[0].data.AWN_version }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openInfoVehicule = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div v-if="vehiculeSet" class="mt-4">
          <infosVehicule :vehicule="vehicule" />
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="openInfoVehicule = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal -->

  <div class="h-screen">
    <div class="-mt-2">
      <div class="flex lg:flex items-center justify-between">
        <!-- menu -->
        <div class="lg:w-1/3 flex flex-row mt-0">
          <router-link to="/actionClient">
            <button
              @mouseover="setHovered1(0, true)"
              @mouseout="setHovered1(0, false)"
              class="cursor-pointer py-1 relative inline-block text-gray-700 hover:text-bluevk text-base md:text-lg font-medium mt-1 md:mt-0"
            >
              Établir un document
              <span
                v-if="isHovered1[0]"
                class="absolute inset-x-0 bottom-[-3px] h-1 bg-bluevk"
              ></span>
            </button>
          </router-link>

          <div class="p-2 text-xs md:text-sm md:text-base">|</div>

          <router-link to="/articleHub">
            <button
              @mouseover="setHovered1(1, true)"
              @mouseout="setHovered1(1, false)"
              class="cursor-pointer py-1 relative inline-block text-gray-700 hover:text-bluevk text-base md:text-lg font-medium mt-1 md:mt-0"
            >
              Articles
              <span
                v-if="isHovered1[1]"
                class="absolute inset-x-0 bottom-[-3px] h-1 bg-bluevk"
              ></span>
            </button>
          </router-link>

          <div class="p-2 text-xs md:text-sm md:text-base">|</div>

          <router-link to="/promotions">
            <button
              @mouseover="setHovered1(2, true)"
              @mouseout="setHovered1(2, false)"
              class="cursor-pointer py-1 relative inline-block text-gray-700 hover:text-bluevk text-base md:text-lg font-medium mt-1 md:mt-0"
            >
              Promotions
              <span
                v-if="isHovered1[2]"
                class="absolute inset-x-0 bottom-[-3px] h-1 bg-bluevk"
              ></span>
            </button>
          </router-link>
        </div>

        <div
          v-if="countConditions > 0"
          class="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 cursor-pointer"
          @click="$router.push('/AlertCondition')"
          role="alert"
        >
          <svg
            class="flex-shrink-0 inline w-4 h-4 me-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
            />
          </svg>
          <span class="sr-only">Info</span>
          <div class="ml-2">
            <span class="font-medium"
              >Attention conditions fournisseur non remplies!</span
            >
            <span class="underline ml-1">{{ countConditions }} conditions</span>
          </div>
        </div>
      </div>

      <!-- affichage du nom du véhicule -->
      <div class="h-screen" v-if="showVehicule">
        <div class="flex justify-center items-center mx-auto mt-2 mb-13">
          <div
            class="flex text-black text-3xl font-medium text-bluevk mx-2 hidden md:flex mx-auto"
            v-if="vehiculeSet"
          >
            <div class="flex flex-col px-1">
              <div class="flex space-x-2">
                <span class="hidden lg:block"
                  >{{ vehicule[0].data.AWN_marque }}
                </span>
                <span class="">{{ vehicule[0].data.AWN_modele }} </span>
              </div>
              <div class="flex space-x-2 hidden xl:block">
                <span class="">{{ vehicule[0].data.AWN_version }} </span>
                <span class=""
                  >{{ vehicule[0].data.AWN_date_mise_en_circulation }}
                </span>
              </div>
            </div>
            <!-- Bouton rouge à droite -->

            <div class="relative hidden sm:block ml-4 mx-5">
              <button
                class="material-icons-round text-white bg-red-500 hover:bg-red-600 rounded"
                @click="clearPlaque()"
              >
                clear
              </button>
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center mx-auto mt-20">
          <!-- Bouton orange à droite -->
          <div class="relative hidden sm:block ml-4 mx-5">
            <button
              class="bg-bluevk hover:bg-gray-700 text-white font-m py-2 px-4 rounded-lg"
              @click="openInfoVehicule = true"
            >
              Infos véhicule
            </button>
          </div>

          <div class="relative hidden sm:block ml-4 mx-5">
            <router-link to="/catalogueHubNew">
              <button
                class="bg-bluevk hover:bg-gray-700 text-white font-m py-2 px-6 rounded-lg"
              >
                Catalogue
              </button>
            </router-link>
          </div>
        </div>
      </div>

      <!-- bandeau commandes en attente-->
      <!-- <div class="flex flex-row mt-0 mb-4 bg-orange-600 justify-center">
      <div class="flex text-white text-1xl font-bold flex-row items-baseline">
        <div class="mr-1">Vous avez</div>
        <div class="flex items-center">
          <span
            class="w-7 h-7 rounded-full bg-gray-700 flex flex-row items-center justify-center"
            >{{ orderInProgress.length }}</span
          >
          <div class="ml-1">commandes en attente</div>
        </div>
      </div>
    </div> -->
    </div>

    <!-- barre de recherche qui s'affiche sur les écrans mobiles -->
    <div class="mt-4 flex items-center justify-center">
      <div
        class="md:hidden flex flex-col items-center justify-center px-8 py-1 shadow rounded border w-auto"
      >
        <div class="flex flex-row items-center">
          <input
            type="text"
            v-model="searchInput"
            placeholder="Rechercher un article"
            class="px-8 py-1 outline-2 outline-sky-300"
            @keyup.enter="
              searchRefFournisseur({ Ref_fournisseur: searchInput })
            "
          />
          <span
            class="material-icons-outlined cursor-pointer text-2xl pl-2"
            @click="searchRefFournisseur({ Ref_fournisseur: searchInput })"
          >
            search
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="flex text-gray-700 flex-row mx-auto">
    <div
      class="flex text-gray-700 text-xl md:text-3xl md:font-medium font-bold flex-row my-10 mx-auto sm: text-bluevk"
    >
      <div class="p-1">Je renseigne le véhicule</div>
    </div>
  </div> -->

    <!-- encarts -->
    <div class="mt-12 mx-1 md:mx-7 flex flex-col sm:flex-row gap-6">
      <!-- info en cours -->
      <div class="w-full lg:w-full flex-1">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-6"
        >
          <!-- Commandes -->
          <div
            class="w-full sm:w-full h-full md:w-full bg-white border border-gray-200 rounded-lg shadow"
          >
            <div class="flex flex-col items-center m-14 md:m-13">
              <img
                src="../assets/dashboard/Commandes.png"
                alt="Pictogramme commandes"
                class="w-16"
              />
              <h5
                class="text-2xl font-medium text-gray-400 w-full text-center mt-7 md:mt-3 lg:mt-7"
              >
                Commandes
              </h5>
              <router-link to="/commandePro">
                <div
                  class="flex flex-col text-center pt-5 hover:scale-110 duration-300 hover:text-gray-600"
                >
                  <span class="text-bluevk font-bold text-6xl">{{
                    orderInProgress.length
                  }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Retours -->
          <div
            class="w-full sm:w-full md:w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
          >
            <div class="flex flex-col items-center m-14">
              <img
                src="../assets/dashboard/RetourCommandes.png"
                alt="Pictogramme retours"
                class="w-16"
              />
              <h5
                class="text-2xl font-medium text-gray-400 w-full text-center mt-7 md:mt-3 lg:mt-7"
              >
                Retours
              </h5>
              <router-link to="/retour">
                <div
                  class="flex flex-col text-center pt-5 hover:scale-110 duration-300 hover:text-gray-600"
                >
                  <span class="text-bluevk font-bold text-6xl">{{
                    retourInProgress
                  }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Consignes -->
          <div
            class="w-full sm:w-full md:w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
          >
            <div class="flex flex-col items-center m-14">
              <img
                src="../assets/dashboard/RetourConsignes.png"
                alt="Pictogramme consignes"
                class="w-16"
              />
              <h5
                class="text-2xl font-medium text-gray-400 w-full text-center mt-7 md:mt-3 lg:mt-7"
              >
                Consignes
              </h5>
              <router-link to="/retour">
                <div
                  class="flex flex-col text-center pt-5 hover:scale-110 duration-300 hover:text-gray-600"
                >
                  <span class="text-bluevk font-bold text-6xl">{{
                    consigneInProgress
                  }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Commandes à réceptionner -->
          <div
            class="w-full sm:w-full md:hidden lg:w-full sm:block lg:block bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
          >
            <div class="flex flex-col items-center m-14">
              <img
                src="../assets/dashboard/Reception.png"
                alt="Pictogramme commandes à réceptionner"
                class="w-16"
              />
              <h5
                class="text-2xl font-medium text-gray-400 w-full text-center mt-7 md:mt-3 lg:mt-7"
              >
                Réception
              </h5>
              <router-link to="/hubReceptionFournisseur">
                <div
                  class="flex flex-col text-center pt-5 hover:scale-110 duration-300 hover:text-gray-600"
                >
                  <span class="text-bluevk font-bold text-6xl">{{
                    commands.length
                  }}</span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- historique -->
      <div class="w-full lg:w-2/3 gap-6 flex flex-col flex-1">
        <!-- Historique -->
        <div
          class="w-full h-full md:h-2/3 lg:w-full lg:h-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
        >
          <div class="flex flex-col px-3 lg:px-7 mt-5">
            <!-- Premier pavé -->
            <div class="bg-beigevk rounded-lg p-2 lg:p-4">
              <router-link to="/historiqueArticle">
                <h5
                  class="font-medium text-bluevk w-full text-left hover:scale-110 duration-300 cursor-pointer"
                >
                  Historique Articles
                </h5>
              </router-link>
              <hr class="lg:my-1 border-t border-bluevk" />
              <div class="flex flex-col">
                <!-- <div
                  class="bg-white rounded-lg p-1 mt-1"
                  v-for="(histArt, index) in histArtTab"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <img
                        src=""
                        alt=" "
                        class="my-auto bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 items-center"
                      />
                      <div class="my-auto">
                        <div
                          v-if="histArt.Search_data.length > 0"
                          class="text-xs font-bold text-bluevk"
                          :title="histArt.Search_data[0].article.Description"
                        >
                          {{
                            histArt.Search_data[0].article.Description.slice(
                              0,
                              10
                            )
                          }}
                          ... |
                          {{ histArt.Search_data[0].article.Code_marque }}
                        </div>
                        <div v-else class="text-xs font-bold text-bluevk">
                          -
                        </div>
                        <div
                          v-if="histArt.Date && histArt.Date != ''"
                          class="text-xs font-light text-gray-800"
                        >
                          {{ getFRDate(histArt.Date) }}
                        </div>
                        <div v-else class="text-xs font-light text-gray-800">
                          -
                        </div>
                        <div
                          v-if="histArt.Search && histArt.Search != ''"
                          class="text-xs font-medium text-orange-600"
                        >
                          {{ histArt.Search }}
                        </div>
                        <div v-else class="text-xs font-medium text-orange-600">
                          -
                        </div>
                      </div>
                    </div>
                    <div class="flex space-x-1">
                      <button
                        v-if="
                          histArt.Result.articleTab.length > 0 &&
                          histArt.Result.articleTab[0].stock.length > 0
                        "
                        class="mr-4 font-bold text-green-600 hover:text-green-700 hover:animate-pulse"
                        style="font-size: 16px"
                        :title="
                          histArt.Result.articleTab[0].stock[0].stock +
                          ' en stock'
                        "
                      >
                        {{ histArt.Result.articleTab[0].stock[0].stock }}
                      </button>
                      <button
                        v-else
                        class="mr-4 font-bold text-green-600 hover:text-green-700 hover:animate-pulse"
                        style="font-size: 16px"
                        title="0 en stock"
                      >
                        -
                      </button>
                      <button
                        v-if="histArt.Search != ''"
                        class="material-icons-round hover:text-gray-600 hover:animate-pulse"
                        style="font-size: 16px"
                        title="Rechercher la référence"
                        @click="
                          searchRefFournisseur({
                            Ref_fournisseur: histArt.Search,
                          })
                        "
                      >
                        search
                      </button>
                      <button
                        v-if="histArt.Result.articleTab.length > 0"
                        class="material-icons-round text-orange-600 hover:text-orange-700 hover:animate-pulse"
                        style="font-size: 16px"
                        title="Ajouter au panier"
                        @click="
                          handlerArticle(
                            {
                              article: histArt.Result.articleTab[0].article,
                              stockData: [],
                            },
                            1
                          )
                        "
                      >
                        add_shopping_cart
                      </button>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>

            <!-- Deuxième pavé -->
            <div class="bg-beigevk rounded-lg p-2 lg:p-4">
              <router-link to="/historiquePlaque">
                <h5
                  class="font-medium text-bluevk w-full text-left hover:scale-110 duration-300 cursor-pointer"
                >
                  Historique Véhicules
                </h5>
              </router-link>
              <hr class="lg:my-1 border-t border-bluevk" />
              <div class="flex flex-col">
                <div
                  class="bg-white rounded-lg p-1 mt-1"
                  v-for="(histPla, index) in histPlaTab"
                  :key="index"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <img
                        src=""
                        alt=" "
                        class="bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 text-center"
                      />
                      <div v-if="!histPla.carData[0].error">
                        <div class="text-xs font-bold text-bluevk">
                          {{ histPla.carData[0].data.AWN_marque_carrosserie }} |
                          {{ histPla.carData[0].data.AWN_modele }}
                        </div>
                        <div
                          v-if="
                            histPla.carData[0].data
                              .AWN_date_mise_en_circulation &&
                            histPla.carData[0].data
                              .AWN_date_mise_en_circulation != ''
                          "
                          class="text-xs font-light text-gray-800"
                          title="Date mise en circulation"
                        >
                          Date mise en cir. :
                          {{
                            histPla.carData[0].data.AWN_date_mise_en_circulation
                          }}
                        </div>
                        <div v-else class="text-xs font-light text-gray-800">
                          Date mise en cir. : -
                        </div>
                        <div
                          v-if="histPla.plaque && histPla.plaque != ''"
                          class="text-xs font-medium text-orange-600"
                        >
                          {{ histPla.plaque.toUpperCase() }}
                        </div>
                        <div v-else class="text-xs font-medium text-orange-600">
                          -
                        </div>
                      </div>
                    </div>
                    <div class="flex space-x-1">
                      <button
                        v-if="
                          histPla.plaque &&
                          histPla.plaque != '' &&
                          !isLoadingPlaque
                        "
                        class="material-icons-round hover:text-gray-600 hover:animate-pulse"
                        style="font-size: 16px"
                        title="Reprendre la plaque"
                        @click="getPlaque(histPla.plaque)"
                      >
                        ads_click
                      </button>
                      <button
                        v-else-if="isLoadingPlaque"
                        class="material-icons-round hover:text-gray-600 animate-pulse"
                        style="font-size: 16px"
                        title="Reprendre la plaque"
                        @click="getPlaque(histPla.plaque)"
                      >
                        ads_click
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Commandes à réceptionner-md -->
        <!-- <div
        class="hidden w-full sm:hidden md:h-1/3 md:block lg:hidden bg-white border border-gray-200 rounded-lg shadow"
      >
        <div class="flex flex-col items-center m-14">
          <img
            src="../assets/dashboard/Reception.png"
            alt="Pictogramme commandes à réceptionner"
            class="w-40"
          />
          <h5
            class="text-2xl font-medium text-gray-400 w-full text-center mt-7 md:mt-3 lg:mt-7"
          >
            Réception
          </h5>
          <router-link to="/hubReceptionFournisseur">
            <div
              class="flex flex-col text-center pt-3 m hover:scale-110 duration-300 hover:text-gray-600"
            >
              <span class="text-bluevk font-bold text-6xl">{{
                commands.length
              }}</span>
            </div>
          </router-link>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import infosVehicule from "@/components/vehicule/infosVehicule.vue";
import { prepareArticle } from "@/hooks/searchEngine/articles";
import { useToast } from "vue-toastification";
import { getFrDate } from "@/hooks/tools/date.ts";
// import VueApexCharts from "vue3-apexcharts"
// import StatsCommandes from "@/components/Statistiques/StatsCommandes.vue"
// import StatsFactures from "@/components/Statistiques/StatsFactures.vue"
// import StatsBdl from "@/components/Statistiques/StatsBdl.vue"
// import PriceDay from "@/components/Statistiques/PriceDay.vue"
// import RadialProgressBar from "vue3-radial-progress";
// import { ref, defineComponent, watch } from "vue";
// import {
//   getTotalPriceAll,
// } from "@/hooks/comptoir/StatsPanier"

export default {
  name: "MyDashboard",
  /*   components: {
    StatsBdl,
    StatsCommandes,
    StatsFactures
    }, */
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      themes: [
        { name: "Immatriculation" },
        { name: "Vin | type mine" },
        { name: "Marque" },
      ],
      selectedTheme: null, // Pour stocker l'index du thème sélectionné
      isHovered1: Array(3).fill(false),
      isHovered: Array(3).fill(false), // Un tableau pour suivre l'état de survol
      isLoading: false,
      isLoadingPlaque: false,
      errorImmat: false,
      orders: [],
      orderInProgress: [],
      retourInProgress: null,
      consigneInProgress: null,
      orderFinished: [],
      commands: [],
      // totalPriceAllDay: 0,
      percent: 0,
      manuTab: [],
      modelTab: [],
      vehiculeTab: [],
      manuSelected: {},
      modelselected: "",
      showVehicleInfo: false,
      showVehicule: false,
      searchInput: "",
      plaque: "",
      openInfoVehicule: false,

      histArtTab: [],
      histPlaTab: [],
      countConditions: 0,
    };
  },
  components: {
    infosVehicule,
  },
  computed: {
    ...mapGetters([
      "vehicule",
      "vehiculeSet",
      "user",
      "comptoirSession",
      "comptoirSessionActive",
      "plateform",
      "actionArticle",
    ]),
    //  totalPriceDay() {
    //   return parseFloat(this.totalPriceAllDay);
    // },
    //  percentComplete() {
    //   const totalPrice = parseFloat(this.totalPriceAllDay);
    //   const francodeport = parseFloat(this.comptoirSessionActive[0].francodeport);
    //   const percent = (totalPrice / francodeport) * 100;
    //   return percent.toFixed(2)
    // },
    alphabet() {
      // Créez un tableau contenant l'alphabet
      return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    },
  },
  methods: {
    ...mapActions([
      "addvehicule",
      "clearvehicule",
      "getallcomptoirsession",
      "setSelectionCarId",
      "setSelectionCarData",
      "searchRefFournisseur",
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
      "savecomptoirsession",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    async getHistArt() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/savedSearch/getLastFourPlateform/${this.user.plateform}`
      );
      this.histArtTab = response.data;
    },
    async getHistPla() {
      const response = await axios.get(
        `${process.env.VUE_APP_API}/plaqueCRUD/getLastFourPlateform/${this.user.plateform}`
      );
      this.histPlaTab = response.data;
    },
    setHovered1(index, value) {
      // Met à jour l'état de survol pour l'élément donné
      this.isHovered1[index] = value;
    },
    setHovered(index, value) {
      // Met à jour l'état de survol pour l'élément donné
      this.isHovered[index] = value;
    },
    getEncartHeight(letter) {
      // Compter le nombre de produits commençant par la lettre
      const count = this.manuTab.filter((manuf) =>
        manuf.manuName.startsWith(letter)
      ).length;

      // Définir la hauteur minimale de l'encart (par exemple, 50 pixels)
      const minHeight = 50;

      // Calculer la hauteur en fonction du nombre de produits
      return `${minHeight + count * 30}px`; // Ajustez la valeur 30 selon vos besoins
    },
    selectTheme(index) {
      // Mettre à jour le thème sélectionné
      this.selectedTheme = index;
    },
    getOrders() {
      axios
        .get(
          `${process.env.VUE_APP_API}/commandesClient/${this.user.proprietaire}`
        )
        .then((response) => {
          this.orders = response.data;
          for (const order of this.orders) {
            if (order.status == "Commande et BL disponibles") {
              this.orderFinished.push(order);
            } else {
              this.orderInProgress.push(order);
            }
          }
          // console.log(response.data);
        });
    },
    getNbrRetours() {
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByOwner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.retourInProgress = response.data;
        });
    },
    getNbrConsignes() {
      axios
        .post(`${process.env.VUE_APP_API}/retourConsigne/getByOwner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.consigneInProgress = response.data;
        });
    },
    getCommands() {
      axios
        .get(
          `${process.env.VUE_APP_API}/hubReceptionFournisseur/waitingCommand/${this.user.proprietaire}`
        )
        .then((response) => {
          this.commands = response.data;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlaque(plaque) {
      this.clearvehicule();
      this.isLoadingPlaque = true;
      this.errorImmat = false;
      this.showVehicule = true;
      this.vehiculeSet = true;

      axios
        .post(`${process.env.VUE_APP_API}/plaque`, {
          plaque: plaque,
          Owner: this.user.proprietaire,
        })
        .then((response) => {
          // this.addvehicule(response.data);
          if (response.data.error) {
            console.log(response.data.message);
            this.errorImmat = true;
          } else {
            this.addvehicule(response.data);
            this.errorImmat = false;
            // this.$router.push("/tecdoc");
            this.plaque = "";
          }
          this.isLoadingPlaque = false;
        })
        .catch((error) => {
          console.log("erooooooooor");
          console.error(error);
          this.isLoadingPlaque = false;
        });
    },
    clearPlaque() {
      this.clearvehicule();
      this.showVehicule = false;
    },
    getVinMine() {
      console.log("getVinMine");
    },
    getManufacturer() {
      axios
        .get(`${process.env.VUE_APP_API}/catalogue/getManufacturer`)
        .then((res) => {
          this.manuTab = res.data;
        });
    },
    getModelSeries(manuId, manuName) {
      this.vehiculeTab = [];
      this.modelselected = "";
      this.manuSelected = { manuName: manuName, manuId: manuId };
      axios
        .post(`${process.env.VUE_APP_API}/catalogue/getModelSeries`, {
          manuId: manuId,
        })
        .then((res) => {
          this.modelTab = res.data;
        });
    },
    getVehicleIdsByCriteria(modId, modelname) {
      this.modelselected = modelname;
      axios
        .post(`${process.env.VUE_APP_API}/catalogue/getVehicleIdsByCriteria`, {
          manuId: this.manuSelected.manuId,
          modId: modId,
        })
        .then((res) => {
          this.vehiculeTab = res.data;
        });
    },
    getCar(carId) {
      this.setSelectionCarId({ carId: carId });
      this.setSelectionCarData({
        manuName: this.manuSelected.manuName,
        modelselected: this.modelselected,
      });
      this.$router.push("/catalogueHubNew");
    },
    deleteManu() {
      this.manuSelected.manuName = "";
      this.modelselected = "";
    },
    deleteModel() {
      this.modelselected = "";
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.comptoirSessionActive[0].dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id + "Consigne",
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        // if (this.comptoirSessionActive[0].Client[0].professionnel == true) {
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
      } else {
        if (
          this.comptoirSessionActive[0].Client[0].numcomptedasilva &&
          this.comptoirSessionActive[0].Client[0].numcomptedasilva != ""
        ) {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    async handlerArticle(structure, qtyToPanier) {
      var existArticle = false;
      //   await MisePanier(this.savedSearch, structure.article.Ref_fournisseur);
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) +
            parseInt(qtyToPanier);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticle(
          structure,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name,
          parseInt(qtyToPanier)
        );

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);

        this.refClient = this.noteDocument;

        this.savecomptoirsession({
          clientFocus: this.comptoirSessionActive[0].Client,
          refClient: this.comptoirSessionActive[0].refClient,
          consigneData: this.comptoirSessionActive[0].consigneData,
          consigneNonData: this.comptoirSessionActive[0].consigneNonData,
          dsvCheck: this.comptoirSessionActive[0].dsvCheck,
        });
      }
      this.success();
    },
    async countConditionToConfigure() {
      let getCond = await axios.post(
        `${process.env.VUE_APP_API}/conditionFournisseur/countConditionToConfigure`,
        {
          plateform: this.user.proprietaire,
        }
      );

      this.countConditions = getCond.data;
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  beforeMount() {
    this.getManufacturer();
  },
  async mounted() {
    this.getHistArt();
    this.getHistPla();
    this.getOrders();
    this.getNbrRetours();
    this.getNbrConsignes();
    this.getCommands();
    this.getallcomptoirsession();
    this.countConditionToConfigure();
    // this.totalPriceAllDay = await getTotalPriceAll(this.user.proprietaire, this.comptoirSessionActive[0].Client[0].Name)
  },
};
</script>
<style scoped>
.plate-immat {
  max-width: 353px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  --tw-border-opacity: 1;
  border: 1px solid rgb(45 116 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(45 116 218 / var(--tw-bg-opacity));
}

.vin-mine {
  max-width: 353px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  --tw-border-opacity: 1;
  border: 1px solid rgb(80 80 80 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
}

.plate-number {
  outline: 0;
  height: 100%;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgb(45 116 218 / var(--tw-border-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
}

.vinMine-number {
  outline: 0;
  height: 100%;
  width: 40%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}

.plate-flag {
  width: 2rem;
  text-align: center;
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.vin-mine {
  width: 2rem;
  text-align: center;
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-vk {
  background-color: #2262b3;
}

.text-vk {
  color: #ff914d;
}

@media screen {
  .xl {
    width: 1000px;
  }
}
</style>
