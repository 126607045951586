import axios from "axios";

export async function getFournisseurByID(fournisseurID: string | number, plateform: string) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getById`, {
        fournisseurID: fournisseurID,
        plateform: plateform,
    }
    );
    return response.data;
}