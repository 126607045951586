import axios from "axios";

export async function deleteAll(plateform: string, fournisseur: string) {
  const deleteAll = await axios.post(
    `${process.env.VUE_APP_API}/conditionFournisseur/deleteAllCondition`,
    {
      plateform: plateform,
      fournisseur: fournisseur,
    }
  );

  return deleteAll.data;
}

export async function updateConditionFromPlateform(
  plateform: string,
  marge: object,
  fournisseur: string
) {
  const condition = await axios.post(
    `${process.env.VUE_APP_API}/fournisseur/updateConditionFromPlateform`,
    {
      plateform: plateform,
      marge: marge,
      fournisseur: fournisseur,
    }
  );

  return condition.data;
}
