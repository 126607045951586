<template>
  <div
    v-if="openModalArticle"
    :class="`modal ${
      !openModalArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalArticle = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalArticle = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <article-info
          :key="articleInfo_componentKey"
          @checkCross="checkCross"
        />
      </div>
    </div>
  </div>
  <div
    id="retour"
    class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400 cursor-pointer"
    @click="$router.back()"
  >
    <img src="@/assets/RetourIcon.png" class="w-6" />
    <span style="color: #5976e8">Retour</span>
  </div>
  <div class="flex justify-between p-2 bg-white rounded shadow mt-2">
    <div class="flex flex-row items-center space-x-4">
      <div
        class="flex flex-row items-center space-x-4 bg-blue-100 text-blue-800 text-base font-medium me-2 px-2.5 py-0.5 rounded"
      >
        <span class="material-icons-round"> business </span
        ><span>{{ searchTab.Client }}</span>
      </div>
      <div
        class="flex flex-row items-center space-x-4 bg-blue-100 text-blue-800 text-base font-medium me-2 px-2.5 py-0.5 rounded"
      >
        <span class="material-icons-round"> person </span
        ><span>{{ searchTab.User }}</span>
      </div>
      <div
        v-if="searchTab.type == 'search'"
        :class="
          searchTab.Search == searchTab.Ref_Panier
            ? 'flex flex-row items-center space-x-3 bg-green-100 text-green-800 text-base font-medium me-2 px-2.5 py-0.5 rounded'
            : 'flex flex-row items-center space-x-3 bg-blue-100 text-blue-800 text-base font-medium me-2 px-2.5 py-0.5 rounded'
        "
      >
        <span class="material-icons-round"> search </span>
        <span>{{ searchTab.Search }}</span>
      </div>
      <div
        v-else-if="searchTab.type == 'catalogue_piece_F'"
        class="flex flex-row items-center space-x-3 bg-blue-100 text-blue-800 text-base font-medium me-2 px-2.5 py-0.5 rounded"
      >
        <span class="material-icons-round"> search </span>
        <span
          >{{ searchTab.Search_data[0].groupeName }} >
          {{ searchTab.Search_data[0].name }}</span
        >
      </div>
      <div
        v-else-if="searchTab.type == 'catalogue_piece_SF'"
        class="flex flex-row items-center space-x-3 bg-blue-100 text-blue-800 text-base font-medium me-2 px-2.5 py-0.5 rounded"
      >
        <span class="material-icons-round"> search </span>
        <span
          >{{ searchTab.Search_data[0].groupeName }} >
          {{ searchTab.Search_data[0].familleName }} >
          {{ searchTab.Search_data[0].name }}</span
        >
      </div>
    </div>

    <div
      v-if="
        searchTab.type == 'catalogue_piece_F' ||
        searchTab.type == 'catalogue_piece_SF'
      "
      class="flex flex-row items-center space-x-4"
    >
      <div v-for="(veh, indexVeh) in searchTab.Vehicule" :key="indexVeh">
        <div
          class="flex items-center border border-blue-300 rounded shadow p-1"
        >
          <img
            v-if="veh.vehicleImages.length > 0"
            :src="veh.vehicleImages[0].imageURL800"
            alt="visuel"
            class="w-1/3 h-10 md:h-10 bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 text-center"
          />
          <div class="w-2/3">
            <div class="text-xs font-bold text-bluevk">
              {{ veh.description }} | {{ veh.vehicleModelSeriesName }}
            </div>
            <div class="text-xs font-bold text-bluevk">
              {{ veh.mfrName }}
            </div>
            <div class="text-xs font-light text-gray-800">
              {{ veh.beginYearMonth }} / {{ veh.endYearMonth }}
            </div>
            <div class="text-xs font-medium text-orange-600">
              {{ veh.engineType }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex flex-col space-y-1 p-2 bg-white rounded shadow mt-2"
    v-if="searchTab.Mise_Panier"
  >
    <span
      class="bg-green-100 text-green-800 text-lg w-fit font-medium me-2 px-2.5 py-0.5 rounded"
      >Reference mise au panier</span
    >
    <div class="border border-green-500 rounded">
      <article-catalogue
        :key="searchTab.Info_Ref_Panier.dbArticle._id + reloadkey.toString()"
        :article="searchTab.Info_Ref_Panier.dbArticle"
        :Ref_fournisseur="searchTab.Info_Ref_Panier.dbArticle.Ref_fournisseur"
        :Code_marque="searchTab.Info_Ref_Panier.dbArticle.Code_marque"
        :Fournisseur="searchTab.Info_Ref_Panier.dbArticle.Prefixe_tarif"
        :genericArticleId="
          searchTab.Info_Ref_Panier.tdArticle.genericArticles[0]
            .genericArticleId
        "
        :Description="searchTab.Info_Ref_Panier.dbArticle.Description"
        :partsList="searchTab.Info_Ref_Panier.partsList"
        :Images="searchTab.Info_Ref_Panier.image"
        :Location="[]"
        :Systeme="[]"
        :Stock="searchTab.Info_Ref_Panier.stock"
        :Prix="searchTab.Info_Ref_Panier.prix"
        :quantity="searchTab.Info_Ref_Panier.quantity"
        :compareTabLength="compareTab.length"
        @infoArticle="plusInfo(searchTab.Info_Ref_Panier.dbArticle)"
        @checkCross="checkCross"
        :partsListTab="localpartsListTab"
        @displayPartsList="displayPartsList"
        @deletePartsList="deletePartsList"
      />
    </div>
  </div>
  <div
    class="flex flex-col space-y-1 p-2 bg-white rounded shadow mt-2 max-h-[60vh] overflow-y-auto"
    v-if="searchTab.Result"
  >
    <span
      class="bg-blue-100 text-blue-800 text-lg w-fit font-medium me-2 px-2.5 py-0.5 rounded"
      >Resultat</span
    >
    <div
      class="space-y-2"
      v-for="(famille, indexFamille) in searchTab.Result.data"
      :key="indexFamille"
    >
      <section-title
        :titre="famille.designation"
        @click="famille.show = !famille.show"
      />
      <div
        v-for="(article, indexArticle) in famille.data"
        :key="article.dbArticle._id + reloadkey.toString()"
        :class="
          article.dbArticle.Ref_fournisseur == searchTab.Ref_Panier
            ? 'border border-green-500 rounded'
            : ''
        "
      >
        <article-catalogue
          v-show="famille.show"
          :article="article.dbArticle"
          :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
          :Code_marque="article.dbArticle.Code_marque"
          :Fournisseur="article.dbArticle.Prefixe_tarif"
          :genericArticleId="
            article.tdArticle.genericArticles[0].genericArticleId
          "
          :Description="article.dbArticle.Description"
          :partsList="article.partsList"
          :Images="article.image"
          :Location="[]"
          :Systeme="[]"
          :Stock="article.stock"
          :Prix="article.prix"
          :quantity="article.quantity"
          @checkedArticle="checkArticle(indexArticle, indexFamille)"
          :compareTabLength="compareTab.length"
          @infoArticle="plusInfo(article.dbArticle)"
          @checkCross="checkCross"
          :partsListTab="localpartsListTab"
          @displayPartsList="displayPartsList"
          @deletePartsList="deletePartsList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getById } from "@/hooks/statistiques/savedSearch.ts";
import articleCatalogue from "@/components/Article/articleCatalogue.vue";
import articleInfo from "@/components/articleInfo/articleInfo.vue";
import sectionTitle from "@/components/Text/SectionTitle.vue";
export default {
  data() {
    return {
      searchTab: [],
      series: [],
      localpartsListTab: [],
      openModalArticle: false,
      articleInfo_componentKey: 0,
      compareTab: [],
      reloadkey: 0,
    };
  },
  components: {
    articleCatalogue,
    articleInfo,
    sectionTitle,
  },
  methods: {
    ...mapActions(["setArticleInfo", "searchArticleSim"]),
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    displayPartsList(partsList) {
      this.localpartsListTab.push(partsList);
    },
    deletePartsList(index) {
      this.localpartsListTab.splice(index, 1);
    },
    checkCross() {
      if (this.$route.path == "/searchArticleExact") {
        this.openModalArticle = false;
        this.searchArticleSim();
      }
    },
  },
  async mounted() {
    this.searchTab = await getById(this.$route.params.searchID);
  },
};
</script>
